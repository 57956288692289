import React, {useState} from 'react';
import NearbyResults from './NearbyResults';
import RecentlyViewed from './RecentlyViewed';
import RecentSearchResults from './RecentSearchResults';
import { initAlgoliaAnalytics } from '../../Search/helpers/initAlgolia';
import { getItems } from '../../Search/helpers/localStorage';
import { SearchStateIndexProvider } from '../../Search/hooks/useSearchStateIndex';
import { SearchStateHitsProvider } from '../../Search/hooks/useSearchStateHits';
import { SearchStateHitCountProvider } from './hooks/useSearchStateHitCounts';

initAlgoliaAnalytics();

const SelectSearchResults = ( { neighborhood_radius, home_radius } ) => {
	const recently_viewed = getItems( ['home_views', 'neighborhood_views'] );
	const recent_search = getItems( ['state', 'submarket'] );
	const [ hasRecentlyViewedResults, setHasRecentlyViewedResults ] = useState(recently_viewed.neighborhood_views || recently_viewed.home_views);
	const [ hasNearbyResults, setHasNearbyResults ] = useState(neighborhood_radius || home_radius);

	return (
		<div className={'tw-px-[20px] sm:tw-px-[50px] lg:tw-px-[70px]'}>
			<SearchStateIndexProvider>
				<SearchStateHitsProvider>
					<SearchStateHitCountProvider>
						{hasRecentlyViewedResults && (
						<RecentlyViewed
							neighborhood_objects={recently_viewed.neighborhood_views || []}
							home_objects={recently_viewed.home_views || []}
							has_results={(value) => {
								setHasRecentlyViewedResults(value);
							}}
						/>)}
					</SearchStateHitCountProvider>
					<SearchStateHitCountProvider>
						{!hasRecentlyViewedResults && hasNearbyResults && (
						<NearbyResults
							neighborhood_radius={neighborhood_radius}
							home_radius={home_radius}
							has_results={(value) => {
								setHasNearbyResults(value);
							}}
						/>)}
					</SearchStateHitCountProvider>
					<SearchStateHitCountProvider>
						{!hasRecentlyViewedResults && !hasNearbyResults && (
						<RecentSearchResults
							state={recent_search.state}
							submarket={recent_search.submarket}
						/>)}
					</SearchStateHitCountProvider>
				</SearchStateHitsProvider>
			</SearchStateIndexProvider>
		</div>
	);
};

export default SelectSearchResults;