import React, { createContext, useContext, useReducer } from 'react';
import logger from 'use-reducer-logger';
import config from '../config/config';

// Keep track of active index toggle

const SearchStateIndexContext = createContext( null );

const SearchStateIndexDispatchContext = createContext( null );

export function SearchStateIndexProvider( { children } ) {
	const [ searchStateIndex, dispatch ] = useReducer(
		process.env.NODE_ENV === 'development' ? logger( searchStateIndexReducer ) : searchStateIndexReducer,
		initialState
	);

	return (
		<SearchStateIndexContext.Provider value={searchStateIndex}>
			<SearchStateIndexDispatchContext.Provider value={dispatch}>
				{children}
			</SearchStateIndexDispatchContext.Provider>
		</SearchStateIndexContext.Provider>
	);
}

export function useSearchStateIndex() {
	return useContext( SearchStateIndexContext );
}

export function useSearchStateIndexDispatch() {
	return useContext( SearchStateIndexDispatchContext );
}

function searchStateIndexReducer( existingState, action ) {
	switch ( action.type ) {
		case 'changed': {
			const index = `${ config.ALGOLA_ENV }_${ action.index }`;
			window.algoliaSearchState.index = index;
			return {
				activeIndexName: index,
			};
		}
		default: {
			throw Error( 'Unknown action: ' + action.type );
		}
	}
}

const initialState = {
	activeIndexName: null,
};

if ( ! window.algoliaSearchState ) {
	window.algoliaSearchState = {};
}
window.algoliaSearchState.index = initialState.activeIndexName;

