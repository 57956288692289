import React from 'react';
import useSearchSubmarkets from '../Search/hooks/useSearchSubmarkets';
import config from '../Search/config/config';

const SubmarketList = () => {
    const { submarketsByState } = useSearchSubmarkets();

    if (submarketsByState.length === 0) {
        return null;
    }

    return (
        <>
            {submarketsByState.map((state) => {
                const link = state.override_search
                    ? state.override_search_link
                    : `/${config.DEFAULT_SEARCH_URL_PATH}${state.alpha_2_code.toLowerCase()}`;
                if (state.submarkets.length === 0 && !state.override_search) {
                    return null;
                }
                return (
                    <ul key={state.slug} className="tw-pl-0 tw-list-none tw-pr-2">
                        <li>
                            <a
                                href={link}
                                className={`hover:tw-underline hover:tw-text-white tw-underline-offset-4 tw-px-2 tw-w-full tw-font-bold tw-mb-2 tw-py-1 tw-text-base tw-text-white tw-flex tw-items-center`}
                            >
                                {state.name}
                            </a>
                        </li>
                        <li>
                            <ul className="tw-pl-0 tw-list-none tw-ml-0">
                                {state.submarkets.map((submarket) => {
                                    const link = state.override_search
                                        ? state.override_search_link
                                        : `/${config.DEFAULT_SEARCH_URL_PATH}${state.alpha_2_code.toLowerCase()}/${submarket.slug}`
                                    return (
                                        <li className="mb-2" key={submarket.name}>
                                            <div>
                                                <a
                                                    href={link}
                                                    className={`hover:tw-underline tw-font-medium tw-underline-offset-4 tw-px-2 tw-py-1 tw-w-full tw-cursor-pointer tw-text-sm tw-text-apple tw-flex tw-items-center`}
                                                >
                                                    {submarket.name}
                                                </a>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                    </ul>
                );
            })}
        </>
    );
};

export default SubmarketList;
