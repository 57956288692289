import React from 'react';
import { useEffect}  from 'react';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from "jquery";

const PromoCards = (props) => {

   
    

    let cards = props.cards;
    const card_options = {
        loop: false,
        nav: true,
        navText: [
            '<div class="sliderArrow arrowLeft"></div>',
            '<div class="sliderArrow arrowRight"></div>'
        ],
        dots: true,
        margin: 0,
        autoplay: false,
        startPosition: 0,
        stagePadding:0,
        responsive: {
            0: {
                items: 1,
                margin: 20,
            },
            667 : {
                items: 2,
            },
            991: {
                items: 4,
                margin: 20
            },
            1601: {
                items: 4,
                margin : 10,
            }
            
        }
    }
    var window_width = window.screen.width;
    useEffect(() => {
        var review_content = []; var plan_div = $(document).find(".card_box"); plan_div.each(function () { review_content.push($(this).find(".promo_card_title").outerHeight()); }); var card_maxheight = Math.max.apply(Math, review_content); var title_cards = document.querySelectorAll('.promo_card_title'); title_cards.forEach(items =>{ items.style.height  = card_maxheight +'px'; })
        var promo_content_height = []; var promo_card_div = $(document).find(".card_box"); promo_card_div.each(function () { promo_content_height.push($(this).find(".promo_card_description").outerHeight()); }); var card_promo_maxheight = Math.max.apply(Math, promo_content_height); var promo_cards = document.querySelectorAll('.promo_card_description'); promo_cards.forEach(items =>{ items.style.height  = card_promo_maxheight +'px'; })
    }, [cards])
  return (
    <>
        {   cards !='' ?
            cards.length <= 4 && window_width > 480 ? (
                <div className="promo_cards">
                    {cards.map((item)=>{
                    return(
                        item.promo_card_title ?
                            <div className="card_box col-lg-3" style={{ padding: '2%' }}>
                                <div className="promo_card_title">
                                {item.promo_card_title}
                                </div>
                                <p className='promo_card_description'>
                                {item.promo_card_desc}
                                </p>
                                <a href={item.promo_url} className='promo_card_btn'>Learn More</a>
                            </div>
                        : ''
                    )
                    })} 
                </div>
            ):( cards.length > 4 && window_width > 480 ? (
            <div className="promo_cards">
            <OwlCarousel {...card_options} className="owl-theme">
            {cards.map((item)=>{
                return(
                    item.promo_card_title ?
                <div className="card_box">
                    <div className="promo_card_title">
                    {item.promo_card_title}
                    </div>
                    <p className='promo_card_description'>
                    {item.promo_card_desc}
                    </p>
                    <a href={item.promo_url} className='promo_card_btn'>Learn More</a>
                </div>
                : ''
                )
            })} 
            </OwlCarousel>
            </div>) : (
                cards.length > 1 && window_width <= 480 ? (
                    <div className="promo_cards">
            <OwlCarousel {...card_options} className="owl-theme">
            {cards.map((item)=>{
                return(
                    item.promo_card_title ?
                <div className="card_box">
                    <div className="promo_card_title">
                    {item.promo_card_title}
                    </div>
                    <p className='promo_card_description'>
                    {item.promo_card_desc}
                    </p>
                    <a href={item.promo_url} className='promo_card_btn'>Learn More</a>
                </div>:'')
            })} 
            </OwlCarousel>
            </div>
                ) : (
                    <div className="promo_cards">
                    {cards.map((item)=>{
                    return(
                        item.promo_card_title ? 
                    <div className="card_box col-lg-3" style={{ padding: '2%' }}>
                        <div className="promo_card_title">
                        {item.promo_card_title}
                        </div>
                        <p className='promo_card_description'>
                        {item.promo_card_desc}
                        </p>
                        <a href={item.promo_url} className='promo_card_btn'>Learn More</a>
                    </div>:'')
                    })} 
                </div>
                )
            )
                
            )
            
            : <p className="noPromo">Promotion Starting Soon – Please Check Back</p>
        }
    </>
  )
}

export default PromoCards
