import React from 'react';
import HeadingSection from './HeadingSection';
import { Link } from 'react-router-dom'

class MPC extends React.Component {  

  render() {    
    let bg_color ={backgroundColor: '#8c8c8c'};
    var mpc_block = this.props.mpc_block;
    let result = this.props.community_details && this.props.community_details.planned_comm;
    let p_comm = result ? result : mpc_block.mpc_posts_data ;
    // console.log('planned_comm',p_comm);
     return (  
      p_comm.length > 0  &&
           <div className='Mpc_main_section spring-section' style={mpc_block.background_color?{backgroundColor: mpc_block.background_color}:bg_color}>
              <HeadingSection
                subtitle='' 
                title_part_1={mpc_block.mpc_title !='' ? mpc_block.mpc_title:'Planned Communities <br> Near'}
                title_part_2={mpc_block.mpc_title !='' ?'': mpc_block.mpc_city_abb}
                headline_part_1_color={mpc_block.text_color}
                headline_part_2_color={mpc_block.text_color}
                headline_size={mpc_block.hasOwnProperty('headline_size') ? mpc_block.headline_size : 'large'}
              />
              {p_comm.length > 0 ? (
             <ul className='mpc-list title-info'>
               {p_comm.map(items => (
               <li style={{color: mpc_block.text_color, textDecoration: 'none'}}>▸ <a  href={items.permalink} style={{color: mpc_block.text_color, textDecoration: 'none'}} target="_blank">{items.title}</a></li>
               ))}
             </ul>):('')}
           </div>
               
     );  
     
  }  
  
}  


export default MPC;