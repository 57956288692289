import React from 'react';
import { getAutocompleteHitUrl } from './helpers';
import useNearbySubmarkets from '../Search/hooks/useNearbySubmarkets';
import useSearchSubmarkets from '../Search/hooks/useSearchSubmarkets';
import AutocompleteCurrentPositionHit from './AutocompleteCurrentPositionHit';

const getUrl = ( item ) => {
	return getAutocompleteHitUrl( { stateAbbr: item.state.alpha_2_code.toLowerCase(), submarketSlug: item.slug } );
};

export const AutocompleteNoResults = ( ) => {
	const { submarketsByState } = useSearchSubmarkets();
	const { submarketsByDistance } = useNearbySubmarkets( );

	const states = submarketsByState.map( ( oneState ) => {
		return { name: oneState.name, abbr: oneState.alpha_2_code };
	} );

	return (
		<div className="aa-PanelLayout aa-Panel--scrollable">
			<div className="tw-pb-4 tw-pt-3 tw-pl-2">No results found. Try selecting from below.</div>
			<div>
				<span className="tw-border-t tw-block tw-border-slate tw-opacity-30 tw-mr-2"></span>
				<div className="tw-pt-3">
					<AutocompleteCurrentPositionHit />
				</div>
			</div>
			<div className="aa-SourceHeader"><span className="aa-SourceHeaderTitle">States</span><span className="aa-SourceHeaderLine"></span></div>
			<ul className='aa-List'>
				{states.map( ( oneState ) => (
					<li key={`search-state-${ oneState.abbr }`} className="aa-Item !tw-p-0">
						<a className='tw-text-charcoal hover:tw-no-underline hover:tw-text-charcoal hover:tw-bg-platinum/60 tw-py-3 tw-px-1' href={getAutocompleteHitUrl( { stateAbbr: oneState.abbr.toLowerCase() } )}>{oneState.name}</a>
					</li>
				) )}
			</ul>
		</div>
	);
};

export default AutocompleteNoResults;
