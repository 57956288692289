import React from 'react';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import useSearchSubmarkets from '../Search/hooks/useSearchSubmarkets';
import { useMobileMediaQuery } from '../Search/hooks/useResponsive';
import useNearbySubmarkets from '../Search/hooks/useNearbySubmarkets';

const AutocompleteContainer = () => {
	const isMobile = useMobileMediaQuery();
	const { submarketsByState } = useSearchSubmarkets();
	const { submarketsByDistance } = useNearbySubmarkets( { submarketsByState } );

	// need submarkets to create plugins
	if ( submarketsByState.length === 0 ) {
		return null;
	}

	return (
		<div>
			<Autocomplete
				submarketsByState={submarketsByState}
				submarketsByDistance={submarketsByDistance}
				placeholder={`${ isMobile ? ' Search by state or city' : 'Search by state, city, metro, or zip code' }`}
				detachedMediaQuery="none"
				openOnFocus
			/>
		</div>
	);
};

export default AutocompleteContainer;
