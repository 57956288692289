import React from 'react'
import fancybox from '@fancyapps/fancybox' // eslint-disable-line no-unused-vars
import './UTour.scss';
import $ from 'jquery';
import cookie from 'react-cookies';
import { Modal } from 'react-bootstrap';
import ScheduleTourForm from './ScheduleTourForm.jsx';
import StageScheduleTourForm from './StageScheduleTourForm.jsx';
import { Link } from 'react-router-dom';

class UTour extends React.Component {
    constructor() {
        super();
        this.state = {
            showHide: false,
            innerModal: ''
        }
    }
    handleModalShowHide() {
        this.setState({ showHide: !this.state.showHide })
    }
    updateInnerModal(str) {
        // if (this.state.innerModal === 'schedule_tour' && !str) { this.setState({ showHide: false }) }
        this.setState({ innerModal: str })
    }
    render() {
        var thumbnail_image = this.props.UTourDetails.tour_post_thumbnail;
        var tour_post_title = this.props.UTourDetails.title;
        var uTourData = this.props.UTourDetails;
        var division_name = this.props.division_name;
        var thumbnail_alt=this.props.UTourDetails.tour_post_thumbnail_alt;
        var thumbnail_title=this.props.UTourDetails.tour_post_thumbnail_title;
        var current_url = window.location.host;
        if (current_url === 'tripointedev.wpengine.com' || current_url === 'tripointestage.wpengine.com') {
            var stageForm = true;
        }else{
            var stageForm = false;
        }
        return (
            <div id="UTour">
                <div className="Utourpop_form">
                    <div className="Uform-column">
                        <div className="Utour-header">
                            <div className="Brandlogo">
                              <Link to={'/'} className="Brandlogo-anchor" title="Tri Pointe Homes" alt="Tri Pointe Homes">
                                    <img src={uTourData.brandLogo} alt="Tri Pointe Homes" title="Tri Pointe Homes" tabindex="0" />
                                </Link>
                                {tour_post_title && <div className="headtxt">{tour_post_title}</div>}
                            </div>
                        </div>
                        <div className="UTour-leftSection">
                            {uTourData.utour_heading && <div className="UTour-Heading" dangerouslySetInnerHTML={{ __html: uTourData.utour_heading.replace(/(?:\r\n|\r|\n)/g, '') }}></div>}
                            {uTourData.utour_first_sub_heading && <div className="UTour-SUBHeading">{uTourData.utour_first_sub_heading}</div>}
                            {uTourData.utour_first_description && <div className="UTour-Description" dangerouslySetInnerHTML={{ __html: uTourData.utour_first_description.replace(/(?:\r\n|\r|\n)/g, '') }}></div>}
                            {uTourData.utour_first_btn && <a className="Utour-btn" href="javascript:void(0)" title="view all" onClick={() => this.handleModalShowHide()}>{uTourData.utour_first_btn}</a>}
                            {uTourData.utour_second_sub_heading && <div className="UTour-SUBHeading home_advisor">{uTourData.utour_second_sub_heading}</div>}
                            {uTourData.utour_second_description && <div className="UTour-Description" dangerouslySetInnerHTML={{ __html: uTourData.utour_second_description.replace(/(?:\r\n|\r|\n)/g, '') }}></div>}
                            {uTourData.utour_second_btn && <a className="Utour-btn" href="javascript:void(0)" onClick={() => this.updateInnerModal('schedule_tour')}
                                title="Schedule a Tour" id="tour_click_btn">{uTourData.utour_second_btn}</a>}

                            <Modal className="Utour-schedule schedule-Modal tph-ui-modal" show={this.state.innerModal === 'schedule_tour'}>
                                <Modal.Header closeButton onClick={() => this.updateInnerModal('')}>
                                </Modal.Header>
                                <Modal.Body>
                                {stageForm ? <StageScheduleTourForm form_details={uTourData} division_name={division_name} /> :<ScheduleTourForm form_details={uTourData} division_name={division_name} />}
                                </Modal.Body>
                            </Modal>

                            <Modal className="Utour-screen" show={this.state.showHide}>
                                <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                                    <Modal.Title>
                                        <h2 tabindex="0">Book Your Self-Guided Tour</h2>
                                        <p tabindex="0">VISIT US TODAY</p>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {uTourData.utour_page_url && <iframe tabindex="0" src={uTourData.utour_page_url} className="sitemap_frame"></iframe>}
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="Uform-image">
                        <div className="form_image_overlay" />
                        <img src={thumbnail_image} alt={thumbnail_alt ? thumbnail_alt :  thumbnail_title} title={ thumbnail_title} />
                        <div className="utour-image-text">
                            {uTourData.tour_image_heading && <h2 className="utour-image-heading" dangerouslySetInnerHTML={{ __html: uTourData.tour_image_heading.replace(/(?:\r\n|\r|\n)/g, '') }} />}
                            {uTourData.tour_image_description && <h4 className="utour-image-description" dangerouslySetInnerHTML={{ __html: uTourData.tour_image_description.replace(/(?:\r\n|\r|\n)/g, '') }} />}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UTour;