import * as React from 'react';
import { twMerge } from 'tailwind-merge'
import IconDesignerCollection from './icons/IconDesignerCollection';

export default function DesignerCollectionBadge({ children, className }) {
  return (
    <>
      <IconDesignerCollection className="tw-cursor-pointer" />
      <div
        className={twMerge(
          "tw-hidden md:tw-block",
          "tw-absolute tw-translate-x-[calc(-50%+16px)] tw-translate-y-2 tw-w-max",
          "tw-invisible tw-opacity-0 group-hover/dc:tw-visible group-hover/dc:tw-opacity-100 tw-transition-opacity tw-duration-500",
          "tw-px-3 tw-py-1 tw-shadow tw-text-xxs tw-bg-white",
          className
        )}
      >
        {children}
        <div className='tw-absolute tw-left-[calc(50%-8px)] -tw-top-[8px] tw-border-solid tw-border-b-white tw-border-b-8 tw-border-x-transparent tw-border-x-8 tw-border-t-0'></div>
      </div>
    </>
  )
}