import axios from 'axios'
import $ from 'jquery'
import React from 'react'
import cookie from 'react-cookies'
import { apiConfig } from './Helper.jsx'

// Components
import './AvatarCard.scss'; // New Home Specialist Avatar Card + Modal
import './LargeCopy.scss'; // Large intro copy with address + hours

// button / modal
import { Modal } from 'react-bootstrap'
import getFormBtnCopy from '../../helpers/getFormBtnCopy.js'
import QmiReserveForm from './QmiReserveForm.jsx'
import ScheduleTourForm from './ScheduleTourForm.jsx'
import SidebarSignUp from './SidebarSignUp.jsx'
import SidebarSignUpNewForm from './SidebarSignUpNewForm.jsx'
import StageQmiReserveForm from './StageQmiReserveForm.jsx'
import StageScheduleTourForm from './StageScheduleTourForm.jsx'
import StageSidebarSignUp from './StageSidebarSignUp.jsx'
import StageSidebarSignUpNewForm from './StageSidebarSignUpNewForm.jsx'
import StageTPCForm from './StageTPCForm.jsx'
import TPCPriorityGroupForm from './TPCPriorityGroupForm.jsx'
import UTour from './UTour.jsx'

class LargeCopyWithDetails extends React.Component {
  constructor() {
    super()

    this.state = {
      show: null,
      showHide: false,
      innerModal:'',
      formvisible:true,
      tourvisible:true,
      utourtpc:true,
      utourtour:true,
      reserveform:true,
    }

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  updateInnerModal(str) {
    this.setState({innerModal: str})
    this.setState({utourtpc: false})
    this.setState({utourtour: true})
  }

  updateInnerModal1(str) {
    this.setState({innerModal: str})
    this.setState({utourtpc :true})
    this.setState({utourtour :false})
  }

  handleClose() {
    this.setState({show: null})
  }

  handleShow(id) {
    this.setState({show: id})
  }

  handleModalShowHide() {
    this.setState({showHide: !this.state.showHide})
    this.setState({formvisible: false})
    this.setState({tourvisible: true})
    this.setState({reserveform: false})
    this.setState({utourtour: true})
  }

  handleModalShowHide1() {
    this.setState({showHide: !this.state.showHide})
    this.setState({formvisible: true})
    this.setState({reserveform: true})
    this.setState({utourtour: false})
  }

  handleModalShowHide2() {
    this.setState({tourvisible: false})
  }

  handleModalShowHide3() {
    this.setState({utourtour: false})
    this.setState({reserveform: true})
    this.setState({formvisible: true})
  }

  componentDidMount() {
    window.onhashchange = function(e) {
      const oldURL = e.oldURL
      const newURL = e.newURL
      const oldHash = newURL.split(oldURL)

      if (oldHash[1] === "#tour-form") {
        window.location.href = oldURL + '?tour-form'
      }

      window.lazy.update()
    }

    if (window.location.href.indexOf("#interest-form") > -1) {
      const $interestBtn = document.getElementById("interest_click_btn")
      if ($interestBtn) $interestBtn.click()
    }

    if (window.location.href.indexOf("?tour-form") > -1 ) {
      document.getElementById("tour_click_btn").click()
    }

    if (window.location.href.indexOf("#tour-form") > -1 ){
      document.getElementById("tour_click_btn").click()
    }
    //TpcPriorityGroup Form
    if(window.location.href.indexOf("#priority_group_form")> -1 ){
      if(document.getElementById("tour_click_btn_show")){
        document.getElementById("tour_click_btn_show").click();
      }
    }
   
    if (window.location.href.indexOf("#priority-group-form")> -1 ) {
        if(document.getElementById("tour_click_btn_show")){
            document.getElementById("tour_click_btn_show").click();
        }
    }
    
    if (this.props.data.tpc_priority_group_form?.group_date) {
        const now = new Date();
        const threshold = new Date(now.getTime() + 10 * 60000);
        const launch = new Date(this.props.data.tpc_priority_group_form.group_date * 1000);
        if (launch > now && launch < threshold) {
            const diff = launch.getTime() - now.getTime() + 50;
            console.log(`${diff} ms`); 
            setTimeout(() => {
                this.setState({ pg_live: true });
            }, diff);
        }
    }
  }

  handleSelect() {
    if ($('.owl-item').hasClass('active')) {
      const iframe = $('.owl-item.active .hero_iframe')

      if (iframe[0]) {
        const src = $(iframe).attr('src')

        $(iframe).attr('src', src)
      }
    }
  }

  fetch_data_for_activity() {
    return new Promise((resolve, reject)=>{
      axios.post(apiConfig().API_URL + '/get_marketo_lead_data/',{
        'get_type': "Activity",
        'activity_type' : "GetDirection",
        'cookie' : cookie.load('_mkto_trk'),
        'URL' : window.location.href,
        'value' : 'true'
      })
      .then(response => response)
      .then(data => resolve(data))
      .catch(error => reject(error))
    });
  }

  direction_data_layer_push(param) {
    this.setState({ value: param })

    if (param && param==='drivingDirections') {
      window.dataLayer.push({'event':'drivingDirections'});
      var mkto_confirm = cookie.load('_mkto_trk');
      if (mkto_confirm) {
        this.fetch_data_for_activity()
        .then( data => console.log(data))
        .catch( error => console.error(error))
      }
    }
  }

  getFormsBtn() {
    const community_details = this.props.data
    const posttype = this.props.posttype
    const tpc_form_btn = community_details.tpc_priority_group_form && community_details.tpc_priority_group_form.show_btn
    let tpc = false
    let rth = false
    let sat = false
    let it = false
    let stageForm = false;
    const current_url = window.location.host;

    if (current_url === 'tripointedev.wpengine.com' || current_url === 'tripointestage.wpengine.com') {
        stageForm = true;
    }
    
    if (posttype === "qmi") {
      for (var i = 0; i < 2; i++) {
        if (community_details.active_form[i] == 'tpc_priority') {
          tpc = true
        } else if (community_details.active_form[i] == 'reserve_this_home') {
          rth = true
        } else if (community_details.active_form[i] == 'schedule_a_tour') {
          sat = true
        } else if (community_details.active_form[i] == 'interest_list') {
          it = true
        }
      }

      return <div className='twoform'>
        {tpc &&
          <React.Fragment>
            <a className="button full-width margin-vertical max-width"
              style={{
                '--margin-vertical-bottom-desktop': '0',
                '--margin-vertical-bottom-mobile': '0',
                '--margin-vertical-top-desktop': '47px',
                '--margin-vertical-top-mobile': '36px',
                '--max-width': '337px',
              }}
              href="#tour_form"
              id="tour_click_btn_show"
              data-fancybox="priority_group_form"
              title="JOIN THE PRIORITY GROUP"
              data-src="#tour_form"
              onClick={()=>{
                window.dataLayer.push({'event':'preQualificationFormClick'});
              }}>
              <span>Get pre-qualified</span>
            </a>

            {stageForm ? <StageTPCForm tpc_form_details={community_details.tpc_priority_group_form}
              division_name={community_details.division_name}/> : <TPCPriorityGroupForm
              tpc_form_details={community_details.tpc_priority_group_form}
              division_name={community_details.division_name}
            />}
          </React.Fragment>
        }

        {sat && [(community_details.tour_details.active_utour && community_details.tour_details.utour_page_url) ?
          <React.Fragment>
            <a className="button full-width margin-vertical max-width"
              style={{
                '--margin-vertical-bottom-desktop': '0',
                '--margin-vertical-bottom-mobile': '0',
                '--margin-vertical-top-desktop': '47px',
                '--margin-vertical-top-mobile': '36px',
                '--max-width': '337px',
              }}
              href="javascript:void(0)"
              onClick={() => {this.updateInnerModal('tour_guide')
              window.dataLayer.push({'event':'scheduleTourFormClick'});
              }}>
              <span>Schedule a Tour</span>
            </a>

            {this.state.utourtour ? <Modal className="Utour-screen tph-ui-modal" show={this.state.innerModal==='tour_guide'}>
              <Modal.Header closeButton onClick={() =>  this.updateInnerModal1('')}></Modal.Header>
              <Modal.Body>
                <UTour UTourDetails={community_details.tour_details} division_name={community_details.division_name} />
              </Modal.Body>
            </Modal> : null }
          </React.Fragment>
            :
          <React.Fragment>
            <a className="button full-width margin-vertical max-width"
              style={{
                '--margin-vertical-bottom-desktop': '0',
                '--margin-vertical-bottom-mobile': '0',
                '--margin-vertical-top-desktop': '47px',
                '--margin-vertical-top-mobile': '36px',
                '--max-width': '337px',
              }}
              href="javascript:void(0)"
              onClick={() => {
              this.handleModalShowHide();
              window.dataLayer.push({'event':'scheduleTourFormClick'});
              }}>
              <span>Schedule a Tour</span>
            </a>

            <Modal className="Utour-screen schedule-Modal tph-ui-modal" show={this.state.showHide}>
              <Modal.Header closeButton onClick={() => this.handleModalShowHide1()}></Modal.Header>
              <Modal.Body>
              {this.state.utourtour ? stageForm ? <StageScheduleTourForm form_details={community_details.tour_details} division_name={community_details.division_name}/> : <ScheduleTourForm form_details={community_details.tour_details} division_name={community_details.division_name} />:null }
              </Modal.Body>
            </Modal>
          </React.Fragment>]
        }

        {rth &&
          <React.Fragment>
            <a className="button full-width margin-vertical max-width"
              style={{
                '--margin-vertical-bottom-desktop': '0',
                '--margin-vertical-bottom-mobile': '0',
                '--margin-vertical-top-desktop': '47px',
                '--margin-vertical-top-mobile': '36px',
                '--max-width': '337px',
              }}
              data-fancybox="tour_forms"
              data-src="#tour_forms"
              href="#tour_forms"
              id="tour_click_btns"
              onClick={() => this.handleModalShowHide3()}>
              <span>{community_details.schedule_button_text}</span>
            </a>

            {this.state.reserveform ? stageForm ? <StageQmiReserveForm form_details={community_details.tour_details} agent_name={community_details.agent_info} division_name={community_details.division_name} homesiteNumber={community_details.homesite_number} /> :<QmiReserveForm form_details={community_details.tour_details} agent_name={community_details.agent_info} division_name={community_details.division_name} homesiteNumber={community_details.homesite_number} /> : null}
          </React.Fragment>
        }

        {it &&
          <React.Fragment>
            <a className="button full-width margin-vertical max-width"
              style={{
                '--margin-vertical-bottom-desktop': '0',
                '--margin-vertical-bottom-mobile': '0',
                '--margin-vertical-top-desktop': '47px',
                '--margin-vertical-top-mobile': '36px',
                '--max-width': '337px',
              }}
              data-fancybox="tour_forms"
              href="javascript:void(0)"
              id="interest_click_btn"
              onClick={() => this.handleShow("Request_quote")}>
              <span>{community_details.interest_list_overrride?community_details.interest_list_overrride:'Join The Interest List'}</span>
            </a>

            <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                {community_details.neigh_id !='85782' ? (
                  stageForm ? <StageSidebarSignUp sidebar_data={community_details.sidebar_form} communityOfInterest={community_details.communityOfInterest} nbd_title={community_details.neigh_title} division_name={community_details.division_name}/> :
                  <SidebarSignUp sidebar_data={community_details.sidebar_form} communityOfInterest={community_details.communityOfInterest} nbd_title={community_details.neigh_title} division_name={community_details.division_name} />
                ):(
                  stageForm ? 
                  <StageSidebarSignUpNewForm sidebar_data={community_details.sidebar_form} communityOfInterest={community_details.communityOfInterest} nbd_title={community_details.neigh_title} division_name={community_details.division_name} post_data={community_details.post_id}/>
                  :
                  <SidebarSignUpNewForm sidebar_data={community_details.sidebar_form} communityOfInterest={community_details.communityOfInterest} nbd_title={community_details.neigh_title} division_name={community_details.division_name}
                    post_data={community_details.post_id} />
                )}
              </Modal.Body>
            </Modal>
          </React.Fragment>
        }
      </div>
    } else if (posttype === 'neighborhood') {
      if (tpc_form_btn && community_details.tour_details.show_tour_btn) {
        if (community_details.tour_details.active_utour && community_details.tour_details.utour_page_url) {
          return (
            <React.Fragment>
              <a className="button full-width margin-vertical max-width"
                style={{
                  '--margin-vertical-bottom-desktop': '0',
                  '--margin-vertical-bottom-mobile': '0',
                  '--margin-vertical-top-desktop': '47px',
                  '--margin-vertical-top-mobile': '36px',
                  '--max-width': '337px',
                }}
                href="javascript:void(0)"
                id="tour_click_btn"
                onClick={() => {this.updateInnerModal('tour_guide')
                window.dataLayer.push({'event':'scheduleTourFormClick'}); 
                }}>
                <span>Schedule a Tour</span>
              </a>

              <Modal className="Utour-screen tph-ui-modal" show={this.state.innerModal==='tour_guide'}>
                <Modal.Header closeButton onClick={() => this.updateInnerModal1('')}></Modal.Header>
                <Modal.Body>
                  {this.state.utourtour ? <UTour UTourDetails={community_details.tour_details} division_name={community_details.division_name} /> : null}
                </Modal.Body>
              </Modal>

              <div className="schedule_tour_div">
                <a className="button full-width margin-vertical max-width"
                  style={{
                    '--margin-vertical-bottom-desktop': '0',
                    '--margin-vertical-bottom-mobile': '0',
                    '--margin-vertical-top-desktop': '47px',
                    '--margin-vertical-top-mobile': '36px',
                    '--max-width': '337px',
                  }}
                  data-fancybox="priority_group_form"
                  data-src="#tour_form"
                  href="#tour_form"
                  id="tour_click_btn_show"
                  onClick={() => {this.handleModalShowHide3()
                    window.dataLayer.push({'event':'preQualificationFormClick'})
                  }}>
                  <span>{getFormBtnCopy(this.props.data.community_status, this.props.data.tpc_priority_group_form)}</span>
                </a>

                {this.state.utourtpc ? stageForm ? <StageTPCForm tpc_form_details={community_details.tpc_priority_group_form}
                  division_name={community_details.division_name}/> :<TPCPriorityGroupForm
                  tpc_form_details={community_details.tpc_priority_group_form}
                  division_name={community_details.division_name}
                /> : null}
              </div>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              <div className="twoforms">
                  <a className="button full-width margin-vertical max-width"
                  style={{
                    '--margin-vertical-bottom-desktop': '0',
                    '--margin-vertical-bottom-mobile': '0',
                    '--margin-vertical-top-desktop': '47px',
                    '--margin-vertical-top-mobile': '36px',
                    '--max-width': '337px',
                  }}
                  href="javascript:void(0)"
                  id="tour_click_btn"
                  onClick={() => {this.handleModalShowHide()
                  window.dataLayer.push({'event':'scheduleTourFormClick'})
                  }}>
                  <span>Schedule a Tour</span>
                </a>

                <Modal className="Utour-screen schedule-Modal tph-ui-modal" show={this.state.showHide}>
                  <Modal.Header closeButton onClick={() => this.handleModalShowHide1()}></Modal.Header>
                  <Modal.Body>
                  {this.state.tourvisible? stageForm ? <StageScheduleTourForm form_details={community_details.tour_details} division_name={community_details.division_name}/> :<ScheduleTourForm form_details={community_details.tour_details} division_name={community_details.division_name} />:null}
                  </Modal.Body>
                </Modal>

                <a className="button full-width margin-vertical max-width"
                  style={{
                    '--margin-vertical-bottom-desktop': '0',
                    '--margin-vertical-bottom-mobile': '0',
                    '--margin-vertical-top-desktop': '47px',
                    '--margin-vertical-top-mobile': '36px',
                    '--max-width': '337px',
                  }}
                  data-fancybox="priority_group_form"
                  data-src="#tour_form"
                  href="#tour_form"
                  id="tour_click_btn_show"
                  onClick={() => {this.handleModalShowHide3()
                  window.dataLayer.push({'event':'preQualificationFormClick'})
                  }}>
                  <span>{getFormBtnCopy(this.props.data.community_status, this.props.data.tpc_priority_group_form)}</span>
                </a>

                {this.state.formvisible ? stageForm ? <StageTPCForm tpc_form_details={community_details.tpc_priority_group_form}
                  division_name={community_details.division_name}/> : <TPCPriorityGroupForm
                  tpc_form_details={community_details.tpc_priority_group_form}
                  division_name={community_details.division_name}
                /> : null}
              </div>
            </React.Fragment>
          );
        }
      } else if (tpc_form_btn) {
        return <React.Fragment>
          <a className="button full-width margin-vertical max-width"
            style={{
              '--margin-vertical-bottom-desktop': '0',
              '--margin-vertical-bottom-mobile': '0',
              '--margin-vertical-top-desktop': '47px',
              '--margin-vertical-top-mobile': '36px',
              '--max-width': '337px',
            }}
            data-fancybox="priority_group_form"
            data-src="#tour_form"
            href="#tour_form"
            id="tour_click_btn_show"
            onClick={() => {this.handleModalShowHide()
            window.dataLayer.push({'event':'preQualificationFormClick'})
            }}>
            <span>{getFormBtnCopy(this.props.data.community_status, this.props.data.tpc_priority_group_form)}</span>
          </a>

          {stageForm ? <StageTPCForm tpc_form_details={community_details.tpc_priority_group_form}
            division_name={community_details.division_name}/> : <TPCPriorityGroupForm
            tpc_form_details={community_details.tpc_priority_group_form}
            division_name={community_details.division_name}
          />}
        </React.Fragment>
      } else if (community_details.tour_details.show_tour_btn) {
        if (community_details.tour_details.active_utour && community_details.tour_details.utour_page_url) {
          return <React.Fragment>
            <a className="button full-width margin-vertical max-width"
              style={{
                '--margin-vertical-bottom-desktop': '0',
                '--margin-vertical-bottom-mobile': '0',
                '--margin-vertical-top-desktop': '47px',
                '--margin-vertical-top-mobile': '36px',
                '--max-width': '337px',
              }}
              href="javascript:void(0)"
              id="tour_click_btn"
              onClick={() => {this.updateInnerModal('tour_guide')
              window.dataLayer.push({'event':'scheduleTourFormClick'});
              }}>
              <span>Schedule a Tour</span>
            </a>

            <Modal className="Utour-screen tph-ui-modal" show={this.state.innerModal==='tour_guide'}>
              <Modal.Header closeButton onClick={() => this.updateInnerModal('')}></Modal.Header>
              <Modal.Body>
                <UTour UTourDetails={community_details.tour_details} division_name={community_details.division_name} />
              </Modal.Body>
            </Modal>
          </React.Fragment>
        } else {
          return <React.Fragment>
            <a className="button full-width margin-vertical max-width"
              style={{
                '--margin-vertical-bottom-desktop': '0',
                '--margin-vertical-bottom-mobile': '0',
                '--margin-vertical-top-desktop': '47px',
                '--margin-vertical-top-mobile': '36px',
                '--max-width': '337px',
              }}
              href="javascript:void(0)"
              id="tour_click_btn"
              onClick={() => {this.handleModalShowHide()
              window.dataLayer.push({'event':'scheduleTourFormClick'});
              }}>
              <span>Schedule a Tour</span>
            </a>

            <Modal className="Utour-screen schedule-Modal tph-ui-modal" show={this.state.showHide}>
              <Modal.Header closeButton onClick={() => this.handleModalShowHide()}></Modal.Header>
              <Modal.Body>
              {stageForm  ? <StageScheduleTourForm form_details={community_details.tour_details} division_name={community_details.division_name}/> :<ScheduleTourForm form_details={community_details.tour_details} division_name={community_details.division_name} />}
              </Modal.Body>
            </Modal>
          </React.Fragment>
        }
      } else {
        return <React.Fragment>
          {community_details.community_status !== 'Sold Out' &&
            <a className="button full-width margin-vertical max-width"
              style={{
                '--margin-vertical-bottom-desktop': '0',
                '--margin-vertical-bottom-mobile': '0',
                '--margin-vertical-top-desktop': '47px',
                '--margin-vertical-top-mobile': '36px',
                '--max-width': '337px',
              }}
              href="javascript:void(0)"
              id="interest_click_btn"
              onClick={() => this.handleShow("Request_quote")}>
              <span>Join The Interest List</span>
            </a>
          }
          <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {community_details.post_id !='85782' ? (
                stageForm ? <StageSidebarSignUp sidebar_data={community_details.sidebar_form} communityOfInterest={community_details.communityOfInterest} nbd_title={community_details.title} division_name={community_details.division_name} neigh_status={community_details.community_status}/> :
                <SidebarSignUp sidebar_data={community_details.sidebar_form} communityOfInterest={community_details.communityOfInterest} nbd_title={community_details.title} division_name={community_details.division_name} neigh_status={community_details.community_status} />
              ):(
                stageForm ? 
                <StageSidebarSignUpNewForm sidebar_data={community_details.sidebar_form} communityOfInterest={community_details.communityOfInterest} nbd_title={community_details.title} division_name={community_details.division_name} neigh_status={community_details.community_status} post_data={community_details.post_id}/>
                :
                <SidebarSignUpNewForm sidebar_data={community_details.sidebar_form} communityOfInterest={community_details.communityOfInterest} nbd_title={community_details.title} division_name={community_details.division_name} neigh_status={community_details.community_status} post_data={community_details.post_id}/>
              )}
            </Modal.Body>
          </Modal>
        </React.Fragment>
      }
    } else {
      if (tpc_form_btn) {
        return <React.Fragment>
          <a className="button full-width margin-vertical max-width"
            style={{
              '--margin-vertical-bottom-desktop': '0',
              '--margin-vertical-bottom-mobile': '0',
              '--margin-vertical-top-desktop': '47px',
              '--margin-vertical-top-mobile': '36px',
              '--max-width': '337px',
            }}
            href="#tour_form"
            data-fancybox="priority_group_form"
            data-src="#tour_form"
            id="tour_click_btn_show"
            onClick={()=>{
              window.dataLayer.push({'event':'preQualificationFormClick'});
            }}>
            <span>Get pre-qualified</span>
          </a>

          {stageForm ? <StageTPCForm tpc_form_details={community_details.tpc_priority_group_form}
            division_name={community_details.division_name}/> : <TPCPriorityGroupForm
            tpc_form_details={community_details.tpc_priority_group_form}
            division_name={community_details.division_name}
          />}
        </React.Fragment>
      } else if (community_details.tour_details.show_tour_btn) {
        if (community_details.tour_details.active_utour && community_details.tour_details.utour_page_url) {
          return <React.Fragment>
            <a className="button full-width margin-vertical max-width"
              style={{
                '--margin-vertical-bottom-desktop': '0',
                '--margin-vertical-bottom-mobile': '0',
                '--margin-vertical-top-desktop': '47px',
                '--margin-vertical-top-mobile': '36px',
                '--max-width': '337px',
              }}
              href="javascript:void(0)"
              onClick={() => {this.updateInnerModal('tour_guide')
              window.dataLayer.push({'event':'scheduleTourFormClick'});
              }}
              id="tour_click_btn">
              <span>Schedule a Tour</span>
            </a>

            <Modal className="Utour-screen tph-ui-modal" show={this.state.innerModal==='tour_guide'}>
              <Modal.Header closeButton onClick={() => this.updateInnerModal('')}></Modal.Header>
              <Modal.Body>
                <UTour UTourDetails={community_details.tour_details} division_name={community_details.division_name} />
              </Modal.Body>
            </Modal>
          </React.Fragment>
        } else {
          return <React.Fragment>
            <a className="button full-width margin-vertical max-width"
              style={{
                '--margin-vertical-bottom-desktop': '0',
                '--margin-vertical-bottom-mobile': '0',
                '--margin-vertical-top-desktop': '47px',
                '--margin-vertical-top-mobile': '36px',
                '--max-width': '337px',
              }}
              href="javascript:void(0)"
              onClick={() => {this.handleModalShowHide()
              window.dataLayer.push({'event':'scheduleTourFormClick'});
              }}
              id="tour_click_btn">
              <span>Schedule a Tour</span>
            </a>

            <Modal className="Utour-screen schedule-Modal tph-ui-modal" show={this.state.showHide}>
              <Modal.Header closeButton onClick={() => this.handleModalShowHide()}></Modal.Header>
              <Modal.Body>
              {stageForm ? <StageScheduleTourForm form_details={community_details.tour_details} division_name={community_details.division_name}/> : <ScheduleTourForm form_details={community_details.tour_details} division_name={community_details.division_name} />}
              </Modal.Body>
            </Modal>
          </React.Fragment>
        }
      } else {
        return <React.Fragment>
          {community_details.community_status !== 'Sold Out' &&
            <a className="button full-width margin-vertical max-width"
              style={{
                '--margin-vertical-bottom-desktop': '0',
                '--margin-vertical-bottom-mobile': '0',
                '--margin-vertical-top-desktop': '47px',
                '--margin-vertical-top-mobile': '36px',
                '--max-width': '337px',
              }}
              href="javascript:void(0)"
              onClick={() => this.handleShow("Request_quote")}
              id="tour_click_btn">
              <span>Join The Interest List</span>
            </a>
          }

          <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {
                stageForm ? <StageSidebarSignUp sidebar_data={community_details.sidebar_form} communityOfInterest={community_details.communityOfInterest} nbd_title={community_details.title} division_name={community_details.division_name}/> : <SidebarSignUp sidebar_data={community_details.sidebar_form} communityOfInterest={community_details.communityOfInterest} nbd_title={community_details.title} division_name={community_details.division_name} />
              }
              
            </Modal.Body>
          </Modal>
        </React.Fragment>
      }
    }
  }
  //clicktocall data-layer
  calltoaction_data_layer_push(param) {
    this.setState({ value:param })
    if(param && param==='clickToCall') {
        window.dataLayer.push({'event':'clickToCall'});
    }
  }
  
  render() {
    const data = this.props.data
    const hours = data.hours_default ? data.hours_default.split(' | ') : [];
    const remove_hours_check = data.remove_hours_check;
    const holidayHours = data.hours_holiday ? data.hours_holiday.split(' | ') : []
    const description = data.long_desc_formatted && data.long_desc_formatted !== '' ? data.long_desc_formatted : data.long_desc
    const salesOfficeAddress = data?.sales_office_address && data.sales_office_address && data.sales_office_address?.sales_address && data.sales_office_address.sales_address ? data.sales_office_address : null
    const bgColor = data?.custom_theme?.color_palette?.theme_color_palette_bg ?? '#FAFAFA';
    const textColor = data?.custom_theme?.color_palette?.theme_color_palette_text ?? '#BFBFBF';
    const primaryColor = data?.custom_theme?.color_palette?.theme_color_palette_primary ?? '#bdd437';

    return (
      <section className="large-copy large-copy--details flex section-margin section-padding rte wrap"
        style={{
          '--flex-direction-mobile': 'column',
          '--section-margin-top': '0',
          '--section-margin-top-desktop': '0',
          '--section-padding-bottom': '10px',
          '--section-padding-bottom-desktop': '20px',
          '--section-padding-top': '50px',
          '--section-padding-top-desktop': '27px',
          '---bg-color': bgColor,
          'background-color': 'var(--bg-color, #FAFAFA)',
          'color': 'var(--text-color, #4C4D4C)',
          'font-size': 'var(--large-copy-font-size, 32px)',
          'fontFamily': 'var(--font-family, var(--font-family-medium))',
          'letter-spacing': '-0.05em',
          'line-height': 'var(--large-copy-line-height, 1.2)',
        }}>
        <div className="large-copy__content width" style={{'--width-desktop': '68.63%',}}>
          {description &&
            <div className="max-width" style={{'--max-width': '41.6ch', color: textColor}}
              dangerouslySetInnerHTML={{ __html: description }}>
            </div>
          }

          {/* New Home Specialist */}
          <div className="avatar-card flex section-padding relative"
            style={{
              '--flex-display-mobile': 'flex',
              '--flex-direction-mobile': 'row',
              '--section-padding-top': '48px',
              '--section-padding-bottom': '22px',
              '--section-padding-top-desktop': '56px',
              '--section-padding-bottom-desktop': '22px',
              'font-size': '18px',
              'letter-spacing': '0',
            }}>
            <img className="avatar-card__image cursor lazy"
              data-src={data.agent_info.agent_thumbnail}
              height="119"
              width="118"
              data-test
              onClick={() => {
                document.documentElement.classList.toggle('details-modal--active');
                const $videoModal = document.querySelector('[data-details-modal-video]');
                if ($videoModal) $videoModal.play();
              }}
              style={{
                'border-radius': '50%',
                'flex-shrink': '0',
                'pointer-events': 'none',
                'height': 'auto',
                'width': '118px',
              }}
              alt="" />

            <div className="avatar-card__info"
              style={{
                'padding-top': '10px'
              }}>
              <div className="avatar-card__eyebrow eyebrow eyebrow--small"
                style={{
                  'color': '#BFBFBF',
                  'font-size': '12px',
                  'padding-left': '30px',
                }}>
                {data.agent_info.agent_nhs_heading ? data.agent_info.agent_nhs_heading : 'New Home Specialist'}
              </div>

              <div className="avatar-card__title"
                style={{
                  'color': '#242424',
                  'padding-left': '30px',
                  'padding-top': '12px',
                }}>
                {data.agent_info.agents_contact_name ? data.agent_info.agents_contact_name : ''}
              </div>

              <a className="avatar-card__link"
                href={`tel:${data.agent_info.agents_contact_phone}`}
                style={{
                  'color': primaryColor,
                  'display': 'inline-block',
                  'margin-top': '5px',
                  'margin-left': '30px',
                  'text-decoration': 'none',
                }} onClick={() => this.calltoaction_data_layer_push('clickToCall')}>
                {data.agent_info.agents_contact_phone}
              </a>

              <small className="avatar-card__legal block color font-size"
                style={{
                  '--color': '#848484',
                  '--font-size': '10px',
                  'margin-top': '4px',
                  'margin-left': '30px',
                }}>
                {data.agent_info.dre_license}
              </small>
            </div>
          </div>
        </div>

        <div className="large-copy__details margin-horizontal section-padding width"
          style={{
            '--margin-horizontal-left-desktop': '4.6%',
            '--section-padding-top-desktop': '0',
            '--section-padding-top': '50px',
            '--width-desktop': '23.8%',
            'color': 'var(--black)',
            'font-size': '16px',
            'letter-spacing': 'normal',
            'line-height': '1.375',
          }}>
          {data.hide_address !== true &&
            <>
              <div className="large-copy__details-eyebrow eyebrow eyebrow--small"
                style={{
                  '--eyebrow-font-size': '10px',
                  'margin-bottom': '16px',
                }}>
                Address
              </div>
              <div className="large-copy__details-copy">
                {data.physical_address.comm_address && data.physical_address.map_directions_link?
                  <a href={data.physical_address.map_directions_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block"
                  title="driving directions"
                  onClick={() => this.direction_data_layer_push('drivingDirections')}
                  style={{
                    'margin-top': '5px',
                  }}>
                  {data.physical_address.comm_address}
                </a>
                : data.physical_address.comm_address}
                {data.physical_address.map_directions_link &&
                  <a href={data.physical_address.map_directions_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block underline"
                    title="driving directions"
                    onClick={() => this.direction_data_layer_push('drivingDirections')}
                    style={{
                      'margin-top': '5px',
                    }}>
                    Get Directions
                  </a>
                }
              </div>
              {remove_hours_check ?
              <>
                <div className="large-copy__details-eyebrow eyebrow eyebrow--small"
                  style={{
                    '--eyebrow-font-size': '10px',
                    'margin-bottom': '13px',
                    'margin-top': '31px',
                  }}>
                  Daily Hours
                </div>
                <div className="large-copy__details-copy"
                  style={{
                    'line-height': '1.5',
                  }}>
                  {data.time_hour}
                </div>
              </> :
              hours.length > 0 ?
              <>
              <div className="large-copy__details-eyebrow eyebrow eyebrow--small"
                  style={{
                    '--eyebrow-font-size': '10px',
                    'margin-bottom': '13px',
                    'margin-top': '31px',
                  }}>
                  Daily Hours
                </div>
                <div className="large-copy__details-copy"
                  style={{
                    'line-height': '1.5',
                  }}>
                  {hours.map((line, i) =>
                    <>
                      {line}
                      {i < hours.length - 1 && <br />}
                    </>
                  )}
                </div>
              </>
              : ''
            }
              {holidayHours &&
                <div className="large-copy__details-copy-small"
                  style={{
                    'color': '#848484',
                    'font-size': '14px',
                    'line-height': '1.5',
                    'margin-top': '24px',
                  }}>
                  {holidayHours.map((line, i) =>
                    <>
                      {line}
                      {i < holidayHours.length - 1 && <br />}
                    </>
                  )}
                </div>
              }

            </>
          }
          {salesOfficeAddress &&
            <>
              <div className="large-copy__details-eyebrow eyebrow eyebrow--small margin-vertical"
                style={{
                  '--eyebrow-font-size': '10px',
                  '--margin-vertical-bottom-desktop': '0',
                  '--margin-vertical-bottom-mobile': '0',
                  '--margin-vertical-top-mobile': data.hide_address !== true ? '36px' : '0',
                  '--margin-vertical-top-desktop': data.hide_address !== true ? '47px' : '0',
                  'margin-bottom': '16px',
                }}
                dangerouslySetInnerHTML={{ __html: salesOfficeAddress.sales_address_heading ? salesOfficeAddress.sales_address_heading : 'Off-Site Sales Office Address' }}>
              </div>

              <div className="large-copy__details-copy">
                {salesOfficeAddress.sales_address}<br />
                {salesOfficeAddress.custom_direction_link &&
                  <a href={salesOfficeAddress.custom_direction_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block underline"
                    title="Get directions to the sales office"
                    onClick={() => this.direction_data_layer_push('drivingDirections')}
                    style={{
                      'margin-top': '5px',
                    }}>
                    Get Directions
                  </a>
                }
              </div>
            </>
          }
          {this.getFormsBtn()}
        </div>
      </section>
    )
  }
}

export default LargeCopyWithDetails
