import React from 'react'
import { GoogleApiWrapper, Map, Marker,InfoWindow } from 'google-maps-react' // eslint-disable-line no-unused-vars
import active from '../../assets/images/map-marker.png'

export class DirectionMapContainer extends React.Component {
  constructor (props) {
    super(props)

    this.onMapClicked = this.onMapClicked.bind(this)
    this.state = {
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false
    }
  }

  onMapClicked () {
    if (this.state.showingInfoWindow) {
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      })
    }
  }
  render () {
    const google = window.google;
    var direction_details = this.props.direction_details;
    return (
      <Map
        className={'map'}
        google={this.props.google}
        onClick={this.onMapClicked}
        initialCenter={{
          lat: direction_details.latitude,
          lng: direction_details.longitude
        }}
        zoom={15}
      >
      {direction_details.latitude &&  direction_details.latitude ? [
        <Marker
            icon={{
              url:active, 
              anchor: new google.maps.Point(48,69),
              scaledSize: new google.maps.Size(48,69) 
            }}
            position={{ lat: direction_details.latitude, lng: direction_details.longitude }}
            title={direction_details.title}
        />
      ]:['']}

      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyC-ILs_PxdVHTRQqgudhC8lydTXH_5PyGQ'
})(DirectionMapContainer)
