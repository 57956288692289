import React from 'react'
import $ from 'jquery'
import './PromotionForm.scss';
import cookie from 'react-cookies';
import featuredImage from '../../assets/images/50gallery.png'


class BobbyInterestForm extends React.Component {

    constructor(props) {
        super()
        this.state = {
            reload: false,
        }

    }


    reloadMarketoForm() {

        $("#mktoForm_6056").find('[type="submit"]').prop('disabled', false);
        $("#mktoForm_6056").find('[type="submit"]').text("Submit");
        // this.setState({reload: false});
        // this.loadMarketoForm();
    }
  
    loadMarketoForm() {
        var that = this;
        const cookie_value = this.state.mkto_cookie;
        const division_name = this.props.division_name;
        var communityOfInterest = this.props.communityOfInterest;
        var nbd_title = this.props.nbd_title;
        var bobby_interest_list=this.props.bobby_interest_list;
        var bobby_page_title=this.props.bobby_page_title;
        const title="Promotion";
        var window_width=window.screen.width;
    
        if (window.MktoForms2 != "undefined") {

            window.MktoForms2.loadForm("//pages.tripointehomes.com", "167-BTV-942", 6056, function (form) {

                form.onSuccess(function(values, followUpUrl) {
                    if (values) {
                        if (values.Communities_of_Interest__c === 'a00j000000OKNFL') {
                            var community_id_value = '40000001';
                        }else if (values.Communities_of_Interest__c === 'a00j000000Phwyv') {
                            var community_id_value = '30000002';
                        }else if (values.Communities_of_Interest__c === 'a00j000000OKNK3') {
                            var community_id_value = '50000002';
                        }else if (values.Communities_of_Interest__c === 'a003Z00000mZDTT') {
                            var community_id_value = '50000999';
                        }else if (values.Communities_of_Interest__c === 'a00j000000OKNKI') {
                            var community_id_value = '50000001';
                        }else if (values.Communities_of_Interest__c === 'a003Z00000mYhtt') {
                            var community_id_value = '36000004';
                        }else if (values.Communities_of_Interest__c === 'a00j000000QJJdp') {
                            var community_id_value = '10000002';
                        }else if (values.Communities_of_Interest__c === 'a00j000000Phwz5') {
                            var community_id_value = '30000003';
                        }else if (values.Communities_of_Interest__c === 'a00j000000OKNJU') {
                            var community_id_value = '70000003';
                        }else if (values.Communities_of_Interest__c === 'a00j000000OKNJj') {
                            var community_id_value = '70000004';
                        }else if (values.Communities_of_Interest__c === 'a00j000000OKNKD') {
                            var community_id_value = '50000003';
                        }else if (values.Communities_of_Interest__c === 'a003Z00000pUCmF') {
                            var community_id_value = '59040999';
                        }else if (values.Communities_of_Interest__c === 'a00f100000mKZP2') {
                            var community_id_value = '50000004';
                        }else if (values.Communities_of_Interest__c === 'a00j000000OKNJt') {
                            var community_id_value = '70000001';
                        }else if (values.Communities_of_Interest__c === 'a00j000000NTELk') {
                            var community_id_value = '20000001';
                        }else{
                            var community_id_value = '';
                        }
                    }
                    window.dataLayer.push({
                        'event': 'bobby_berk_interest_list_form',
                        'salesforceId' : values.Communities_of_Interest__c,
                        'e1CommunityId' : community_id_value,
                        'divisionName' : values.Division__c ? values.Division__c : '',
                        'page_type' : 'Bobby Berk',
                        'userEmail': values.Email,
                        'userPhone': values?.Phone,
                        'userFirstName': values?.FirstName,
                        'userLastName': values?.LastName,
                    });
                    var useremail = values.Email;
                    var cookieexpiry = new Date();
                    cookieexpiry.setFullYear(cookieexpiry.getFullYear() + 2);
			 
                    // set cookie
                    document.cookie="_mkto_email="+useremail+"; expires="+cookieexpiry+"; path=/";

                    $(".row-class-1").html("");
                    $(".mktoButtonRow").html("");
                    $(".sidebar-signup__disclaimer p").html("");
                    $("#mktoForm_6056").height(0);
                    document.querySelector('.sidebar-signup-panel').parentElement.style.height = '100%';
                    that.setState({reload: true});    
                    return false;

                });
                    window.JQUERY('.logo > a:first-child').attr({href:window.location.origin,title:"Tri Pointe Homes", alt:"Tri Pointe Homes"});
                    window.JQUERY('.logo img').attr({src:require('../../assets/images/Tripointe_updated_Icon.png'),title:"Tri Pointe Homes", alt:"Tri Pointe Homes"});
                    window.JQUERY('#form_title').html(bobby_interest_list.bobby_form_title);
                    window.JQUERY('#formcopy').html(bobby_interest_list.bobby_form_description);
                    window.JQUERY(".mktoButton").css("background-color", '#bed245');
                    window.JQUERY(".sidebar-signup__disclaimer a").css("color", '#bed245');
                    
                    $('.mktoFormCol').addClass(function(i){
                    return 'Col'+(i+1);
                    });
                    
                    $('#mktoForm_6056 #Brand__c').on('input', function(evt) {
                        var inputValue = $("#mktoForm_6056 #Brand__c").val();
                        inputValue = inputValue.replace(/[^a-zA-Z ]/g, "");
                        $("#mktoForm_6056 #Brand__c").val(inputValue);
                        
                        var withoutSpclChar = new RegExp(/^[\w| |_-]+$/);
                        if (!inputValue.match(withoutSpclChar)) {
                        var newInputWithHas = $("#mktoForm_6056 #Brand__c").val();
                        var newInputWithoutHas = newInputWithHas.substring(0, newInputWithHas.length - 1);
                        return $("#mktoForm_6056 #Brand__c").val(newInputWithoutHas);
                        }
                        if (inputValue.match(/\s+/g)) {
                          $("#mktoForm_6056 #Brand__c").val(spaceHandle(inputValue))
                        }
                        
                        if ((inputValue.match(new RegExp(" ", "g")) || []).length > 1) {
                        $("#mktoForm_6056 #Brand__c").val(spaceHandle(inputValue))
                        var newInputValue = $("#mktoForm_6056 #Brand__c").val();
                        //var noDoubleSpace = newInputValue.replace(/ +/g, '');
                        //var NoSpaceinEnd = noDoubleSpace.replace(/ $/, "");
                        return $("#mktoForm_6056 #Brand__c").val(newInputValue);
                        }
                        if (/\d/.test(inputValue)) {
                        var noNumber = inputValue.replace(/[0-9]/g, '');
                        return $("#mktoForm_6056 #Brand__c").val(noNumber);
                        }
                    });
                    function spaceHandle(s) {
                        var replaced = s.split(' ');
                        var final_string = '';
                        $.each(replaced, function(index, value) {
                        if (index == 0 && value.length > 1) {
                            final_string = final_string + value + ' ';
                        } else {
                             final_string = final_string + value;
                        }
                        })
                        return final_string;
                    }
                    form.onValidate(function() {
                        var vals = form.vals();
                        var email = form.vals().Email;
                        var fname = form.vals().FirstName;
                        var lname = form.vals().LastName;
                        var phone = form.vals().Phone;
                        var maxLength = 2;
                        var ContactName = form.vals().Brand__c;
                        var invalidDomains = ["@gmail.", "@yahoo.", "@hotmail.", "@live.", "@aol.", "@outlook.", "@test.", "@rediff."];
                        var ContactFirstName = ContactName.substr(0, ContactName.indexOf(' '));
                        var ContactLastName = ContactName.substr(ContactName.indexOf(' ') + 1); 
                        if (/\w+\s+\w+/.test($("#mktoForm_6056 #Brand__c").val())) {
                        // Enable submission for those who met the criteria
                        $('input[name="FirstName"]').val(ContactFirstName);
                        $('input[name="LastName"]').val(ContactLastName); 
                        
                        var lname = $("input[name='LastName']");
                        var lvalue = lname[0].value.length;
                        if(lvalue < maxLength){
                        form.submittable(false);
                        var CcontactNameElem = $("#mktoForm_6056").find("#Brand__c");
                        form.showErrorMessage("Please enter at least 2 character of your last name", CcontactNameElem);
                        return false;
                        }else{
                        form.submittable(true);
                        }
                        } else {
                        form.submittable(false);
                        // Show error message, pointed at VehicleSize element
                        var CcontactNameElem = $("#mktoForm_6056").find("#Brand__c");
                        form.showErrorMessage("Please enter first and last name only.", CcontactNameElem);
                        return false;
                        }
                    });

            });
      
            window.MktoForms2.whenReady(function (form) {


                var j = 1;
                $('#mktoForm_6056 .mktoFormRow').each(function (i, e) {
                    $(this).addClass('row-class-' + j);
                    j++;
                });

               
     
               
                $("#mktoForm_6056").find('[name="formURL"]').val(window.location.href);
                $("#mktoForm_6056").find('[name="communityName"]').val(bobby_page_title);
             
            
                
                if(that.props.neigh_status == 'Temporarily Sold Out' || that.props.neigh_status == 'Limited Availability'){
                    $("#mktoForm_6056").find('[name="waitlist"]').val('true');
                }

                var formfields = form.vals();
					var formfields_names = Object.keys(formfields);

                    var formfilterValue = cookie_value;
                    // console.log('Cookie value: '+formfilterValue);
                    var outputArray = [];
                    for (var i = 0; i < formfields_names.length; i++){
                        if(formfields_names[i] && formfields_names[i]!='munchkinId' && formfields_names[i]!='formid'){
                            if ((window.JQUERY.inArray(formfields_names[i], outputArray)) == -1){
                                outputArray.push(formfields_names[i]);
                            }
                        }                               
                    }
                    const reducedArray = outputArray.reduce((fields_names, feilds) => `${fields_names}${feilds},` ,'')
                    var data = "";
					var data = {
    					action: 'formnames',
    					requestData: reducedArray,
    					filterType:'cookies',
    					filterValue: formfilterValue
					};
                    window.JQUERY.ajax({
                        type: 'POST',
                        data: data,
                        url: "/wp-admin/admin-ajax.php",
                        success:function(response){
                            var field_havevalues = [];
                            var results = [];
                            results = JSON.parse(response);
                            var finalvaluestofill = {};
                            window.JQUERY.each(formfields_names,function(value, elem){
                                if(elem){
                                    var keys = elem.toLowerCase();
                                    if(keys in results){
                                        if(results[keys] && results[keys] != ''){
                                            finalvaluestofill[elem] = results[keys];
                                            field_havevalues.push(elem);
                                        }
                                    }
                                }
                            });

                            if (finalvaluestofill) {
                                form.vals(finalvaluestofill);
                            }
                        }
                    });

            });


        }

    }
 

    componentDidMount() {
        this.state = {
            mkto_cookie: cookie.load('_mkto_trk')
        };
        this.loadMarketoForm();
    }
    
   

    componentWillReceiveProps(nextProps) {
        if (this.state.reload === true) {
            this.loadMarketoForm();
            this.setState({reload: false});
        }
    }

    render() {
        var bobby_interest_list=this.props.bobby_interest_list;
        var window_width=window.screen.width;
        var promo_form=this.props.promo_form;
    
    
        return (
          window_width>767?
            <>
            <div style={{display:'flex',widows:'100%' ,height:'100%'}}>
                <div className="sidebar-signup-panel" id="interest-form" style={{width:'50%',backgroundColor:'#fff',padding:'2% 5%'}}>
                    <div className="sidebar-signup-div"> 
                        <form id="mktoForm_6056" className="sidebar-signup-form"></form>
                    </div>
                     {this.state.reload === false ? (
                        bobby_interest_list.form_disclaimer ?
                        <div tabindex="0" className="sidebar-signup__disclaimer" dangerouslySetInnerHTML={{ __html: bobby_interest_list.form_disclaimer.replace(/(?:\r\n|\r|\n)/g, '') }}>
                        </div> 
                        :
                        <div tabindex="0" className="sidebar-signup__disclaimer">
                            <p>By providing your name and contact information and clicking the submission button, you consent and agree to receive marketing communications from us or made on our behalf, including emails and calls or text messages using an automatic telephone dialing system or an artificial or prerecorded voice. Your consent and agreement to the foregoing, including the receipt of autodialed marketing messages, is not a condition of purchasing any property, goods, or services from us, and you may call us instead of submitting your information online. Your consent herein also applies to any future registration on national or state Do Not Call lists. For mobile phones, standard text message and data charges apply. Consult our Privacy Policy for additional information, including unsubscribe options.</p>
                            </div>
                        ) : ('')} 

                    {this.state.reload === true ? (
                            bobby_interest_list.thank_you_message ? 
                            <small>  <div tabindex="0" className={"thankyou_message "} dangerouslySetInnerHTML={{ __html: bobby_interest_list.thank_you_message .replace(/(?:\r\n|\r|\n)/g, '') }}></div>
                            </small>
                            :
                            <small> <div tabindex="0" className="thankyou_message">
                                Thanks for signing up.
                                </div>
                            </small>
                    ) 
                    : ('')
                    }
                </div>
                <div class="image"  style={{width:'50%'}} >
                    <img src={bobby_interest_list.hero_image ? bobby_interest_list.hero_image : featuredImage}></img>
                </div>
            </div>
              
            </>
          :
            <>
            <div style={{display:'flex',widows:'100%'}}>
                <div className="sidebar-signup-panel" id="interest-form">                  
                      <div className="sidebar-signup-div">
                         <form id="mktoForm_6056" className="sidebar-signup-form"></form>
                     </div>
                    {this.state.reload === true ? (
                        bobby_interest_list.thank_you_message ? 
                        <small>  <div tabindex="0" className={"thankyou_message "} dangerouslySetInnerHTML={{ __html: bobby_interest_list.thank_you_message .replace(/(?:\r\n|\r|\n)/g, '') }}></div>
                        </small>
                        :
                        <small> <div tabindex="0" className="thankyou_message">
                            Thanks for signing up.
                            </div>
                        </small>
                    ) 
                    : ('')
                    }
                </div>
            </div> 
               </>
          
        )
    }
}

export default BobbyInterestForm
