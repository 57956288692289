import React, {useEffect} from 'react';
import { useStats } from 'react-instantsearch-hooks-web';
import { useSearchStateHitCountDispatch} from './hooks/useSearchStateHitCounts';

const TabToggle = ( props ) => {
	const dispatch = useSearchStateHitCountDispatch();
	const { isActive, onClick, label, index_type } = props;
	const stats = useStats();

	useEffect( () => {
		// This conditional is a hacky way to determine if stats
		// has been updated with the correct number of hits and
		// not that it has simply been initialized with the default
		// settings. There may be a better way to determine this.
		if (4 === stats.hitsPerPage) {
			dispatch({
			    type: 'changed',
                index: index_type,
                count: stats.nbHits,
            });
        }
    }, [stats] );

    return (
        <div className='z-10 flex'>
            <button
                type="button"
                aria-label={`Change search tab to ${ label }`}
                className={`tw-bg-transparent tw-text-base lg:tw-text-lg tw-font-medium hover:tw-text-charcoal tw-border-b-4 tw-pb-2 tw-mb-0 ${ isActive ? 'tw-text-charcoal tw-border-apple' : 'tw-text-slate tw-border-transparent' }`}
                onClick={() => onClick( )}>
                {label}
            </button>
        </div>
    );
};

export default TabToggle;
