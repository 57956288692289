import { useEffect } from 'react';

// algolia does not support 2 autocompletes on a page.
// Modal one is only initalized on click of the hamburger
// but modal on page still stays open, so close it
const useCloseAutocomplete = ( { autocompleteContainer, autocompleteInstance } ) => {
	// close autocompletes on hamburger click
	useEffect( () => {
		const burgerEl = document.getElementById( 'hamburgerBtn' );
		if ( ! burgerEl ) {
			return;
		}
		const burgerListener = burgerEl.addEventListener( 'click', () => {
			autocompleteInstance.setIsOpen( false );
		} );
		return () => {
			burgerEl.removeEventListener( 'click', burgerListener );
		};
	}, [ autocompleteInstance ] );

	// When there are 2 autocompletes, the first one will not close on outside click
	useEffect( () => {
		const outsideClickListener = ( event ) => {
			const element = document.getElementsByClassName( 'aa-Panel' )[ 0 ];
			if ( element && ! element.contains( event.target ) && ! autocompleteContainer.current.contains( event.target ) ) {
				autocompleteInstance.setIsOpen( false );
			}
		};

		document.addEventListener( 'click', outsideClickListener );

		return () => {
			document.removeEventListener( 'click', outsideClickListener );
		};
	}, [ autocompleteInstance, autocompleteContainer ] );
};

export default useCloseAutocomplete;
