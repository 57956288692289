import React from 'react';
import axios from 'axios';
import GlobalField from '../../Classes/GlobalField';
import {apiConfig} from '../../Classes/Helper.jsx';
// import FaqSection from '../../Classes/FaqSection';
import NoMatch from './NoMatch';
import { Redirect } from "react-router-dom";
import SkeletonComp from '../../Classes/SkeletonComp.jsx';
import {Helmet} from "react-helmet";
import { Modal } from 'react-bootstrap';
import BobbyInterestForm from '../../Classes/BobbyInterestForm';
import StageBobbyForm from '../../Classes/StageBobbyForm';
import callTrkSwap from '../../../helpers/callTrkSwap.js';

class StaticPage extends React.Component {  
  
  constructor(props) {
    super(props)
    this.state = {
      static_component : [],
      success : false,
      message : '',
      show: false,
    }

  }

  fetch_data(){
    axios.get(apiConfig().API_URL+'/static_info/'+ this.props.match.params.slug)
    .then(response => {
      this.setState({      
        static_component : response.data.data,
        success: response.data.success,
        message : response.data.message,
      })
      // console.log(response);
    })
  }

  componentDidMount(){
    this.fetch_data();
    callTrkSwap();

    setTimeout(() => {
      if (window.location.href.indexOf("#bobby-interest-form") > -1 ) {
          if (document.getElementById("bobby-interest-form")) {
              document.getElementById("bobby-interest-form").click()
          }    
      }
    }, 2000);
  }

  componentDidUpdate(){
    callTrkSwap();
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.location.pathname != nextProps.location.pathname){
      this.props = nextProps;
      this.setState({
        success: false
      })
      this.fetch_data();
    }
    if (nextProps.location.hash.match('#bobby-interest-form')) {
      if (document.getElementById("bobby-interest-form")) {
        console.log('got btn');
        document.getElementById("bobby-interest-form").click()
      } 
    }
  } 
  aba_data_layer_push(param) {
    this.setState({ value:param })
    if(param && param==='Accept') {
        window.dataLayer.push({'event':'prequalification'});
    }
  }

  handleOpen = () => {
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false });
    window.history.pushState("", "", ' ');
  };

  render() {    
    var success = this.state.success;
    var message = this.state.message; 
    var static_component = this.state.static_component;
    var component = static_component.component;
    var division_value_id = static_component.division_value_id; 
    var bobby_interest_form = static_component.bobby_interest_form;
    var bobby_page_title = static_component.bobby_page_title;
    var bobby_interest_list = static_component.bobby_interest_list;
    var current_url = window.location.host;
    if (current_url === 'tripointedev.wpengine.com' || current_url === 'tripointestage.wpengine.com') {
        var stageForm = true;
    }else{
        var stageForm = false;
    }
    if (division_value_id) {
      division_value_id.sort((a, b) => {
        const nameA = a.neighborhood_name.toUpperCase();
        const nameB = b.neighborhood_name.toUpperCase();
    
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
      });
    }
    for(var x in component ){
      if(component[x].acf_fc_layout == "global_faq_section")
       {component.push( component.splice(x,1)[0] )}
      
      }
      var yoast_following = static_component.yoast_following;
        var yoast_indexing = static_component.yoast_indexing;
        var context_yoast = '';
        if (yoast_indexing === 'true' && yoast_following === 'true') {
        var context_yoast = 'index, follow';
        }else if (yoast_indexing === 'true' && yoast_following === 'false') {
        var context_yoast = 'index, nofollow';
        }else if (yoast_indexing === 'false' && yoast_following === 'true') {
        var context_yoast = 'noindex, follow';
        }else if (yoast_indexing === 'false' && yoast_following === 'false') {
        var context_yoast = 'noindex, nofollow';
        }
    // const meta = {
    //   title: static_component.page_title,
    //   description: static_component.page_desc,
    //   canonical: window.location.href
    // };
     return (  
      <>  
            <Helmet>  
              <title>{static_component.page_title }</title>
              <meta name="description" content={static_component.page_desc} />
              <meta name="robots" content={context_yoast} />  
              <meta property="og:title" content={static_component.page_title ? static_component.page_title : ''} />
              <meta property="og:description" content={static_component.page_desc ? static_component.page_desc : ''} />
              <meta property="og:image" content={static_component.yoast_image}/>
              <meta property="og:url" content={window.location.href} />
              <link rel="canonical" href={window.location.href} />
              <meta property="og:type" content='Website' />
            </Helmet>
          {success === true ? [ 
            <div role="main" className="global-main"> 
                { bobby_interest_form ?
                <>
                   <a class="button full-width margin-vertical max-width"S
                   href="javascript:void(0)"
                   id="bobby-interest-form"
                   hidden= "hidden"
                   onClick={this.handleOpen}>
                   <span>Bobby list form</span>
                 </a>
                 <Modal className="Estimate_modal Request_quote" show={this.state.show} onHide={this.handleClose} >
                   <Modal.Header closeButton onClick={this.handleClose}></Modal.Header>
                   <Modal.Body style={{backgroundColor:'#fff'}}>
                     {
                       stageForm ? <StageBobbyForm bobby_page_title={bobby_page_title} bobby_interest_list={bobby_interest_list} /> : <BobbyInterestForm bobby_page_title={bobby_page_title} bobby_interest_list={bobby_interest_list}/>
                     }
                     
                   </Modal.Body>
                 </Modal>
                </>: ''
                }

                <GlobalField global_component={component} />
                {/* <FaqSection community_details={static_component}/> */}
                {static_component.static_content && <div tabindex="0" className="staic-content" dangerouslySetInnerHTML={{__html:static_component.static_content.replace(/(?:\r\n|\r|\n)/g, '')}}></div>}
                { division_value_id ? 
                  <div className="neighborhood-staic-content">
                    {division_value_id.map(item => 
                      <a className="neigh_anchor" href={item.neighborhood_url+'#priority_group_form'} target="_blank">{item.neighborhood_name}</a>
                    )}
                  </div>
                : ""}
                  {static_component.static_button_content ? ( 
                    <div className="static_button_content">
                    {static_component.static_button_content.map(items => (
                      items.button_link && items.static_button ? (
                        <a href={items.button_link}  style={{backgroundColor:items.button_color }} className="learn-more" title={items.static_button} onClick={() =>  this.aba_data_layer_push(items.static_button)}>{items.static_button}</a>
                      ):('')
                    ))}
                    </div>
                  ):('')}
                </div>
           
          ]:[ message != 'Not Completed' ?   <SkeletonComp/>  :  <Redirect to={<NoMatch/>} />]}
        
      </>
     );  
  }  
}  

export default StaticPage;
