import React from 'react';
import { useEffect, useState,useRef }  from 'react';
import '../../Classes/NationalPromoPage.scss';
// import PromoCardSection from '../../Classes/PromoCardSection';
import PromoCards from '../../Classes/PromoCards';
import GlobalField from '../../Classes/GlobalField';
import axios from 'axios';
import {apiConfig} from '../../Classes/Helper.jsx';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {Helmet} from "react-helmet";


const CustomNationalPromo = (props) => {
  const ref = useRef();
  const [ getData , setData] = useState({ component_data: [],
    success: false,
    message: '',
    promo_data:[],
  });
  const [getdiv , setdiv] = useState([]);
  const [ getPromo , setPromo] = useState([]);
  const [ getStateAbbrName , setStateAbbrName] = useState('');
  const [ getdivName , setdivName] = useState('');
  const [ getvalue , setvalue] = useState('');
  const [promo_items, setItem] = useState(getdiv);
  const [getCommunity, setCommunity] = useState('');
  const [getDivisonName, setDivisonName] = useState('');
  // const [ getPage , setPage] = useState(1);

  const [isOpen, setOpen] = useState(false);
  
  const [selectedItem, setSelectedItem] = useState(null);
  
  const toggleDropdown = () => setOpen(!isOpen);
  
  const handleItemClick = (id) => {
    selectedItem == id ? setSelectedItem(null) : setSelectedItem(id);
  }
  // const [getId , setId] = useState('');

  function fetch_data(id) {
    // var page_no = paged;

    axios.get(apiConfig().API_URL+'/national_promo_post/'+props.match.params.slug+'?id='+id).then(response => {
      setData(
          {
              component_data: response.data.data,
              success: response.data.success,
              message: response.data.message,
              promo : response.data.data.promo_detail,
          },
      );
      setPromo(response.data.data.promo_detail)
          setdiv(response.data.data.divisons)
          setItem(response.data.data.divisons)
          setvalue(response.data.data.divisons_name)
          setCommunity(response.data.data.page_details.communityOfInterest)
          setDivisonName(response.data.data.page_details.post_title)


          if (window.location.href.includes('#')) {
            var split_url_div1 = window.location.href.split('#')[1];
            var split_url_div2 = window.location.href.split('#')[2]
            if (split_url_div2) {
              var split_url2 = split_url_div2.toLowerCase();
              if (response.data.data.divisons.length != 0 && split_url2 != '') {
                response.data.data.divisons.forEach(items =>{
                  var text_crop = items.name.toLowerCase();
                  var space_remove = text_crop.replaceAll(' ', '-');
                  if (space_remove ==(split_url2)){
                    var res = response.data.data.divisons_name.toLowerCase();
                    if (res === 'arizona') {
                      var result_name = 'phoenix metro';
                    }else if (res === 'colorado') {
                      result_name = 'denver metro';
                    }
                    else if (res === 'washington') {
                      result_name = 'seattle metro';
                    }else{
                      result_name = res;
                    }
                    var result_all = result_name.replaceAll(' ', '-');
                    if(result_all !== split_url2){
                      fetch_data(items.id);
                      
                    }
                    setdivName(items.name);
                    setStateAbbrName(items.state_abb)
                  }
                })
              }
            }else if (split_url_div1) {
              if (split_url_div1.includes('?')) {
                if (split_url_div1.includes('/?')) {
                var split_url_link3 = split_url_div1.split('/?')[0];
                var split_url3 = split_url_link3.toLowerCase();
                if (response.data.data.divisons.length != 0 && split_url3 != '') {
                  response.data.data.divisons.forEach(items =>{
                    var text_crop = items.name.toLowerCase();
                    var space_remove = text_crop.replaceAll(' ', '-');
                    if (space_remove ==(split_url3)){
                      var res = response.data.data.divisons_name.toLowerCase();
                      if (res === 'arizona') {
                        var result_name = 'phoenix metro';
                      }else if (res === 'colorado') {
                        result_name = 'denver metro';
                      }
                      else if (res === 'washington') {
                        result_name = 'seattle metro';
                      }else{
                        result_name = res;
                      }
                      var result_all = result_name.replaceAll(' ', '-');
                      if(result_all !== split_url3){
                        fetch_data(items.id);
                        
                      }
                      setdivName(items.name);
                      setStateAbbrName(items.state_abb)
                    }
                  })
                }
                  
                }
                else if (split_url_div1.includes('?')) {
                var split_url_link4 = split_url_div1.split('?')[0];
                  var split_url4 = split_url_link4.toLowerCase();
                  if (response.data.data.divisons.length != 0 && split_url4 != '') {
                    response.data.data.divisons.forEach(items =>{
                      var text_crop = items.name.toLowerCase();
                      var space_remove = text_crop.replaceAll(' ', '-');
                      if (space_remove ==(split_url4)){
                        var res = response.data.data.divisons_name.toLowerCase();
                        if (res === 'arizona') {
                          var result_name = 'phoenix metro';
                        }else if (res === 'colorado') {
                          result_name = 'denver metro';
                        }
                        else if (res === 'washington') {
                          result_name = 'seattle metro';
                        }else{
                          result_name = res;
                        }
                        var result_all = result_name.replaceAll(' ', '-');
                        if(result_all !== split_url4){
                          fetch_data(items.id);
                          
                        }
                        setdivName(items.name);
                        setStateAbbrName(items.state_abb)
                      }
                    })
                  }
                }
              }else{
                var split_url = split_url_div1.toLowerCase();
                  if (response.data.data.divisons.length != 0 && split_url != '') {
                    response.data.data.divisons.forEach(items =>{
                      var text_crop = items.name.toLowerCase();
                      var space_remove = text_crop.replaceAll(' ', '-');
                      if (space_remove ==(split_url)){
                        var res = response.data.data.divisons_name.toLowerCase();
                        if (res === 'arizona') {
                          var result_name = 'phoenix metro';
                        }else if (res === 'colorado') {
                          result_name = 'denver metro';
                        }
                        else if (res === 'washington') {
                          result_name = 'seattle metro';
                        }else{
                          result_name = res;
                        }
                        var result_all = result_name.replaceAll(' ', '-');
                        if(result_all !== split_url){
                          fetch_data(items.id);
                          
                        }
                        setdivName(items.name);
                        setStateAbbrName(items.state_abb)
                      }
                    })
                  }
              }
            }
            if (response.data.data) {
              if (window.location.href.indexOf("#interest-form") > -1) {
                document.getElementById("interest_click_btn").click();
              }
            }
          } 
    })
}


const sortDivison = (e , div , coi, state_abbr) =>{
  let sort_division = e.target.attributes.getNamedItem("data-field-value").value;
  let div_name = div;
  let communityOfInterest = coi;
  setCommunity(communityOfInterest);
  var core = div_name.replaceAll(" ", "-");
  var correct_name_lower = core.toLowerCase();
  window.history.pushState("", "", '#'+correct_name_lower)
  setdivName(div_name);
  setStateAbbrName(state_abbr);
  setDivisonName(div_name);
  fetch_data(sort_division);
  setOpen(!isOpen);
}

const sortSingleDivison = (e) =>{
  var default_filter = e.target.attributes.getNamedItem("data-field-value").value;
  if (default_filter === '1111') {
    fetch_data();
    setdiv('');
    setOpen(!isOpen);
    setdivName('');
    setCommunity('');
    window.history.pushState("", "", '#all');
  }
}

useEffect(() => {
  fetch_data();
}, [])

useEffect(() => {
  const checkIfClickedOutside = e => {
    // If the menu is open and the clicked target is not within the menu,
    // then close the menu
    if (isOpen && ref.current && !ref.current.contains(e.target)) {
      setOpen(false)
    }
  }

  document.addEventListener("mousedown", checkIfClickedOutside)

  return () => {
    // Cleanup the event listener
    document.removeEventListener("mousedown", checkIfClickedOutside)
  }
}, [isOpen])

if(getData.component_data !=''){
    var division = getData.divisons ;
    var success = getData.success;
        var message = getData.message;
      if (getData.component_data.page_details) {
          var page_data = getData.component_data.page_details;
          var global_component = page_data.component;
          var post_title = page_data.post_title;
          var communityOfInterest = page_data.communityOfInterest;
          var hero_logo = page_data.Logo;
          var sidebar_form = page_data.promo_sidebar_form;
          var banner_img =page_data.bg_img.url ;
                if (banner_img) {
                  var background_image = {
                      backgroundImage:'url('+banner_img+')',
                  }
                }
                else{
                  var background_image = {
                      backgroundImage:'url('+background_image+')',
                  }
                }
          var promo_subtext = page_data.hero_subtext;
          var meta_title = page_data.meta_title;
          var meta_description = page_data.meta_desc;
          var promo_eyebrow = page_data.eyebrow_text;
          var no_promotion_text = page_data.no_promotion_text;
          if (no_promotion_text) {
            var default_text = no_promotion_text.toUpperCase();
          }else{
            default_text = 'TO VIEW PROMOTION IN YOUR AREA, SELECT YOUR DESIRED LOCATION FROM THE DROPDOWN ABOVE';
          }
          var promotion_offer_headline = page_data.promotion_offer_headline;
          if (promotion_offer_headline === '') {
              var promo_headline = "Promotion Offers Headline";
          }else{
              promo_headline = promotion_offer_headline;
          }

      }
      var yoast_following = getData.component_data.yoast_following;
    var yoast_indexing = getData.component_data.yoast_indexing;
    var context_yoast = '';
    if (yoast_indexing === 'true' && yoast_following === 'true') {
      var context_yoast = 'index, follow';
    }else if (yoast_indexing === 'true' && yoast_following === 'false') {
      var context_yoast = 'index, nofollow';
    }else if (yoast_indexing === 'false' && yoast_following === 'true') {
      var context_yoast = 'noindex, follow';
    }else if (yoast_indexing === 'false' && yoast_following === 'false') {
      var context_yoast = 'noindex, nofollow';
    }
  }
  var  current_location = window.location.href;
    if (current_location.includes('#') || current_location.includes('?')) {
      var canonical_location = window.location.origin + window.location.pathname  ;
    }else{
        canonical_location = current_location;
    }
    const meta = {
      title: 'New Year New Home Event | Tri Pointe Homes',
      description: 'Take advantage of special pricing and financing on select move-in ready homes during the Tri Pointe Homes New Year New Home Event.',
      canonical: canonical_location
    };
      if (getdivName) {
        var promo_title = getdivName +' Promotion Offers';
      }
      else{
        promo_title = promotion_offer_headline;
      }

  return (
    <>
    <Helmet>  
              <title>{meta_title?meta_title:meta.title}</title>
              <meta name="description" content={meta_description?meta_description:meta.description} />
              <meta property="og:title" content={meta_title?meta_title:meta.title} />
              <meta property="og:description" content={meta_description?meta_description:meta.description} />
              <link rel="canonical" href={meta.canonical} />
              <meta name="robots" content={context_yoast}  /> 
              <meta property="og:image" content={getData.component_data.yoast_image}/>
              <meta property="og:url" content={window.location.href} />
              <meta property="og:type" content='Website' />
            </Helmet> 
    {success=== true ?[
        <div tabindex="0" className="promo_plan-banner" style={background_image}>
            {hero_logo ? <div className="hero-logo">
                <img src={hero_logo.url} alt="Hero Logo" />
            </div> : ''}
            
            <div className="promo-subtext">{promo_subtext ? promo_subtext : 'Move-in Ready Homes that can check all your Resolution Boxes'}</div>
            
        </div>,
        <div className="promotion_cards">
        <div className="promotion_card_section">
            <div className="promotion_eyebrow_text">
              {promo_eyebrow ? promo_eyebrow : 'New Year New Homes'}
            </div>

          <h1 className="promotion_card_headline">
            {promo_title}
          </h1>

          <div className='dropdown' ref={ref}>
              <div className='dropdown-header' onClick={toggleDropdown}>
                {getdivName && getStateAbbrName ? getStateAbbrName.toUpperCase()+"  -  " +getdivName.toUpperCase() : "WHERE DO YOU WANT TO LIVE?"}
                <i className={`fa fa-chevron-right icon ${isOpen && "open"}`}></i>
              </div>
              <div className={`dropdown-body ${isOpen && 'open'}`}>
                {/* <div className="dropdown-item" data-field-value='1111' onClick={(e) => {sortSingleDivison(e)}}>All Divison</div> */}
                {promo_items.map(item => (
                  <div className="dropdown-item" onClick={(e) => { handleItemClick(e.target.id); sortDivison(e,item.name,item.communityOfInterest,item.state_abb);}} value={item.id} data-field-value={item.id}>
                    <span className={`dropdown-item-dot ${item.id == selectedItem && 'selected'}`}>• </span>
                    {item.state_abb.toUpperCase()+" - "+item.name.toUpperCase()}
                  </div>
                ))}
              </div>
            </div>

            {/* promotion cards */}
            <div className="promo_cards_section">
              {
                getdivName ? <PromoCards cards={getPromo} /> : <div className='no_promotion_text'>{default_text}</div>
              }
            </div>

        </div>
      </div>,
        // <PromoCardSection promo_data = {getPromo}  promo_eyebrow={promo_eyebrow} getdivName={getdivName} promotion_offer_headline={promo_headline}/>
        <GlobalField global_component={global_component} promo_list_form = 'yes' sidebar_form={sidebar_form} background_image={background_image} post_title={getDivisonName} communityOfInterest={getCommunity}/>
      ]:[
            message != 'Not Completed' ?
            <SkeletonTheme>
                <div tabindex="0" className="promo_plan-banner">
                <Skeleton height={100}/>
                <div className="hero-logo">
                <Skeleton height={100}/>
                </div>
            <div className="promo-subtext">
            <Skeleton height={60}/>
            <Skeleton height={40}/>
            </div>
            <div className="promo_filter">
            <Skeleton height={100}/>
            </div>
                </div>
                <Skeleton height={100}/>
            </SkeletonTheme>:''
            // : <Redirect to={<NoMatch/>} />
        ]      
    }
    </>
  )
}

export default CustomNationalPromo;
