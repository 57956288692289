import React from 'react';

// Couldn't make this click analytics event work with GTA
const AutocompleteHit = ( props ) => {
	const { hit, type, insights } = props;

	const getText = () => {
		const parts = [ ];
		if ( hit.cities && hit.cities.length > 0 ) {
			parts.push( hit.cities[ 0 ] );
		}
		if ( hit.state_alpha_2_code ) {
			parts.push( hit.state_alpha_2_code );
		}
		if ( parts.length === 1 ) {
			return parts[ 0 ];
		}
		if ( type === 'home-plans' && hit.neighborhood_data && hit.neighborhood_data.title ) {
			return `${ hit.neighborhood_data.title } - ${ parts.join( ', ' ) }`;
		}
		return parts.join( ', ' );
	};

	return (
		<a
			href={ hit.url }
			className="gtm-search-autocomplete-hit aa-ItemLink tw-flex tw-items-center tw-h-full tw-justify-between tw-pr-2"
			onClick={( event ) => {
				event.stopPropagation();
				insights.clickedObjectIDsAfterSearch( {
					eventName: 'Algolia autocomplete - result',
					index: hit.__autocomplete_indexName,
					objectIDs: [ hit.objectID ],
					// can't find how to get position in autocomplete
					positions: [ 1 ],
					queryID: hit.__autocomplete_queryID,
				} );
			}}
		>
			<span>{hit.title}</span>
			<span className='tw-italic tw-text-slate'>
				{getText()}
			</span>
		</a>
	);
};

export default AutocompleteHit;
