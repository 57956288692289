import { useMediaQuery } from 'react-responsive';

export const useDesktopMediaQuery = () =>
	useMediaQuery( { query: '(min-width: 1024px)' } );

export const useMobileMediaQuery = () =>
	useMediaQuery( { query: '(max-width: 639px)' } );

export const useTabletAndBelowMediaQuery = () =>
	useMediaQuery( { query: '(max-width: 1023px)' } );
