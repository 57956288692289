
import $ from 'jquery';
import qs from 'qs';
import React from 'react';
import cookie from 'react-cookies';
import fancybox from '@fancyapps/fancybox'; // eslint-disable-line no-unused-vars
import './ScheduleTourForm.scss';

var evtTriggred = false;
const { index, objectID, position, queryID } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

class QmiReserveForm extends React.Component {
    TourloadMarketoForm() {
        var that = this;
        const zillow_hour = that.props.form_details.zillow_hour;
        const title = that.props.form_details.title;
        const agent_name = that.props.agent_name;
        const division_name = this.props.division_name;
        const holidays = that.props.form_details.holidays;
        const map_directions_link = that.props.form_details.map_directions_link;
        const community_id = that.props.form_details.community_id;
        const crm_salesforce_id = that.props.form_details.crm_salesforce_id;
        // const post_type = that.props.post.post_type;
        const ID = that.props.form_details.ID;
		const form_headline = that.props.form_details.form_headline;
		const form_text = that.props.form_details.form_text;
		const new_row = that.props.form_details.new_row;
		const contact_heading = that.props.form_details.contact_heading;
		const contact_text = that.props.form_details.contact_text;
		const appointment_heading = that.props.form_details.appointment_heading;
		const appointment_text = that.props.form_details.appointment_text;
		const appointment_disclaimer = that.props.form_details.appointment_disclaimer;
		const agent_heading = that.props.form_details.agent_heading;
		const agent_text = that.props.form_details.agent_text;
		const agent_info = that.props.form_details.agent_info;
		const section_1_text = that.props.form_details.section_1_text;
		const section_2_text = that.props.form_details.section_2_text;
		const button_text = that.props.form_details.button_text;
        const form_aba_link = that.props.form_details.form_aba_link;
        const cookie_value = this.state.mkto_cookie;
        var agent_detail = '';
        const homesiteNumber = that.props.homesiteNumber;

        if (window.MktoForms2 !== "undefined") {
            window.MktoForms2.loadForm("//app-sj10.marketo.com", "167-BTV-942", 4738, function(form) {
                form.onSuccess(function (values, followUpUrl) {
                    window.dataLayer.push({
                        'event': 'globalBuyNow',
                        'userEmail': values?.Email,
                        'userPhone': values?.Phone,
                        'userFirstName': values?.FirstName,
                        'userLastName': values?.LastName,
                    });
                });
                window.JQUERY('.headtxt').html(title);
                window.JQUERY('#Step1Row1').html(form_headline);
                window.JQUERY('#Step1Row2').html(form_text);
                window.JQUERY('#stepsInfo').append(new_row);
                window.JQUERY('#Step2Row1').html(contact_heading);
                window.JQUERY('#Step2Row2').html(contact_text);
                window.JQUERY('#Step3Row1').html(appointment_heading);
                window.JQUERY('#Step3Row2').html(appointment_text);
                window.JQUERY('#Step3-Dis').html(appointment_disclaimer);
                window.JQUERY('#Step5Row1').html(agent_heading);
                window.JQUERY('#Step5Row2').html(agent_text);
                window.JQUERY('#Step5Row3 img').attr({src:require('../../assets/images/last-icon.png')});
                window.JQUERY('#Step4Row2').html(section_1_text);
                window.JQUERY('#Step4Row4').html(section_2_text);
                window.JQUERY('#Step4Row5 a').html(button_text);
                window.JQUERY('#Step4Row5 a').attr('href',form_aba_link);

                if(agent_name.agents_contact_name || agent_name.agents_contact_phone || (button_text &&form_aba_link)){
                    agent_detail = '<p>'+agent_info+'<span class="form_agent_name" style="display:block;margin:10px 0 5px;">'+agent_name.agents_contact_name+'</span><span class="form_agent_number" style="display:block;margin-bottom:5px;">New Home Specialists</span><span class="form_agent_number" style="display:block;margin-bottom:10px;">'+agent_name.agents_contact_phone+'</span>';
                    if(button_text!='' && form_aba_link!=''){
                        agent_detail = agent_detail+'<a class="Prequalified_button" href='+form_aba_link+' target="_blank" style="padding:10px;display:inline-block;color:#545454;border-radius:0;font-weight:600;background-color:#BED245;text-decoration:none;letter-spacing: 3px;">'+button_text+'</a></p>';
                    }
                }

                window.JQUERY('#Step5Row4').html(agent_detail);

                if(button_text=='' && form_aba_link=='') {
                    window.JQUERY("#Step4Row5").css("display", "none");
                }

                window.JQUERY('#tour_form .logo > a:first-child').attr({href: window.location.origin ,title:"Tri Pointe Homes", alt:"Tri Pointe Homes"});
                window.JQUERY('#tour_form .logo img').attr({src:require('../../assets/images/TRIPOINTEHOMES_MASTER_COLOR_RGB.png'),title:"Tri Pointe Homes", alt:"Tri Pointe Homes"});
                window.JQUERY('#tour_forms .logo > a:first-child').attr({href: window.location.origin ,title:"Tri Pointe Homes", alt:"Tri Pointe Homes"});
                window.JQUERY('#tour_forms .logo img').attr({src:require('../../assets/images/TRIPOINTEHOMES_MASTER_COLOR_RGB.png'),title:"Tri Pointe Homes", alt:"Tri Pointe Homes"});
                window.JQUERY('#tour_forms #mktoForm_4738 .mktoRadioList').addClass('remove_dots');

                if(map_directions_link !== ""){
                    window.JQUERY('#DriveDir a').attr("target","_blank");
                    window.JQUERY('#DriveDir a').attr("href",map_directions_link);
                }else{
                    window.JQUERY('#DriveDir').hide();
                }
                
                window.JQUERY('#mktoForm_4738 a#PrivacyLink').attr("href",window.location.origin+"/privacy-policy/");
                window.JQUERY('#mktoForm_4738 input[name="External_Lead_Id__c"]').val(community_id);
                var date_trigger;
                date_trigger = setInterval(function(){ 
                    if(window.jQuery('#mktoForm_4738 #requestedDate').val() && window.jQuery('#mktoForm_4738 #requestedDate').val().length > 0){

                        window.JQUERY( "#mktoForm_4738 #requestedDate" ).trigger( "change" ); 
                        clearInterval(date_trigger);
                    }
                }, 200);

                window.JQUERY("#mktoForm_4738 #requestedDate").change(function(){
                    if(holidays.length > 0 && that.state.holidays_added == false){
                        var selected_date = window.JQUERY.datepicker.formatDate('dd/mm/yy', window.JQUERY("#mktoForm_4738 #requestedDate").datepicker('getDate'));

                        if(holidays.indexOf(selected_date) != -1){
                            var current_date = window.JQUERY("#mktoForm_4738 #requestedDate").datepicker('getDate');
                            var new_selected_date,next_date,modified_date;

                            current_date.setDate(current_date.getDate() + 1);

                            new_selected_date = window.JQUERY.datepicker.formatDate('dd/mm/yy', current_date);

                                if(holidays.indexOf(new_selected_date) != -1){
                                    next_date = current_date;
                                    next_date.setDate(next_date.getDate() + 1);
                                    modified_date = next_date;
                                }
                                else{
                                    modified_date = current_date;
                                }
                                window.JQUERY('#mktoForm_4738 #requestedDate').datepicker("setDate", modified_date );
                        }

                        window.JQUERY('#mktoForm_4738 #requestedDate').datepicker('option', 'beforeShowDay', function(date){
                            var string = window.JQUERY.datepicker.formatDate('dd/mm/yy', date);
                            if(holidays.indexOf(string) == -1){
                                return [true, ""];
                            }
                            else {
                                return [false, 'national_holiday_date'];
                            }
                        });

                        that.setState({
                            holidays_added:true
                        })
                    }

                    window.JQUERY("#apptRequestedTime option").each(function() {
                        window.JQUERY(this).remove();
                    });
                    var addHour = ["10:00 am", "11:00 am" , "12:00 pm" , "01:00 pm", "02:00 pm", "03:00 pm", "04:00 pm", "05:00 pm"];
                    window.JQUERY.each(addHour, function(index, value){
                        window.JQUERY("#apptRequestedTime").append( window.JQUERY("<option>",{
                              value: value,
                              text: value
                        }));
                    });
                    var requestedDate =  window.JQUERY('#mktoForm_4738 #requestedDate').val();
                    if(requestedDate !=''){
                        window.JQUERY.ajax({
                            type:"POST",
                            url: "/wp-admin/admin-ajax.php",
                            data:{
                                action:"get_shedule_hour",
                                requestedDate: requestedDate,
                                n_id : ID,
                                result : zillow_hour
                            },
                            success:function(response){
                                response = JSON.parse(response);
                                if(response.close_day == 'true'){
                                    if( window.JQUERY('#mktoForm_4738 .Row_21:has(p)')){
                                        window.JQUERY('#mktoForm_4738 .Row_21 p').remove();
                                    }
                                    window.JQUERY('#mktoForm_4738 .Row_21').append('<p class="red-error">Appointments are not available for this date.</p>');
                                    window.JQUERY('#mktoForm_4738 .Row_23 a').css('pointer-events','none');
                                }else{
                                    window.JQUERY('#mktoForm_4738 .Row_23 a').css('pointer-events','unset');
                                    window.JQUERY('#mktoForm_4738 .Row_21 p').remove();
                                }
                                
                                var startTime = 0;
                                var option_length =  window.JQUERY('#apptRequestedTime > option').length;
                                window.JQUERY("#apptRequestedTime option").each(function() {
                                    if( window.JQUERY(this).val() !=response.start_time){
                                        window.JQUERY(this).remove();
                                        startTime++;
                                        if(startTime==option_length){
                                            window.JQUERY.each(addHour, function(index, value){
                                                window.JQUERY("#apptRequestedTime").append( window.JQUERY("<option>",{
                                                    value: value,
                                                    text: value
                                                }));
                                            });
                                            window.JQUERY("#apptRequestedTime").val('10:00 am');
                                        }
                                    }else{								
                                        window.JQUERY("#apptRequestedTime").val(response.start_time);
                                        return false;
                                    }
                                });
                                var endtime = false;var k =1;
                                window.JQUERY("#apptRequestedTime option").each(function() {
                                    if( window.JQUERY(this).val() ==response.end_time || endtime==true){
                                        endtime = true;
                                        k++;
                                        if(k!=2){ window.JQUERY(this).remove();}
                                    }
                                });
                            }
                        });
                    }
                });
              
            });
            
            window.MktoForms2.whenReady(function (form) {
                window.JQUERY("#mktoForm_4738").find('[name="Communities_of_Interest__c"]').val(crm_salesforce_id);
                window.JQUERY("#mktoForm_4738").find('[name="formURL"]').val(window.location.href);
                window.JQUERY("#mktoForm_4738").find('[name="Division__c"]').val(division_name);
                window.JQUERY("#mktoForm_4738").find('[name="communityName"]').val(title);
                window.JQUERY("#mktoForm_4738").find('[name="Community_Home_Site_Floor_Plan_Pref_1__c"]').val(homesiteNumber);

                var formfields = form.vals();
                var formfields_names = Object.keys(formfields);

                var formfilterValue = cookie_value;
                // console.log('Cookie value: '+formfilterValue);
                var outputArray = [];
                for (var i = 0; i < formfields_names.length; i++){
                    if(formfields_names[i] && formfields_names[i]!='munchkinId' && formfields_names[i]!='formid'){
                        if ((window.JQUERY.inArray(formfields_names[i], outputArray)) == -1){
                            outputArray.push(formfields_names[i]);
                        }
                    }                               
                }
                const reducedArray = outputArray.reduce((fields_names, feilds) => `${fields_names}${feilds},` ,'')
                var data = "";
                var data = {
                    action: 'formnames',
                    requestData: reducedArray,
                    filterType:'cookies',
                    filterValue: formfilterValue
                };
                window.JQUERY.ajax({
                    type: 'POST',
                    data: data,
                    url: "/wp-admin/admin-ajax.php",
                    success:function(response){
                        var field_havevalues = [];
                        var results = [];
                        results = JSON.parse(response);
                        //var dissatisfaction = results.dissatisfaction__c;
                       // var value_here = Array.from(dissatisfaction.split(';'));
                        var finalvaluestofill = {};
                        window.JQUERY.each(formfields_names,function(value, elem){
                            if(elem){
                                var keys = elem.toLowerCase();
                                if(keys in results){
                                    if(results[keys] && results[keys] != ''){
                                        finalvaluestofill[elem] = results[keys];
                                        field_havevalues.push(elem);
                                    }
                                }
                            }
                        });

                        if (finalvaluestofill) {
                            form.vals(finalvaluestofill);
                        }
                    }
                });
            });
        }
    }

    componentDidUpdate(){
        $('#mktoForm_4738 #requestedDate, #mktoForm_4738 img, .headtxt, .step, .heading, .subhead, p, #LbldecisionMakers, .whteInfo, label, .des, .nxtStep, .disclaimer, .skip, .boxed, .headd, .fancybox-close-small, input').attr('tabindex', '0');
        if(!evtTriggred){
            evtTriggred = true;
            if($('#mktoForm_4738 #requestedDate').length>0){
                $('#mktoForm_4738 #requestedDate').on('keydown',(e)=>{
                    if(e.which===9 || e.keyCode === 9){
                    $('#LblapptRequestedTime').focus();
                }    
            })
          }
        }

        $('#LblmktoCheckbox_90153_0, #LblmktoCheckbox_90153_1, #LblmktoCheckbox_90153_2, #LblmktoRadio_90111_0, #LblmktoRadio_90111_1, #LblmktoRadio_90163_0, #LblmktoRadio_90163_1').on('keydown',(e)=>{
            if(e.which===13 || e.keyCode === 13){
             e.target.click(); 
            }
        });

        if (this.props.form_details.schedule_appointment && window.JQUERY('#mktoForm_4738 #requestedDate').length > 0) {
            var minDate = new Date();
            minDate.setDate(minDate.getDate() + Number(this.props.form_details.schedule_appointment));
            window.JQUERY('#mktoForm_4738 #requestedDate').each(function () {
                window.JQUERY(this).datepicker('setDate', new Date(minDate));
                window.JQUERY(this).datepicker("option", "minDate", new Date(minDate));
            });
        }
    }

    componentDidMount() {
        this.state = {
            mkto_cookie: cookie.load('_mkto_trk'),
            holidays_added: false
        };

        this.TourloadMarketoForm();
    }

    render() {
        var pattern_image = this.props.form_details.pattern_image;
        var pattern_image_title=this.props.form_details.pattern_image.title;
        var thumbnail_image = this.props.form_details.tour_post_thumbnail;
        var tour_post_title = this.props.form_details.title;
        var tour_post_alt = this.props.form_details.pattern_image.alt;
        var thumbnail_image_alt=this.props.form_details.tour_post_thumbnail_alt;
        var thumbnail_image_title=this.props.form_details.tour_post_thumbnail_title;

        return (
            <div
                id="tour_forms"
                data-insights-index={index}
                data-insights-object-id={objectID}
                data-insights-position={position}
                data-insights-query-id={queryID}
            >
                <div class="tourpop_form">
                    <div class="form-column">
                        <form id="mktoForm_4738"></form>
                    </div>

                    <div className="form-image">
                        {/* {pattern_image ? (
                            <div className="two_image">
                                <img tabindex="0" src={pattern_image.url} alt={tour_post_alt ? tour_post_alt : tour_post_title} title={pattern_image_title}/>	
                            </div>
                        ):('')}   */}
                        <picture><img tabindex="0" src={thumbnail_image} alt={ thumbnail_image_alt ?  thumbnail_image_alt : thumbnail_image_title } title={thumbnail_image_title}/></picture>
                    </div>
                </div>
            </div>
        );
    }
}

export default QmiReserveForm;
