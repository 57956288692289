import React from 'react'
import SectionTitle from './SectionTitle'
import {Accordion,Card,Button} from 'react-bootstrap'
import './FaqSection.scss'

class FaqSection extends React.Component {
  constructor(props) {
    super(props)

    this.details = this.props.community_details
    this.faqs_data = this.props.hasOwnProperty('faqs') && this.props.faqs && this.props.faqs.hasOwnProperty('faq_info') && this.props.faqs.faq_info ? this.props.faqs.faq_info : this.details.hasOwnProperty('faq_info') && this.details.faq_info && this.details.faq_info.hasOwnProperty('faq_info') && this.details.faq_info.faq_info ? this.details.faq_info.faq_info : null
    this.section_id = this.details.section_id && this.details.section_id.trim()
  }

  /* Faqs Structured Data */
  faqs_structured_data(community_details) {
    var faq_data = []

    if (community_details.length > 0 ) {
      for (var i = 0; i < community_details.length; i++) {
        var structure_schema = `{
          "@type": "Question",
          "name": "${community_details[i].post_title}",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "${community_details[i].post_content.replace(/<\/?[^>]+(>|$)/g, '')}"
          }
        }`
        faq_data.push(structure_schema)
      }
    }

    const script = document.createElement("script")

    script.type = "application/ld+json"
    script.innerHTML = `{
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        ${faq_data}
      ]
    }`

    document.head.appendChild(script)
  }

  componentDidMount() {
    if (this.faqs_data && this.faqs_data.faq_questions.length > 0) {
      this.faqs_structured_data(this.faqs_data.faq_questions)
    }

    const section_id = this.details.section_id && this.details.section_id.trim()
    if (section_id) {
      setTimeout(function () {
        if (window.location.href.indexOf("#" +section_id) > -1) {
          document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth', block:'start' })
        }
      }, 2000)
    }
  }

  render() {
    return (
      this.faqs_data && this.faqs_data.faq_questions && this.faqs_data.faq_questions.length > 0 ? (
        <div className="faq-section" id={this.section_id}>
          <div className="wrap">
            <SectionTitle
              headline='Frequently Asked Questions'
              subtext={this.faqs_data.faq_heading}
              headline_size={this.faqs_data.hasOwnProperty('headline_size') ? this.faqs_data.headline_size : 'large'}
            />

            {this.faqs_data.faq_description  ? (
              <p className="faq-section-subtext description no-mr" dangerouslySetInnerHTML={{__html:this.faqs_data.faq_description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
            ):('')}

            <Accordion defaultActiveKey="0item">
              {this.faqs_data.faq_questions.map((item,key) => (
                <Card>
                  <Accordion.Collapse eventKey={key+"item"}>
                    <Card.Body>
                      <div class="faq-panel">
                        <div class="faq-description" dangerouslySetInnerHTML={{__html:item.post_content.replace(/(?:\r\n|\r|\n)/g, '<br>')}}></div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>

                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey={key+"item"}>
                      <div class="ques-heading">
                        <p class="faq-question">{item.post_title}</p>
                        <span class="plus-icon"></span>
                      </div>
                    </Accordion.Toggle>
                  </Card.Header>
                </Card>
              ))}
            </Accordion>
          </div>
        </div>
      ):('')
    )
  }
}

export default FaqSection