import React from 'react'
import './PlanDetails.scss';

class PlanDetails extends React.Component {
  render() {
    const bed = this.props.data.hasOwnProperty('bed') ? this.props.data.bed : this.props.data.hasOwnProperty('bedroom') ? this.props.data.bedroom : null

    return (
      <div class={`plan-details${this.props.border_top !== false ? ' plan-details--border-top' : ''}${this.props.class ? ' ' + this.props.class : ''} flex full-width margin-vertical padding-vertical`}
        style={{
          '--flex-align-items': 'stretch',
          '--flex-justify-content': 'space-between',
          '--flex-wrap': 'nowrap',
          '--margin-vertical-bottom-desktop': '0',
          '--margin-vertical-bottom-mobile': '0',
          '--margin-vertical-top-desktop': '22px',
          '--margin-vertical-top-mobile': '0',
          '--padding-vertical-bottom-desktop': '33px',
          '--padding-vertical-top-desktop': '22px',
          '--padding-vertical-bottom-mobile': '0',
          '--padding-vertical-top-mobile': '19px',
        }}>
        {this.props.data.square_feet &&
          <div class="plan-details__item flex flex-shrink font-size font--medium padding-vertical text-center width"
            data-square-feet
            style={{
              '--flex-align-items': 'center',
              '--flex-direction-desktop': 'column',
              '--flex-direction-mobile': 'column',
              '--flex-justify-content': 'space-between',
              '--font-size-desktop': '18px',
              '--font-size-mobile': '14px',
              '--padding-vertical-bottom-desktop': '5px',
              '--padding-vertical-top-desktop': '5px',
              '--padding-vertical-bottom-mobile': '6px',
              '--padding-vertical-top-mobile': '6px',
              '--width-mobile': 'auto',
              '--width-desktop': '25%',
            }}>
            {this.props.data.square_feet}
            <div class="plan-details__eyebrow eyebrow margin-vertical"
              style={{
                '--eyebrow-font-size': 'var(--plan-details-item-eyebrow-font-size, 10px)',
                '--margin-vertical-bottom-desktop': '0',
                '--margin-vertical-bottom-mobile': '0px',
                '--margin-vertical-top-desktop': '7px',
                '--margin-vertical-top-mobile': '7px',
              }}>
              <span class="hide-desktop-medium">Sq. Ft</span>
              <span class="show-desktop-medium">Sq. Footage</span>
            </div>
          </div>
        }

        {bed &&
          <>
            <div class="divider-line hide-desktop--off flex-shrink width"
              data-bed
              style={{
                '--height': '100%',
                '--width': '1px',
                'background-color': '#DEDEDE',
              }}>
            </div>

            <div class="plan-details__item border-custom--off flex flex-shrink font-size font--medium padding-vertical text-center width"
              style={{
                '--border-width-desktop': '0 0 0 1px',
                '--border-width-mobile': '0',
                '--flex-align-items': 'center',
                '--flex-direction-desktop': 'column',
                '--flex-direction-mobile': 'column',
                '--flex-justify-content': 'space-between',
                '--font-size-desktop': '18px',
                '--font-size-mobile': '14px',
                '--padding-vertical-bottom-desktop': '5px',
                '--padding-vertical-top-desktop': '5px',
                '--padding-vertical-bottom-mobile': '6px',
                '--padding-vertical-top-mobile': '6px',
                '--width-mobile': 'auto',
                '--width-desktop': '25%',
              }}>
              {bed}
              <div class="plan-details__eyebrow eyebrow margin-vertical"
                style={{
                  '--eyebrow-font-size': 'var(--plan-details-item-eyebrow-font-size, 10px)',
                  '--margin-vertical-bottom-desktop': '0',
                  '--margin-vertical-bottom-mobile': '0',
                  '--margin-vertical-top-desktop': '7px',
                  '--margin-vertical-top-mobile': '7px',
                }}>
                <span class="hide-desktop-medium">Beds</span>
                <span class="show-desktop-medium">Bedrooms</span>
              </div>
            </div>
          </>
        }

        {this.props.data.bath &&
          <>
            <div class="divider-line hide-desktop--off flex-shrink width"
              style={{
                '--width': '1px',
                'background-color': '#DEDEDE',
              }}>
            </div>

            <div class="plan-details__item border-custom--off flex flex-shrink font-size font--medium padding-vertical text-center width"
              data-bath
              style={{
                '--border-width-desktop': '0 0 0 1px',
                '--border-width-mobile': '0',
                '--flex-align-items': 'center',
                '--flex-direction-desktop': 'column',
                '--flex-direction-mobile': 'column',
                '--flex-justify-content': 'space-between',
                '--font-size-desktop': '18px',
                '--font-size-mobile': '14px',
                '--padding-vertical-bottom-desktop': '5px',
                '--padding-vertical-top-desktop': '5px',
                '--padding-vertical-bottom-mobile': '6px',
                '--padding-vertical-top-mobile': '6px',
                '--width-mobile': 'auto',
                '--width-desktop': '25%',
              }}>
              {this.props.data.bath}
              <div class="plan-details__eyebrow eyebrow margin-vertical"
                style={{
                  '--eyebrow-font-size': 'var(--plan-details-item-eyebrow-font-size, 10px)',
                  '--margin-vertical-bottom-desktop': '0',
                  '--margin-vertical-bottom-mobile': '0px',
                  '--margin-vertical-top-desktop': '7px',
                  '--margin-vertical-top-mobile': '7px',
                }}>
                <span class="hide-desktop-medium">Baths</span>
                <span class="show-desktop-medium">Bathrooms</span>
              </div>
            </div>
          </>
        }

        {this.props.data.garage &&
          <>
            <div class="divider-line hide-desktop--off flex-shrink width"
              style={{
                '--width': '1px',
                'background-color': '#DEDEDE',
              }}>
            </div>

            <div class="plan-details__item border-custom--off flex flex-shrink font-size font--medium padding-vertical text-center width"
              data-garage
              style={{
                '--border-width-desktop': '0 0 0 1px',
                '--border-width-mobile': '0',
                '--flex-align-items': 'center',
                '--flex-direction-desktop': 'column',
                '--flex-direction-mobile': 'column',
                '--flex-justify-content': 'space-between',
                '--font-size-desktop': '18px',
                '--font-size-mobile': '14px',
                '--padding-vertical-bottom-desktop': '5px',
                '--padding-vertical-top-desktop': '5px',
                '--padding-vertical-bottom-mobile': '6px',
                '--padding-vertical-top-mobile': '6px',
                '--width-mobile': 'auto',
                '--width-desktop': '25%',
              }}>
              {this.props.data.garage}
              <div class="plan-details__eyebrow eyebrow margin-vertical"
                style={{
                  '--eyebrow-font-size': 'var(--plan-details-item-eyebrow-font-size, 10px)',
                  '--margin-vertical-bottom-desktop': '0',
                  '--margin-vertical-bottom-mobile': '0px',
                  '--margin-vertical-top-desktop': '7px',
                  '--margin-vertical-top-mobile': '7px',
                }}>
                Bay Garage
              </div>
            </div>
          </>
        }

        {/* {this.props.data.stories &&
          <>
            <div class="plan-details__item border-custom--off flex flex-shrink font-size font--medium padding-vertical text-center width"
              style={{
                '--border-width-desktop': '0 0 0 1px',
                '--border-width-mobile': '0',
                '--flex-align-items': 'center',
                '--flex-direction-desktop': 'column',
                '--flex-direction-mobile': 'column',
                '--flex-justify-content': 'space-between',
                '--font-size-desktop': '18px',
                '--font-size-mobile': '14px',
                '--padding-vertical-bottom-desktop': '5px',
                '--padding-vertical-top-desktop': '5px',
                '--padding-vertical-bottom-mobile': '6px',
                '--padding-vertical-top-mobile': '6px',
                '--width-mobile': 'auto',
                '--width-desktop': '25%',
              }}>
              {this.props.data.stories}
              <div class="plan-details__eyebrow eyebrow margin-vertical"
                style={{
                  '--eyebrow-font-size': 'var(--plan-details-item-eyebrow-font-size, 10px)',
                  '--margin-vertical-bottom-desktop': '0',
                  '--margin-vertical-bottom-mobile': '0',
                  '--margin-vertical-top-desktop': '7px',
                  '--margin-vertical-top-mobile': '7px',
                }}>
                <span class="hide-desktop-medium">{this.props.data.stories === '1' ? ('Story') : ('Stories')}</span>
                <span class="show-desktop-medium">{this.props.data.stories === '1' ? ('Story') : ('Stories')}</span>
              </div>
            </div>
          </>
        } */}
      </div>
    )
  }
}

export default PlanDetails