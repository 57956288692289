import React, { useMemo } from 'react';
import { useSearchStateHitsDispatch } from './hooks/useSearchStateHits';
import IconDesignerCollection from '../ui/icons/IconDesignerCollection';
import DesignerCollectionHoverIcon from '../ui/DesignerCollectionBadge';
import DesignerCollectionBadge from '../ui/DesignerCollectionBadge';

function communityTitle(hit) {
	const { override_neighborhood_name, community_name_override } = hit;
	if (!override_neighborhood_name || !community_name_override) return hit.community;

	return community_name_override
}

function sqFootage(min, max) {
	const minVal = min ? min.toLocaleString() : "";
	if (!max || min === max) return minVal;

	const maxVal = max ? max.toLocaleString() : "";

	return `${minVal} - ${maxVal}`;
}

const SearchHitParentLink = ({ hit }) => {
	const isNeighborhood = hit.type === 'Neighborhood';
	const url = isNeighborhood && hit.community ? hit.community_url : hit.neighborhood_data?.url;
	const title = isNeighborhood ? `${communityTitle(hit)} Community` : hit.neighborhood_data?.title;
	const prefix = isNeighborhood ? 'in the' : 'at';

	if (!url || !title) {
		return null;
	}

	return (
		<h5 className="tw-text-charcoal tw-text-base tx-font-semibold tw-mb-3">
			{prefix} <a
				href={url}
				className="gtm-search-hit__link-group tw-text-charcoal tw-relative tw-border-b-2 tw-border-apple tw-pb-[1px] hover:tw-text-slate hover:tw-border-melon hover:tw-no-underline tw-leading-relaxed">
				{title}
			</a>
		</h5>
	);
};

// :after absolute so the whole card is clickable, but also can have community link
// https://heydon.github.io/Inclusive-Components/cards-pseudo-content-author-link/
const SearchHit = ({ hit, indexName }) => {
	const dispatch = useSearchStateHitsDispatch();
	const image = hit.image?.medium_large;

	// why does this get called twice after first one? doesn't happen with other state hooks
	const handleMouseEnter = () => {
		dispatch({
			type: 'changed',
			activeHitId: hit.objectID,
		});
	};
	const handleMouseLeave = () => {
		dispatch({
			type: 'changed',
			activeHitId: null,
		});
	};

	// Neighborhood | Floor Plan | Home
	const { type } = hit;
	const isNeighborhood = type === 'Neighborhood';

	const status = useMemo(() => {
		let hitStatus = isNeighborhood ? hit.neighborhood_status : hit.home_status;
		if (hitStatus === 'Move-In Ready') {
			const moveInDate = hit.move_in_date_timestamp * 1000;
			const moveInDateFormatted = new Date(moveInDate).toLocaleDateString('en-US', {
				month: 'short',
				year: 'numeric',
			});
			return `Move-In ${moveInDateFormatted}`;
		}
		if (hitStatus === "Priority Group Forming" && hit?.pg_launch_time_pst) {
			const launch = new Date(hit.pg_launch_time_pst)
			const now = new Date();
			if (launch > now) {
				hitStatus = "Coming Soon";
			}
		}
		return hitStatus;
	}, [isNeighborhood, hit]);

	const typeLabel = useMemo(() => {
		if (isNeighborhood) {
			return '';
		}
		return type.toLowerCase().includes('home') ? 'Available Home' : 'Floorplan';
	}, [isNeighborhood, type]);


	const title = useMemo(() => {
		if (!isNeighborhood) return hit.title;

		if (hit?.override_neighborhood_name === true) {
			if (hit?.neighborhood_name_override) {
				return hit.neighborhood_name_override;
			}
		}
		return hit.title
	}, [hit])

	const nowUnix = Math.round(Date.now() / 1000);
	const hasPromotion = hit.promotion && hit.promotion.start_date < nowUnix && hit.promotion.end_date > nowUnix;
	const href = `${hit.url}?index=${indexName}&objectID=${encodeURIComponent(hit.objectID)}&queryID=${hit.__queryID}&position=${hit.__position}`;

	return (
		<div
			className={`tw-animate-fade-in tw-relative tw-pb-12 tw-h-full tw-group`}
			onMouseEnter={() => handleMouseEnter()}
			onMouseLeave={() => handleMouseLeave()}
			data-insights-object-id={hit.objectID}
			data-insights-position={hit.__position}
			data-insights-query-id={hit.__queryID}
		>
			{hit.designer_collections?.length > 0 && (
				<div className="tw-group/dc tw-top-4 tw-absolute tw-right-4 tw-z-[1]">
					<DesignerCollectionBadge className="group-hover/dc:tw-visible group-hover/dc:tw-opacity-100">
						Designer Collections Available
					</DesignerCollectionBadge>
				</div>
			)}
			<div className="tw-ratio-3/2 tw-h-[234px] tw-w-full tw-relative tw-overflow-hidden">
				<img src={image} alt="" className="group-hover:tw-scale-105 tw-ratio-3/2 tw-h-[234px] tw-w-full tw-object-cover tw-transition-all tw-duration-500" />
				{hasPromotion && (
					<a
						style={{ backgroundColor: hit.promotion.bg_color }}
						href={hit.promotion.link}
						className="gtm-search-hit__link-promo tw-absolute tw-bottom-0 tw-right-0 tw-w-full tw-block tw-bg-aqua tw-text-center tw-text-charcoal tw-text-xxxs tw-font-bold tw-tracking-wide tw-uppercase tw-z-[2] tw-px-4 tw-py-2 hover:tw-text-black">
						{hit.promotion.snipe}
					</a>
				)}
				{status && (
					<span className={`tw-top-4 tw-rounded tw-absolute tw-font-bold tw-left-4 tw-uppercase tw-text-charcoal tw-text-xxxs tw-tracking-wide tw-bg-melon tw-px-4 tw-py-2 tw-z-1`}>{status}</span>
				)}
			</div>

			<div className="tw-flex tw-flex-col tw-z-[2] tw-mt-6 tw-mb-4" >
				{typeLabel && <span className='tw-uppercase tw-text-slate tw-text-xxxs tw-font-medium tw-tracking-wide tw-mb-2'>{typeLabel}</span>}
				<div>
					<h4 className="tw-text-charcoal tw-font-bold tw-text-2xl ">
						<a
							className="gtm-search-hit__link tw-text-charcoal hover:tw-text-charcoal hover:tw-no-underline after:tw-absolute after:tw-top-0 after:tw-bottom-0 after:tw-content[''] after:tw-right-0 after:tw-left-0 after:tw-z-0 "
							href={href}>
							{title}
						</a>
					</h4>
					<SearchHitParentLink hit={hit} />
					<div className="tw-flex tw-items-center">
						{hit.cities && (
							<>
								<span className="tw-font-medium tw-text-sm tw-text-charcoal tw-pr-2">{hit.cities[0]}, {hit.state_alpha_2_code}</span>
								{hit.price_copy && (
									<span className="tw-h-4 tw-w-[1px] tw-bg-slate"></span>
								)}
							</>
						)}
						{hit.price_copy && (
							<span className="tw-font-medium tw-text-sm tw-text-charcoal tw-pl-2">{hit.price_copy}</span>
						)}
					</div>
				</div>
			</div>
			<div className="tw-flex tw-flex-row tw-justify-between tw-mb-4 tw-min-h-[44px] tw-border-t tw-border-steel tw-pt-4">
				<div className="tw-w-full tw-border-r tw-border-steel">
					<div className="tw-flex tw-flex-col tw-h-full tw-mx-2">
						<span className="pb-1 tw-text-sm tw-text-ash tw-text-center tw-whitespace-nowrap tw-font-medium">
							{sqFootage(hit.min_sq_feet, hit.max_sq_feet)}
						</span>
						<span className="tw-tracking-wide tw-text-center tw-text-slate tw-uppercase tw-text-xxxxs tw-font-bold">SQ. FT.</span>
					</div>
				</div>
				<div className="tw-w-full tw-border-r tw-border-steel">
					<div className="tw-flex tw-flex-col tw-justify-end tw-h-full tw-px-2">
						<span className="pb-1 tw-text-sm tw-text-ash tw-text-center tw-font-medium">
							{hit.max_bedrooms === hit.min_bedrooms || !hit.max_bedrooms ? hit.min_bedrooms : `${hit.min_bedrooms} – ${hit.max_bedrooms}`}
						</span>
						<span className="tw-tracking-wide tw-text-center tw-text-slate tw-uppercase tw-text-xxxxs tw-font-bold">Bed</span>
					</div>
				</div>
				<div className="tw-w-full tw-border-r tw-border-steel">
					<div className="tw-flex tw-flex-col tw-justify-end tw-h-full tw-px-2">
						<span className="pb-1 tw-text-sm tw-text-ash tw-text-center tw-font-medium">
							{hit.max_bathrooms === hit.min_bathrooms || !hit.max_bathrooms ? hit.min_bathrooms : `${hit.min_bathrooms} – ${hit.max_bathrooms}`}
						</span>
						<span className="tw-tracking-wide tw-text-center tw-text-slate tw-uppercase tw-text-xxxxs tw-font-bold">Bath</span>
					</div>
				</div>
				<div className="tw-w-full">
					<div className="tw-flex tw-flex-col tw-justify-end tw-h-full tw-px-2">
						<span className="pb-1 tw-text-sm tw-text-ash tw-text-center tw-font-medium">
							{hit.max_garage === hit.min_garage || !hit.max_garage ? hit.min_garage : `${hit.min_garage} – ${hit.max_garage}`}
						</span>
						<span className="tw-tracking-wide tw-text-center tw-text-slate tw-uppercase tw-text-xxxxs tw-font-bold">Bay Garage</span>
					</div>
				</div>
			</div>
			<a
				style={{ '--btn-border': '1px solid var(--highlight-color)' }}
				href={href}
				className="gtm-search-hit__link button button--hollow full-width margin-vertical tw-cursor-pointer !tw-absolute tw-bottom-0 tw-left-0"
			>
				<span>
					View Details
				</span>
			</a>
		</div>
	);
};

export default SearchHit;
