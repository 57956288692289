import React from 'react';
import { useHits } from 'react-instantsearch-hooks-web';
import SearchHit from '../../Search/SearchHit';

const Hits = ({ hitType }, props ) => {
	const { hits, sendEvent, showMore, isLastPage } = useHits( props );

	if ( ! hits ) {
		return null;
	}

	return (
		<div className='tw-mb-16'>
			<div className="tw-grid tw-grid-cols-12 tw-gap-6">
				{hits.map( ( hit, index ) => {
					return (
						<div className="tw-col-span-12 sm:tw-col-span-6 lg:tw-col-span-3" key={index}>
							<SearchHit hit={hit} sendEvent={sendEvent} />
						</div>
					);
				} )}
			</div>
		</div>
	);
};

export default Hits;
