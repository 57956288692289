/* eslint-disable no-undef */
export const config = Object.freeze({
    ANALYTICS_TAGS: {
        searchForm: {
            nav: 'Search Form:Nav',
            searchPage: 'Search Form:Search Page',
            nearbyResults: 'Search Form:Nearby Neighborhoods and Homes',
            recentResults: 'Search Form:Based on Recent Search',
            nearby_neighborhoods: 'Search Form:Nearby Neighborhoods',
        },
    },
    ALGOLA_ENV: window.algolia.environment,
    ALGOLIA_SEARCH_API_KEY: window.algolia.search_key,
    ALGOLIA_APPLICATION_ID: window.algolia.app_id,
    ALGOLIA_INDEX_DEFAULT: 'neighborhoods',
    ALGOLIA_INDEX_HOMES: 'homes',
    ALGOLIA_INDEX_QUERY_SUGGESTIONS: `neighborhoods_homes_query_suggestions`,
    DEFAULT_SEARCH_URL_PATH: `find-your-home/`,
    DEFAULT_SEARCH_META_TITLE: 'Discover New Homes by Tri Pointe Homes',
    DEFAULT_SEARCH_META_DESC_LINE_1: 'Discover your dream home',
    DEFAULT_SEARCH_META_DESC_LINE_2:
        'Explore a wide range of neighborhoods and homes tailored to your preferences. Begin your journey with us and experience the joy of homeownership',
    API_ENDPOINT: '/wp-json/tpgsite/v1/',
    SQ_FEET_RANGE: [1000, 5000],
    PRICE_RANGE: [200000, 1000000],
    FILTER_OPTIONS_BEDROOM: [1, 2, 3, 4, 5, 6, 7],
    FILTER_OPTIONS_BATHROOM: [1, 2, 3, 4, 5, 6, 7],
    FILTER_OPTIONS_GARAGE: [1, 2, 3, 4],
    // everything isn't able to reference these, but most do
    // if atttribute names are changes, there are a few places that need to be updated, e.g. hits, filter string
    FILTER_ATTRIBUTES: {
        amenity: 'amenity_categories',
        availability: 'availability_status',
        bathMax: 'max_bathrooms',
        bathMin: 'min_bathrooms',
        bedMax: 'max_bedrooms',
        bedMin: 'min_bedrooms',
        cities: 'cities',
        designerCollections: 'designer_collections',
        garageMax: 'max_garage',
        garageMin: 'min_garage',
        garageRV: 'rv_garage_available',
        homeFeature: 'home_features',
        homeType: 'plan_type',
        modelTour: 'model_home_tour_available',
        moveInWindow: 'move_in_window',
        neighborhoodType: 'neighborhood_type',
        priceInUrl: 'price',
        priceMax: 'max_price',
        priceMin: 'min_price',
        schoolDistrict: 'school_district',
        sqFeetInUrl: 'sq_feet',
        sqFeetMax: 'max_sq_feet',
        sqFeetMin: 'min_sq_feet',
        tourOption: 'tour_options',
    },
    GOOGLE_MAPS_API_KEY: 'AIzaSyC-ILs_PxdVHTRQqgudhC8lydTXH_5PyGQ',
    LOCAL_STORAGE_KEY_PREFIX: 'tph_search.',
    MARKER_SIZE: 40,
    MARKER_ICON: require('../../../assets/images/location_marker.svg'),
    MARKER_ICON_ACTIVE: require('../../../assets/images/location_marker_active.svg'),
    MAP_DEFAULT_BOUNDS: {
        ne: {
            lat: 47.6062095,
            lng: -76.64127119999999,
        },
        sw: {
            lat: 31.12707458753413,
            lng: -122.3320708,
        },
    },
    SEARCH_PARAMS_WHITELIST: ['cities'],
});

export default config;
