import { useEffect, useState } from 'react';
import useDataApi from './useDataApi';
import config from '../config/config';

// [
// 	{
// 		label: 'Washington',
// 		key: 'washington',
// 		abbr: 'WA',
// 		submarkets: [
// 			{
// 				label: 'Greater Seattle Area',
// 				key: 'greater-seattle-area',
// 				stateLabel: 'Washington',
// 				stateAbbr: 'WA',
// 			}
//			...
// 		]
// 	}
//  ...
// ]
const useSearchSubmarkets = () => {
	const [ submarketsByState, setSubmarketsByState ] = useState( [] );
	const query = useDataApi(
		`${ config.API_ENDPOINT }search-data`,
		{ states: [] },
	);

	useEffect( () => {
		if ( ! query.data ) {
			return;
		}
		// const states = [];
		// for ( const [ key, value ] of Object.entries( data.data.state ) ) {
		// 	states.push( {
		// 		label: value.title,
		// 		key: value.name,
		// 		abbr: value.alpha_2_code,
		// 		submarkets: [],
		// 	} );
		// }
		// for ( const [ key, value ] of Object.entries( data.data.submarket ) ) {
		// 	// array of states because of Charlotte is in both NC and SC
		// 	const statesForSubmarket = value.states;
		// 	for ( const state of statesForSubmarket ) {
		// 		const stateInArray = states.find( ( s ) => s.key === state );
		// 		if ( stateInArray ) {
		// 			stateInArray.submarkets.push( {
		// 				label: value.title,
		// 				key: value.name,
		// 				stateLabel: stateInArray.label,
		// 				stateAbbr: stateInArray.abbr,
		// 			} );
		// 		}
		// 	}
		// }
		setSubmarketsByState( query.data.states );
	}, [ query.data ] );

	return { submarketsByState, isLoading: query.isLoading, isError: query.error };
};

export default useSearchSubmarkets;
