import React, { createElement, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { render } from 'react-dom';
import { initAlgoliaAnalytics, searchClient } from '../Search/helpers/initAlgolia';
import { autocomplete } from '@algolia/autocomplete-js';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';

import createStatesPlugin from './pluginStates';
import createNeighborhoodsPlugin from './pluginNeighborhoods';
import createHomePlansPlugin from './pluginHomePlans';
import createSubmarketsPlugin from './pluginSubmarkets';
import createCitiesPlugin from './pluginCities';
import config from '../Search/config/config';
import { getAutocompleteHitUrl, getQueryAsState, getQueryAsStateSubmarket } from './helpers';
import AutocompleteNoResults from './AutocompleteNoResults';
import createNearbyPlugin from './pluginNearby';
import useCloseAutocomplete from './useCloseAutocomplete';

const INSTANT_SEARCH_QUERY_SUGGESTIONS = `${config.ALGOLA_ENV}_${config.ALGOLIA_INDEX_QUERY_SUGGESTIONS}`;

const getUrl = ({ submarketsByState, query }) => {
    const state = getQueryAsState({ submarketsByState, query });
    if (state) {
        return getAutocompleteHitUrl({
            stateAbbr: state.alpha_2_code.toLowerCase(),
            override: state.override_search,
            override_url: state.override_search_link,
        });
    }
    const stateSubmarket = getQueryAsStateSubmarket({ submarketsByState, query });
    if (stateSubmarket) {
        return getAutocompleteHitUrl({
            stateAbbr: stateSubmarket.state.alpha_2_code.toLowerCase(),
            submarketSlug: stateSubmarket.submarket.slug,
            override: stateSubmarket.state.override_search,
            override_url: stateSubmarket.state.override_search_link,
        });
    }
    return getAutocompleteHitUrl({ query });
};

const handleQuerySelect = ({ submarketsByState, query }) => {
    const url = getUrl({ submarketsByState, query });
    window.location.href = url;
};

// this may not be specifically necessary for autocomplete, but it's on every page,
// so initial analytics for other blocks
initAlgoliaAnalytics();

export function Autocomplete({ submarketsByState, className, ...autocompleteProps }) {
    const autocompleteContainer = useRef(null);
    const [autocompleteInstance, setAutocompleteInstance] = useState(null);

    useCloseAutocomplete({ autocompleteContainer, autocompleteInstance });

    const plugins = useMemo(() => {
        const nearbySearches = createNearbyPlugin({ submarketsByState });

        const recentSearches = createLocalStorageRecentSearchesPlugin({
            key: 'recent-searches',
            limit: 3,
            transformSource(props) {
                const { source, state } = props;
                return {
                    ...source,
                    // getItemUrl( { item } ) {
                    // 	handleQuerySelect( { submarketsByState, query: item.label } );
                    // },
                    getItems({ items }) {
                        if (state.context.selectedState) {
                            return [];
                        }
                        return source.getItems({ items });
                    },
                    onSelect({ item }) {
                        handleQuerySelect({ submarketsByState, query: item.label });
                    },
                };
            },
        });

        const querySuggestions = createQuerySuggestionsPlugin({
            searchClient,
            indexName: INSTANT_SEARCH_QUERY_SUGGESTIONS,

            getSearchParams({ state }) {
                return recentSearches.data.getAlgoliaSearchParams({
                    hitsPerPage: state.query ? 3 : 0,
                });
            },
            transformSource(props) {
                const { source, state } = props;
                return {
                    ...source,
                    sourceId: 'querySuggestionsPlugin',
                    getItems({ items }) {
                        if (state.query === '') {
                            return [];
                        }
                        if (state.context.selectedState) {
                            return [];
                        }
                        return source.getItems({ items });
                    },
                    onSelect({ item }) {
                        handleQuerySelect({ submarketsByState, query: item.query });
                    },
                };
            },
        });

        const stateSuggestions = createStatesPlugin({ submarketsByState });

        const submarketSuggestions = createSubmarketsPlugin({ submarketsByState });
        const citiesSuggestions = createCitiesPlugin({ submarketsByState });
        const neighborhoodSuggestions = createNeighborhoodsPlugin();
        const homePlansSuggestions = createHomePlansPlugin();

        return [
            nearbySearches,
            recentSearches,
            querySuggestions,
            stateSuggestions,
            submarketSuggestions,
            citiesSuggestions,
            neighborhoodSuggestions,
            homePlansSuggestions,
        ];
    }, []);

    useEffect(() => {
        if (!autocompleteContainer.current) {
            return;
        }

        const instance = autocomplete({
            ...autocompleteProps,
            container: autocompleteContainer.current,
            insights: true,
            plugins,
            // keeps panel open
            // debug: true,
            onSubmit({ state }) {
                handleQuerySelect({ submarketsByState, query: state.query });
            },
            renderer: { createElement, Fragment, render },
            renderNoResults(props, root) {
                const { state, html } = props;

                render(<AutocompleteNoResults submarketsByState={submarketsByState} />, root);
            },
            onStateChange(props) {
                const { prevState, state, setContext } = props;
                if (prevState.query !== state.query && state.context.selectedState) {
                    setContext({ selectedState: null });
                }
            },
        });

        setAutocompleteInstance(instance);

        return () => instance.destroy();
    }, [plugins]);

    return <div className={className} ref={autocompleteContainer} />;
}
