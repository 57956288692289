import React from 'react';
import './VideoBlock.scss';
import HeadingSection from './HeadingSection';
import DirectionMapContainer from './DirectionMapContainer';

class DirectionMapBlock extends React.Component {  

  render() {  
    var direction_details = this.props.direction_details;  
    var direction_map_data = direction_details.direction_map_data;

     return (  
          direction_map_data ? (
            direction_map_data.map_description || direction_map_data.map_sub_text || direction_map_data.map_headline ? (
              <div className="video-main-div direction-map-div"> 
                  <div className="video-iframe divide-equal"  >
                    {direction_map_data.map_link ?(
                      <iframe tabindex="0" src={direction_map_data.map_link} className="direction_map" title="direction map" ></iframe>
                    ):(
                      <DirectionMapContainer direction_details={direction_details}/>
                    )}  
                  </div>
                  <div className="video-info divide-equal">
                      <HeadingSection 
                        subtitle={direction_map_data.map_sub_text}
                        title={direction_map_data.map_headline}
                        highlight_class='green-color'
                        highlight_dir='left'
                        highlighted_word_count={2}
                        text_class='gray-color'
                        headline_size={direction_map_data.hasOwnProperty('headline_size') ? direction_map_data.headline_size : 'large'}
                      />
                      {direction_map_data.map_description ?( 
                        <p tabindex="0" className="title-info" dangerouslySetInnerHTML={{__html:direction_map_data.map_description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                      ):('')}
                      {direction_map_data.cta_label && direction_map_data.cta_link ? (
                              <a href={direction_map_data.cta_link}  className="learn-more" title={direction_map_data.cta_label}>{direction_map_data.cta_label}</a>
                      ):('')}
                  </div>
              </div> 
            ):('')
          ):('')
     );  
  }  
}  

export default DirectionMapBlock; 