import React from 'react';
import './Direction.scss';
import '../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'
class Direction extends React.Component { 
    direction_data_layer_push(param) {
        this.setState({ value:param })
        if(param && param==='drivingDirections') {
            window.dataLayer.push({'event':'drivingDirections'});
        }
     }
    render() {  
        var direction_details = this.props.direction_details;  
        var posttype = this.props.posttype;  
        var toShow,time_split;
       return ( 
            <div className="direction-main-div">
                <div className="main-header">
                    <div className="direction-header">
                        {direction_details.title ? (
                            <h1 tabindex="0" className="direction-heading">
                                {direction_details.title} Hours & Directions
                            </h1>
                        ):('')}
                        {direction_details.sales_office_address.sales_address ?(
                            <p tabindex="0" className="direction-subtext" dangerouslySetInnerHTML={{__html: direction_details.sales_office_address.sales_address.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                        ):(
                            direction_details.physical_address.comm_address ? (
                                <p tabindex="0" className="direction-subtext" dangerouslySetInnerHTML={{__html: direction_details.physical_address.comm_address.replace(/(?:\r\n|\r|\n)/g, '')}}>
                                    
                                </p>
                            ):('')
                        )}
                    </div>
                </div>
                <div className="direction-content-image">
                    <div className="direction-content">
                        <div className="hours-direction">
                            {direction_details.sales_office_address.sales_address_heading || direction_details.sales_office_address.sales_address ? [
                                <p className="hours-main">
                                    <h3 tabindex="0" className="hours-heading">Visit our New Homes Gallery</h3>
                                    {direction_details.sales_office_address.sales_address_heading ?(
                                        <p tabindex="0" className="hours" dangerouslySetInnerHTML={{__html: direction_details.sales_office_address.sales_address_heading.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                                    ):('')}
                                    {direction_details.sales_office_address.sales_address ?(
                                        <p tabindex="0" className="hours" dangerouslySetInnerHTML={{__html:direction_details.sales_office_address.sales_address.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                                    ):('')}
                                    {direction_details.sales_office_address.custom_direction_link ?(
                                        <a href={direction_details.sales_office_address.custom_direction_link} target="_blank" rel="noopener noreferrer"  onClick={() => this.direction_data_layer_push('drivingDirections')} className="driving-directions" title="Get Directions">Get Directions</a>
                                    ):('')}
                                </p>,
                                <p className="hours-main">
                                {direction_details.physical_address.comm_address || direction_details.physical_address.physical_address_heading ? [
                                    direction_details.title ?(
                                        <h3 tabindex="0" className="hours-heading" dangerouslySetInnerHTML={{__html:direction_details.title.replace(/(?:\r\n|\r|\n)/g, '')}}></h3>
                                    ):(''),
                                    direction_details.physical_address.physical_address_heading ?(
                                        <p tabindex="0" className="hours" dangerouslySetInnerHTML={{__html:direction_details.physical_address.physical_address_heading.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                                    ):(''),
                                    direction_details.physical_address.comm_address ?(
                                        <p tabindex="0" className="hours"  dangerouslySetInnerHTML={{__html:direction_details.physical_address.comm_address.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                                    ):('')
                                ]:['']}
                                </p>
                            ]:['']}
                 
                            {direction_details.time_hour ? (
                                <p className="hours-main">
                                    <h3 tabindex="0" className="hours-heading">Hours</h3>
                                    {time_split = direction_details.time_hour.split('|'),
                                    time_split.map(items => (
                                    <p tabindex="0" className="hours" dangerouslySetInnerHTML={{__html: items.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                                    ))}
                                </p>
                            ):('')}

                            {direction_details.driving_directions_detail ? (
                                <p className="hours-main">
                                    <h3 tabindex="0" className="hours-heading">Directions</h3>
                                    {toShow = direction_details.driving_directions_detail.substring(0,2000),
                                    <p tabindex="0" className="address" dangerouslySetInnerHTML={{__html:toShow.replace(/(?:\r\n|\r|\n)/g, '')}}></p>}
                                </p>
                            ):('')}

                            {direction_details.sales_office_address.sales_address_heading === null || direction_details.sales_office_address.sales_address === null ?(
                                    direction_details.physical_address.map_directions_link  ? (
                                        <a href={direction_details.physical_address.map_directions_link}  onClick={() => this.direction_data_layer_push('drivingDirections')} className="neigh-map" target="_blank"  rel="noopener noreferrer" title="Get Directions">Get Directions</a>
                                    ):('')
                            ):('')}
                        </div>
                    </div>
                    <div className="direction-image">
                        {direction_details.direction_images ? (
                            <div className="image-div">
                                <img tabindex="0" src={direction_details.direction_images} className="full-image" alt={direction_details.direction_images_alt ? direction_details.direction_images_alt:direction_details.direction_images_title} title={direction_details.direction_images_title}/>
                            </div>
                        ):('')}
                    </div>
                </div>
            </div>
      );  
    }  
  }  
  export default Direction; 
