import React from 'react';
import './Gallery.scss';
import HeadingSection from './HeadingSection';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Modal } from 'react-bootstrap';
import GalleryModal from './GalleryModal';
const options = {
    nav: true,
    dots: false,
    loop : false,
    responsive : {
        0:{
            items: 1,
            margin: 10
        },
        480:{
            items: 2,
            margin: 25
        },
        1024:{
            items: 3,
            margin: 15
        },
        1200:{
            items: 2,
            margin: 25
        }
    }
}

class Gallery extends React.Component {
    constructor(){
        super();
        this.state = {
          showHide : false
        }
      }

    handleModalShowHide() {
        this.setState({ showHide: !this.state.showHide })
    }
    componentDidMount() {
        const gallery_v2 = this.props.gallery_v2;
        const section_id = gallery_v2.section_id && gallery_v2.section_id.trim();
        if (section_id) {
            setTimeout(function () {
                if (window.location.href.indexOf("#" + section_id) > -1) {
                    document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
                }
            }, 2000)
        }
    }
  render() {
    var window_width = window.screen.width;
      var gallery_v2 = this.props.gallery_v2;
      var section_id = gallery_v2.section_id && gallery_v2.section_id.trim();
     return (
        gallery_v2.gallery_images.length > 0 ? (
             <div className="gallery-section" id={section_id}>
            <div className="gallery-side-img" >
                {gallery_v2.image.url ? (
                    <img tabindex="0" src={gallery_v2.image.url} title={gallery_v2.image.title} alt={gallery_v2.image.alt ? gallery_v2.image.alt : gallery_v2.image.title } />
                ):('')}
            </div>
            {gallery_v2.gallery_images.length > 0 ? [
            <div className="gallery-info section-margin section-padding wrap">
                <HeadingSection
                  subtitle={gallery_v2.sub_text}
                  title={gallery_v2.headline}
                  headline_size={gallery_v2.hasOwnProperty('headline_size') ? gallery_v2.headline_size : 'large'}
                />
                <div className="gallery-main-img">
                        <OwlCarousel {...options} className="owl-theme">
                            {gallery_v2.gallery_images.map(items => (
                                items.image !== false ? (
                                <div >
                                    <img tabindex="0" src={items.image.url} title={items.image.title}  alt={items.image.alt ?items.image.alt : items.image.title} />
                                    <p tabindex="0" className="image-text">{items.image_caption}</p>
                                </div>
                                ):('')
                            ))}
                            {(window_width > 1024) ? (
                                <div className="carousel-btn">
                                <a href="javascript:void(0)" title="view all" onClick={() => this.handleModalShowHide()} className="carousel-learn-more">View All</a>
                            </div> ):('')}
                        </OwlCarousel>
                </div>
                <a href="javascript:void(0)" title="view all" onClick={() => this.handleModalShowHide()} className="gallery-learn-more">View All</a>
            </div>,
            <Modal className="gallery_modal comm_gallery_modal" show={this.state.showHide}>
                <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                <Modal.Title>
                    {gallery_v2.iframe_heading && <h2 tabindex="0">{gallery_v2.iframe_heading}</h2>}
                    {gallery_v2.iframe_subtext && <p tabindex="0">{gallery_v2.iframe_subtext}</p>}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GalleryModal gallery={gallery_v2}/>
                </Modal.Body>
            </Modal>
            ]:['']}
        </div>
        ):('')
     );
  }
}

export default Gallery;
