import HeadingSection from './HeadingSection';
import './FocusBoxForTable.scss';
import React, { useState, useEffect } from "react";

export const FocusBoxForTable = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [getImage, setImage] = useState('');
  const [getTable, setTable] = useState('');

  var focus_data = props.focus_table_data;

  const accordionHandle = (item,key) => {
      setImage('');
      setActiveTab('');
      if (item.show_table === true && item.wp_table_builder) {
          setTable(item.wp_table_builder);
      }else{
          setImage(item.grid_icon);
      }
      setActiveTab(key);
  }

  useEffect(() => {
    if (focus_data && focus_data.grid_layout && focus_data.grid_layout.length > 0) {
      if (focus_data.grid_layout[0].show_table === true && focus_data.grid_layout[0].wp_table_builder) {
        setTable(focus_data.grid_layout[0].wp_table_builder);
      } else {
        setImage(focus_data.grid_layout[0].grid_icon);
      }
    }
  }, [])

  return (
    focus_data && focus_data.grid_layout && focus_data.grid_layout.length > 0 ? [
      <section className="focus_boxes">
        <div className="boxes_wrapper">
            <div className="accordion_wrapper">
                <div className="heading_wrap">
                <HeadingSection
                          subtitle={focus_data.sub_text}
                          title_part_1={focus_data.headline}
                          title_part_2={focus_data.headline_part_2}
                          headline_part_1_color = {focus_data.headline_part_1_color}
                          headline_part_2_color = {focus_data.headline_part_2_color}
                />
                {focus_data.description  ? (
                                <p tabindex="0" className="focus-boxes-subtext no-mr" dangerouslySetInnerHTML={{__html:focus_data.description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                            ):('')}
                </div>
                {focus_data.grid_layout.map((item,key) => (
                                item.grid_headline && item.grid_description && item.grid_icon.url ? (
                <div className="accordion_block">
                    <div className="item" onClick={ () => accordionHandle(item,key)}>
                        <div className={"item_head " + (key === activeTab ? 'active' : '')}>
                            <h3 tabindex="0" className="focus-boxes-heading">{item.grid_headline}</h3>
                            {item.grid_description ? [
                                                <p tabindex="0"
                                                onKeyPress={(e)=>{
                                                    if(e.keyCode == 13 || e.which == 13) e.target.click();
                                                }}
                                                className="focus-boxes-description" dangerouslySetInnerHTML={{__html:item.grid_description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                                            ]:['']}
                            <div tabindex="0" className="arrowRight"></div>
                        </div>
                    </div>

                </div>
                  ):('')
                  ))}
            </div>

            <div className="accordion_content">
                <div className="boxes-wrap-result">

                { getImage ?
                        <img src={getImage.url} title={getImage.title} alt={getImage.alt ? getImage.alt : getImage.title}/>
                    : getTable ?  <div className="wp_shortcode" dangerouslySetInnerHTML={{ __html:getTable.replace(/(?:\r\n|\r|\n)/g, '') }} />  : ''}

                </div>
            </div>
        </div>
      </section>
    ]:['']
  )
}
