import React, { useMemo } from 'react';
import { useInstantSearch } from 'react-instantsearch-hooks-web';

const NoResults = () => {
	const { indexUiState } = useInstantSearch();

	const noResultsText = useMemo( () => {
		return indexUiState.query ? indexUiState.query : 'this search';
	}, [ indexUiState.query ] );

	return (
		<div className='tw-my-6'>
			<h3>
		        No results {noResultsText}.
            </h3>
        </div>
    );
};

export default NoResults;
