import React from 'react';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";


class SkeletonComp extends React.Component {  
 
  render() {    
    var dynamicClass = this.props.dynamicClass
     return ( 
        <SkeletonTheme >
            {dynamicClass == 'dynamic' ? [
                <div className="sub-navbar">
                    <nav className="navbar navbar-expand-xl navbar-light">
                        <div className="left-nav navbar-nav">
                            <Skeleton width={100} className="nav-link"/>
                            <Skeleton width={100} className="nav-link"/>
                            <Skeleton width={100} className="nav-link"/>
                            <Skeleton width={100} className="nav-link"/>
                            <Skeleton width={100} className="nav-link"/>
                        </div>
                    </nav>
                    <div className="breadcrumbs_section">
                        <Skeleton width={300}/>
                    </div>
                </div>,
                <div className="Banner-Main">
                    <div className="banner-top-section">
                        <div className="banner-first-section">
                            <div className="banner-left-section">
                                <Skeleton height={50} className="comm-name"/>
                                <Skeleton height={20} className="comm-price"/>
                                <Skeleton height={20} className="card-status" width={150}/>
                                <Skeleton height={20} className="comm-price marketing-headline"/>
                            </div>
                            <ul className="comm-info">
                                <li>
                                    <Skeleton width={100}/>
                                    <Skeleton width={100}/>
                                </li>
                                <li>
                                    <Skeleton width={100}/>
                                    <Skeleton width={100}/>
                                </li>
                                <li>
                                    <Skeleton width={100}/>
                                    <Skeleton width={100}/>
                                </li>
                                <li>
                                    <Skeleton width={100}/>
                                    <Skeleton width={100}/>
                                </li>
                            </ul>
                        </div>
                        <div className="banner-second-section">
                            
                            <div className="banner-bottom-section">
                                <ul>
                                    <div className='banner-address'>
                                            <Skeleton height={60}/>
                                    </div>
                                    <Skeleton height={40} className="city"/>
                                </ul>
                            </div>
                            <div className="schedule_tour_div">
                                <Skeleton height={30}/>
                            </div>
                        </div>
                    </div>
                    
                    <Skeleton className="banner-img-section"/>
                </div> 

            ]:[
                <div className="plan-banner">
                    <div className="HeroContent d-flex">
                        <div className="HeroContentWrapper">
                            <div className="bannerHeader">
                                <div className="heading-section">
                                    <Skeleton height={40}/>
                                    <Skeleton />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ]}
            <div className="schedule-tour">
                <div className="two-image">
                    <div className="image-section" style={{backgroundColor:'#eeeeee'}}>
                        <div className="image-building">
                           <Skeleton className="image-building-image"/>
                        </div>
                    </div>
                </div>
                <div className="content-section">
                    <Skeleton height={20} />
                    <Skeleton height={40}/>
                    <Skeleton height={200}/>
                    <Skeleton width='20%' height={35} />
                </div>
            </div>
            <div className="spring-section" style={{backgroundColor:'#f9f9f9'}}>
                <Skeleton height={20}/>
                <Skeleton height={40}/>
                <Skeleton height={200}/>
            </div>
            <section className="main-div">
                <div className="accordion-newHomeSection">
                    <div className="accordionWrapper">
                        <div className="mobileImageArea"></div>
                        <div className="headerArea">
                        <Skeleton height={40}/>
                        <Skeleton height={100}/>
                        </div>
                        <div className="accordionWrapperItem">
                            <div className="item">
                                <div className="itemHead">
                                    <Skeleton height={20}/>
                                    <div className="mobileAccordion">
                                        <div className="mobileImgArea">
                                            <img/>
                                        </div>
                                        <Skeleton height={20} className="mobileDesc"/>
                                    </div>
                                    <Skeleton height={50} className="focus-boxes-description"/>
                                    <div className="arrowRight"></div>
                                </div>
                                <div className="imgarea">
                                   <img/>
                                </div>
                            </div>
                            <div className="item">
                                <div className="itemHead">
                                    <Skeleton height={20}/>
                                    <div className="mobileAccordion">
                                        <div className="mobileImgArea">
                                            <img/>
                                        </div>
                                        <Skeleton height={20} className="mobileDesc"/>
                                    </div>
                                    <Skeleton height={50} className="focus-boxes-description"/>
                                    <div className="arrowRight"></div>
                                </div>
                                <div className="imgarea">
                                   <img/>
                                </div>
                            </div>
                            <div className="item">
                                <div className="itemHead">
                                    <Skeleton height={20}/>
                                    <div className="mobileAccordion">
                                        <div className="mobileImgArea">
                                            <img/>
                                        </div>
                                        <Skeleton height={20} className="mobileDesc"/>
                                    </div>
                                    <Skeleton height={50} className="focus-boxes-description"/>
                                    <div className="arrowRight"></div>
                                </div>
                                <div className="imgarea">
                                   <img/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
           </section>
        </SkeletonTheme>
     );  
  }  
}  

export default SkeletonComp; 