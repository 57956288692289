import React from 'react';
import axios from 'axios';
import './Header.scss';
import { apiConfig } from '../Classes/Helper.jsx';
import LoginButton from '../Classes/LoginButton.jsx';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import AutocompleteContainer from '../Autocomplete/AutocompleteContainer';
import SubmarketList from '../ui/SubmarketList';
import IconCaret from '../ui/icons/IconCaret';
import callTrkSwap from '../../helpers/callTrkSwap.js';

class Header extends React.Component {

    state = {
        header_info: [],
        show_search_input: false,
        success: false,
    }

    Clickhamburger() {
        $('#hamburgerBtn').on('click', () => {
            if ($('.headerWrapper').hasClass('active')) {
                this.setState({show_search_input: false});
                $('body').css('overflow', 'auto');
                $('.headerWrapper').removeClass('active');
                $('.main-container, .scroll-bar, .footer_section').css('display','block');
            } else {
                this.setState({show_search_input: true});
                $('body').css('overflow', 'hidden');
                $('.headerWrapper').addClass('active');
                $('.main-container, .scroll-bar, .footer_section').css('display','none');
            }
            
        })
         /*Close header on anchor click*/
        $(".header_section a").click(function(){
            $('body').css('overflow', 'auto');
            $('.headerWrapper').removeClass('active');
            $('.main-container, .scroll-bar, .footer_section').css('display','block');
        })

        // Close header on Headerlink click
        $(".headerLink").click(function(){
            $('body').css('overflow', 'auto');
            $('.headerWrapper').removeClass('active');
            $('.main-container, .scroll-bar, .footer_section').css('display','block');
        });
    }

    componentDidMount() {
        
        axios.get(apiConfig().API_URL + '/header_info')
            .then(response => {
                this.setState({ header_info: response.data.data, success: response.data.success })
            });
        
        setTimeout(() => {
            this.Clickhamburger();
        }, 2000);

        if(window.location.href.indexOf("wpengine") > -1) {
            window.dataLayer.push({
                'event':'environment',
                'staging_production_flag':'Staging'
            });
        } else {
            window.dataLayer.push({
                'event':'environment',
                'staging_production_flag':'Production'
            });
        }
        
        callTrkSwap();
        
        if (window.location.href.indexOf("#") === -1) {
            window.scrollTo(0, 0);
        }
    }

    componentDidUpdate(){
        callTrkSwap();
        if (window.location.href.indexOf("#") === -1) {
            window.scrollTo(0, 0);
        }
    }

    componentWillReceiveProps(){
        $('.header_section .headerWrapper').removeClass('active');
        $('.main-container, .scroll-bar, .footer_section').css('display','block');
        this.Clickhamburger();
        callTrkSwap();
    }

    render() {
        var success = this.state.success;
        var Header_details = this.state.header_info;
        return (
            <div role="banner" className="header_section">
                 {
                    success ? [
                        <div className="headerWrapper">
                            <div className="headerLogoArea">
                                <div className="headerLogoWrapper d-flex">
                                    <div className="logoWrapper">
                                        <Link to={'/'}
                                            onKeyPress={(e) => {
                                                if (e.keyCode === 13 || e.which === 13) e.target.click();
                                            }}>
                                            <img tabindex="0" className="headerLogo" src={require('../../assets/images/TRIPOINTEHOMES_MASTER_COLOR_RGB.png')} title="Tri Pointe Homes logo" alt="Tri Pointe Homes logo" />
                                            <img tabindex="0" className="headerLogo logoLight" src={require('../../assets/images/TRIPOINTEHOMES_MASTER_COLOR_REVERSE_RGB.png')} title="Tri Pointe Homes logo 2" alt="Tri Pointe Homes logo 2" />
                                        </Link>
                                    </div>
                                    <button 
                                        aria-label="Open nav" 
                                        type="button" 
                                        id="hamburgerBtn" 
                                        className="hamburgerBtn d-flex"
                                        onKeyPress={(e)=>{ if(e.keyCode === 13 || e.which === 13) e.target.click(); }}
                                    >
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>
                                </div>
                            </div>
                                
                            <div role="navigation" className="navigationMain bg-diagonal" >
                                <div className="navigationWrapper tw-h-full tw-flex tw-flex-col tw-justify-between">
                                    <div className="tw-flex tw-justify-between tw-flex-col lg:tw-flex-row">
                                        {/* autocomplete search and submarket list */}
                                        <div className="tw-w-full lg:tw-mr-4 xl:tw-mr-24 tw-max-w-[750px]">
                                            <div className="headingWrapper">
                                                {/* not using  due to styling differences */}
                                                <div className='tw-text-2xl lg:tw-text-3xl tw-font-bold tw-text-white tw-mb-2'>
                                                    <span className="tw-text-kiwi">Where</span> do you want to live?
                                                </div>
                                            </div>
                                            <div className="tw-mb-8">
                                                {this.state.show_search_input && <AutocompleteContainer />}
                                            </div>
                                            <div className="tw-hidden tw-max-h-[780px] md:tw-max-h-[440px] xl:tw-max-h-[500px] lg:tw-flex tw-flex-col tw-flex-wrap tw-flex-auto">
                                                <SubmarketList />
                                            </div>
                                        </div>
                                        {/* Big links */}
                                        <div className='tw-mt-0 lg:tw-mt-20'>
                                            <div className="tw-flex tw-flex-col tw-mx-0 lg:tw-mr-8 lg:tw-ml-6">
                                                <div>
                                                    {Header_details.header_details.map((item, key) => {
                                                        const url = item.page_title === 'Find Your Home' ? '/find-your-home/' : item.page_url;
                                                        return (
                                                            <h4 className="tw-bold tw-text-xl lg:tw-text-2xl tw-mb-4 tw-group">
                                                                <a href={url} className="tw-text-white tw-inline-block hover:tw-no-underline tw-border-b-2 tw-border-transparent hover:tw-border-apple">
                                                                    <span className="tw-flex tw-items-center">
                                                                        <span className="tw-mr-2">
                                                                            {item.page_title}
                                                                        </span>
                                                                        <IconCaret fill="white" direction="right" />
                                                                    </span>
                                                                </a>
                                                            </h4>
                                                        )
                                                    })}
                                                </div>
                                                <div className="tw-my-2 lg:tw-my-8">
                                                    <LoginButton/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Bottom links */}
                                    <div className="tw-hidden lg:tw-block">
                                        <ul className='tw-pl-0 tw-flex tw-list-none tw-ml-2'>
                                        {Header_details.footer_menu.map((item, key) => (
                                            <>
                                            {item.title && (
                                                <li className='tw-mr-4'>
                                                    <a href={item.url} className="tw-underline-offset-4 tw-font-semibold tw-text-white tw-text-xs">{item.title}</a>
                                                </li>
                                            )}
                                            </>
                                        ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ] : [
                        <SkeletonTheme >
                            <div className="headerWrapper">
                                <div className="headerLogoArea">
                                    <div className="headerLogoWrapper d-flex">
                                        <Skeleton className="logoWrapper"/>
                                    </div>
                                </div>
                            </div>
                        </SkeletonTheme>
                    ]
                }
            </div>
        );
    }
}

export default Header;
