import React from "react";
import { FaStar ,FaStarHalfAlt,FaRegStar } from "react-icons/fa";
import "./ReviewBox.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import moment from "moment";


export default function ReviewBox(props) {

  var main_data = props.eliant_reviews;
  var review_exits =props.review_exits;
  console.log(main_data);

  const Small_starRating = (star_rating) => {
    var Small_finalRating = [];
    var rating = star_rating;
    var ratingDevide = (rating + "").split(".");
    var isHalfPrint = false;

    for (var i = 1; i <= 5; i++) {
      if (i <= ratingDevide[0]) {
        Small_finalRating.push(
          <span>
            <FaStar />
          </span>
        );
      } else {
        if (ratingDevide[1] !== "" && isHalfPrint === false) {
          isHalfPrint = true;
          Small_finalRating.push(
            <span>
              <FaStarHalfAlt />
            </span>
          );
        } else {
          Small_finalRating.push(
            <span>
              <FaRegStar />
            </span>
          );
        }
      }
    }
    return Small_finalRating;
  };
  var toShow,textTrim;
  return (
    review_exits ? 
    main_data ? main_data.map((items)=>
        (
          <div id="review_card">
        <div className="review_content">
          {items.comment ? (
              items.comment.length >350 ? (
                toShow = items.comment.substring(0,350),
                textTrim = toShow.substring(0, toShow.lastIndexOf(" ")) + "...",
                <p className="review_comment">
                {textTrim}
            </p>
              ) : (
                <p className="review_comment">
                {items.comment}
            </p>
              )
          ) : ('') }
              <div className="review_star_rating">
                    <div className="star_rating">
                      {Small_starRating(items.star_rating)}
                    </div>
                    <div className="review_rating">
                      {items.star_rating} / 5
                    </div>
              </div>
        </div>
        <div className="review_date">{moment(items.date).format("LL")}</div>
    </div>
        )
        
    ) : ''
    : <SkeletonTheme>
    <div id="review_card">
    <Skeleton height={100}/>
    <div className="review_content">
          <div className="review_comment">
          <Skeleton height={100}/>
          </div>
  
          <div className="review_star_rating">
                <Skeleton height={20}/>             
            </div>
      </div>
    </div>
    <Skeleton height={100}/>
  </SkeletonTheme>
  )
}
