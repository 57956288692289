import React from 'react';
import './SectionTitle.scss';

class SectionTitle extends React.Component {
  split_string(str, count = 2, dir = 'left', splitBy = ' ') {
    var part1, part2;
    var split_array = str.split(splitBy);
    if (dir == 'right') {
      part2 = split_array.splice(split_array.length - count).join(splitBy);
      part1 = split_array.join(splitBy);
    } else {
      part1 = split_array.splice(0, count).join(splitBy);
      part2 = split_array.join(splitBy);
    }
    return [part1, part2];
  }
  componentDidMount() {
    const section_id = this.props.section_id && this.props.section_id.trim();
    if (section_id) {
      setTimeout(function () {
        if (window.location.href.indexOf("#" + section_id) > -1) {
            document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth', block:'start' });
        }
      }, 2000)
    }
  }
  render() {
    var headline = this.props.headline;
    var sitemap_heading = this.props.check;
    var posttype = this.props.posttype;
    var community_check = this.props.community;
    var splited_title = community_check === 'community' ? headline && this.split_string(headline, 2) : headline && this.split_string(headline, 1);
    var promo_title_color = this.props.promo_title_color;
    var subtext = this.props.subtext;
    var highlight_class = 'green-color';
    var text_class = 'gray-color';
    var section_id = this.props.section_id && this.props.section_id.trim();
    var headlineSize = this.props.hasOwnProperty('headline_size') ? this.props.headline_size : 'large'
    return (
      (headline || subtext || this.props.title_part_1 || this.props.title_part_2 || sitemap_heading) ? (
        <div className={`sectiontitle-main-div${this.props.hasOwnProperty('wrap') ? ' wrap' : ''}`} id={section_id}>
          {this.props.title_part_1 || this.props.title_part_2 ? (
            <div className={`sectiontitle-headline sectiontitle-headline--${headlineSize}`}>
              {this.props.title_part_1 && <span style={{ color: this.props.headline_part_1_color }} dangerouslySetInnerHTML={{ __html: this.props.title_part_1.replace(/(?:\r\n|\r|\n)/g, '') }}></span>}
              <span> </span>
              {this.props.title_part_2 && <span style={{ color: this.props.headline_part_2_color }} dangerouslySetInnerHTML={{ __html: this.props.title_part_2.replace(/(?:\r\n|\r|\n)/g, '') }}></span>}
            </div>
          ) : (
            headline ? ( this.props.posttype == "submarket" || this.props.posttype == "city" || this.props.posttype == "state" ? (<h2 className={`sectiontitle-headline sectiontitle-headline--${headlineSize} ${text_class || ""}`}>
              <span className={highlight_class || ''} dangerouslySetInnerHTML={{ __html: splited_title[0].replace(/(?:\r\n|\r|\n)/g, '') }}></span>
              <span> </span>
              <span dangerouslySetInnerHTML={{ __html: splited_title[1].replace(/(?:\r\n|\r|\n)/g, '') }}></span>
            </h2>) : (
              (sitemap_heading === 'true') ? (<h1 className={`sectiontitle-headline sectiontitle-headline--${headlineSize} ${text_class || ""}`}>
                <span className={highlight_class || ""}
                  dangerouslySetInnerHTML={{
                  __html: splited_title[0].replace(/(?:\r\n|\r|\n)/g, ""),
                }}></span>
                <span> </span>
                <span dangerouslySetInnerHTML={{
                  __html: splited_title[1].replace(/(?:\r\n|\r|\n)/g, ""),
                }}></span>
              </h1>) : (
                posttype === "promotion" ? 
                <div className={`sectiontitle-headline sectiontitle-headline--${headlineSize} ${text_class || ""}`}>
                <span style={{color : promo_title_color}}
                  dangerouslySetInnerHTML={{
                    __html: splited_title[0].replace(/(?:\r\n|\r|\n)/g, ""),
                  }}></span>
                <span> </span>
                <span dangerouslySetInnerHTML={{
                  __html: splited_title[1].replace(/(?:\r\n|\r|\n)/g, ""),
                }}></span>
              </div>
                :
                <div className={`sectiontitle-headline sectiontitle-headline--${headlineSize} ${text_class || ""}`}>
                <span className={highlight_class || ""}
                  dangerouslySetInnerHTML={{
                    __html: splited_title[0].replace(/(?:\r\n|\r|\n)/g, ""),
                  }}></span>
                <span> </span>
                <span dangerouslySetInnerHTML={{
                  __html: splited_title[1].replace(/(?:\r\n|\r|\n)/g, ""),
                }}></span>
              </div>
              )
            )
              ) : ('')
          )}
          {subtext && <div tabindex="0" className="eyebrow-text-div">
            <div tabindex="0" className="sectiontitle-eyebrow_text eyebrow">{subtext}</div>
            <div className="eyebrowtext-border"></div>
          </div>}
        </div>) : ('')
    )
  }
}

export default SectionTitle;
