// wp-admin -> Custom fields -> Global Components -> Video Block
import React from 'react';
import { Modal } from 'react-bootstrap'
import { srcset } from './Helpers'
import './GenericBlock.scss'
import './VideoBlock.scss'

class VideoBlock extends React.Component {
  constructor() {
    super()

    this.state = {
      showHide: false
    }
  }

  // Video Object Structured Data
  video_object_structured_data(data) {
    if (data.video_cover_image && data.video_cover_image.sizes && data.video_cover_image.sizes.medium_large) {
      var thumbnailUrl = data.video_cover_image.sizes.medium_large
    }

    if (data.video_cover_image && data.video_cover_image.date) {
      var uploadDate = data.video_cover_image.date
    }

    if (data.video_link) {
      var embedUrl = data.video_link
    }

    if (data.description) {
      var description = data.description.replace( /(<([^>]+)>)/ig, '')
    }

    const script = document.createElement('script')

    // Structure Data script start
    script.type = "application/ld+json"
    script.innerHTML = `{
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Tri Pointe Homes",
      "description": "${description}",
      "thumbnailUrl": "${thumbnailUrl}",
      "uploadDate": "${uploadDate}",
      "embedUrl": "${embedUrl}"
    }`

    //  Structure Data script end
    document.head.appendChild(script)
  }

  handleModalShowHide() {
    this.videoLaunch_data_layer_push('videoLaunch')
    this.setState({ showHide: !this.state.showHide })
  }

  videoLaunch_data_layer_push(param) {
    this.setState({ value:param })

    if (param && param==='videoLaunch') {
      window.dataLayer.push({'event': 'videoLaunch'})
    }
 }

  componentDidMount() {
    if (this.props.data && this.props.video_cover_image && this.props.data.video_cover_image.sizes && this.props.data.video_cover_image.sizes.medium_large) {
      this.video_object_structured_data(this.props.data)
    }

    const data = this.props.video_data
    const section_id = data.section_id && data.section_id.trim()

    if (section_id) {
      setTimeout(() => {
        if (window.location.href.indexOf("#" + section_id) > -1) {
          if (window.location.href.indexOf('#' + section_id) > -1) {
            document.querySelector('#' + section_id).scrollIntoView({behavior:'smooth', block: 'start'})
          }
        }
      }, 2000)
    }

    window.lazy.update()
  }

  render() {
    const data = this.props.video_data
    const section_id = data.section_id && data.section_id.trim()

    return (
      data.sub_text || data.headline || data.description ? (
        <section class="media-lockup flex margin-vertical viewport-width"
          id={section_id}
          style={{
            '--flex-align-items-desktop': 'center',
            '--flex-direction-desktop': data.video_position === "right" ? 'row-reverse' : 'row',
            '--flex-direction-mobile': 'column',
            '--margin-vertical-bottom-desktop': '0',
            '--margin-vertical-bottom-mobile': '90px',
            '--viewport-width-desktop': 'var(--media-lockup-viewport-width, 100vw)',
            '--viewport-width-mobile': '100%',
            'background-color': data.background_color ? data.background_color : '#fff',
          }}>
          {data.video_link ? 
            data.video_cover_image && data.video_cover_image.sizes && data.video_cover_image.sizes.medium_large &&
            <>
            <a class="media-lockup__media aspect-ratio block margin-vertical width"
            href="javascript:void(0)"

            onClick={() => this.handleModalShowHide()}
            style={{
              '--aspect-ratio-height': '833',
              '--aspect-ratio-width': '1101',
              '--margin-vertical-bottom-desktop': '0',
              '--margin-vertical-bottom-mobile': '33px',
              '--margin-vertical-top-desktop': '0',
              '--margin-vertical-top-mobile': '0',
              '--width-desktop': '57.34375%',
              '--width-mobile': '100%',
              'align-self': 'stretch',
            }}>
                {data.video_cover_image && data.video_cover_image.sizes && data.video_cover_image.sizes.medium_large &&
                  <img class="lazy object-fit"
                    data-srcset={srcset(data.video_cover_image)}
                    alt={data.video_cover_image.alt ? data.video_cover_image.alt : data.video_cover_image.title}
                  />
                }
                {data.video_link &&
                  <span className="videoArrow pointer-events"></span>
                }
             </a>
              <Modal tabindex="0" className="interactive_modal" show={this.state.showHide}>
                      <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                        <Modal.Title>
                          {data.iframe_heading && <h2 tabindex="0">{data.iframe_heading}</h2>}
                          {data.iframe_subtext && <p tabindex="0">{data.iframe_subtext}</p>}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <iframe tabindex="0" src={data.video_link} className="interactive_frame" title="interactive iframe"></iframe>
                      </Modal.Body>
              </Modal> 
            </>
            : 
            data.video_cover_image && data.video_cover_image.sizes && data.video_cover_image.sizes.medium_large &&
              <a class="media-lockup__media aspect-ratio block margin-vertical width"
              href="javascript:void(0)"
              style={{
                '--aspect-ratio-height': '833',
                '--aspect-ratio-width': '1101',
                '--margin-vertical-bottom-desktop': '0',
                '--margin-vertical-bottom-mobile': '33px',
                '--margin-vertical-top-desktop': '0',
                '--margin-vertical-top-mobile': '0',
                '--width-desktop': '57.34375%',
                '--width-mobile': '100%',
                'align-self': 'stretch',
                'pointer-events': 'none',
              }}>
              {data.video_cover_image && data.video_cover_image.sizes && data.video_cover_image.sizes.medium_large &&
                <img class="lazy object-fit"
                  data-srcset={srcset(data.video_cover_image)}
                  alt={data.video_cover_image.alt ? data.video_cover_image.alt : data.video_cover_image.title}
                />
              }
              </a>
          }

          <div class="media-lockup__lockup margin-horizontal padding-horizontal max-width padding-vertical width"
            style={{
              '--margin-horizontal-left-desktop': data.video_position === "right" ? 'auto' : '0',
              '--margin-horizontal-right-desktop': data.video_position === "right" ? '0' : 'auto',
              '--max-width': '810px',
              '--padding-horizontal-left-desktop': 'min(5.52vw, 106px)',
              '--padding-horizontal-right-desktop': 'min(5.52vw, 106px)',
              '--padding-horizontal-left-mobile': 'var(--wrap-padding-h-mobile, var(--wrap-padding-h, 32px))',
              '--padding-horizontal-right-mobile': 'var(--wrap-padding-h-mobile, var(--wrap-padding-h, 32px))',
              '--padding-vertical-bottom-mobile': '0',
              '--padding-vertical-top-mobile': '0',
              '--padding-vertical-bottom-desktop': '40px',
              '--padding-vertical-top-desktop': '40px',
              '--width-desktop': '42.65625%',
              '--width-mobile': '100%',
            }}>
            {data.sub_text && data.headline_large &&
              <div class="eyebrow"
                style={{
                  'margin-bottom': 'var(--section-header-eyebrow-margin-bottom, 16px)',
                }}>
                {data.sub_text}
              </div>
            }

            {data.headline &&
              <div class={`media-lockup__title margin-vertical title section-header__title section-header__title--${data.hasOwnProperty('headline_size') ? data.headline_size : 'small'}`}
                style={{
                  '--color': 'var(--gray-mid)',
                  '--margin-vertical-bottom-desktop': '19px',
                  '--margin-vertical-bottom-mobile': '19px',
                }}>
                <span style={{'color': data.headline_part_1_color ? data.headline_part_1_color : 'var(--gray-mid)' }} dangerouslySetInnerHTML={{ __html: data.headline.replace(/(?:\r\n|\r|\n)/g, '') }}></span> {data.headline_part_2 && <span style={{'color': data.headline_part_2_color ? data.headline_part_2_color : 'var(--highlight-color)' }} dangerouslySetInnerHTML={{ __html: data.headline_part_2.replace(/(?:\r\n|\r|\n)/g, '') }}></span>}
              </div>
            }

            {data.sub_text && !data.headline_large &&
              <div class="media-lockup__subtitle font-size font--medium"
                style={{
                  '--font-size-desktop': '20px',
                  '--font-size-mobile': '16px',
                  '--margin-vertical-bottom-mobile': '25px',
                  '--margin-vertical-bottom-mobile': '25px',
                }}>
                {data.sub_text}
              </div>
            }

            {data.description &&
              <div class="description margin-vertical max-width rte"
                style={{
                  '--margin-vertical-bottom-desktop': '0',
                  '--margin-vertical-bottom-mobile': '33px',
                  '--margin-vertical-top-desktop': '25px',
                  '--margin-vertical-top-mobile': '16px',
                  '--max-width': '450px',
                }}
                dangerouslySetInnerHTML={{ __html: data.description.replace(/(?:\r\n|\r|\n)/g, '') }}>
              </div>
            }

            {data.cta_link &&
              <a class="button margin-vertical"
                  style={{
                    '--margin-vertical-bottom-desktop': '0',
                    '--margin-vertical-bottom-mobile': '0',
                    '--margin-vertical-top-desktop': '65px',
                    '--margin-vertical-top-mobile': '7px',
                  }}
                  target = {data.open_in_new_tab ? '_blank' : '_self'}
                  href={data.cta_link}>
                  <span>{data.cta_label ? data.cta_label : 'Get Started'}</span>
              </a>
            }

          </div>
        </section>
      ) : ('')
    )
  }
}

export default VideoBlock