import React from 'react';
import './LivingSmart.scss';
import HeadingSection from './HeadingSection';
class LivingSmart extends React.Component {
  componentDidMount() {
    const living_smart_data = this.props.living_smart_data;
    const section_id = living_smart_data.section_id && living_smart_data.section_id.trim();
    if (section_id) {
      setTimeout(function () {
        if (window.location.href.indexOf("#" + section_id) > -1) {
            document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
        }
      }, 2000)
    }
  }
  render() {
    var living_smart_data = this.props.living_smart_data;
    var section_id = living_smart_data.section_id && living_smart_data.section_id.trim();
     return (
      living_smart_data.image !== false && living_smart_data.headline && living_smart_data.description  ? (
         <div className="living-section" id={section_id}>
                  <div className="living-content">
                      <div className="living-info divide-equal">
                            <div className="header-section section-margin section-padding wrap">
                              <HeadingSection
                                subtitle={living_smart_data.sub_text}
                                title_part_1={living_smart_data.headline}
                                title_part_2={living_smart_data.headline_part_2}
                                headline_part_1_color={living_smart_data.headline_part_1_color}
                                headline_part_2_color={living_smart_data.headline_part_2_color}
                                headline_size={living_smart_data.hasOwnProperty('headline_size') ? living_smart_data.headline_size : 'large'}
                              />
                              {living_smart_data.description ? (
                                <div className="description title-info" dangerouslySetInnerHTML={{__html:living_smart_data.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                              ):('')}
                              {living_smart_data.cta_label && living_smart_data.cta_link ? (
                                    <a href={living_smart_data.cta_link}  title={living_smart_data.cta_label} className="button learn-more">
                                      <span class="button__text">{living_smart_data.cta_label}</span>
                                    </a>
                              ):('')}
                            </div>
                      </div>
                      {living_smart_data.image !== false ? (
                        <div className="living-img divide-equal">
                            <img class="section-padding wrap" src={living_smart_data.image.sizes.medium_large}  title={living_smart_data.image.title} alt={living_smart_data.image.alt ? living_smart_data.image.alt : living_smart_data.image.title} />
                        </div>
                      ):('')}
                  </div>
          </div>
        ):('')
     );
  }
}

export default LivingSmart;
