import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import SectionTitle from '../../Classes/SectionTitle';
import LogoutButton from '../../Classes/LogoutButton';
import ProfileInfo from '../../Classes/ProfileInfo';
import PasswordInfo from '../../Classes/PasswordInfo';
import FloorplansInfo from '../../Classes/FloorplansInfo';
import '../../Classes/MyAccount.scss';
import { withAuth0 } from "@auth0/auth0-react";
import $ from 'jquery';

// const MyAccount = () => {
class MyAccount extends React.Component {
  componentDidMount(){
    // console.log("this.props.auth0");
    // console.log(this.props.auth0);
      if(window.location.search.indexOf("floorplans") > -1){ 
          $($(".react-tabs__tab")[1]).trigger("click");
          $(".react-tabs__tab")[1].click();
      }
    window.JQUERY('.header_section').show();
    window.JQUERY('.footer_section').show();
  }
  render () {
    const user = this.props.auth0.user
    return (
      <>
        <div role="main" className="account_info">
            <div className="user_name_section">
                <SectionTitle 
                    subtext = ""
                    title_part_1="My"    
                    title_part_2="Account"  
                    headline_part_1_color = "#bed245"  
                    headline_part_2_color = "#545454" 
                />
            </div>
            <div tabindex="0" className="assist_message">How can we assist you?</div>
            <div tabindex="0" class="user_mobile_details"><h3 tabindex="0">{user.name}</h3><p tabindex="0">{user.email}</p></div>
            <Tabs>
            <div className="account_options">
              <TabList>
                <Tab tabindex="0">Profile</Tab>
                <Tab tabindex="0">Floorplans</Tab>
              </TabList>
              <LogoutButton/>
            </div>
            <div tabindex="0" className="account_details">
             <TabPanel>
                <ProfileInfo/>
              </TabPanel>
              <TabPanel>
                <FloorplansInfo/>
              </TabPanel>
            </div>
            </Tabs>
        </div>
      </>
     
    )
  }
}


export default withAuth0(MyAccount)

