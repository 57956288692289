import React from 'react'
import './BlogSection.scss'
import SectionHeader from './SectionHeader'
import { srcset } from './Helpers'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

class BlogSection extends React.Component {
  componentDidMount() {
    const three_block_data = this.props.three_block_data
    const section_id = three_block_data.section_id && three_block_data.section_id.trim()

    if (section_id) {
      setTimeout(function () {
        if (window.location.href.indexOf("#" + section_id) > -1) {
          document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth', block:'start' })
        }
      }, 2000)
    }

    window.lazy.update()
  }

  render() {
    var three_block_data = this.props.three_block_data
    var section_id = three_block_data.section_id && three_block_data.section_id.trim()

    return (
      three_block_data.headline && three_block_data.description && (three_block_data.grid_section.length > 0)? (
        <div className="blog-section section-margin section-padding wrap" id={section_id}>
          <div className="blog-content" >
            <div className="header-section">
              <div className="heading-section-left">
                <SectionHeader
                  eyebrow={three_block_data.sub_text}
                  headline_part_1={three_block_data.headline}
                  headline_part_2={three_block_data.headline_part_2}
                  headline_part_1_color={three_block_data.headline_part_1_color}
                  headline_part_2_color={three_block_data.headline_part_2_color}
                  headline_size={three_block_data.hasOwnProperty('headline_size') ? three_block_data.headline_size : 'large'}
                />
              </div>

              <div className="heading-section-right">
                {three_block_data.description ? (
                  <div className="description" dangerouslySetInnerHTML={{__html:three_block_data.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                ):('')}
                {three_block_data.cta_label && three_block_data.cta_link ? (
                  <a href={three_block_data.cta_link} className="button">
                    <span class="button__text">
                    {three_block_data.cta_label}
                    </span>
                  </a>
                ):('')}
              </div>
            </div>

            {three_block_data.grid_section.length > 0 && <div className="blog-info-div blog-content-width">
              {three_block_data.grid_section.map((items,key) => (
                items.image.url && (items.headline || items.description) ? (
                  <div className="blog-info">
                    <div className="blog-img">
                      {items.image.url ? (
                        <img class="lazy" data-srcset={srcset(items.image)} alt={items.image.alt ? items.image.alt : items.image.title} />
                      ):('')}
                    </div>
                    {items.headline ? (
                      <h6>{key+1+". "}{items.headline}</h6>
                    ):('')}
                    {items.description ? (
                      <div className="blog-text" dangerouslySetInnerHTML={{__html:items.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                    ):('')}
                  </div>
                ):('')
              ))}
            </div>}
          </div>
        </div>
      ):('')
    )
  }
}

export default BlogSection