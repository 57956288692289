import React from 'react';
// import '../assets/scss/App.scss';

import Main from './Main/Main';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import callTrkSwap from '../helpers/callTrkSwap';


// import MainPopup from './PopUp/MainPopup';

class App extends React.Component {  

  componentDidMount(){
    callTrkSwap();
    window.JQUERY('.header_section').show();
    window.JQUERY('.footer_section').show();
    window.JQUERY('.top-scroll').show();
    window.onpopstate = function(){
      window.JQUERY('.header_section').show();
      window.JQUERY('.footer_section').show();
      window.JQUERY('.top-scroll').show();
    }

    document.addEventListener('DOMContentLoaded', () => {
      window.lazy.update()
    })
  }

  componentDidUpdate() {
      callTrkSwap();
  }
  
  //Code check here
  render() {     
     return (  
          <>  
            <Header />
            {/* <MainPopup/> */}
            <Main />
            <Footer/>
          </>  
     );  
  }  
}  

export default App;
