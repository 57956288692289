import React,  { useEffect, useState }  from 'react';
import background_image from "../../../assets/images/50gallery.png";
import ReviewBox from '../../Classes/ReviewBox';
import '../../Classes/ReviewListing.scss';
import axios from 'axios';
import {apiConfig} from '../../Classes/Helper.jsx';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import moment from "moment";
import {Helmet} from "react-helmet";

export default function ReviewListing(props)  {
    var highlight_class = 'green-color';
    var text_class = 'gray-color';

    const [ getData , setData] = useState({ component_data: [],
        success: false,
        message: '',
        review:[],
        last_page: ''
      });
      
    const [ getPage , setPage] = useState(1);
    const [ getReview , setReview] = useState([]);
    const [getSort , setSort] = useState('star_rating');
    const [getId , setId] = useState('All');
    const [getdiv , setdiv] = useState([]);

    const sortFilter = (e)=>{
        let sort_val = e.target.value;
        console.log(sort_val);
        setSort(sort_val);
        fetch_data(1,sort_val,getId);
        setReview([]);
    }

    const sortDivison = (e) =>{
        let sort_division = e.target.value;
        console.log(sort_division);
        setId(sort_division);
        fetch_data(1,getSort,sort_division);
        setReview([]);
    }

    function fetch_data(paged=1,sort='star_rating',id='all') {
        // var page_no = paged;

        axios.get(apiConfig().API_URL+'/review_listing/reviews'+'?id='+id+'&sort='+sort+'&page='+paged).then(response => {
          setData(
              {
                  component_data: response.data.data,
                  success: response.data.success,
                  message: response.data.message,
                  review : response.data.data.review_details.jd_orders.reviews,
                  last_page : response.data.data.review_details.last_page
              },
              setdiv(response.data.data.divisons)
          );
        })
    }

    useEffect(() => {
        fetch_data(1);
        console.log(getData.review);
      }, [getPage === '1'])
    window.JQUERY('.top-scroll').addClass('dynamic');

    var con_cat = [...getReview,...getData.review];
    if (getData.component_data ) {   
        var success = getData.success;
        var message = getData.message;
        if(getData.review.length <= 20 && con_cat.length<=20){
            review_structured_data(getData.review);
        }
        if (getData.component_data.page_details) {
            var review_banner_image = getData.component_data.page_details.banner_image;
            var meta_desc = getData.component_data.page_details.meta_desc;
            var meta_title = getData.component_data.page_details.meta_title;
            var review_eyebrow_text = getData.component_data.page_details.review_eyebrow_text;
            var review_subtitle = getData.component_data.page_details.review_subtitle;
            var review_title = getData.component_data.page_details.review_title;
            var yoast_image = getData.component_data.page_details.yoast_image;
            var yoast_following = getData.component_data.page_details.yoast_follow;
            var yoast_indexing = getData.component_data.page_details.yoast_indexing;
            var context_yoast = '';
            if (yoast_indexing === 'true' && yoast_following === 'true') {
            var context_yoast = 'index, follow';
            }else if (yoast_indexing === 'true' && yoast_following === 'false') {
            var context_yoast = 'index, nofollow';
            }else if (yoast_indexing === 'false' && yoast_following === 'true') {
            var context_yoast = 'noindex, follow';
            }else if (yoast_indexing === 'false' && yoast_following === 'false') {
            var context_yoast = 'noindex, nofollow';
        }
        if (review_banner_image) {
            var review_background_image = {
                backgroundImage:'url('+review_banner_image+')',
            }
        }
        else{
            var review_background_image = {
                backgroundImage:'url('+background_image+')',
            }
        }
        if (getData.last_page) {
            var load_more_check = getData.last_page;
        }
        if (con_cat.length > 0) {
            var total_reviews = con_cat.length;
        }
        
    }
    }
    const load_more = () => {
            setPage((prev) => prev + 1);
            fetch_data(getPage+1,getSort,getId);
            setReview(con_cat);
            if (getData.review) {
                review_structured_data(getData.review);
                // console.log('Load more schema');
            }
    };

    const meta = {
        title: meta_title ? meta_title : '',
        description: meta_desc ? meta_desc: '',
        canonical: window.location.href
      };
    
    /*Review Structured Data*/
    function review_structured_data(review_details) {
        if (review_details) {
            if (review_details.length > 0) {
                for (var i = 0; i < review_details.length; i++) {
                    //date
                    if (
                        review_details &&
                        review_details[i].date
                    ) {
                        var date = review_details.date;
                        var datePublished = moment(date).format("MMMM d, YYYY");
                    }
                    //star_rating
                    if (
                        review_details &&
                        review_details[i].star_rating
                    ) {
                        var ratingValue = review_details[i].star_rating;
                    }
                    //comments
                    if (
                        review_details &&
                        review_details[i].comment
                    ) {
                        var comment = review_details[i].comment;
                        if (comment.length > 150) {
                        var comment_text = comment.substring(0, 150);
                        comment_text = comment_text.replace(/(<([^>]+)>)/gi, "").replaceAll('"', '');
                        }
                        else{
                        var comment_text = comment;
                        }
                        } 
                        const script = document.createElement("script");
                        // Structure Data script start
                        script.type = "application/ld+json";
                        script.innerHTML = `{
                            "@context": "https://schema.org/",
                            "@type": "Review",
                            "datePublished": "${datePublished}",
                                "reviewRating": {
                                    "@type": "Rating",
                                    "ratingValue": "${ratingValue}",
                                    "bestRating": "5"
                                },
                                "author":  {
                                    "@type" : "Person",
                                    "name" : "Verified Homeowner"
                                },
                                "reviewBody":"${comment_text}",
                                "itemReviewed":{
                                    "@context":"https://schema.org/",
                                    "@type":"Organization",
                                    "name":"Tri Pointe Homes",
                                    "sameAs":"http://www.tripointehomes.com/"
                                }    
                        }`;
                        //  Structure Data script end
                        document.head.appendChild(script);
                }
            }
        }


           
    }

    if (getdiv) {
        var sort_division_values = getdiv.sort(function(a, b) {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
              
          // sort in an ascending order
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          });
    }

    return(
      <>
        {/* <DocumentMeta {...meta} /> */}
            <Helmet>  
              <title>{meta_title}</title>
              <meta name="description" content={meta_desc} />
              <meta property="og:title" content={meta_title} />
              <meta property="og:description" content={meta_desc} />
              <link rel="canonical" href={window.location.href} />
              <meta name="robots" content={context_yoast}  /> 
              <meta property="og:image" content={yoast_image}/>
              <meta property="og:url" content={window.location.href} />
              <meta property="og:type" content='Website' />
            </Helmet> 
        {success === true ?[
            <div tabindex="0" className="review-landing-banner" style={review_background_image}>
                <div className={"HeroContent d-flex "}>
                    <div className="HeroContentWrapper">
                        <div className="bannerHeader">
                            <div className="heading-section">
                                <h1 className={"main-title"}>
                                    <span tabindex="0" className={text_class}>{review_title ? review_title : 'TriPointe Homes'}</span>
                                    <span> </span>
                                    <span tabindex="0" className={highlight_class}>{review_subtitle ? review_subtitle : 'Reviews'}</span>
                                </h1>
                            </div>
                        </div>
                        <div tabindex="0" className="eyebrow-text-div">
                            <div tabindex="0" className="sectiontitle-eyebrow_text">{review_eyebrow_text ? review_eyebrow_text : 'WHAT OUT HOMES OWNERS SAY'}
                            </div>
                            <div className="eyebrowtext-border"></div>
                        </div>
                    </div>
                </div>
         </div>,
            <div className="review_filter">
                <p className="filter_area">
                    <label htmlFor="FilterArea">Filter by Region</label>
                    <select name="FilterArea" id="FilterArea" onChange={sortDivison}>
                        <option value="1">All Regions</option>
                        {sort_division_values ? sort_division_values.map((item)=>
                            <option value={item.name}>{item.name}</option>
                        ) : ''}
                    </select>
                </p>
                <p className="filter_price">
                    <label htmlFor="FilterPrice">Sort By</label>
                    <select name="sortPriced" id="sortPriced" onChange={sortFilter}>
                        <option value="star_rating">Highest to Lowest</option>
                        <option value="date">New to old</option>
                    </select>
                </p>
            </div>,           
            <ReviewBox eliant_reviews={con_cat} review_exits={success}/>,
             total_reviews <= load_more_check ? <button role="button" onClick={load_more} type="button" className="load-more" tabindex="0">Load more</button> : ''
            
                
                        ]:[
                            message != 'Not Completed' ?
                            <SkeletonTheme>
                                <div tabindex="0" className="review-landing-banner">
                                <Skeleton height={100}/>
                                    <div className={"HeroContent d-flex "}>
                                        <div className="HeroContentWrapper">
                                            <div className="bannerHeader">
                                                <div className="heading-section">
                                                        <h2 className={"main-title "+(text_class)}>
                                                        <Skeleton height={20} className={highlight_class}/>
                                                        <Skeleton height={40}/>
                                                         <Skeleton height={100}/>
                                                        </h2>
                                                        <p tabindex="0" className="generic-text"> <Skeleton height={"100%"}/></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Skeleton height={100}/>
                            </SkeletonTheme>:''
                        ]
        }
            
        </>
    );
}
