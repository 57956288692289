import React, { Fragment } from 'react';
import { getAutocompleteHitUrl } from './helpers';

const getUrl = ( item ) => {
	return getAutocompleteHitUrl( { stateAbbr: item.stateAbbr, submarketSlug: item.submarketSlug, cities: item.name } );
};

const getCities = ( submarketsByState ) => {
	const submarkets = submarketsByState.map( ( state ) => {
		return state.submarkets.map( ( submarket ) => {
			return {
				...submarket,
				state: {
					alpha_2_code: state.alpha_2_code,
					name: state.name,
				},
			};
		} );
	} ).flat();

	const submarketsWithCities = submarkets.filter( ( submarket ) => submarket.cities && submarket.cities.length > 1 );

	const cities = submarketsWithCities.map( function( submarket ) {
		return submarket.cities.map( ( city ) => {
			return {
				name: city.name,
				submarketName: submarket.name,
				submarketSlug: submarket.slug,
				state: submarket.state.name,
				stateAbbr: submarket.state.alpha_2_code.toLowerCase(),
			};
		} );
	} );
	return cities.flat();
};

const makeStringSearchable = ( str ) => {
	return str
		.toLowerCase()
		.replace( /[^a-zA-Z ]/g, '' );
};

const CityHit = ( { item } ) => {
	return (
		<a
			className="tw-text-charcoal tw-flex tw-items-center tw-h-full tw-justify-between tw-pr-2"
			href={getUrl( item )}
		>
			<span>{item.name}</span>
			<span className='tw-italic tw-text-slate'>{item.submarketName}</span>
		</a>
	);
};

const createCitiesPlugin = ( { submarketsByState, ...rest } ) => {
	const cities = getCities( submarketsByState );
	return {
		...rest,
		limit: 3,
		getSources( { state: searchState } ) {
			return [
				{
					sourceId: 'pluginCities',
					getItems( { query } ) {
						if ( ! query || cities.length === 0 || searchState.context.selectedState ) {
							return [];
						}
						const queryToMatch = query.toLowerCase().trim();
						return cities.filter( ( { name, state, stateAbbr, submarketSlug } ) => {
							const attrsToMatch = [ name, state, stateAbbr, submarketSlug ];
							const toMatch = attrsToMatch.map( makeStringSearchable );
							const strToMatch = toMatch.join( ' ' );
							return strToMatch.includes( queryToMatch );
						} );
					},
					onSelect( { item } ) {
						window.location.href = getUrl( item );
					},
					// keyboard
					getItemUrl( { item } ) {
						return getUrl( item );
					},
					templates: {
						item( { item } ) {
							return <CityHit item={item} />;
						},
						header( { items } ) {
							if ( items.length === 0 ) {
								return <Fragment />;
							}
							return (
								<Fragment>
									<span className="aa-SourceHeaderTitle">Cities</span>
									<span className="aa-SourceHeaderLine" />
								</Fragment>
							);
						},
					},
				},
			];
		},
	};
};

export default createCitiesPlugin;
