import React from 'react';
import $ from 'jquery';
import './EventRsvp.scss';
class StageEventRsvp extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            reload: false
        }
    }  

    loadRsvpForm = () => {
        var that = this;
        var communityOfInterest=this.props.neigh_id;
        var community_Id=this.props.neigh_comm_id;
        var Division=this.props.neigh_name;

        if (window.MktoForms2 != "undefined") {
            window.MktoForms2.loadForm("//456-RUT-066.mktoweb.com", "456-RUT-066", that.props.event.event_metas.rsvp_options[0].form_id, function (form) {
                form.onSuccess(function () {
                    window.dataLayer.push({
                        'event': 'rsvpForm',
                        'salesforceId' : communityOfInterest ? communityOfInterest : '',
                        'e1CommunityId' : community_Id ? community_Id : '',
                        'divisionName' : Division ? Division : '',
                        'page_type' : 'Event',
                    });
                    $("#mktoForm_" + that.props.event.event_metas.rsvp_options[0].form_id).html("");
                 
                    that.setState({ reload: true });
                    return false;
                });
                if(window.screen.width<=320){
                window.JQUERY(".mktoButton").css("min-width", '272px');
                }
                else{
                    window.JQUERY(".mktoButton").css("min-width", '324px');
                }
                
            });

            window.MktoForms2.whenReady(function () {
                $("#mktoForm_" + that.props.event.event_metas.rsvp_options[0].form_id).find('[name="Communities_of_Interest__c"]').val(communityOfInterest);
                $("#mktoForm_" + that.props.event.event_metas.rsvp_options[0].form_id).find('[name="formURL"]').val(window.location.href);
                $("#mktoForm_" + that.props.event.event_metas.rsvp_options[0].form_id).find('[name="Division__c"]').val(Division);
             
            });
        }
    }

    componentDidMount() {
        if (this.props.event.event_metas.rsvp_options[0].show_rsvp) {
            this.loadRsvpForm();
        }
    }

    render() {
        const { event } = this.props;
        return (
            <React.Fragment>
                <div className="event-detail-content-column event-detail-content-column--form">
                    {this.state.reload===false? 
                    <h3 className="rsvp_heading">RSVP TO ATTEND</h3>:('')}
                   
                    <form id={"mktoForm_" + event.event_metas.rsvp_options[0].form_id} class="rsvp-form"></form>
                    {this.state.reload === true ? (
                        <div class="thanks_msg_div">
                        <small className="event_rsvp__thanks" >{
                            (event.event_metas.rsvp_options[0].thanks_message.length > 0) ? (
                           
                                <p tabindex="0"  dangerouslySetInnerHTML={{__html:event.event_metas.rsvp_options[0].thanks_message.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                              
                            ) : ('Thank you for registering for this event. We look forward to seeing you there!')
                        }</small>
                        </div>
                    ) : ('')}
                    {this.state.reload === false ? (
                        <div className="sidebar-signup__disclaimer">By providing your name and contact information and clicking the submission button, you consent and agree to receive marketing communications from us or made on our behalf, including emails and calls or text messages using an automatic telephone dialing system or an artificial or prerecorded voice. Your consent and agreement to the foregoing, including the receipt of autodialed marketing messages, is not a condition of purchasing any property, goods, or services from us, and you may call us instead of submitting your information online. Your consent herein also applies to any future registration on national or state Do Not Call lists. For mobile phones, standard text message and data charges apply. Consult our <a href="https://www.tripointehomes.com/privacy-policy/" className="form_privacy" target="_blank">Privacy Policy</a> for additional information, including unsubscribe options.
                        <div class="sidebar_broker_agent"> Broker or Agent? <a href="https://www.tripointehomes.com/brokers/" target="_blank" >Stay Informed Here</a></div>
                        </div>
                    ) : ( <div className="sidebar-signup__disclaimer" style={{margin:'8% 0 0 0 '}} >By providing your name and contact information and clicking the submission button, you consent and agree to receive marketing communications from us or made on our behalf, including emails and calls or text messages using an automatic telephone dialing system or an artificial or prerecorded voice. Your consent and agreement to the foregoing, including the receipt of autodialed marketing messages, is not a condition of purchasing any property, goods, or services from us, and you may call us instead of submitting your information online. Your consent herein also applies to any future registration on national or state Do Not Call lists. For mobile phones, standard text message and data charges apply. Consult our <a href="https://www.tripointehomes.com/privacy-policy/" className="form_privacy" target="_blank">Privacy Policy</a> for additional information, including unsubscribe options.
                    <div class="sidebar_broker_agent"> Broker or Agent? <a href="https://www.tripointehomes.com/brokers/" target="_blank" >Stay Informed Here</a></div>
                    </div>)
                    }
                  
                </div>
            </React.Fragment>
        )
    }
}

export default StageEventRsvp
