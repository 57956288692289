import React from 'react'
import $ from 'jquery'
import './PromotionForm.scss';
// import './PromotionForm1.css';
import cookie from 'react-cookies';
import form_background_image from "../../assets/images/TPH-default-image2.png";


class StagePromoNationalForm extends React.Component {

    constructor(props) {

        super()
        this.state = {

            reload: false,
            // referenceId: this.props.referenceId ? this.props.referenceId : false,
            // neigh_detail: this.props.neigh_detail

        }

    }


    reloadMarketoForm() {

        $("#mktoForm_6081").find('[type="submit"]').prop('disabled', false);
        $("#mktoForm_6081").find('[type="submit"]').text("Submit >");
        // this.setState({reload: false});
        // this.loadMarketoForm();
    }
  
    loadMarketoForm() {
        var that = this;
        const cookie_value = this.state.mkto_cookie;
        const division_name = this.props.division_name;
        var communityOfInterest = this.props.communityOfInterest;
        var nbd_title = this.props.nbd_title;
        var promo_form=this.props.promo_form;
        var btn_color=this.props.btn_color; 
        console.log(btn_color);
        const title="Promotion";
        var window_width=window.screen.width;
        console.log(communityOfInterest);
        console.log(nbd_title);
        console.log(division_name);

        if (window.MktoForms2 != "undefined") {

            window.MktoForms2.loadForm("//456-RUT-066.mktoweb.com", "456-RUT-066", 6081, function (form) {
                form.onSuccess(function(values, followUpUrl) {
                    window.dataLayer.push({
                        'event': 'interestListStaging',
                        'userEmail': values?.Email,
                        'userPhone': values?.Phone,
                        'userFirstName': values?.FirstName,
                        'userLastName': values?.LastName,                        
                    });
                    var useremail = values.Email;
                    var cookieexpiry = new Date();
                    cookieexpiry.setFullYear(cookieexpiry.getFullYear() + 2);
                    
                    document.querySelector('.sidebar-signup-panel').parentElement.style.height = '100%';
                    // set cookie
                    document.cookie="_mkto_email="+useremail+"; expires="+cookieexpiry+"; path=/";

                    if ($("#mktoForm_6081 #openQuestion1").is(":checked")) {

                        let t = new Date();
                        t.setDate(t.getDate() + 2);
                        let month = "0" + (t.getUTCMonth() + 1);
                        let date = "0" + t.getUTCDate();
                        month = month.slice(-2);
                        date = date.slice(-2);
                        date = t.getUTCFullYear() + "-" + month + "-" + date;
                        let todayToDate = Date.parse(date);


                        if ($("#mktoForm_6081 #requestedDate").val() == "") {

                            alert("Please select request date.");
                            that.reloadMarketoForm();
                            return false;
                        } else if (Date.parse($("#mktoForm_6081 #requestedDate").val()) <= todayToDate) {

                            alert("Please select a date at least three days from today. Call to schedule earlier.");
                            that.reloadMarketoForm();
                            return false;

                        } else {

                            $("#mktoForm_6081").html("");

                            that.setState({reload: true});

                            return false;

                        }


                    } else {

                        $(".row-class-2").html("");
                        $(".mktoButtonRow").html("");

                        that.setState({reload: true});

                        return false;

                    }
                    
                });
                // if(promo_form){
                window.JQUERY('.headtxt').html(promo_form.promotion_title);
                window.JQUERY('.logo > a:first-child').attr({href:window.location.origin,title:"Tri Pointe Homes", alt:"Tri Pointe Homes"});
                window.JQUERY('.logo img').attr({src:require('../../assets/images/Tripointe_updated_Icon.png'),title:"Tri Pointe Homes", alt:"Tri Pointe Homes"});
                window.JQUERY('.sidebar-signup__title').html(promo_form.sidebar_title);
                window.JQUERY('.sidebar-signup__info').html(promo_form.sidebar_info);
                
                window.JQUERY(".mktoButton").css("background-color", btn_color);
                window.JQUERY(".sidebar-signup__disclaimer a").css("color", btn_color);
                

            // }
                // window.JQUERY('#tour_form .logo > a:first-child').attr({href:window.location.origin,title:"Tri Pointe Homes", alt:"Tri Pointe Homes"});
                // window.JQUERY('#tour_form .logo img').attr({src:require('../../assets/images/Tripointe_updated_Icon.png'),title:"Tri Pointe Homes", alt:"Tri Pointe Homes"});
                
			$('#mktoForm_6081 #Brand__c').on('input', function(evt) {
              

                    console.log("input");

                    var inputValue = $("#mktoForm_6081 #Brand__c").val();
                    inputValue = inputValue.replace(/[^a-zA-Z ]/g, "");
                    $("#mktoForm_6081 #Brand__c").val(inputValue);
                    
                    var withoutSpclChar = new RegExp(/^[\w| |_-]+$/);
                    if (!inputValue.match(withoutSpclChar)) {
                    var newInputWithHas = $("#mktoForm_6081 #Brand__c").val();
                    var newInputWithoutHas = newInputWithHas.substring(0, newInputWithHas.length - 1);
                    return $("#mktoForm_6081 #Brand__c").val(newInputWithoutHas);
                    }
                    if (inputValue.match(/\s+/g)) {
                      $("#mktoForm_6081 #Brand__c").val(spaceHandle(inputValue))
                    }
                    
                    if ((inputValue.match(new RegExp(" ", "g")) || []).length > 1) {
                    console.log();
                    $("#mktoForm_6081 #Brand__c").val(spaceHandle(inputValue))
                    var newInputValue = $("#mktoForm_6081 #Brand__c").val();
                    //var noDoubleSpace = newInputValue.replace(/ +/g, '');
                    //var NoSpaceinEnd = noDoubleSpace.replace(/ $/, "");
                    return $("#mktoForm_6081 #Brand__c").val(newInputValue);
                    }
                    if (/\d/.test(inputValue)) {
                    console.log("it has number");
                    var noNumber = inputValue.replace(/[0-9]/g, '');
                    return $("#mktoForm_6081 #Brand__c").val(noNumber);
                    }
                    });
                    
                    function spaceHandle(s) {
                    console.log(s);
                    var replaced = s.split(' ');
                    console.log("Replaced------------>");
                    console.log(replaced);
                    var final_string = '';
                    $.each(replaced, function(index, value) {
                        console.log("value length------------>");
                        console.log(value.length);
                    if (index == 0 && value.length > 1) {
                        final_string = final_string + value + ' ';
                    } else {
                         final_string = final_string + value;
                    }
                    })
                    console.log("Final String");
                    console.log(final_string);
                    
                    return final_string;
                    }
                    
                    $('.mktoFormCol').addClass(function(i){
                    return 'Col'+(i+1);
                    });
                    form.onValidate(function() {
                    var vals = form.vals();
                    var email = form.vals().Email;
                    var fname = form.vals().FirstName;
                    var lname = form.vals().LastName;
                    var phone = form.vals().Phone;
                    var maxLength = 2;
                    var ContactName = form.vals().Brand__c;
                    var invalidDomains = ["@gmail.", "@yahoo.", "@hotmail.", "@live.", "@aol.", "@outlook.", "@test.", "@rediff."];
                    var ContactFirstName = ContactName.substr(0, ContactName.indexOf(' '));
                    var ContactLastName = ContactName.substr(ContactName.indexOf(' ') + 1); 
                    if (/\w+\s+\w+/.test($("#mktoForm_6081 #Brand__c").val())) {
                    // Enable submission for those who met the criteria
                    $('input[name="FirstName"]').val(ContactFirstName);
                    $('input[name="LastName"]').val(ContactLastName); 
                    
                    var lname = $("input[name='LastName']");
                    var lvalue = lname[0].value.length;
                    if(lvalue < maxLength){
                    form.submittable(false);
                    var CcontactNameElem = $("#mktoForm_6081").find("#Brand__c");
                    form.showErrorMessage("Please enter at least 2 character of your last name", CcontactNameElem);
                    return false;
                    }else{
                    form.submittable(true);
                    }
                    } else {
                    form.submittable(false);
                    // Show error message, pointed at VehicleSize element
                    var CcontactNameElem = $("#mktoForm_6081").find("#Brand__c");
                    form.showErrorMessage("Please enter first and last name only.", CcontactNameElem);
                    return false;
                    }
                    });

            });
      
            window.MktoForms2.whenReady(function (form) {


                var j = 1;
                $('#mktoForm_6081 .mktoFormRow').each(function (i, e) {
                    $(this).addClass('row-class-' + j);
                    j++;
                });

     
                $("#mktoForm_6081").find('[name="Communities_of_Interest__c"]').val(communityOfInterest);
                $("#mktoForm_6081").find('[name="formURL"]').val(window.location.href);
                $("#mktoForm_6081").find('[name="Division__c"]').val(division_name);
                $("#mktoForm_6081").find('[name="communityName"]').val(nbd_title);
             
            
                
                if(that.props.neigh_status == 'Temporarily Sold Out' || that.props.neigh_status == 'Limited Availability'){
                    $("#mktoForm_6081").find('[name="waitlist"]').val('true');
                }

                var formfields = form.vals();
					var formfields_names = Object.keys(formfields);

                    var formfilterValue = cookie_value;
                    console.log('Cookie value: '+formfilterValue);
                    var outputArray = [];
                    for (var i = 0; i < formfields_names.length; i++){
                        if(formfields_names[i] && formfields_names[i]!='munchkinId' && formfields_names[i]!='formid'){
                            if ((window.JQUERY.inArray(formfields_names[i], outputArray)) == -1){
                                outputArray.push(formfields_names[i]);
                            }
                        }                               
                    }
                    const reducedArray = outputArray.reduce((fields_names, feilds) => `${fields_names}${feilds},` ,'')
                    var data = "";
					var data = {
    					action: 'formnames',
    					requestData: reducedArray,
    					filterType:'cookies',
    					filterValue: formfilterValue
					};
                    window.JQUERY.ajax({
                        type: 'POST',
                        data: data,
                        url: "/wp-admin/admin-ajax.php",
                        success:function(response){
                            var field_havevalues = [];
                            var results = [];
                            results = JSON.parse(response);
                            var finalvaluestofill = {};
                            window.JQUERY.each(formfields_names,function(value, elem){
                                if(elem){
                                    var keys = elem.toLowerCase();
                                    if(keys in results){
                                        if(results[keys] && results[keys] != ''){
                                            finalvaluestofill[elem] = results[keys];
                                            field_havevalues.push(elem);
                                        }
                                    }
                                }
                            });

                            if (finalvaluestofill) {
                                form.vals(finalvaluestofill);
                            }
                        }
                    });

            });


        }

    }
 

    componentDidMount() {
        this.state = {
            mkto_cookie: cookie.load('_mkto_trk')
        };

        
        this.loadMarketoForm();
        if(window.location.href.indexOf("#interest-list") > -1) {
            document.getElementById("interest_click_btn").click();
        }

        // this.setState({
        //     referenceId: this.props.referenceId
        // });

    }
 

    componentWillReceiveProps(nextProps) {

        if (this.state.reload === true) {

            this.loadMarketoForm();
            this.setState({reload: false});
        }

        // this.setState({
        //     referenceId: this.props.referenceId
        // });

    }

    render() {

        const sidebar_data = this.props.sidebar_data;
        var post_id = this.props.post_value;
        var landingPage = this.props.landingPage;
        var window_width=window.screen.width;
        var promo_form=this.props.promo_form;
        var promotion_form=this.props.promotion_form;
        console.log(promotion_form);
    
    
        return (
          window_width>767?
            <>
            
           
            <div style={{display:'flex',widows:'100%'}}>

                <div className="sidebar-signup-panel" id="interest-form" style={{width:'50%'}}>
               
            
               
                    <div className="sidebar-signup-div">
                  
                        {/* <form id="mktoForm_6081" className="sidebar-signup-form"></form> */}
                        <form id="mktoForm_6081"></form>
                    </div>

                     {this.state.reload === false ? (
                       promo_form.sidebar_disclaimer ? (
                            <div tabindex="0" className="sidebar-signup__disclaimer" dangerouslySetInnerHTML={{ __html: promo_form.sidebar_disclaimer.replace(/(?:\r\n|\r|\n)/g, '') }}>
                                {/* <div class="sidebar_broker_agent"><a href="https://www.pardeehomes.com/agent-registration/" target="_blank" >Broker or Agent? Stay Informed Here</a></div> */}
                             </div> 
                              ):('') 
                     ) : ('')} 

                    {this.state.reload === true ? (
                     post_id === 150592 ? 
                     promo_form.sidebar_thankyou_message ? (
                            <small>  <div tabindex="0" className={"thankyou_message "} dangerouslySetInnerHTML={{ __html: promo_form.sidebar_thankyou_message.replace(/(?:\r\n|\r|\n)/g, '') }}></div>
                                {/* sidebar_data.sidebar_thankyou_message */}
                            </small>
                            
                        ):(
                            <small> <div tabindex="0" className="thankyou_message">
                                Thanks for signing up.
                                </div>
                            </small>
                        )
                    :
                    promo_form.sidebar_thankyou_message ? (
                        <small> <div tabindex="0" className={"thankyou_message "} dangerouslySetInnerHTML={{ __html: promo_form.sidebar_thankyou_message.replace(/(?:\r\n|\r|\n)/g, '') }}></div>
                            {/* sidebar_data.sidebar_thankyou_message */}
                        </small>
                        
                     ):(
                        <small> <div tabindex="0" className="thankyou_message">
                        Thanks for signing up.
                        </div>
                    </small>
                     ) 
                    ) 
                    : ('')
                    }
                </div>
                <div class="image"  style={{width:'50%'}} >
                    <img src={promo_form.sidebar_featured_image ? promo_form.sidebar_featured_image : form_background_image}></img>
                </div>
                </div>
              
            </>
          :
            <>
                <div className="sidebar-signup-panel" id="interest-form">
                      <div className="sidebar-signup-div">
                         <form id="mktoForm_6081" className="sidebar-signup-form"></form>
                     </div>

                    {this.state.reload === true ? (
                    promo_form?(
                     promo_form.sidebar_thankyou_message ? (
                            <small >
                                <div tabindex="0" className={"thankyou_message "} dangerouslySetInnerHTML={{ __html: promo_form.sidebar_thankyou_message.replace(/(?:\r\n|\r|\n)/g, '') }}></div>
                                {/* sidebar_data.sidebar_thankyou_message */}
                            </small>
                            
                        ):(
                            <small> <div tabindex="0" className="thankyou_message">
                            Thanks for signing up.
                            </div>
                        </small>
                        )):(
                            <small> <div tabindex="0" className="thankyou_message">
                            Thanks for signing up.
                            </div>
                        </small>
                        )
              
                    ) 
                    : ('')
                    }
                </div>
              
           
              
            </>
          
        )
    }
}

export default StagePromoNationalForm
