import React from 'react'
import axios from 'axios'
import { Helmet } from "react-helmet"
import Dreamweaver from '../../Classes/Dreamweaver'
import NoMatch from './NoMatch'
import Residence from '../../Classes/Residence'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { apiConfig } from '../../Classes/Helper.jsx'
import { Redirect } from "react-router-dom"
import { storeResidenceView as storeResidenceViewForSearch } from "../../Search/helpers/localStorage";
import callTrkSwap from '../../../helpers/callTrkSwap.js'

class ResidencePage extends React.Component {
  hasDataFetched = false

  constructor() {
    super()

    this.state = {
      residence_component : '',
      success : false,
    }
  }

  fetch_data() {
    if (this.hasDataFetched) return

    axios.get(`${apiConfig().API_URL}/residence_info/${this.props.slug}`)
    .then(response => {
      this.setState({
        residence_component : response.data.data,
        success: response.data.success,
      })

      console.log('ResidencePage response')
      console.log(response)

      this.hasDataFetched = true
    }).catch(error => {
      console.log('ResidencePage error', error)
      this.hasDataFetched = true
    })
  }

  /* Local Business */
  local_business_structured_data(residence_Component) {
    if (residence_Component) {
      if (residence_Component.agent_info.agents_contact_phone) {
        var telephone = residence_Component.agent_info.agents_contact_phone;
      }

      if (residence_Component.display_price) {
        var priceRange = residence_Component.display_price
      }

      if (residence_Component.physical_address.physical_address_heading) {
        var addressLocality = residence_Component.physical_address.physical_address_heading.replace( /(<([^>]+)>)/ig, '')
      }

      if (residence_Component.streetAddress) {
        var streetAddress = residence_Component.streetAddress
      }

      if (residence_Component.postalCode) {
        var postalCode = residence_Component.postalCode
      }

      if (residence_Component.latitude) {
        var latitude = residence_Component.latitude
      }

      if (residence_Component.longitude) {
        var longitude = residence_Component.longitude
      }

      if (residence_Component.state_abb) {
        var state_abb = residence_Component.state_abb.toUpperCase()
      }

      if (residence_Component.site_logo) {
        var site_logo = residence_Component.site_logo
      }
    }

    // Structure Data script start
    const script = document.createElement("script")

    script.type = "application/ld+json"
    script.innerHTML = `{
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "Tri Pointe Homes",
      "image": "${site_logo}",
      "@id": "",
      "url": "${window.location.href}",
      "telephone": "${telephone}",
      "priceRange": "${priceRange}",
      "address": {
      "@type": "PostalAddress",
      "streetAddress": "${streetAddress}",
      "addressLocality": "${addressLocality}",
      "addressRegion": "${state_abb}",
      "postalCode": "${postalCode}",
      "addressCountry": "US"
      },
      "geo": {
      "@type": "GeoCoordinates",
      "latitude": "${latitude}",
      "longitude": "${longitude}"
      }
    }`

    document.head.appendChild(script)
  }

   // Product structured data
   product_structured_data(residence_Component,media_gallery) {
    if (residence_Component) {
      if (residence_Component.min_price) {
        var priceRange = residence_Component.min_price
      }

      if (residence_Component.description) {
        var description = residence_Component.description.replace( /(<([^>]+)>)/ig, '')
      }

      if (residence_Component.res_title) {
        var title = residence_Component.res_title
      }
    }

    if (media_gallery) {
      if (media_gallery.gallery_images.length>=1 && media_gallery.gallery_images[0].image.url) {
        var image1 = media_gallery.gallery_images[0].image.url
      }

      if (media_gallery.gallery_images.length>=2 && media_gallery.gallery_images[1].image.url) {
        var image2 = media_gallery.gallery_images[1].image.url
      }

      if (media_gallery.gallery_images.length>=3 && media_gallery.gallery_images[2].image.url) {
        var image3 = media_gallery.gallery_images[2].image.url
      }
    }

    const availability = residence_Component.community_status !== 'Coming Soon' || residence_Component.neigh_status !== 'Coming Soon' ? 'https://schema.org/InStock' : ''

    // Structure Data script start
    const script = document.createElement("script")

    script.type = "application/ld+json"
    script.innerHTML = `{
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "${title}",
      "image": [
        "${image1}",
        "${image2}",
        "${image3}"
        ],
      "description": "${description}",
      "sku": "",
      "mpn": "",
      "brand": {
        "@type": "Organization",
        "name": "Tri Pointe Homes"
      },
      "offers": {
        "@type": "Offer",
        "url": "${window.location.href}",
        "priceCurrency": "USD",
        "price": "${priceRange}",
        "priceValidUntil": "",
        "itemCondition": "https://schema.org/UsedCondition",
        "availability": "${availability}",
        "seller": {
          "@type": "Organization",
          "name": "Executive Objects"
        }
      }
    }`

    document.head.appendChild(script)
  }

	componentDidMount() {
    this.fetch_data()
    callTrkSwap();
    window.scrollTo(0, 0)
    window.JQUERY('.header_section').show()
    window.JQUERY('.footer_section').show()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.slug != nextProps.slug) {
      this.props = nextProps

      this.setState({
        success: false
      })

      this.fetch_data()
    }
  }

  componentDidUpdate() {
    if (this.state.success) {
      window.dataLayer.push({
        'event': 'communityId',
        'divisionName': this.state.residence_component.common_detail.nbd_title ? this.state.residence_component.common_detail.nbd_title : '',
        'e1CommunityId': this.state.residence_component.common_detail.e1_community_id ? this.state.residence_component.common_detail.e1_community_id : '',
        'salesforceId': this.state.residence_component.common_detail.communityOfInterest ? this.state.residence_component.common_detail.communityOfInterest : '',
      })
    }

    callTrkSwap();
    window.scrollTo(0, 0)
    window.lazy.update()
    window.swipersInit()

    // Log this
    if (this.state.residence_component.search_key) {
        storeResidenceViewForSearch(this.state.residence_component.search_key);
    }
  }

  render() {
    const residence_component = this.state.residence_component
    const success = this.state.success
    const message = this.state.message
    const state_name = this.props.state.toLowerCase()
    const submarket_name = this.props.submarket.toLowerCase()
    const residence_Component = this.state.residence_component.common_detail

    if (residence_Component) {
      this.local_business_structured_data(residence_Component)
    }

    if (residence_Component && residence_component && residence_component.detail) {
      this.product_structured_data(residence_Component,residence_component.detail)
    }

    var yoast_following = residence_component.yoast_following;
    var yoast_indexing = residence_component.yoast_indexing;
    var context_yoast = '';
    if (yoast_indexing === 'true' && yoast_following === 'true') {
    var context_yoast = 'index, follow';
    }else if (yoast_indexing === 'true' && yoast_following === 'false') {
    var context_yoast = 'index, nofollow';
    }else if (yoast_indexing === 'false' && yoast_following === 'true') {
    var context_yoast = 'noindex, follow';
    }else if (yoast_indexing === 'false' && yoast_following === 'false') {
    var context_yoast = 'noindex, nofollow';
    }

    return (
      <>
        <Helmet>
          <title>{residence_component.yoast_title }</title>
          <meta name="description" content={residence_component.yoast_desc} />
          <meta name="robots" content={context_yoast} />
          <meta property="og:title" content={residence_component.yoast_title ? residence_component.yoast_title : ''} />
          <meta property="og:description" content={residence_component.yoast_desc ? residence_component.yoast_desc : ''} />
          <meta property="og:image" content={residence_component.yoast_image}/>
          <link rel="canonical" href={window.location.href} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content='Website' />
        </Helmet>
        {success === true ? (
          state_name === residence_component.common_detail.state_abb.toLowerCase() && submarket_name === residence_component.common_detail.submarket.toLowerCase() ? (
            residence_component.common_detail.post_status !== "Archive" &&  residence_component.common_detail.plan_not_available !== "true" ? [
              residence_component.enable_dreamweaver == true && residence_component.dreamweaver_plan_id != '' ? (
                <Dreamweaver residence_component={residence_component}/>
              ):(
                <Residence is_qmi={false} residence_component={residence_component} plan_details={residence_component.common_detail}/>
              )
            ]:[ <Redirect to={residence_component.common_detail.nbd_permalink} /> ]
          ):(<Redirect to={<NoMatch/>} /> )
        ):(message != 'Not Completed' ?
          <SkeletonTheme>
            <div className="residence-main-div" id='residence-main-div' style={{'background-color': '#FAFAFA'}}>
              <div className="residence-header">
                <Skeleton width={150} height={20}/>
                <div className="residence-nav-bar">
                  <ul>
                    <Skeleton width={150} height={20}/>
                    <Skeleton width={150} height={20}/>
                    <Skeleton width={150} height={20}/>
                    <Skeleton width={150} height={20}/>
                  </ul>
                </div>
                <div className="plan-icons"></div>
              </div>
              <div className="residence-details" id="screen-1">
                <div className="residence-image">
                  <Skeleton height={"100%"}/>
                </div>
                <div className="residence-content">
                  <Skeleton height={20}/>
                  <Skeleton height={40}/>
                  <Skeleton height={20}/>
                  <Skeleton height={300}/>
                  <Skeleton height={20}/>
                  <Skeleton height={20}/>
                </div>
              </div>
              <div className="plan-features">
                <div className="plan-footer-left" style={{backgroundColor: '#f9f9f9'}}>
                  <div className="plan-details-container">
                    <div className="plan-details">
                      <Skeleton width={100} height={80}/>
                    </div>
                  </div>
                  <ul className="plan-features-list">
                    <li>
                      <Skeleton width={50} count={2}/>
                    </li>
                    <li>
                      <Skeleton width={50} count={2}/>
                    </li>
                    <li>
                      <Skeleton width={50} count={2}/>
                    </li>
                    <li>
                      <Skeleton width={50} count={2}/>
                    </li>
                  </ul>
                </div>
                <div className="plan-footer-right" style={{backgroundColor: '#dfdfdf'}}>
                </div>
              </div>
            </div>
          </SkeletonTheme>
        : <Redirect to={<NoMatch/>} /> )}
      </>
     )
  }
}

export default ResidencePage
