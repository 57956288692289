import React from 'react'
import SectionHeader from './SectionHeader'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { groupBy, srcset } from './Helpers'
import './PhotoGalleryModal.scss'
import axios from 'axios';
import { apiConfig } from './Helper.jsx';
import cookie from 'react-cookies';

class PhotoGalleryGrid extends React.Component {
  activeModal = null
  preModalScrollY = null

  constructor() {
    super()
  }

  componentDidMount() {
    window.lazy.update()
    this.escapeKeyListener()
    const section_id = this.props.data.section_id && this.props.data.section_id !== '' ? this.props.data.section_id.trim() : 'gallery';
    if (section_id) {
      setTimeout(function () {
          if (window.location.href.indexOf("#" + section_id) > -1) {
              document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
          }
      }, 2000)
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  escapeKeyListener() {
    // detect Escape key press
    document.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        this.modalClose()
      }
    })
  }

  imageGalleryClick_data_layer_push(param) {
    this.setState({ value:param })
    if (param && param === 'imageGalleryClick') {
      window.dataLayer.push({'event': 'imageGalleryClick'})
    }
  }

  loadingModalEnd() {
    if (window.matchMedia('(max-width: 1023px').matches) return

    setTimeout(() => {
      document.querySelector('[data-mouse-follow=unzoom]').classList.add('active')
      document.querySelector('[data-mouse-follow=loading]').classList.remove('active')
    }, 1000)
  }

  mouseFollow(event, name) {
    if (window.matchMedia('(max-width: 1023px').matches) return

    const x = event.clientX
    const y = event.clientY + window.pageYOffset

    const $el = document.querySelector(`[data-mouse-follow="${name}"]`)

    if ($el) $el.classList.add('active')

    document.documentElement.style.setProperty('--mouse-x', `${x}px`)
    document.documentElement.style.setProperty('--mouse-y', `${y}px`)
  }

  mouseFollowLeave(name) {
    if (window.matchMedia('(max-width: 1023px').matches) return

    const $el = document.querySelector(`[data-mouse-follow="${name}"]`)

    if ($el) $el.classList.remove('active')
  }

  modalClose() {
    if (!this.activeModal) return

    enableBodyScroll(this.activeModal)

    if (window.matchMedia('(max-width: 1023px)').matches) {
      // fix scroll top position for iOs
      window.scroll(0, this.preModalScrollY)
    }

    this.activeModal.classList.add('hide')
    this.stopModalScrollTextIO()

    this.activeModal = null
    this.preModalScrollY = null
  }

  fetchData() {
    return new Promise((resolve, reject) => {
        axios.post(apiConfig().API_URL + '/get_marketo_lead_data/',{
            'get_type': "Activity",
            'activity_type' : "ViewGallery",
            'cookie' : cookie.load('_mkto_trk'),
            'URL' : window.location.href,
            'value' : 'true'
        }).then(response => response)
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  modalOpen(key, index) {
    var mkto_confirm = cookie.load('_mkto_trk');
        if (mkto_confirm) {
            this.fetchData()
              .then(data => {
                console.log(data);
              })
              .catch(error => console.error(error));
        }
    this.activeModal = document.querySelector(`[data-gallery-modal=${key}-${index}]`)
    this.activeModal.classList.remove('hide')

    this.preModalScrollY = window.pageYOffset

    disableBodyScroll(this.activeModal)

    if (window.matchMedia('(max-width: 1023px').matches) return

    document.querySelector('[data-mouse-follow=loading]').classList.add('active')

    setTimeout(() => {
      document.querySelector('[data-mouse-follow=loading]').classList.remove('active')
      document.querySelector('[data-mouse-follow=unzoom]').classList.add('active')

      this.startModalScrollTextIO()
    }, 500)
  }

  hideModalScrollText () {
    if (!this.activeModal) return

    // Hide modal scroll down text
    window.hideModalScrollTextIOEl = this.activeModal.querySelector('[data-modal-meta-threshold]')

    if (!window.hideModalScrollTextIOEl) return

    window.hideModalScrollTextIO = new IntersectionObserver(([entry]) => {
      document.documentElement.classList[entry.boundingClientRect.y < 0 ? 'add' : 'remove']('gallery-modal--scrolled')
    })

    window.hideModalScrollTextIO.observe(window.hideModalScrollTextIOEl)
  }

  startModalScrollTextIO () {
    if (!this.activeModal) return

    this.hideModalScrollText()
  }

  stopModalScrollTextIO () {
    if (!this.activeModal) return

    if (window.hideModalScrollTextIO) window.hideModalScrollTextIO.unobserve(window.hideModalScrollTextIOEl)

    document.documentElement.classList.remove('gallery-modal--scrolled')
  }

  render() {
    const data = this.props.data

    // change 'Elevation' to 'Exterior'
    const galleriesCombineElevationWithExterior = data.gallery_images.map(x => (x.image_type === 'elevation' ? { ...x, image_type: 'exterior' } : x))
    // exclude Floorplan and Standard Images
    const galleriesWithoutExlusions = galleriesCombineElevationWithExterior.filter(x => x.image_type !== 'floorplan' && x.image_type !== 'standard')
    const galleries = groupBy(galleriesWithoutExlusions, 'image_type')
    const sectionId = data.section_id && data.section_id !== '' ? data.section_id.trim() : 'gallery';
    return (
      data.gallery_images.length > 0 ? (
        <>
          <img data-mouse-follow="zoom"
            class="mouse-follow lazy position transform hide-mobile z-index"
            data-src="https://assets.codepen.io/7797206/icon-plus.svg"
            height="168"
            width="168"
            style={{
              '--opacity-loaded': 'var(--mouse-follow-opacity, 0)',
              '--position-left': 'var(--mouse-x-override, var(--mouse-x, auto))',
              '--position-top': 'var(--mouse-y-override, var(--mouse-y, auto))',
              '--z-index': '8',
              'height': '168px',
              'pointer-events': 'none',
              'transform': 'translate(-50%, -50%)',
              'width': '168px',
            }}
          />

          <img data-mouse-follow="unzoom"
            class="mouse-follow lazy position transform hide-mobile z-index"
            data-src="https://assets.codepen.io/7797206/icon-x_2.svg"
            height="168"
            width="168"
            style={{
              '--opacity-loaded': 'var(--mouse-follow-opacity, 0)',
              '--position-left': 'var(--mouse-x-override, var(--mouse-x, auto))',
              '--position-top': 'var(--mouse-y-override, var(--mouse-y, auto))',
              '--z-index': '1001',
              'height': '168px',
              'pointer-events': 'none',
              'transform': 'translate(-50%, -50%)',
              'width': '168px',
            }}
          />

          <svg data-mouse-follow="loading"
            class="mouse-follow height loaded width position hide-mobile z-index"
            style={{
              '--height': '84px',
              '--position-left': 'var(--mouse-x-override, var(--mouse-x, auto))',
              '--position-top': 'var(--mouse-y-override, var(--mouse-y, auto))',
              '--width': '84px',
              '--z-index': '1001',
              'background-color': '#fff',
              'border-radius': '100%',
              'opacity': 'var(--mouse-follow-opacity, 0)',
              'pointer-events': 'none',
              'transform': 'translate(-50%, -50%)',
            }}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="84px"
            height="84px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke="#242424" stroke-width="3" r="16" stroke-dasharray="65.97344572538566 23.991148575128552">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
            </circle>
          </svg>

          <section id={sectionId}
            data-sticky-subnav-section={sectionId}
            class="media-gallery section-margin background-fill section-padding relative wrap">
            <SectionHeader
              eyebrow={data.sub_text}
              headline_part_1={data.headline}
              headline_part_2={data.headline_part_2}
              headline_part_1_color={data.headline_part_1_color}
              headline_part_2_color={data.headline_part_2_color}
              headline_size={data.hasOwnProperty('headline_size') ? data.headline_size : 'large'}
            />

            <div class="media-gallery__container grid relative"
              style={{
                '--grid-columns-desktop': '2',
                '--grid-columns-mobile': '1',
                '--grid-columns-tablet': '2',
                '--grid-column-gap': '20px',
                '--grid-row-gap': '70px',
              }}>
              {Object.entries(galleries).map(([key, value], columnIndex) => (
                <div class="media-gallery__item"
                  onClick={() => this.modalOpen(key, columnIndex)}>
                  {value.slice(0, 1).map((gallery, index) => (
                    <>
                      <figure class="media-gallery__media aspect-ratio cursor"
                        onMouseMove={(event) => this.mouseFollow(event, 'zoom')}
                        onMouseLeave={() => this.mouseFollowLeave('zoom')}
                        style={{
                          '--aspect-ratio-height': '643',
                          '--aspect-ratio-width': '886',
                          '--aspect-ratio-height-mobile': '200',
                          '--aspect-ratio-width-mobile': '342',
                          '--cursor-desktop': 'none',
                        }}>
                        <img class="media-gallery__media-item lazy object-fit"
                          data-srcset={srcset(gallery.image)}
                          onClick={() => {this.imageGalleryClick_data_layer_push('imageGalleryClick')}}
                          sizes="auto"
                          alt={gallery.image.alt !== '' ? gallery.image.alt : gallery.image.title !== '' ? gallery.image.title : gallery.title}
                        />

                        <span class="position hide-desktop pointer-events-none"
                          style={{
                            '--position': 'absolute',
                            'bottom': '0',
                            'height': '100px',
                            'right': '0',
                            'width': '100px',
                          }}>
                          <img src="https://assets.codepen.io/7797206/icon-plus.svg"
                            height="100"
                            width="100"
                            style={{
                              'display': 'block',
                              'height': 'auto',
                              'pointer-events': 'none',
                              'width': '100%',
                            }}
                          />
                        </span>
                      </figure>

                      <div class="media-gallery__copy media-card"
                        style={{
                          'flex': '1',
                        }}
                        tabindex="-1">
                        <div class="media-card__title font--medium"
                          style={{
                            'border-top': '1px solid var(--black)',
                            'font-size': 'var(--media-card-title-font-size, 22px)',
                            'margin-top': 'var(--media-card-title-margin-top, 16px)',
                            'padding-top': 'var(--media-card-title-padding-top, 16px)',
                            'transition': 'color 300ms ease-in-out',
                          }}>
                          {data.hasOwnProperty('gallery_info') && data.gallery_info[columnIndex] && data.gallery_info[columnIndex].heading || `${key.charAt(0).toUpperCase()}${key.slice(1)} Gallery`}
                        </div>

                        {data.hasOwnProperty('gallery_info') && data.gallery_info[columnIndex] && data.gallery_info[columnIndex].description &&
                          <div class="media-card__copy rte"
                            style={{
                              'color': 'var(--media-card-copy-color, #848484)',
                              'font-size': 'var(--media-card-copy-font-size, 16px)',
                              'line-height': '1.375',
                              'margin-top': '16px',
                              'max-width': 'var(--media-card-copy-max-width, 50ch)',
                              'transition': 'color 300ms ease-in-out',
                            }}>
                            <p>
                              {data.gallery_info[columnIndex].description}
                            </p>
                          </div>
                        }
                      </div>
                    </>
                  ))}
                </div>
              ))}
            </div>

            {Object.entries(galleries).map(([key, value], columnIndex) => (
              <>
                <div class="gallery-modal cursor position viewport-height z-index hide"
                  data-gallery-modal={`${key}-${columnIndex}`}
                  onMouseMove={(event) => this.mouseFollow(event, 'unzoom')}
                  onMouseLeave={() => this.mouseFollowLeave('unzoom')}
                  onClick={() => {
                    if (window.matchMedia('(min-width: 1024px)').matches) this.modalClose()
                  }}
                  style={{
                    '--cursor-desktop': 'none',
                    '--cursor-mobile': 'default',
                    'overflow': 'auto',
                    '--position': 'fixed',
                    '--position-left': '0',
                    '--position-right': '0',
                    '--position-top': '0',
                    '--z-index': '1000',
                    'background': '#fff',
                    'overscroll-behavior': 'contain',
                  }}>
                  {data.gallery_images.length > 1 &&
                    <>
                      <span class="gallery-modal__meta"
                        style={{
                          'background-color': 'rgba(255,255,255,0.8)',
                          'border-radius': '4px',
                          'bottom': '10px',
                          'font-size': '14px',
                          'color': 'var(--black)',
                          'left': '50%',
                          'opacity': 'var(--gallery-modal-meta-opacity, 1)',
                          'padding': '10px 15px',
                          'pointer-events': 'none',
                          'position': 'fixed',
                          'text-align': 'center',
                          'transform': 'translateX(-50%)',
                          'transition': 'opacity 500ms ease-in-out',
                          'z-index': '10',
                        }}>
                        Scroll down to see more
                      </span>

                      <span class="gallery-modal__meta-threshold"
                        data-modal-meta-threshold
                        style={{
                          'position': 'absolute',
                          'top': '10px',
                          'height': '1px',
                          'left': '0',
                          'width': '1px',
                          'pointer-events': 'none',
                        }}>
                      </span>
                    </>
                  }
                  {value.map((gallery, index) => (
                    <figure class="gallery-modal__item cursor flex"
                      style={{
                        '--flex-direction-mobile': 'column',
                        'margin-top': index > 0 ? '20px;' : '0',
                      }}>
                      <div class="gallery-modal__media aspect-ratio width"
                        style={{
                          '--aspect-ratio-height': '911',
                          '--aspect-ratio-width': '1320',
                          '--width-desktop': 'calc(1320/1920 * 100%)',
                          '--width-mobile': '100%',
                        }}>

                        <img class="lazy object-fit"
                          style={{'--object-fit': gallery.image.height > gallery.image.width ? 'contain' : 'cover'}}
                          data-srcset={srcset(gallery.image)}
                          alt={gallery.image.alt !== '' ? gallery.image.alt : gallery.image.title !== '' ? gallery.image.title : gallery.title}
                        />
                      </div>

                      <figcaption class="gallery-modal__info media-gallery__copy media-card width padding z-index"
                        style={{
                          '--padding-desktop': '65px',
                          '--padding-mobile': '40px',
                          '--width-desktop': 'calc(600/1920 * 100%)',
                          '--width-mobile': '100%',
                        }}>
                        <div class="media-card__title font-size font--medium padding-vertical"
                          style={{
                              '--padding-vertical-bottom-desktop': '0',
                              '--padding-vertical-top-mobile': '16px',
                              '--padding-vertical-bottom-tablet': '0',
                              '--padding-vertical-bottom-mobile': '0',
                              '--padding-vertical-top-desktop': '24px',
                              '--padding-vertical-top-mobile': '16px',
                              '--font-size-desktop': '24px',
                              '--font-size-mobile': '22px',
                              'border-top': '1px solid var(--black)',
                          }}>
                          {gallery.image_title ? gallery.image_title : data.hasOwnProperty('gallery_info') && data.gallery_info[columnIndex] && data.gallery_info[columnIndex].heading || `${key.charAt(0).toUpperCase()}${key.slice(1)} Gallery`}
                        </div>

                        {gallery.image_caption &&
                          <div class="media-card__copy font-size rte"
                            style={{
                              '--font-size-desktop': '20px',
                              '--font-size-mobile': '16px',
                              'color': '#525252',
                              'line-height': '1.3',
                              'margin-top': '16px',
                            }}>
                            <p>{gallery.image_caption}</p>
                          </div>
                        }
                      </figcaption>
                    </figure>
                  ))}
                </div>
                <button class="gallery-modal__close position hide-desktop"
                  onClick={() => this.modalClose()}
                  type="button"
                  style={{
                    '--position': 'fixed',
                    'background': 'none',
                    'border': 'none',
                    'height': '100px',
                    'right': '0',
                    'top': '0',
                    'width': '100px',
                    'z-index': '1001',
                  }}>
                  <img src="https://assets.codepen.io/7797206/icon-x_2.svg"
                    height="100"
                    style={{
                      'display': 'block',
                      'height': 'auto',
                      'pointer-events': 'none',
                      'width': '100%',
                    }}
                    width="100" />
                </button>
              </>
            ))}
          </section>
        </>
      ):('')
    )
  }
}

export default PhotoGalleryGrid