import React from 'react';
import './ProductList.scss';
import $ from 'jquery';
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const qmi_options = {
    loop: false,
    nav: true,
    navText: [
        '<div class="sliderArrow arrowLeft"></div>',
        '<div class="sliderArrow arrowRight"></div>'
    ],
    dots: true,
    margin: 0,
    autoplay: false,
    startPosition: 0,
    stagePadding:0,
    responsive: {
        0: {
            items: 1,
            margin: 20,
        },
        667 : {
            items: 2,
        },
        768: {
            items: 3,
            margin : 20,
        },
        1601: {
            items: 3,
            margin : 46,
        }

    }
}
class ProductList extends React.Component {
    componentDidMount() {
        var card_content_height = [];

        var plan_card_div = $(document).find(".qmi-card .card-main-div");
        plan_card_div.each(function () {
            card_content_height.push($(this).find(".card-img-info").outerHeight());
        });
        var card_height = $(plan_card_div[0]).outerHeight();
        var card_content_maxheight = Math.max.apply(Math, card_content_height);
        var cards = document.querySelectorAll('.card-img-info');
        cards.forEach(items =>{
            items.style.height  = card_content_maxheight +'px';
        })
    }



    split_string(str, count = 2, dir = "left", splitBy = " ") {
        var part1, part2;
        var split_array = str.split(splitBy);
        if (dir == "right") {
          part2 = split_array.splice(split_array.length - count).join(splitBy);
          part1 = split_array.join(splitBy);
        } else {
          part1 = split_array.splice(0, count).join(splitBy);
          part2 = split_array.join(splitBy);
        }
        return [part1, part2];
      }


    render() {
        var Qmi_detail = this.props.list;
        var details= this.props.list_detail;
        var posttype= this.props.posttype;
        var title=this.split_string(details.neigh_list_headline,1);
        // console.log('sort_order',this.props.sort_order)
        if(this.props.sort_order=='movedate'){
            function dateComparison(a, b) {
                const date1 = new Date(a.move_date)
                const date2 = new Date(b.move_date)

                return date1 - date2;
            }

             Qmi_detail=Qmi_detail.sort(dateComparison);
        } else if(this.props.sort_order=='alphanumeric'){
            Qmi_detail=Qmi_detail.sort((a, b) => a.title.localeCompare(b.title));
        }
        else if(this.props.sort_order=='price'){
            function priceComparison(a, b) {
                const p1 = a.price
                const p2 = b.price

                return p1 - p2;
            }

             Qmi_detail=Qmi_detail.sort(priceComparison);

        }else if(this.props.sort_order=='size'){
            function sqftComparison(a, b) {
                const p1 = a.min_sq_feet
                const p2 = b.min_sq_feet

                return p1 - p2;
            }

             Qmi_detail=Qmi_detail.sort(sqftComparison);
            //  console.log('size',Qmi_detail);
        }
        for(var x in Qmi_detail){
            if(Qmi_detail[x].post_status == "sold" || Qmi_detail[x].post_status == "Sold Out")
             {Qmi_detail.push( Qmi_detail.splice(x,1)[0])}
            }
         for(var x in Qmi_detail){
            if(Qmi_detail[x].post_status == "sold" || Qmi_detail[x].post_status == "Sold Out")
             {Qmi_detail.push( Qmi_detail.splice(x,1)[0])}
            }
        for(var x in Qmi_detail){
            if(Qmi_detail[x].post_status == "sold" || Qmi_detail[x].post_status == "Sold Out")
             {Qmi_detail.push( Qmi_detail.splice(x,1)[0])}
            }
        var window_width = window.screen.width;
        var toShow;
        return (


                    <div className="product-card-section" id="product-card-section">
                    <div className="sectiontitle-main-div">
                        <div className="sectiontitle-headline">
                            <span tabindex="0" className='green-color'>{title[0]}</span>
                            <span> </span>
                            <span>{title[1]}</span>
                        </div>

                        <div tabindex="0" className="eyebrow-text-div">
                            <div tabindex="0" className="sectiontitle-eyebrow_text">{this.props.eyebrow}
                            </div>
                            <div className="eyebrowtext-border"></div>
                        </div>
                    </div>
                    {Qmi_detail.length <= 3 && window_width > 480 ? (
                    <div className="qmi-card">
                        {Qmi_detail.map(items => (
                            <div className="card-main-div col-lg-4 col-sm-4">
                                <div className="card-imgs">
                                    <div className="plan-img">
                                        {items.image.length > 0 ? (
                                            items.image.length === 1 ? (
                                                <Link to={items.post_permalink}>
                                                <div className="overlay">
                                                    <img src={items.image[0]} className="image-building-image" alt={items.image_alt[0]?items.image_alt[0]:items.image_alt[1]} title={items.image_alt[1]} />
                                                    </div>
                                                </Link>
                                            ) : ( '')
                                        ) : ('')}
                                    </div>
                                    <div className="plan-img-info">
                                        {/* <span className="card-snipe">Promotion Snipe Here</span> */}
                                        {items.promotion_info ? (
                                            items.promotion_info.promo_snipe ? (
                                                <a href={items.promotion_info.promo_link}><span className="card-snipe" tabindex="0" style={{backgroundColor:items.promotion_info.promo_snipe_color}}
                                                onKeyPress={(e)=>{
                                                    if(e.keyCode == 13 || e.which == 13) e.target.click();
                                                }}>{items.promotion_info.promo_snipe}</span></a>
                                            ) : (
                                                items.promotion_info.promo_icon?.url ? (
                                                    <a href={items.promotion_info.promo_link}><span className="promo-icon" tabindex="0"
                                                    onKeyPress={(e)=>{
                                                        if(e.keyCode == 13 || e.which == 13) e.target.click();
                                                    }}><img src={items.promotion_info.promo_icon.url} alt={items.promotion_info.promo_icon.alt ? items.promotion_info.promo_icon.alt : items.promotion_info.promo_icon.title} title={items.promotion_info.promo_icon.title}/></span></a>
                                                ):('')
                                            )
                                        ) : ('')}
                                        <div className="card-img-info">
                                            {items.post_status ? (
                                                <span tabindex="0" className="card-status">{items.post_status}</span>
                                            ) : ('')}
                                            {items.title ? (
                                                <h4 tabindex="0" className="card-name">{items.title}</h4>
                                            ) : ('')}
                                        {items.price_value && items.post_status!== "Sold Out"  ? (
                                            items.city && posttype !== 'qmi' ?
                                            <p tabindex="0" className="card-price">{items.city}, {items.state} | {items.price_value}</p> :
                                            <p tabindex="0" className="card-price">{items.price_value}</p>

                                        ):('')}
                                        {items.neighborhood && items.city && items.state && posttype=== 'qmi'?
                                                <p tabindex="0" className="card-price cardloc ">{items.neighborhood}  {" "} | {" "}  {items.city},{" "}{items.state}</p>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-info">
                                    <ul className="card-detail">
                                        {items.square_feet ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.square_feet}</span>
                                                <span tabindex="0" className="info-name">Sq.{'\u00A0'}Footage</span>
                                            </li>
                                        ) : ('')}
                                        {items.bed ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.bed}</span>
                                                <span tabindex="0" className="info-name">
                                                    {items.bed === '1' ? ('Bed') : ('Beds')}
                                                </span>
                                            </li>
                                        ) : ('')}
                                        {items.bath ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.bath}</span>
                                                <span tabindex="0" className="info-name">
                                                    {items.bath === '1' ? ('Bath') : ('Baths')}
                                                </span>
                                            </li>
                                        ) : ('')}
                                        {items.garage ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.garage}</span>
                                                <span tabindex="0" className="info-name">Bay Garage</span>
                                            </li>
                                        ) : ('')}
                                    </ul>
                                    <Link to={items.post_permalink} className="learn-more qmi-learn-more">LEARN MORE</Link>
                                </div>
                                <div className="card-hover-content">
                                    <div class="qmi-card-content">
                                        <Link to={items.post_permalink} className="learn-more">LEARN MORE</Link>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                    ):(Qmi_detail.length > 3 && window_width > 480 ? (
                        <div className="qmi-card">
                        <OwlCarousel {...qmi_options} className="owl-theme">
                        {Qmi_detail.map(items => (
                            <div className="card-main-div col-lg-12 col-md-12 col-sm-12">
                                <div className="card-imgs">
                                    <div className="plan-img">
                                        {items.image.length > 0 ? (
                                            items.image.length === 1 ? (
                                                <Link to={items.post_permalink}>
                                                <div className="overlay">
                                                    <img src={items.image[0]} className="image-building-image" alt={items.image_alt[0] ? items.image_alt[0] : items.image_alt[1] } title={items.image_alt[1]} />
                                                    </div>
                                                </Link>
                                            ) : ( '')
                                        ) : ('')}
                                    </div>
                                    <div className="plan-img-info">
                                        {/* <span className="card-snipe">Promotion Snipe Here</span> */}
                                        {items.promotion_info ? (
                                            items.promotion_info.promo_snipe ? (
                                                <a href={items.promotion_info.promo_link}><span className="card-snipe" tabindex="0" style={{backgroundColor:items.promotion_info.promo_snipe_color}}
                                                onKeyPress={(e)=>{
                                                    if(e.keyCode == 13 || e.which == 13) e.target.click();
                                                }}>{items.promotion_info.promo_snipe}</span></a>
                                            ) : (
                                                items.promotion_info.promo_icon?.url ? (
                                                    <a href={items.promotion_info.promo_link}><span className="promo-icon" tabindex="0"
                                                    onKeyPress={(e)=>{
                                                        if(e.keyCode == 13 || e.which == 13) e.target.click();
                                                    }}><img src={items.promotion_info.promo_icon.url} alt={items.promotion_info.promo_icon.alt ? items.promotion_info.promo_icon.alt : items.promotion_info.promo_icon.title} title={items.promotion_info.promo_icon.title}/></span></a>
                                                ):('')
                                            )
                                        ) : ('')}
                                        <div className="card-img-info">
                                            {items.post_status ? (
                                                <span tabindex="0" className="card-status">{items.post_status}</span>
                                            ) : ('')}
                                            {items.title ? (
                                                <h4 tabindex="0" className="card-name">{items.title}</h4>
                                            ) : ('')}
                                            <p tabindex="0" className="card-price">
                                           {items.price_value && items.post_status !== "Sold" ? (
                                            items.city && posttype !== 'qmi'?
                                            <p tabindex="0" className="card-price">{items.city}, {items.state} | {items.price_value}</p> :
                                            <p tabindex="0" className="card-price">{items.price_value}</p>
                                           ) : ('')}
                                           {items.neighborhood && items.city && items.state && posttype=== 'qmi'?
                                                <p tabindex="0" className="card-price cardloc ">{items.neighborhood}  {" "} | {" "}  {items.city},{" "}{items.state}</p>
                                                : ''}
                                           </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-info">
                                    <ul className="card-detail">
                                        {items.square_feet ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.square_feet}</span>
                                                <span tabindex="0" className="info-name">Sq.{'\u00A0'}Footage</span>
                                            </li>
                                        ) : ('')}
                                        {items.bed ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.bed}</span>
                                                <span tabindex="0" className="info-name">
                                                    {items.bed === '1' ? ('Bed') : ('Beds')}
                                                </span>
                                            </li>
                                        ) : ('')}
                                        {items.bath ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.bath}</span>
                                                <span tabindex="0" className="info-name">
                                                    {items.bath === '1' ? ('Bath') : ('Baths')}
                                                </span>
                                            </li>
                                        ) : ('')}
                                        {items.garage ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.garage}</span>
                                                <span tabindex="0" className="info-name">Bay Garage</span>
                                            </li>
                                        ) : ('')}
                                    </ul>
                                    <Link to={items.post_permalink} className="learn-more qmi-learn-more">LEARN MORE</Link>
                                </div>
                                <div className="card-hover-content">
                                    <div class="qmi-card-content">
                                        <Link to={items.post_permalink} className="learn-more">LEARN MORE</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </OwlCarousel>
                    </div>
                    ):(
                        [Qmi_detail.length > 1 && window_width < 480 ?                        <div className="qmi-card">
                        <OwlCarousel {...qmi_options} className="owl-theme">
                        {Qmi_detail.map(items => (
                            <div className="card-main-div col-lg-12 col-md-12 col-sm-12">
                                <div className="card-imgs">
                                    <div className="plan-img">
                                        {items.image.length > 0 ? (
                                            items.image.length === 1 ? (
                                                <Link to={items.post_permalink}>
                                                <div className="overlay">
                                                    <img src={items.image[0]} className="image-building-image" alt={items.image_alt[0] ? items.image_alt[0] : items.image_alt[1] } title={items.image_alt[1]} />
                                                    </div>
                                                </Link>
                                            ) : ( '')
                                        ) : ('')}
                                    </div>
                                    <div className="plan-img-info">
                                        {/* <span className="card-snipe">Promotion Snipe Here</span> */}
                                        {items.promotion_info ? (
                                            items.promotion_info.promo_snipe ? (
                                                <a href={items.promotion_info.promo_link}><span className="card-snipe" tabindex="0" style={{backgroundColor:items.promotion_info.promo_snipe_color}}
                                                onKeyPress={(e)=>{
                                                    if(e.keyCode == 13 || e.which == 13) e.target.click();
                                                }}>{items.promotion_info.promo_snipe}</span></a>
                                            ) : (
                                                items.promotion_info.promo_icon.url ? (
                                                    <a href={items.promotion_info.promo_link}><span className="promo-icon" tabindex="0"
                                                    onKeyPress={(e)=>{
                                                        if(e.keyCode == 13 || e.which == 13) e.target.click();
                                                    }}><img src={items.promotion_info.promo_icon.url} alt={items.promotion_info.promo_icon.alt ? items.promotion_info.promo_icon.alt : items.promotion_info.promo_icon.title} title={items.promotion_info.promo_icon.title}/></span></a>
                                                ):('')
                                            )
                                        ) : ('')}
                                        <div className="card-img-info">
                                            {items.post_status ? (
                                                <span tabindex="0" className="card-status">{items.post_status}</span>
                                            ) : ('')}
                                            {items.title ? (
                                                <h4 tabindex="0" className="card-name">{items.title}</h4>
                                            ) : ('')}
                                         {items.price_value && items.post_status!== "Sold Out"  ? (
                                            items.city && posttype !== 'qmi'?
                                            <p tabindex="0" className="card-price">{items.city}, {items.state} | {items.price_value}</p> :
                                            <p tabindex="0" className="card-price">{items.price_value}</p>
                                        ):('')}
                                        {items.neighborhood && items.city && items.state && posttype=== 'qmi'?
                                                <p tabindex="0" className="card-price cardloc ">{items.neighborhood}  {" "} | {" "}  {items.city},{" "}{items.state}</p>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-info">
                                    <ul className="card-detail">
                                        {items.square_feet ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.square_feet}</span>
                                                <span tabindex="0" className="info-name">Sq.{'\u00A0'}Footage</span>
                                            </li>
                                        ) : ('')}
                                        {items.bed ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.bed}</span>
                                                <span tabindex="0" className="info-name">
                                                    {items.bed === '1' ? ('Bed') : ('Beds')}
                                                </span>
                                            </li>
                                        ) : ('')}
                                        {items.bath ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.bath}</span>
                                                <span tabindex="0" className="info-name">
                                                    {items.bath === '1' ? ('Bath') : ('Baths')}
                                                </span>
                                            </li>
                                        ) : ('')}
                                        {items.garage ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.garage}</span>
                                                <span tabindex="0" className="info-name">Bay Garage</span>
                                            </li>
                                        ) : ('')}
                                    </ul>
                                    <Link to={items.post_permalink} className="learn-more qmi-learn-more">LEARN MORE</Link>
                                </div>
                                <div className="card-hover-content">
                                    <div class="qmi-card-content">
                                        <Link to={items.post_permalink} className="learn-more">LEARN MORE</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </OwlCarousel>
                    </div> :
                        <div className="qmi-card">
                            {Qmi_detail.map(items => (
                                <div className="card-main-div col-lg-6 col-sm-6">
                                    <div className="card-imgs">
                                        <div className="plan-img">
                                            {items.image.length > 0 ? (
                                                items.image.length === 1 ? (
                                                    <Link to={items.post_permalink}>
                                                    <div className="overlay">
                                                        <img src={items.image[0]} className="image-building-image" alt={items.image_alt[0]?items.image_alt[0]:items.image_alt[1]} title={items.image_alt[1]} />
                                                        </div>
                                                    </Link>
                                                ) : ( '')
                                            ) : ('')}
                                        </div>
                                        <div className="plan-img-info">
                                            {/* <span className="card-snipe">Promotion Snipe Here</span> */}
                                            {items.promotion_info ? (
                                                items.promotion_info.promo_snipe ? (
                                                    <a href={items.promotion_info.promo_link}><span className="card-snipe" tabindex="0" style={{backgroundColor:items.promotion_info.promo_snipe_color}}
                                                    onKeyPress={(e)=>{
                                                        if(e.keyCode == 13 || e.which == 13) e.target.click();
                                                    }}>{items.promotion_info.promo_snipe}</span></a>
                                                ) : (
                                                    items.promotion_info.promo_icon.url ? (
                                                        <a href={items.promotion_info.promo_link}><span className="promo-icon" tabindex="0"
                                                        onKeyPress={(e)=>{
                                                            if(e.keyCode == 13 || e.which == 13) e.target.click();
                                                        }}><img src={items.promotion_info.promo_icon.url} alt={items.promotion_info.promo_icon.alt ? items.promotion_info.promo_icon.alt : items.promotion_info.promo_icon.title} title={items.promotion_info.promo_icon.title}/></span></a>
                                                    ):('')
                                                )
                                            ) : ('')}
                                            <div className="card-img-info">
                                                {items.post_status ? (
                                                    <span tabindex="0" className="card-status">{items.post_status}</span>
                                                ) : ('')}
                                                {items.title ? (
                                                    <h4 tabindex="0" className="card-name">{items.title}</h4>
                                                ) : ('')}
                                                <p tabindex="0" className="card-price">
                                                {items.price_value && items.post_status!== "Sold Out"  ? (
                                            items.city && posttype !== 'qmi'?
                                            <p tabindex="0" className="card-price">{items.city}, {items.state} | {items.price_value}</p> :
                                            <p tabindex="0" className="card-price">{items.price_value}</p>
                                            ):('')}
                                                {items.neighborhood && items.city && items.state && posttype=== 'qmi'?
                                                <p tabindex="0" className="card-price cardloc ">{items.neighborhood}  {" "} | {" "}  {items.city},{" "}{items.state}</p>
                                                : ''}
                                               </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-info">
                                        <ul className="card-detail">
                                            {items.square_feet ? (
                                                <li>
                                                    <span tabindex="0" className="info-value">{items.square_feet}</span>
                                                    <span tabindex="0" className="info-name">Sq.{'\u00A0'}Footage</span>
                                                </li>
                                            ) : ('')}
                                            {items.bed ? (
                                                <li>
                                                    <span tabindex="0" className="info-value">{items.bed}</span>
                                                    <span tabindex="0" className="info-name">
                                                        {items.bed === '1' ? ('Bed') : ('Beds')}
                                                    </span>
                                                </li>
                                            ) : ('')}
                                            {items.bath ? (
                                                <li>
                                                    <span tabindex="0" className="info-value">{items.bath}</span>
                                                    <span tabindex="0" className="info-name">
                                                        {items.bath === '1' ? ('Bath') : ('Baths')}
                                                    </span>
                                                </li>
                                            ) : ('')}
                                            {items.garage ? (
                                                <li>
                                                    <span tabindex="0" className="info-value">{items.garage}</span>
                                                    <span tabindex="0" className="info-name">Bay Garage</span>
                                                </li>
                                            ) : ('')}
                                        </ul>
                                        <Link to={items.post_permalink} className="learn-more qmi-learn-more">LEARN MORE</Link>
                                    </div>

                                </div>
                            ))}

                        </div>
                        ])
                    )}
                </div>

        );
    }
}

export default ProductList;
