import React from 'react';
import './FlexiableContent.scss';
import HeadingSection from './HeadingSection';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const options = {
    nav: false,
    dots: false,
    loop : false,
    responsive : {
        0:{
            items: 1,
            stagePadding: 40,
            margin:20
        },
        680:{
            items: 2,
            margin:30
        }
    }
}
class FlexiableContent extends React.Component {
    componentDidMount() {
        const blogs_data = this.props.blogs_data;
        const section_id = blogs_data.section_id && blogs_data.section_id.trim();
        if (section_id) {
            setTimeout(function () {
                if (window.location.href.indexOf("#" + section_id) > -1) {
                    document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth', block:'start' });
                }
            }, 2000)
        }
    }
  render() {
    var window_width = window.screen.width;
    var blogs_data = this.props.blogs_data;
    var section_id = blogs_data.section_id && blogs_data.section_id.trim();
    var toShow;
     return (
        blogs_data.blogs.length > 0 ? [
            (window_width > 1024) ? (
                 <div className="flexiable-section section-margin section-padding wrap" id={section_id}>
                {blogs_data.blogs.map(items => (
                    <div className="flexiable-content">
                        <div className="flexibale-img">
                            {items.card_image ? (
                                <a href={items.blog_url}>
                                    <img tabindex="0" src={items.card_image.url} title={items.card_image.title} alt={items.card_image.alt?items.card_image.alt:items.card_image.title} />
                                </a>
                            ):('')}
                        </div>
                        <div className="flexiable-info" >
                            <HeadingSection
                              subtitle={items.sub_text}
                              title={items.post_title}
                              highlight_class='green-color'
                              highlight_dir='left'
                              highlighted_word_count={2}
                              headline_size={items.hasOwnProperty('headline_size') ? items.headline_size : 'large'}
                              text_class='gray-color'
                              heading_anchor = 'anchor'
                              flexible_blog_url = {items.blog_url}
                            />
                            {items.formatted_excerpt ? (
                                <p className="title-info" dangerouslySetInnerHTML={{__html: items.formatted_excerpt}}>
                                </p>
                            ):('')}
                             {items.blog_url ? (
                                <a href={items.blog_url} className="learn-more" title="learn more">Learn More</a>
                            ):('')}

                        </div>
                    </div>
                ))}
            </div> )
            :(
                     <div className="flexiable-section section-margin section-padding wrap" id={section_id}>
                    <OwlCarousel {...options} className="owl-theme">
                    {blogs_data.blogs.map(items => (
                    <div className="flexiable-content">
                            <div className="flexibale-img">
                                {items.card_image? (
                                    <a href={items.blog_url}>
                                        <img src={items.card_image.url} title={items.card_image.title} alt={items.card_image.alt ? items.card_image.alt : items.card_image.title } />
                                    </a>
                                ):('')}
                            </div>
                            <div className="flexiable-info"  >
                                <HeadingSection
                                 subtitle={items.sub_text}
                                 title={items.post_title}
                                 highlight_class='green-color'
                                 highlight_dir='left'
                                 highlighted_word_count={2}
                                 text_class='gray-color'
                                 heading_anchor='anchor'
                                 flexible_blog_url={items.blog_url}
                                />
                                {items.formatted_excerpt ? (
                                    <p className="title-info">
                                        {items.formatted_excerpt}
                                    </p>
                                ):('')}
                                {items.blog_url ? (
                                    <a href={items.blog_url} className="learn-more" title="learn more">Learn More</a>
                                ):('')}
                            </div>
                        </div>
                    ))}
                    </OwlCarousel>
                </div>
            )
        ]:['']
     );
  }
}

export default FlexiableContent;
