import React from 'react'
import { srcset } from './Helpers'
import './GenericBlock.scss'

class GenericBlock extends React.Component {
  componentDidMount() {
    const data = this.props.generic_block
    const section_id = data.section_id && data.section_id.trim()

    if (section_id) {
      setTimeout(() => {
        if (window.location.href.indexOf('#' + section_id) > -1) {
          document.querySelector('#' + section_id).scrollIntoView({behavior:'smooth', block: 'start'})
        }
      }, 2000)
    }

    window.lazy.update()
  }

  render() {
    const data = this.props.generic_block
    const hide_component = this.props.hide_component
    const section_id = data.section_id && data.section_id.trim()
    let target = '';

    if (this.props.target_change || data.open_in_new_tab) {
      target= "_blank"
    }

    return (
      !hide_component && data.image.url && (data.headline || data.description) ? (
        <section class={`media-lockup${data.image_position === 'left' ? ' media-lockup--reverse' : ''} flex margin-vertical viewport-width`}
          id={section_id}
          style={{
            '--flex-align-items-desktop': 'center',
            '--flex-direction-desktop': data.image_position === "right" ? 'row-reverse' : 'row',
            '--flex-direction-mobile': 'column',
            '--margin-vertical-bottom-desktop': '0',
            '--margin-vertical-bottom-mobile': '90px',
            '--viewport-width-desktop': 'var(--media-lockup-viewport-width, 100vw)',
            '--viewport-width-mobile': '100%',
            'background-color': data.background_color ? data.background_color : '#fff',
          }}>
          <figure class="media-lockup__media aspect-ratio margin-vertical width"
            style={{
              '--aspect-ratio-height': '833',
              '--aspect-ratio-width': '1101',
              '--margin-vertical-bottom-desktop': '0',
              '--margin-vertical-bottom-mobile': '33px',
              '--margin-vertical-top-desktop': '0',
              '--margin-vertical-top-mobile': '0',
              '--width-desktop': '57.34375%',
              '--width-mobile': '100%',
              'align-self': 'stretch',
            }}>
            {data.image.url &&
              <img class="lazy object-fit"
                data-srcset={srcset(data.image)}
                alt={data.image.alt ? data.image.alt : data.image.title}
              />
            }
          </figure>

          <div class="media-lockup__lockup margin-horizontal padding-horizontal max-width padding-vertical width"
            style={{
              '--margin-horizontal-left-desktop': data.image_position === "right" ? 'auto' : '0',
              '--margin-horizontal-right-desktop': data.image_position === "right" ? '0' : 'auto',
              '--max-width': '810px',
              '--padding-horizontal-left-desktop': 'min(5.52vw, 106px)',
              '--padding-horizontal-right-desktop': 'min(5.52vw, 106px)',
              '--padding-horizontal-left-mobile': 'var(--wrap-padding-h-mobile, var(--wrap-padding-h, 32px))',
              '--padding-horizontal-right-mobile': 'var(--wrap-padding-h-mobile, var(--wrap-padding-h, 32px))',
              '--padding-vertical-bottom-mobile': '0',
              '--padding-vertical-top-mobile': '0',
              '--padding-vertical-bottom-desktop': '40px',
              '--padding-vertical-top-desktop': '40px',
              '--width-desktop': '42.65625%',
              '--width-mobile': '100%',
            }}>
            {data.sub_text && data.headline_large &&
              <div class="eyebrow"
                style={{
                  'margin-bottom': 'var(--section-header-eyebrow-margin-bottom, 16px)',
                }}>
                {data.sub_text}
              </div>
            }

            {data.headline &&
              <div class={`media-lockup__title margin-vertical title section-header__title section-header__title--${data.hasOwnProperty('headline_size') ? data.headline_size : 'small'}`}
                style={{
                  '--color': 'var(--gray-mid)',
                  '--margin-vertical-bottom-desktop': '19px',
                  '--margin-vertical-bottom-mobile': '19px',
                }}>
                <span style={{'color': data.headline_part_1_color ? data.headline_part_1_color : 'var(--gray-mid)' }} dangerouslySetInnerHTML={{ __html: data.headline.replace(/(?:\r\n|\r|\n)/g, '') }}></span> {data.headline_part_2 && <span style={{'color': data.headline_part_2_color ? data.headline_part_2_color : 'var(--highlight-color)' }} dangerouslySetInnerHTML={{ __html: data.headline_part_2.replace(/(?:\r\n|\r|\n)/g, '') }}></span>}
              </div>
            }

            {data.sub_text && !data.headline_large &&
              <div class="media-lockup__subtitle font-size font--medium"
                style={{
                  '--font-size-desktop': '20px',
                  '--font-size-mobile': '16px',
                  '--margin-vertical-bottom-mobile': '25px',
                  '--margin-vertical-bottom-mobile': '25px',
                }}>
                {data.sub_text}
              </div>
            }

            {data.description &&
              <div class="description margin-vertical max-width rte"
                style={{
                  '--margin-vertical-bottom-desktop': '0',
                  '--margin-vertical-bottom-mobile': '33px',
                  '--margin-vertical-top-desktop': '25px',
                  '--margin-vertical-top-mobile': '16px',
                  '--max-width': '450px',
                }}
                dangerouslySetInnerHTML={{ __html: data.description.replace(/(?:\r\n|\r|\n)/g, '') }}>
              </div>
            }

            {data.cta_label && data.cta_link &&
              <a class="button margin-vertical"
                style={{
                  '--margin-vertical-bottom-desktop': '0',
                  '--margin-vertical-bottom-mobile': '0',
                  '--margin-vertical-top-desktop': '65px',
                  '--margin-vertical-top-mobile': '7px',
                }}
                href={data.cta_link}
                target={target}>
                <span>{data.cta_label}</span>
              </a>
            }
          </div>
        </section>
      ):('')
    )
  }
}

export default GenericBlock