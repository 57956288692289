import React from 'react';
import axios from 'axios';
import {apiConfig} from '../../Classes/Helper.jsx';
import NoMatch from './NoMatch';
import { Redirect } from "react-router-dom";
import Blogs from '../../Classes/Blogs.jsx';
import GlobalField from '../../Classes/GlobalField';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {Helmet} from "react-helmet";
import callTrkSwap from '../../../helpers/callTrkSwap.js';

class BlogListing extends React.Component {
  state ={
    bloglisting_data : [],
    success : false,
    message : '',
  }

  fetch_data(){
    axios.get(apiConfig().API_URL+'/blog_listing')
    .then(response => {
      this.setState({
        bloglisting_data : response.data.data,
        success: response.data.success,
        message : response.data.message,
      })
    })
  }

  componentDidMount(){
    this.fetch_data();
    callTrkSwap();
    window.scrollTo(0,0)
  }

  componentDidUpdate(){
    callTrkSwap();
    window.scrollTo(0,0)
  }

  render() {
    var success = this.state.success;
    var message = this.state.message;
    let component ;
    var bloglisting_data = this.state.bloglisting_data;
    var yoast_following = bloglisting_data.yoast_following;
    var yoast_indexing = bloglisting_data.yoast_indexing;
      var context_yoast = '';
        if (yoast_indexing === 'true' && yoast_following === 'true') {
          var context_yoast = 'index, follow, max-image-preview:large';
        }else if (yoast_indexing === 'true' && yoast_following === 'false') {
          var context_yoast = 'index, nofollow, max-image-preview:large';
        }else if (yoast_indexing === 'false' && yoast_following === 'true') {
          var context_yoast = 'noindex, follow, max-image-preview:large';
        }else if (yoast_indexing === 'false' && yoast_following === 'false') {
          var context_yoast = 'noindex, nofollow, max-image-preview:large';
        }

    component = bloglisting_data.component;
    for(var x in component ){
      if(component[x].acf_fc_layout == "global_faq_section")
       {component.push( component.splice(x,1)[0] )}
      
      }
      
    const category_list = bloglisting_data.categories;

    return (
        <>
        <Helmet>
              <meta name="robots" content={context_yoast} />  
              <link rel="canonical" href={window.location.href} />
              <meta property="og:url" content={window.location.href} />
        </Helmet> 
            {success === true ? [
              <GlobalField global_component={component} />,
                <Blogs updated_blog_post={bloglisting_data.blog_posts} category_list={category_list}/>

            ]:[ message != 'Not Completed' ?
              <SkeletonTheme >
                  <div className="blog_listing_container">
                    <div className="blog-cover-section">
                      <div className="blog-cover-content">
                        <div className="blog-cover-img divide-equal">
                            <img/>
                        </div>
                        <div className="blog-cover-info divide-equal">
                          <div className="header-section">
                            <div className="heading-section">
                              <Skeleton className="sub-title"/>
                              <h2 className="main-title">
                                <Skeleton height={50}/>
                              </h2>
                            </div>
                            <Skeleton height={100} className="title-info"/>
                            <Skeleton height={50} width={100}/>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="category_list">
                    {Array(6).fill().map((item, index) => (
                      <div className="cat_list_item"> <Skeleton width={100}/></div>
                    ))}
                    </div>
                    <div className="card_heading_content">
                      <Skeleton height={30}/>
                      <Skeleton height={50}/>
                    </div>
                    <div className="blog_card_container">
                    {Array(6).fill().map((item, index) => (
                      <div className="blog_card">
                          <div className="blog_card_img">
                            <Skeleton height="100%"/>
                          </div>
                          <div className="blog_card_content">
                            <div className="blog_card_category">
                              <Skeleton />
                            </div>
                            <div className="blog_card_content_caption">
                              <Skeleton height={30}/>
                            </div>
                            <div className="blog_card_content_para">
                              <Skeleton height={100}/>
                            </div>
                            <div className="read_more_btn">
                              <Skeleton height={30} width={100}/>
                            </div>
                          </div>
                      </div>
                    ))}
                    </div>
                  </div>
              </SkeletonTheme>
            :  <Redirect to={<NoMatch/>} />]}

        </>
       );

  }
}

export default BlogListing;
