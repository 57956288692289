import React from 'react';
import HeadingSection from './HeadingSection';

class PromotionBlock extends React.Component {

  render() {
    var promo_data = this.props.promo_data ;
     return (
          promo_data.promo_label && promo_data.promo_link && promo_data.promo_headline ? [
            <div className="spring-section" style={{backgroundColor: promo_data.promo_bg_color}}>
                <HeadingSection
                  subtitle={promo_data.promo_sub_text}
                  title={promo_data.promo_headline}
                  headline_size={promo_data.hasOwnProperty('headline_size') ? promo_data.headline_size : 'large'}
                />
                <div className="text-flex">
                {promo_data.promo_description ?( <p tabindex="0" className="title-info" dangerouslySetInnerHTML={{__html:promo_data.promo_description.replace(/(?:\r\n|\r|\n)/g, '')}}></p> ):('')}
                {promo_data.promo_label && promo_data.promo_link ? (
                  <a href={promo_data.promo_link} title={promo_data.promo_label} className="button button--light learn-more">
                    <span class="button__text">
                      {promo_data.promo_label}
                    </span>
                  </a>
                ):('')}
                </div>
            </div>
          ]:['']
     );
  }
}

export default PromotionBlock;
