import React, { Fragment } from 'react';
import IconCaret from '../ui/icons/IconCaret';
import { getAutocompleteHitUrl } from './helpers';

const SubmarketHit = ({ item }) => {
    return (
        <a
            className="tw-text-charcoal tw-flex tw-items-center tw-h-full tw-justify-between tw-pr-2"
            href={getAutocompleteHitUrl({
                stateAbbr: item.stateAbbr.toLowerCase(),
                submarketSlug: item.slug,
                override: item.override,
                override_url: item.override_url,
            })}
        >
            <span>{item.name}</span> <span className="tw-italic tw-text-slate">{item.state}</span>
        </a>
    );
};

const createSubmarketsPlugin = ({ submarketsByState, ...rest }) => {
    const submarkets = submarketsByState
        .map((state) => {
            return state.submarkets.map((submarket) => {
                return {
                    ...submarket,
                    state: state.name,
                    stateAbbr: state.alpha_2_code.toLowerCase(),
                    override: state.override_search,
                    override_url: state.override_search_link,
                };
            });
        })
        .flat();
    return {
        ...rest,
        limit: 3,
        getSources({ state: searchState, setQuery, setContext, refresh }) {
            return [
                {
                    sourceId: 'pluginSubmarkets',
                    getItems({ query }) {
                        if (!query || submarkets.length === 0) {
                            return [];
                        }
                        const queryToMatch = query.toLowerCase().trim();
                        return submarkets.filter(({ name, state, stateAbbr }) => {
                            const matchesState = state.toLowerCase().includes(queryToMatch);
                            const matchesStateAbbr = stateAbbr.toLowerCase().includes(queryToMatch);
                            const matchesTitle = name.toLowerCase().includes(queryToMatch);
                            return matchesTitle || matchesState || matchesStateAbbr;
                        });
                    },
                    onSelect({ item }) {
                        window.location.href = getAutocompleteHitUrl({
                            stateAbbr: item.stateAbbr.toLowerCase(),
                            submarketSlug: item.slug,
                            override: item.override_search,
                            override_url: item.override_search_link,
                        });
                    },
                    // keyboard
                    getItemUrl({ item }) {
                        return getAutocompleteHitUrl({
                            stateAbbr: item.stateAbbr.toLowerCase(),
                            submarketSlug: item.slug,
                            override: item.override_search,
                            override_url: item.override_search_link,
                        });
                    },
                    templates: {
                        item({ item }) {
                            return <SubmarketHit item={item} />;
                        },
                        header(props) {
                            const { items } = props;
                            if (items.length === 0) {
                                return <Fragment />;
                            }
                            const hasState = searchState.context.selectedState;
                            return (
                                <Fragment>
                                    {hasState && (
                                        <div className="tw-pr-2 tw-pb-3 tw-flex tw-items-center tw-min-h-[40px]">
                                            <button
                                                onClick={() => {
                                                    setQuery('');
                                                    setContext({ selectedState: null });
                                                    refresh();
                                                }}
                                                className="tw-flex tw-items-center tw-text-charcoal hover:tw-no-underline hover:tw-text-slate tw-w-full tw-h-full tw-text-left"
                                            >
                                                <IconCaret direction="left" />
                                                <span className="tw-ml-2">Back to States</span>
                                            </button>
                                        </div>
                                    )}
                                    <span className={`aa-SourceHeaderTitle ${hasState ? '!tw-mt-4' : ''}`}>
                                        Submarket Metro
                                    </span>
                                    <span className={`aa-SourceHeaderLine ${hasState ? '!tw-mt-8' : ''}`} />
                                </Fragment>
                            );
                        },
                    },
                },
            ];
        },
    };
};

export default createSubmarketsPlugin;
