import React from 'react';
import  pattern_image  from '../../assets/images/50gallery.png';
import fancybox from '@fancyapps/fancybox' // eslint-disable-line no-unused-vars
import './ScheduleTourForm.scss';
import $ from 'jquery';
//import Cookies from 'js-cookie';
import cookie from 'react-cookies';
var evtTriggred = false;


class STFormQMI extends React.Component {
 
    TourloadMarketoForm() {

        var that = this;
        const title = this.props.form_title;
        const community_id = this.props.community_id;
        const crm_salesforce_id = this.props.communityOfInterest;
        const division_name = this.props.division_name;
		const cookie_value = this.state.mkto_cookie;
        if (window.MktoForms2 !== "undefined") {
            window.MktoForms2.loadForm("//456-RUT-066.mktoweb.com", "456-RUT-066", 5543, function(form) {
				
                form.onSuccess(function(values, followUpUrl) {
                        var useremail = values.Email;
                        var cookieexpiry = new Date();
                        cookieexpiry.setFullYear(cookieexpiry.getFullYear() + 2);
                    
                        //set cookie
                        document.cookie="_mkto_email="+useremail+"; expires="+cookieexpiry+"; path=/";
                        return false;
                });
                window.JQUERY('.headtxt').html(title);
                window.JQUERY('#tour_form .logo > a:first-child').attr({href:window.location.origin,title:"Tri Pointe Homes", alt:"Tri Pointe Homes"});
                window.JQUERY('#tour_form .logo img').attr({src:require('../../assets/images/Tripointe_updated_Icon.png'),title:"Tri Pointe Homes", alt:"Tri Pointe Homes"});
           
                // if(map_directions_link !== ""){
                //     window.JQUERY('#DriveDir a').attr("target","_blank");
                //     window.JQUERY('#DriveDir a').attr("href",map_directions_link);
                // }else{
                //     window.JQUERY('#DriveDir').hide();
                // }
                
                window.JQUERY('#mktoForm_5543 a#PrivacyLink').attr("href",window.location.origin+"/privacy-policy/");
                window.JQUERY('#mktoForm_5543 input[name="External_Lead_Id__c"]').val(community_id);
               
                var date_trigger = setInterval(function(){ 
                    if(window.jQuery('#mktoForm_5543 #requestedDate').val() && window.jQuery('#mktoForm_5543 #requestedDate').val().length > 0){
                        window.JQUERY( "#mktoForm_5543 #requestedDate" ).trigger( "change" ); 
                        clearInterval(date_trigger);
                    }
                }, 200);
            });
            
            window.MktoForms2.whenReady(function (form) {

                window.JQUERY("#mktoForm_5543").find('[name="Communities_of_Interest__c"]').val(crm_salesforce_id);
                window.JQUERY("#mktoForm_5543").find('[name="formURL"]').val(window.location.href);
                window.JQUERY("#mktoForm_5543").find('[name="Division__c"]').val(division_name);
                window.JQUERY("#mktoForm_5543").find('[name="communityName"]').val(title);
				
				var formfields = form.vals();
					var formfields_names = Object.keys(formfields);

                    var formfilterValue = cookie_value;
                    // console.log('Cookie value: '+formfilterValue);
                    var outputArray = [];
                    for (var i = 0; i < formfields_names.length; i++){
                        if(formfields_names[i] && formfields_names[i]!='munchkinId' && formfields_names[i]!='formid'){
                            if ((window.JQUERY.inArray(formfields_names[i], outputArray)) == -1){
                                outputArray.push(formfields_names[i]);
                            }
                        }                               
                    }
                    const reducedArray = outputArray.reduce((fields_names, feilds) => `${fields_names}${feilds},` ,'')
                    var data = "";
					var data = {
    					action: 'formnames',
    					requestData: reducedArray,
    					filterType:'cookies',
    					filterValue: formfilterValue
					};
                    window.JQUERY.ajax({
                        type: 'POST',
                        data: data,
                        url: "/wp-admin/admin-ajax.php",
                        success:function(response){
                            var field_havevalues = [];
                            var results = [];
                            results = JSON.parse(response);
                            var finalvaluestofill = {};
                            window.JQUERY.each(formfields_names,function(value, elem){
                                if(elem){
                                    var keys = elem.toLowerCase();
                                    if(keys in results){
                                        if(results[keys] && results[keys] != ''){
                                            finalvaluestofill[elem] = results[keys];
                                            field_havevalues.push(elem);
                                        }
                                    }
                                }
                            });

                            if (finalvaluestofill) {
                                form.vals(finalvaluestofill);
                            }
                        }
                    });


            });
        }

    }

    componentDidMount() {
        this.state = {
            mkto_cookie: cookie.load('_mkto_trk'),
            holidays_added: false
        };
        this.TourloadMarketoForm();	
       
       
    }
    componentDidUpdate(){
        $('#requestedDate,#mktoForm_5543 img, .headtxt, .step, .heading, label, .subhead, .nxtStep, .disclaimer, .skip, .boxed, .headd, .fancybox-close-small').attr('tabindex', '0');
        if(!evtTriggred){
            evtTriggred = true;
            if($('#requestedDate').length>0){
                $('#requestedDate').on('keydown',(e)=>{
                    if(e.which===9 || e.keyCode === 9){
                    $('#LblapptRequestedTime').focus();
                }
                
            })
          }
        }
        $('#LblmktoRadio_90111_0, #LblmktoRadio_90111_1').on('keydown',(e)=>{
            if(e.which===13 || e.keyCode === 13){
             e.target.click(); 
            }
        })
        
    }
    componentWillUnmount(){         
        $('#ui-datepicker-div').remove();          
    }
    render() {
        var tour_post_title = 'Form Title';
        var tour_post_alt = 'Form_alt';
        var thumbnail_image_alt= "image_title";
        var thumbnail_image_title= "image_title" ;
        var pattern_image_title= 'pattern_image_title' ;
        return (
            
            <div id="tour_form">
                <div className="tourpop_form" >
                    <div className="form-column">
                        <form id="mktoForm_5543"></form>		
                    </div>	
                    <div className="form-image">
                        
                            {pattern_image ? (
                                <div className="two_image">
                                    <img tabindex="0" src={pattern_image} alt={tour_post_alt ? tour_post_alt : tour_post_title} title={pattern_image_title}/>	
                                </div>
                                ):('')
                                }
                            
                        <picture><img tabindex="0" src={pattern_image} alt={thumbnail_image_alt ? thumbnail_image_alt : thumbnail_image_title} title={thumbnail_image_title}/></picture>
                    </div>	
                </div>
            </div>
        )
    }
}

export default STFormQMI