import React from 'react'

class SectionHeader extends React.Component {
  render() {
    var headlineSize = this.props.hasOwnProperty('headline_size') ? this.props.headline_size : 'large'

    const eyebrowDefaultStyles = {
      'margin-bottom': 'var(--section-header-eyebrow-margin-bottom, 16px)',
    }

    const titleMarginBottom = this.props.hasOwnProperty('title_margin_bottom_small') && this.props.title_margin_bottom_small ? '25px' : 'var(--section-header-title-margin-bottom, 32px)'

    const eyebrowBorderStyles = {
      '--section-padding-bottom-desktop': '0',
      '--section-padding-bottom': '0',
      '--section-padding-top-desktop': '82px',
      '--section-padding-top': '68px',
      'border-top': '1px solid #bfbfbf',
      'margin-bottom': 'var(--section-header-eyebrow-margin-bottom, 16px)',
    }

    const eyebrowStyles = this.props.eyebrow_border ? eyebrowBorderStyles : eyebrowDefaultStyles

    const headingPart2Class = this.props.headline_part_2_class ? this.props.headline_part_2_class : this.props.headline_part_2 && this.props.headline_part_2.split(' ').length > 1 ? 'title__highlight block' : 'title__highlight'

    return (
      <>
        {this.props.eyebrow &&
          <div class={`section-header__eyebrow eyebrow relative${this.props.eyebrow_border ? ' section-padding' : ''}`}
            style={eyebrowStyles}>
            {this.props.eyebrow}
          </div>
        }

        {this.props.headline_part_1 &&
          <div class={`section-header__title section-header__title--${headlineSize} relative title title--medium`}
            style={{
              'margin-bottom': titleMarginBottom,
            }}>
            <span style={{ color: this.props.headline_part_1_color }} dangerouslySetInnerHTML={{__html:this.props.headline_part_1.replace(/(?:\r\n|\r|\n)/g, '')}}></span> {this.props.headline_part_2 && <span style={{ color: this.props.headline_part_2_color }} class={headingPart2Class} dangerouslySetInnerHTML={{__html:this.props.headline_part_2.replace(/(?:\r\n|\r|\n)/g, '')}}></span>}
          </div>
        }
      </>
    )
  }
}

export default SectionHeader
