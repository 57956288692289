import config from '../Search/config/config';

const getStrippedString = (str) => {
    return str
        .toLowerCase()
        .replaceAll('-', ' ')
        .replace(/[^\w\s]/gi, '');
};

export const getQueryAsState = ({ submarketsByState, query }) => {
    const queryToTest = getStrippedString(query);
    const states = submarketsByState.map((state) => state.name.toLowerCase());
    const abbrevs = submarketsByState.map((state) => state.alpha_2_code.toLowerCase());
    if (states.includes(queryToTest)) {
        return submarketsByState.find((state) => state.name.toLowerCase() === queryToTest);
    }
    if (abbrevs.includes(queryToTest)) {
        return submarketsByState.find((state) => state.alpha_2_code.toLowerCase() === queryToTest);
    }
};

export const getQueryAsStateSubmarket = ({ submarketsByState, query }) => {
    const queryToTest = getStrippedString(query);
    const submarkets = submarketsByState.reduce((acc, state) => {
        return acc.concat(state.submarkets.map((submarket) => getStrippedString(submarket.name)));
    }, []);
    // Could this match odd things? e.g. "sac" would match "sacramento"
    const match = submarkets.find((submarket) => submarket.includes(queryToTest));
    if (match) {
        let foundSubmarket = null;
        const foundState = submarketsByState.find((state) => {
            const found = state.submarkets.find((submarket) => {
                return getStrippedString(submarket.name).includes(queryToTest);
            });
            if (found) {
                foundSubmarket = found;
            }
            return found;
        });
        return {
            state: foundState,
            submarket: foundSubmarket,
        };
    }
};

export const getAutocompleteHitUrl = ({ stateAbbr, submarketSlug, query, cities, override, override_url }) => {
    const urlSegments = [];

    if (override) {
        return override_url;
    }

    if (stateAbbr) {
        urlSegments.push(stateAbbr);
    }
    if (submarketSlug) {
        urlSegments.push(submarketSlug);
    }

    const queryParams = [];
    if (query) {
        queryParams.push(`query=${query}`);
    }
    if (cities) {
        queryParams.push(`cities=${cities}`);
    }

    return `/${config.DEFAULT_SEARCH_URL_PATH}${urlSegments.join('/')}${queryParams.length ? `?${queryParams.join('&')}` : ''}`;
};
