import React from 'react';
import './LivingSmartTabbed.scss';
import HeadingSection from './HeadingSection';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Accordion,Card} from 'react-bootstrap';
import 'react-tabs/style/react-tabs.css';
import $ from 'jquery';


class LivingSmartTabbed extends React.Component {
    componentDidMount() {
        const living_smart_tabbed = this.props.living_smart_tabbed;
        const section_id = living_smart_tabbed.section_id && living_smart_tabbed.section_id.trim();
        if (section_id) {
            setTimeout(function () {
                if (window.location.href.indexOf("#" + section_id) > -1) {
                    document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth', block:'start' });
                }
            }, 2000)
        }
    }

  render() {
    var living_smart_tabbed = this.props.living_smart_tabbed ;
    var section_id = living_smart_tabbed.section_id && living_smart_tabbed.section_id.trim();
    var window_width = window.screen.width;
    var target_change;
    if( living_smart_tabbed.anchor_in_new_tab == true) {
        target_change = "_blank";
    } else {
        target_change = "";
    }
     return (
      (living_smart_tabbed.tabbed_section.length > 0)? (
             <div className="blog-section living-smart-tabbed section-margin section-padding wrap" id={section_id}>
                  <div className="blog-content">
                        <div className="header-section">
                            <div className="heading-section-left">
                              <HeadingSection
                                subtitle={living_smart_tabbed.sub_text}
                                title_part_1={living_smart_tabbed.headline}
                                title_part_2={living_smart_tabbed.headline_part_2}
                                headline_part_1_color={living_smart_tabbed.headline_part_1_color}
                                headline_part_2_color={living_smart_tabbed.headline_part_2_color}
                                headline_size={living_smart_tabbed.hasOwnProperty('headline_size') ? living_smart_tabbed.headline_size : 'large'}
                              />
                            </div>
                            <div className="heading-section-right">
                            {living_smart_tabbed.description ? (
                                <div className="description rte" dangerouslySetInnerHTML={{__html:living_smart_tabbed.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                            ):('')}
                            {living_smart_tabbed.cta_label && living_smart_tabbed.cta_link ? (
                                <a href={living_smart_tabbed.cta_link} title={living_smart_tabbed.cta_label} target={target_change} className="button"><span class="button__text">{living_smart_tabbed.cta_label}</span></a>
                            ):('')}
                            </div>
                        </div>
                        {living_smart_tabbed.tabbed_section.length > 0 ? [
                            <div className="living-smart-div">
                            {(window_width >= 1025) ? (
                                <Tabs>
                                    <TabList className="living-smart-tablist">
                                    {living_smart_tabbed.tabbed_section.map((items,key) => [
                                        items.headline ? (
                                            <Tab className="living-smart-tab divide-equal">
                                                {items.tab_icon ? (
                                                    <img src={items.tab_icon.url} title={items.tab_icon.title} alt={items.tab_icon.alt ? items.tab_icon.alt : items.tab_icon.title} className='tab-icon' />
                                                ):('')}
                                               <p role="tablist" className='tab-heading' dangerouslySetInnerHTML={{ __html: items.headline.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                            </Tab>
                                        ):('')
                                    ])}
                                    </TabList>
                                    {living_smart_tabbed.tabbed_section.map((items,key) => [
                                    <TabPanel>
                                        {items.image ? (
                                            <div className="tabbed-img divide-equal">
                                                <img src={items.image.url} title={items.image.title} alt={items.image.alt ? items.image.alt :items.image.title} />
                                            </div>
                                        ):('')}
                                        {items.description ? (
                                            <div className="tabbed-text divide-equal" dangerouslySetInnerHTML={{__html:items.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                                        ):('')}
                                    </TabPanel>
                                    ])}
                                </Tabs>
                            ):(
                                <Accordion defaultActiveKey="1">
                                    {living_smart_tabbed.tabbed_section.map((items,key) => [
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey={key+1}>
                                            {items.tab_icon ? (
                                                <img src={items.tab_icon.url} title={items.tab_icon.title} alt={items.tab_icon.alt ? items.tab_icon.alt : items.tab_icon.title} className='tab-icon' />
                                            ):('')}
                                            <p className='tab-heading' dangerouslySetInnerHTML={{ __html: items.headline.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                            <div className="arrowRight"></div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={key+1}>
                                            <Card.Body>
                                                {items.image ? (
                                                    <div className="tabbed-img divide-equal" >
                                                        <img src={items.image.url} title={items.image.title} alt={items.image.alt ? items.image.alt :items.image.title} />
                                                    </div>
                                                ):('')}
                                                {items.description ? (
                                                    <div className="tabbed-text divide-equal" dangerouslySetInnerHTML={{__html:items.description.replace(/(?:\r\n|\r|\n)/g, '')}} ></div>
                                                ):('')}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    ])}
                                </Accordion>
                            )}
                            </div>
                        ]:['']}
                  </div>
          </div>
        ):('')
     );
  }
}

export default LivingSmartTabbed;
