import React from 'react';
import './FullImageBlock.scss';

class FullImageBlock extends React.Component {

    componentDidMount(){
        if(window.screen.width <= 1024 && document.querySelector('.youtube_video_iframe') != null ){
            this.loadVideo();
        }
        const image_block_data = this.props.full_image_block;
        const section_id = image_block_data.section_id && image_block_data.section_id.trim();
        if (section_id) {
            setTimeout(function () {
                if (window.location.href.indexOf("#" + section_id) > -1) {
                    document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
                }
            }, 2000)
        }
    }

    loadVideo = () => {
        this.player = new window.YT.Player('video_frame', {
            events: {
            onReady: this.onPlayerReady,
            },
        });
    };

    onPlayerReady = event => {
        event.target.playVideo();
    };

    render() {
        var image_block_data = this.props.full_image_block;
        console.log('image_block_data', image_block_data)
        var section_id = image_block_data.section_id && image_block_data.section_id.trim();
        var url_split,id_url ;
        var fullImage = this.props.fullImage;
        var overlay_hide = image_block_data.hide_overlay == 1 ? '':'add_overlay';
        var backgroundImage = image_block_data.background_image.url;
        if(backgroundImage !==""){
            var background_image = {
                backgroundImage:'url('+backgroundImage+')',
            }
        }
        return (
            fullImage ? 
                image_block_data.background_media ? (
                    <div className="image-box-main" >
                        <div tabindex="0" className={"promo_image-box "+overlay_hide} style={background_image}>
                            {image_block_data.background_media === "video" ? (
                                image_block_data.background_video ? (
                                    url_split = image_block_data.background_video.split("/"),
                                    id_url = url_split[url_split.length - 1],
                                    image_block_data.background_video.indexOf("tube") >= 1 ? [
                                        <iframe tabindex="0" src={image_block_data.background_video+"?autoplay=1&showinfo=0&controls=0&loop=1&mute=1&playlist="+id_url+";&enablejsapi=1&html5=1"} frameborder="0" allowfullscreen className="video-box youtube_iframe" title="video-iframe" id="video_frame"></iframe>
                                    ]:[
                                        <iframe tabindex="0" src={image_block_data.background_video+"?autoplay=1&showinfo=0&controls=0&loop=1&mute=1&playlist="+id_url} frameborder="0" allowfullscreen className="video-box" title="video-iframe"></iframe>
                                    ]
                                ):('')
                            ):('')}
                            {image_block_data.promo_hero_image ? <div className="hero-logo">
                                <img src={image_block_data.promo_hero_image} alt="Promotion Logo" />
                            </div> : ''}
                            
                            <div className="image-box-content">
                                {image_block_data.headline ? (
                                    <h2 tabindex="0" className="image-heading" dangerouslySetInnerHTML={{__html:image_block_data.headline.replace(/(?:\r\n|\r|\n)/g, '')}}></h2>
                                ):('')}
                                {image_block_data.cta_label && image_block_data.cta_link ? (
                                    <a href={image_block_data.cta_link}  className="image-button" title="learn more">{image_block_data.cta_label}</a>
                                ):('')}
                            </div>
                        </div>
                    </div>
                    ):('')
            : image_block_data.background_media ? (
                <div className="image-box-main" >
                    <div tabindex="0" className={"image-box "+overlay_hide} style={background_image}>
                        {image_block_data.background_media === "video" ? (
                            image_block_data.background_video ? (
                                url_split = image_block_data.background_video.split("/"),
                                id_url = url_split[url_split.length - 1],
                                image_block_data.background_video.indexOf("tube") >= 1 ? [
                                    <iframe tabindex="0" src={image_block_data.background_video+"?autoplay=1&showinfo=0&controls=0&loop=1&mute=1&playlist="+id_url+";&enablejsapi=1&html5=1"} frameborder="0" allowfullscreen className="video-box youtube_iframe" title="video-iframe" id="video_frame"></iframe>
                                ]:[
                                    <iframe src={image_block_data.background_video+"?autoplay=1&showinfo=0&controls=0&loop=1&mute=1&playlist="+id_url} frameborder="0" allowfullscreen className="video-box" title="video-iframe"></iframe>
                                ]
                            ):('')
                        ):('')}
                       
                        <div className="image-box-content">
                            {image_block_data.headline ? (
                                <h2 className="section-header__title relative title title--medium image-heading" dangerouslySetInnerHTML={{__html:image_block_data.headline.replace(/(?:\r\n|\r|\n)/g, '')}}></h2>
                            ):('')}
                            {image_block_data.cta_label && image_block_data.cta_link ? (
                                <a href={image_block_data.cta_link}  className="button button--light">
                                  <span class="button__text">{image_block_data.cta_label}</span>
                                </a>
                            ):('')}
                        </div>
                    </div>
                </div>
                ):('')
        );
  }
}

export default FullImageBlock;
