import React, { useState, useEffect } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-hooks-web';
import { config } from '../../Search/config/config';
import Hits from './Hits';
import NoResultsBoundary from './NoResultsBoundary';
import Tabs from './Tabs';
import { searchClient } from '../../Search/helpers/initAlgolia';
import { useDesktopMediaQuery } from '../../Search/hooks/useResponsive';
import { useSearchStateIndex, useSearchStateIndexDispatch } from '../../Search/hooks/useSearchStateIndex';
import NoResults from './NoResults';

const RecentSearchResults = ( { state, submarket } ) => {
	const dispatch = useSearchStateIndexDispatch();
	const isDesktop = useDesktopMediaQuery();
	const { activeIndexName } = useSearchStateIndex();
	const [ filterString, setFilterString ] = useState('');

	useEffect(() => {
		if ( submarket ) {
			setFilterString(`submarket:"${submarket}"`);
		} else if ( state ) {
			setFilterString(`state:"${state}"`);
		} else {
			setFilterString('');
		}
	}, [ state, submarket ] );

	useEffect(() => {
		dispatch({ type: 'changed', index: config.ALGOLIA_INDEX_DEFAULT });
	}, [] );

	if ( ! activeIndexName || ! filterString ) {
		return null;
	}

	return (
		<InstantSearch searchClient={searchClient} indexName={activeIndexName}>
			<Configure
				analyticsTags={[
					config.ANALYTICS_TAGS.searchForm.recentResults,
					`Device: ${ isDesktop ? 'Desktop' : 'Mobile' }`,
				]}
				filters={filterString}
				hitsPerPage={4}
				clickAnalytics
			/>
			<div data-insights-index={activeIndexName}>
				<div className={'tw-text-charcoal tw-font-normal'}>
					<h2 className={'tw-text-2xl lg:tw-text-3xl'}>Based on Your Last Search</h2>
				</div>
				<Tabs />
				<div className={'tw-mt-6'}>
					<div className={! activeIndexName.includes( 'neighborhoods' ) ? 'tw-hidden' : ''}>
						<NoResultsBoundary fallback={<NoResults />}>
							<Hits hitType={'neighborhood'} activeIndex={activeIndexName} />
						</NoResultsBoundary>
					</div>
					<div className={! activeIndexName.includes( 'homes' ) ? 'tw-hidden' : ''}>
						<NoResultsBoundary fallback={<NoResults />}>
							<Hits hitType={'home'} activeIndex={activeIndexName} />
						</NoResultsBoundary>
					</div>
				</div>
			</div>
		</InstantSearch>
	);
};

export default RecentSearchResults;