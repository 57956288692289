import React from 'react';
import axios from 'axios';
import CommonNeighCard from '../../Classes/CommonNeighCard';
import GlobalField from '../../Classes/GlobalField';
import GeoAssociates from '../../Classes/GeoAssociates'
import NoMatch from './NoMatch';
import { Redirect } from "react-router-dom";
import {apiConfig} from '../../Classes/Helper.jsx';
import SectionTitle from '../../Classes/SectionTitle';
// import FaqSection from '../../Classes/FaqSection';
import CityDetail from '../../Classes/CityDetail';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {Helmet} from "react-helmet";
import callTrkSwap from '../../../helpers/callTrkSwap.js';

class CityPage extends React.Component {  

    state ={
       city_component : [],
        success : false,
        message : ''
      }
      constructor() {
        super();
      }
      componentDidMount(){
        axios.get(apiConfig().API_URL+'/sub_city_info/'+ this.props.slug)
        .then(response => {
          this.setState({
            city_component : response.data.data,
            success: response.data.success,
            message: response.data.message
          })
          // console.log(response);
        })
 

        callTrkSwap();
      }
      componentDidUpdate(){
          callTrkSwap();
      }
  render(){  
 
    var success = this.state.success; 
    var message = this.state.message; 
    var city_component = this.state.city_component;
    var state_name = this.props.state.toLowerCase();
    var submarket_name = this.props.submarket.toLowerCase();
    if(city_component && city_component.site_logo){
      var gc_count=city_component.global_components;
      var component = city_component.component;
      for(var x in component ){
        if(component[x].acf_fc_layout == "global_faq_section")
         {component.push( component.splice(x,1)[0] )}
        
        }
    }
    var yoast_following = city_component.yoast_following;
    var yoast_indexing = city_component.yoast_indexing;
    var context_yoast = '';
    if (yoast_indexing === 'true' && yoast_following === 'true') {
    var context_yoast = 'index, follow';
    }else if (yoast_indexing === 'true' && yoast_following === 'false') {
    var context_yoast = 'index, nofollow';
    }else if (yoast_indexing === 'false' && yoast_following === 'true') {
    var context_yoast = 'noindex, follow';
    }else if (yoast_indexing === 'false' && yoast_following === 'false') {
    var context_yoast = 'noindex, nofollow';
    }
    const meta = {
      title: city_component.yoast_title,
      description:  city_component.yoast_desc,
      canonical: window.location.href
    };
    console.log('message',message);
    return(
      <> 
      <Helmet>  
              <title>{city_component.yoast_title}</title>
              <meta name="description" content={city_component.yoast_desc} />
              <meta name="robots" content={context_yoast} /> 
              <meta property="og:title" content={city_component.yoast_title ? city_component.yoast_title : ''} />
              <meta property="og:description" content={ city_component.yoast_desc ?  city_component.yoast_desc : ''} />
              <meta property="og:image" content={city_component.yoast_image}/>
              <link rel="canonical" href={window.location.href} />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:type" content='Website' />
            </Helmet> 
      <div role="main" className="cards-section">
        {success === true ? [ 
            state_name === city_component.state_details.state_abb.toLowerCase() && submarket_name === city_component.submarket.toLowerCase() ? [
              city_component.community.length > 0 || city_component.neighborhood.length > 0 ||    city_component.global_components > 0 ? [
                <CityDetail city_data={city_component.city_detail}/>,
                gc_count > 0 ? [

                  <GlobalField global_component={city_component.component} community_details={city_component.city_detail} slice_start={0} slice_end={city_component.cards_position}/>,
                ]:[''],

                  city_component.community.length > 0 ? [
                    <SectionTitle headline={city_component.canonical_pages_data.community_headline} subtext={city_component.canonical_pages_data.community_subtext+' ('+city_component.community.length+')'} posttype="city" community="community"/>,
                      <CommonNeighCard card_detail={city_component.community} posttype="community"/>
                  ]:[''],

                  city_component.neighborhood.length > 0 ? [
                    <SectionTitle headline={city_component.canonical_pages_data.neighborhood_headline} subtext={city_component.canonical_pages_data.neighborhood_subtext+' ('+city_component.neighborhood.length+')'} posttype="city" />,
                      <CommonNeighCard card_detail={city_component.neighborhood} posttype="neighborhood"/>
                  ]:[''],
                  gc_count >=city_component.cards_position ? (
                    <GlobalField global_component={city_component.component} community_details={city_component.city_detail} slice_start={city_component.cards_position} />
                    ):(''),
                  <GeoAssociates associated_data={city_component.associated_cities} component={city_component} title='Where We Build Near' />
                 
                
                // <FaqSection community_details={city_component}/>
              ]:[<NoMatch/> ]
            ]:[ <NoMatch/> ]
        ]:[ message != 'Not Completed' ?  
          <SkeletonTheme>
            <div className="plan-banner">
                <div className="HeroContent d-flex">
                    <div className="HeroContentWrapper">
                        <div className="bannerHeader">
                            <div className="heading-section">
                                <Skeleton height={40}/>
                                <Skeleton />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sectiontitle-main-div">
              <div className="sectiontitle-headline gray-color">
                <Skeleton height={40}/>
              </div>
              <div className="eyebrow-text-div">
                <Skeleton height={30} width={200} className="sectiontitle-eyebrow_text"/>
                <div className="eyebrowtext-border"></div>
              </div>
            </div>
            <div className="common-card">
              <div className="card-main-div">
                <div className="card-imgs">
                  <Skeleton height={"100%"} />
                  <div className="plan-img-info">
                    <div className="card-img-info">
                      <Skeleton height={20} className="card-status"/>
                      <Skeleton height={40} className="card-name"/>
                      <Skeleton height={20} className="card-price"/>
                    </div>
                  </div>
                </div>
                <div className="card-info">
                  <ul className="card-detail">
                    <li>
                      <Skeleton width={100}/>
                      <Skeleton width={100}/>
                    </li>
                    <li>
                      <Skeleton width={100}/>
                      <Skeleton width={100}/>
                    </li>
                    <li>
                      <Skeleton width={100}/>
                      <Skeleton width={100}/>
                    </li>
                    <li>
                      <Skeleton width={100}/>
                      <Skeleton width={100}/>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-main-div">
                <div className="card-imgs">
                  <Skeleton height={"100%"} />
                  <div className="plan-img-info">
                    <div className="card-img-info">
                      <Skeleton height={20} className="card-status"/>
                      <Skeleton height={40} className="card-name"/>
                      <Skeleton height={20} className="card-price"/>
                    </div>
                  </div>
                </div>
                <div className="card-info">
                  <ul className="card-detail">
                    <li>
                      <Skeleton width={100}/>
                      <Skeleton width={100}/>
                    </li>
                    <li>
                      <Skeleton width={100}/>
                      <Skeleton width={100}/>
                    </li>
                    <li>
                      <Skeleton width={100}/>
                      <Skeleton width={100}/>
                    </li>
                    <li>
                      <Skeleton width={100}/>
                      <Skeleton width={100}/>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="sectiontitle-main-div">
              <div className="sectiontitle-headline gray-color">
                <Skeleton height={40}/>
              </div>
              <div className="eyebrow-text-div">
                <Skeleton height={30} width={200} className="sectiontitle-eyebrow_text"/>
                <div className="eyebrowtext-border"></div>
              </div>
            </div>
            <div className="common-card">
              <div className="card-main-div">
                <div className="card-imgs">
                  <Skeleton height={"100%"} />
                  <div className="plan-img-info">
                    <div className="card-img-info">
                      <Skeleton height={20} className="card-status"/>
                      <Skeleton height={40} className="card-name"/>
                      <Skeleton height={20} className="card-price"/>
                    </div>
                  </div>
                </div>
                <div className="card-info">
                  <ul className="card-detail">
                    <li>
                      <Skeleton width={100}/>
                      <Skeleton width={100}/>
                    </li>
                    <li>
                      <Skeleton width={100}/>
                      <Skeleton width={100}/>
                    </li>
                    <li>
                      <Skeleton width={100}/>
                      <Skeleton width={100}/>
                    </li>
                    <li>
                      <Skeleton width={100}/>
                      <Skeleton width={100}/>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-main-div">
                <div className="card-imgs">
                  <Skeleton height={"100%"} />
                  <div className="plan-img-info">
                    <div className="card-img-info">
                      <Skeleton height={20} className="card-status"/>
                      <Skeleton height={40} className="card-name"/>
                      <Skeleton height={20} className="card-price"/>
                    </div>
                  </div>
                </div>
                <div className="card-info">
                  <ul className="card-detail">
                    <li>
                      <Skeleton width={100}/>
                      <Skeleton width={100}/>
                    </li>
                    <li>
                      <Skeleton width={100}/>
                      <Skeleton width={100}/>
                    </li>
                    <li>
                      <Skeleton width={100}/>
                      <Skeleton width={100}/>
                    </li>
                    <li>
                      <Skeleton width={100}/>
                      <Skeleton width={100}/>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </SkeletonTheme> 
        : <NoMatch/> ]}
        </div>
      </>  
    );  
  }  
}  

export default CityPage;
