import React from 'react';
import axios from 'axios';
import {apiConfig} from '../../Classes/Helper.jsx';
import TPHLoader from '../../Classes/TPHLoader.jsx';

class SearchPost extends React.Component {  
    constructor(props) {
        super(props);
        this.state = {
            post_type : '',
            success : false,
            search:''
        };
    
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event) {
        this.setState({search: event.target.value});
        alert(event.target.value);
        this.fetch_data(event.target.value);
    }
  fetch_data(search = ''){
    axios.get(apiConfig().API_URL+'/search_text/'+search)
    .then(response => {
      this.setState({
        post_type : response.data.data,
        success: response.data.success
      })
    })
  }
  componentDidMount(){
    this.fetch_data();
  }
 
  render() {     
    var success = this.state.success; 
     return (
        // success === true ? [
            <form>
                <input type="text" name="name" />
                <input type="submit" value="Search" aria-label="Search" onClick={this.handleChange}/>
            </form>
        // ]:[<TPHLoader/>]
      );  
  }  
}  

export default SearchPost; 