import { useEffect, useState } from 'react';

// doesn't get updated after first call - can't find browser option to add listener
const useGeoLocation = () => {
	const [ hasGeoLocationPermission, setHasGeoLocationPermission ] = useState( null );
	const [ geoLocationCoords, setGeoLocationCoords ] = useState( null );

	const getGeolocation = () => {
		if ( 'geolocation' in navigator ) {
			navigator.geolocation.getCurrentPosition( ( position ) => {
				setHasGeoLocationPermission( true );
				const currentLatitude = position.coords.latitude;
				const currentLongitude = position.coords.longitude;
				setGeoLocationCoords( {
					lat: currentLatitude,
					lng: currentLongitude,
				} );
			},
			function( error ) {
				if ( error.code === error.PERMISSION_DENIED ) {
					setHasGeoLocationPermission( false );
				}
			} );
		}
	};

	useEffect( () => {
		getGeolocation();
	}, [] );

	return { geoLocation: geoLocationCoords, hasGeoLocationPermission };
};

export default useGeoLocation;

