import React from 'react';
import './GenericBlockWithTable.scss';
import HeadingSection from './HeadingSection';
import $ from 'jquery';
class GenericBlockWithTable extends React.Component {
    componentDidMount() {
        const generic_block = this.props.generic_block;
        const section_id = generic_block.section_id && generic_block.section_id.trim();
        if (section_id) {
            setTimeout(function () {
                if (window.location.href.indexOf("#" + section_id) > -1) {
                    document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth', block:'start' });
                }
            }, 2000)
        }
    }

    render() {
        var generic_block = this.props.generic_block;
        if (generic_block.show_wp_table) {
            var block_padding = '3% 0';
        }else{
            block_padding = '0';
        }
        var section_id = generic_block.section_id && generic_block.section_id.trim();
        var hide_component = this.props.hide_component;
        var target_change = '';
        if(this.props.target_change || generic_block.open_in_new_tab == true) {
            target_change = "_blank";
        }
        return (
            hide_component != true && generic_block.image.url && generic_block.headline && generic_block.description ? (
                <div className="generic-main-div" style={{ backgroundColor: generic_block.background_color ,padding : block_padding}} id={section_id}>
                    {generic_block.image_position === "right" ? [
                        <div className="generic-content divide-equal" >
                            <div className="generic-content-section">
                                <HeadingSection
                                    subtitle={generic_block.sub_text}
                                    title_part_1={generic_block.headline}
                                    title_part_2={generic_block.headline_part_2}
                                    headline_part_1_color = {generic_block.headline_part_1_color}
                                    headline_part_2_color = {generic_block.headline_part_2_color}
                                />
                                {generic_block.description ? (
                                    <p tabindex="0" className="generic-description" dangerouslySetInnerHTML={{ __html: generic_block.description.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                ) : ('')}
                                {generic_block.cta_label && generic_block.cta_link  ? (
                                    <a href={generic_block.cta_link} target={target_change} className="generic-button" title={generic_block.cta_label}>{generic_block.cta_label}</a>
                                ) : ('')}
                            </div>
                        </div>,
                        generic_block.generic_shortcode && generic_block.show_wp_table? 
                            <section className="wp_shortcode" dangerouslySetInnerHTML={{ __html:generic_block.generic_shortcode.replace(/(?:\r\n|\r|\n)/g, '') }} /> 
                        :
                            generic_block.image.url ? (
                                <div className="generic-image divide-equal" >
                                    <img loading="lazy" tabindex="0" src={generic_block.image.url} title={generic_block.image.title} alt={generic_block.image.alt ? generic_block.image.alt : generic_block.image.title} className="generic-block-image" />
                                </div>
                            ) : ('')
                    ] : [
                            generic_block.generic_shortcode && generic_block.show_wp_table ? 
                                <section className="wp_shortcode" dangerouslySetInnerHTML={{ __html:generic_block.generic_shortcode.replace(/(?:\r\n|\r|\n)/g, '') }} /> 
                            :
                            generic_block.image.url ? (
                                <div className="generic-image divide-equal" >
                                    <img loading="lazy" tabindex="0" src={generic_block.image.url} title={generic_block.image.title} alt={generic_block.image.alt ? generic_block.image.alt : generic_block.image.title} className="generic-block-image" />
                                </div>
                        ) : (''),

                            <div className="generic-content divide-equal" >
                                <div className="generic-content-section">
                                    <HeadingSection
                                        subtitle={generic_block.sub_text}
                                        title_part_1={generic_block.headline}
                                        title_part_2={generic_block.headline_part_2}
                                        headline_part_1_color = {generic_block.headline_part_1_color}
                                        headline_part_2_color = {generic_block.headline_part_2_color}
                                    />
                                    {generic_block.description ? (
                                        <p tabindex="0" className="generic-description" dangerouslySetInnerHTML={{ __html: generic_block.description.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                    ) : ('')}
                                    {generic_block.cta_label && generic_block.cta_link ? (
                                       <a href={generic_block.cta_link} target={target_change} className="generic-button" title={generic_block.cta_label}>{generic_block.cta_label}</a>
                                     ) : ('')}
                                </div>
                            </div>
                        ]}
                </div>
                ):('')
            );
  }
}

export default GenericBlockWithTable;

