import classNames from 'classnames';
import React from 'react';

const IconCaret = ( props ) => {
	const { fill = 'charcoal', direction = 'down' } = props;
	const fillClass = classNames( {
		'tw-fill-charcoal group-hover:tw-fill-slate': fill === 'charcoal',
		'tw-fill-slate': fill === 'slate',
		'tw-fill-white group-hover:tw-fill-apple': fill === 'white',
	} );
	const rotateClass = classNames( {
		'tw-transform -tw-rotate-90': direction === 'right',
		'tw-transform tw-rotate-90': direction === 'left',
	} );
	return (
		<svg className={`tw-block ${ rotateClass }`} aria-hidden="true" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path className={fillClass} d="M5.99911 6.70192L0.691406 1.39422L1.39911 0.686523L5.99911 5.28652L10.5991 0.686523L11.3068 1.39422L5.99911 6.70192Z" />
		</svg>

	);
};

export default IconCaret;
