import React, { useEffect, useState } from 'react';
import { Index } from 'react-instantsearch-hooks-web';
import TabToggle from './TabToggle';
import { useSearchStateIndex, useSearchStateIndexDispatch } from '../../Search/hooks/useSearchStateIndex';
import { useSearchStateHitCounts } from './hooks/useSearchStateHitCounts';
import { config } from '../../Search/config/config';

const Tabs = () => {
	const dispatch = useSearchStateIndexDispatch();
	const { activeIndexName } = useSearchStateIndex();
	const { neighborhood_hits, home_hits } = useSearchStateHitCounts();
    const [ showNeighborhoodTab, setShowNeighborhoodTab ] = useState(true);
    const [ showHomesTab, setShowHomesTab ] = useState(true);

    useEffect( () => {
        if ( 0 === neighborhood_hits ) {
            dispatch( {
                type: 'changed',
                index: 'homes',
            } );
            setShowNeighborhoodTab( false );
        }
        if ( 0 === home_hits ) {
            setShowHomesTab( false );
        }
    }, [ neighborhood_hits, home_hits ] );

	return (
		<div className='tw-relative'>
			<div className='tw-flex tw-pt-8 before:tw-w-full before:tw-animate-fade-in before:tw-border-b before:tw-border-platinum before:tw-z-index-0 before:tw-content-[""] before:tw-absolute before:tw-bottom-0'>
			    <Index indexName={`${ config.ALGOLA_ENV }_${ config.ALGOLIA_INDEX_DEFAULT }`}>
                    {showNeighborhoodTab && (<TabToggle
                        onClick={() => {
                            dispatch( {
                                type: 'changed',
                                index: 'neighborhoods',
                            } );
                        }}
                        isActive={activeIndexName.includes( 'neighborhoods' )}
                        label="Neighborhoods"
                        index_type="neighborhoods"
                    />)}
                </Index>
                <Index indexName={`${ config.ALGOLA_ENV }_${ config.ALGOLIA_INDEX_HOMES }`}>
                    <div className={showNeighborhoodTab ? 'ml-3 lg:ml-8' : ''}>
                        {showHomesTab && (<TabToggle
                            onClick={() => {
                                dispatch( {
                                    type: 'changed',
                                    index: 'homes',
                                } );
                            }}
                            isActive={activeIndexName.includes( 'homes' )}
                            label="Homes & Floorplans"
                            index_type="homes"
                        />)}
                    </div>
                </Index>
            </div>
        </div>
    );
};

export default Tabs;
