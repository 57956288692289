import { useEffect, useState } from 'react';
import useGeoLocation from './useGeoLocation';
import { getSubmarketsSortByCoord } from '../helpers/distance';
import useSearchSubmarkets from './useSearchSubmarkets';

const useNearbySubmarkets = ( ) => {
	const { submarketsByState } = useSearchSubmarkets();
	const [ submarketsByDistance, setSubmarketsByDistance ] = useState( [] );
	const { geoLocation } = useGeoLocation();

	useEffect( () => {
		if ( ! geoLocation || submarketsByState.length === 0 ) {
			return;
		}
		setSubmarketsByDistance( getSubmarketsSortByCoord( { submarketsByState, lat: geoLocation.lat, lng: geoLocation.lng } ) );
	}, [ submarketsByState, geoLocation ] );

	return { submarketsByDistance };
};

export default useNearbySubmarkets;
