import React from 'react'
import './InfoGrid.scss'
import SectionHeader from './SectionHeader'
import { srcset } from './Helpers'
import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class InfoGrid extends React.Component {
    render() {
        const carousel_options = {
            nav: false,
            dots: true,
            loop : false,
            responsive : {
                0:{
                    items: 1,
                    margin:20
                },
                680:{
                    items: 1,
                    margin:30
                }
            }
        };

        return (
            this.props.headline_part_1 && this.props.description && this.props.grid_items.length > 0 && (
                <div className="info-grid section-margin section-padding wrap" id={this.props.section_id || ''}>
                    <div className="info-grid__border">
                        <div className="info-grid__header">
                             <div className="heading-section-left">
                                 <SectionHeader
                                     eyebrow={this.props.eyebrow_text}
                                     headline_size='large'
                                     headline_part_1={this.props.headline_part_1}
                                     headline_part_2={this.props.headline_part_2}
                                     headline_part_1_color={this.props.headline_part_1_color}
                                     headline_part_2_color={this.props.headline_part_2_color}
                                     headline_part_2_class='title__highlight'
                                 />
                            </div>

                            <div className="heading-section-right">
                                {this.props.description && (
                                    <div className="description" dangerouslySetInnerHTML={{__html:this.props.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                                )}
                                {this.props.cta_label && this.props.cta_link && (
                                    <Link to={this.props.cta_link}>
                                        {this.props.cta_label}
                                    </Link>
                                )}
                            </div>
                        </div>

                        {this.props.grid_items.length > 0 && (
                            <>
                                <div className="info-grid__item_grid">
                                    {this.props.grid_items.map((item, key) =>
                                        (item.headline || item.description) ? (
                                            <div className="blog-info">
                                                <div className="blog-img">
                                                    {item.image.url && (
                                                        <img className="lazy" data-srcset={srcset(item.image)}
                                                             alt={item.image.alt ? item.image.alt : item.image.title}/>
                                                    )}
                                                </div>
                                                {item.headline && (
                                                    <h6>{(key + 1) + ". "}{item.headline}</h6>
                                                )}
                                                {item.description && (
                                                    <div className="blog-text"
                                                         dangerouslySetInnerHTML={{__html: item.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                                                )}
                                                {item.link && item.link_text && (
                                                    <Link to={item.link}>{item.link_text}</Link>
                                                )}
                                            </div>
                                        ) : ('')
                                    )}
                                </div>
                                <OwlCarousel {...carousel_options}>
                                    {this.props.grid_items.map((item, key) =>
                                        (item.headline || item.description) ? (
                                            <div className="blog-info">
                                                <div className="blog-img">
                                                    {item.image.url && (
                                                        <img className="lazy" data-srcset={srcset(item.image)}
                                                             alt={item.image.alt ? item.image.alt : item.image.title}/>
                                                    )}
                                                </div>
                                                {item.headline && (
                                                    <h6>{(key + 1) + ". "}{item.headline}</h6>
                                                )}
                                                {item.description && (
                                                    <div className="blog-text"
                                                         dangerouslySetInnerHTML={{__html: item.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                                                )}
                                                {item.link && item.link_text && (
                                                    <Link to={item.link}>{item.link_text}</Link>
                                                )}
                                            </div>
                                        ) : ('')
                                    )}
                                </OwlCarousel>
                            </>
                        )}
                    </div>
                </div>
            )
        )
    }
}

export default InfoGrid