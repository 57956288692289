import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { apiConfig } from "../Classes/Helper.jsx";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import { FaHeart, FaRegHeart} from "react-icons/fa";

const FloorplanSave = (props) => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  const [popup_message, setPopup_message] = useState();
  const [http_code, sethttp_code] = useState();
  const [popup_show, setpopup_show] = useState(false);
  const [isPlanLikeState, setIsPlanLikeState] = useState(false);
  const [planLikeAPIStatus, setPlanLikeAPIStatus] = useState(false);

  useEffect(() => {
    console.log('tested');
    console.log(isAuthenticated);
    setTimeout(()=> {
    if (isAuthenticated) {
      axios
        .post(
          apiConfig().API_URL + "/floorplan_list",
          {
            user_id: user.sub,
          },
          {
            headers: {
              Authorization: "Aa147147147#",
            },
          }
        )
        .then((response) => {
          console.log("User FloorPlan Data");

          console.log(response);

          setPlanLikeAPIStatus(true);

          if (
            response.data.data.email &&
            response.data.data.user_metadata.floorplans &&
            response.data.data.user_metadata.floorplans.length > 0
          ) {
            let floorPlans = response.data.data.user_metadata.floorplans;

            let floorPlanIds = [];

            floorPlans.map((fp) => {
              floorPlanIds.push(fp.plan_id);
            });

            if (
              floorPlanIds.includes(
                props.residence_component.common_detail.residence_id
              )
            ) {
              setIsPlanLikeState(true);
            }
          }
        });
    } else {
      setPlanLikeAPIStatus(true);
    }
  },1000);
  }, [isAuthenticated]);

  function popup_hide() {
    setpopup_show(false);
    //props.summary_screen("Summary")
  }

  function send_floorplan_data() {
    axios
      .post(
        apiConfig().API_URL + "/floorplan_save",
        {
          user_id: user.sub,
          plan_id: props.residence_component.common_detail.residence_id,
          title: props.residence_component.common_detail.residence_title,
          name: window.floorplan_name,
          price:
            props.residence_component.summary.base_price &&
            props.residence_component.summary.hide_price != 1
              ? props.residence_component.summary.base_price
              : "",
          url: window.location.pathname,
          image: props.residence_component.common_detail.residence_image[0],
          image_title:
            props.residence_component.common_detail.residence_image_alt[1],
          image_alt:
            props.residence_component.common_detail.residence_image_alt[0],
        },
        {
          headers: {
            Authorization: "Aa147147147#",
          },
        }
      )
      .then((response) => {
        setPopup_message(response.data.data.status);
        setpopup_show(true);
        sethttp_code(response.data.data.status_code);
        setIsPlanLikeState(true);
      });

    window.dataLayer.push({ event: "saveFloorplan" });
  }

  function fp_login_redirection() {
    const plan_user_details = {
      plan_id: props.residence_component.common_detail.residence_id,
      title: props.residence_component.common_detail.residence_title,
      name: window.floorplan_name,
      price:
        props.residence_component.summary.base_price &&
        props.residence_component.common_detail.post_status !== "Sold Out" &&
        props.residence_component.common_detail.post_status !==
          "Temporarily Sold Out" &&
        props.residence_component.summary.hide_price != 1
          ? props.residence_component.summary.base_price
          : "",
      url: window.location.pathname,
      image: props.residence_component.common_detail.residence_image[0],
      image_title:
        props.residence_component.common_detail.residence_image_alt[1],
      image_alt: props.residence_component.common_detail.residence_image_alt[0],
    };

    Cookies.set("last_url", window.location.pathname, { expires: 2 });
    Cookies.set("saved_plan_details", plan_user_details, { expires: 1 });
    loginWithRedirect();
    window.dataLayer.push({ event: "saveFloorplan" });
  }

  function renderLikeButton() {
    if (planLikeAPIStatus && isAuthenticated) {
      if (isPlanLikeState) {
        return (
          <div
            title="Save to favorites"
            tabindex="0"
            className="floorplan_save_btn liked"
          >
            <FaHeart style={{color: '#bed245',fontSize: '2rem'}}/>
          </div>
        );
      } else {
        return (
          <div
            title="Save to favorites"
            tabindex="0"
            className="floorplan_save_btn"
            onClick={send_floorplan_data}
          >
            <FaRegHeart style={{fontSize: '2rem'}}/>
          </div>
        );
      }
    } else if (planLikeAPIStatus && !isAuthenticated) {
      return (
        <div
          title="Save to favorites"
          tabindex="0"
          className="floorplan_save_btn floorplan_login_btn"
          onClick={fp_login_redirection}
        >
          <FaRegHeart style={{fontSize: '2rem'}}/>
        </div>
      );
    }
  }

  return (
    <div>
      {typeof popup_message != "undefined" && popup_show
        ? [
            <div className="floorplan-save__modal">
              <div className="floorplan-save__modal-content">
                <div
                  class="floorplan-save__modal-close flex"
                  tabindex="0"
                  onClick={() => popup_hide()}
                  style={{
                    "--flex-align-items": "center",
                    "--flex-justify-content": "center",
                    background: "rgba(0, 0, 0, 0.8)",
                    border: "none",
                    "border-radius": "50%",
                    color: "#fff",
                    cursor: "pointer",
                    height: "32px",
                    position: "absolute",
                    right: "20px",
                    top: "20px",
                    width: "32px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.931"
                    height="8.93"
                    viewBox="0 0 8.931 8.93"
                    style={{
                      height: "11px",
                      left: "50%",
                      position: "absolute",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "11px",
                    }}
                  >
                    <g transform="translate(11.878 4.475) rotate(135)">
                      <path
                        d="M0,0,3.655,3.655,0,7.31"
                        transform="translate(5.169 0) rotate(45)"
                        fill="none"
                        stroke="#fff"
                        stroke-width="2"
                      />
                      <path
                        d="M0,0,3.655,3.655,0,7.31"
                        transform="translate(5.301 10.497) rotate(-135)"
                        fill="none"
                        stroke="#fff"
                        stroke-width="2"
                      />
                      <path
                        d="M0,7.31,3.655,3.655,0,0"
                        transform="translate(10.47 5.169) rotate(135)"
                        fill="none"
                        stroke="#fff"
                        stroke-width="2"
                      />
                      <path
                        d="M0,7.31,3.655,3.655,0,0"
                        transform="translate(0 5.328) rotate(-45)"
                        fill="none"
                        stroke="#fff"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                  <span class="visually-hidden">Close modal</span>
                </div>

                <p class="floorplan-save__modal-message" dangerouslySetInnerHTML={{ __html: popup_message }}></p>

                {http_code == 200 ? (
                  <p>
                    <Link
                      to="/my_account/?tab=floorplans"
                      classs="floorplan-save__modal-link"
                    >
                      View saved homes
                    </Link>
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>,
          ]
        : [""]}

      {renderLikeButton()}
    </div>
  );
};

export default FloorplanSave;
