import React from 'react'
import $ from 'jquery'
import './SidebarSignUpNewForm.scss';
import cookie from 'react-cookies';

class SidebarSignUpNewForm extends React.Component {

    constructor(props) {


       
        super()
        this.state = {

            reload: false,
            // referenceId: this.props.referenceId ? this.props.referenceId : false,
            // neigh_detail: this.props.neigh_detail

        }

    }

    reloadMarketoForm() {

        $("#mktoForm_5595").find('[type="submit"]').prop('disabled', false);
        $("#mktoForm_5595").find('[type="submit"]').text("Submit");
        // this.setState({reload: false});
        // this.loadMarketoForm();
    }

    loadMarketoForm() {
        var that = this;
        const cookie_value = this.state.mkto_cookie;
        const division_name = this.props.division_name;
        if (window.MktoForms2 != "undefined") {

            window.MktoForms2.loadForm("//app-sj10.marketo.com", "167-BTV-942", 5595, function (form) {

                form.onSuccess(function(values, followUpUrl) {
                    window.dataLayer.push({
                        'event': 'interestList',
                        'userEmail': values?.Email,
                        'userPhone': values?.Phone,
                        'userFirstName': values?.FirstName,
                        'userLastName': values?.LastName,
                    });
                    
                    var useremail = values.Email;
                    var cookieexpiry = new Date();
                    cookieexpiry.setFullYear(cookieexpiry.getFullYear() + 2);
			 
                    //set cookie
                    document.cookie="_mkto_email="+useremail+"; expires="+cookieexpiry+"; path=/";

                    if ($("#mktoForm_5595 #openQuestion1").is(":checked")) {

                        let t = new Date();
                        t.setDate(t.getDate() + 2);
                        let month = "0" + (t.getUTCMonth() + 1);
                        let date = "0" + t.getUTCDate();
                        month = month.slice(-2);
                        date = date.slice(-2);
                        date = t.getUTCFullYear() + "-" + month + "-" + date;
                        let todayToDate = Date.parse(date);

                        //Thank you message
                        $("#mktoForm_5595").html("");
                        that.setState({reload: true});
                        return false;


                    } 
                });
				
			$('#mktoForm_5595 #Brand__c').on('input', function(evt) {

                    // console.log("input");

                    var inputValue = $("#mktoForm_5595 #Brand__c").val();
                    inputValue = inputValue.replace(/[^a-zA-Z ]/g, "");
                    $("#mktoForm_5595 #Brand__c").val(inputValue);
                    
                    var withoutSpclChar = new RegExp(/^[\w| |_-]+$/);
                    if (!inputValue.match(withoutSpclChar)) {
                    var newInputWithHas = $("#mktoForm_5595 #Brand__c").val();
                    var newInputWithoutHas = newInputWithHas.substring(0, newInputWithHas.length - 1);
                    return $("#mktoForm_5595 #Brand__c").val(newInputWithoutHas);
                    }
                    if (inputValue.match(/\s+/g)) {
                      $("#mktoForm_5595 #Brand__c").val(spaceHandle(inputValue))
                    }
                    
                    if ((inputValue.match(new RegExp(" ", "g")) || []).length > 1) {
                    // console.log();
                    $("#mktoForm_5595 #Brand__c").val(spaceHandle(inputValue))
                    var newInputValue = $("#mktoForm_5595 #Brand__c").val();
                    //var noDoubleSpace = newInputValue.replace(/ +/g, '');
                    //var NoSpaceinEnd = noDoubleSpace.replace(/ $/, "");
                    return $("#mktoForm_5595 #Brand__c").val(newInputValue);
                    }
                    if (/\d/.test(inputValue)) {
                    // console.log("it has number");
                    var noNumber = inputValue.replace(/[0-9]/g, '');
                    return $("#mktoForm_5595 #Brand__c").val(noNumber);
                    }
                    });
                    
                    function spaceHandle(s) {
                    // console.log(s);
                    var replaced = s.split(' ');
                    // console.log("Replaced------------>");
                    // console.log(replaced);
                    var final_string = '';
                    $.each(replaced, function(index, value) {
                        // console.log("value length------------>");
                        // console.log(value.length);
                    if (index == 0 && value.length > 1) {
                        final_string = final_string + value + ' ';
                    } else {
                         final_string = final_string + value;
                    }
                    })
                    // console.log("Final String");
                    // console.log(final_string);
                    
                    return final_string;
                    }
                    
                    $('.mktoFormCol').addClass(function(i){
                    return 'Col'+(i+1);
                    });
                    form.onValidate(function() {
                    var vals = form.vals();
                    var email = form.vals().Email;
                    var fname = form.vals().FirstName;
                    var lname = form.vals().LastName;
                    var phone = form.vals().Phone;
                    var maxLength = 2;
                    var ContactName = form.vals().Brand__c;
                    var invalidDomains = ["@gmail.", "@yahoo.", "@hotmail.", "@live.", "@aol.", "@outlook.", "@test.", "@rediff."];
                    var ContactFirstName = ContactName.substr(0, ContactName.indexOf(' '));
                    var ContactLastName = ContactName.substr(ContactName.indexOf(' ') + 1); 
                    if (/\w+\s+\w+/.test($("#mktoForm_5595 #Brand__c").val())) {
                    // Enable submission for those who met the criteria
                    $('input[name="FirstName"]').val(ContactFirstName);
                    $('input[name="LastName"]').val(ContactLastName); 
                    
                    var lname = $("input[name='LastName']");
                    var lvalue = lname[0].value.length;
                    if(lvalue < maxLength){
                    form.submittable(false);
                    var CcontactNameElem = $("#mktoForm_5595").find("#Brand__c");
                    form.showErrorMessage("Please enter at least 2 character of your last name", CcontactNameElem);
                    return false;
                    }else{
                    form.submittable(true);
                    }
                    } else {
                    form.submittable(false);
                    // Show error message, pointed at VehicleSize element
                    var CcontactNameElem = $("#mktoForm_5595").find("#Brand__c");
                    form.showErrorMessage("Please enter first and last name only.", CcontactNameElem);
                    return false;
                    }
                    });

            });

            window.MktoForms2.whenReady(function (form) {


                var j = 1;
                $('#mktoForm_5595 .mktoFormRow').each(function (i, e) {
                    $(this).addClass('row-class-' + j);
                    j++;
                });


                $("#mktoForm_5595").find('[name="Communities_of_Interest__c"]').val(that.props.communityOfInterest);
                $("#mktoForm_5595").find('[name="formURL"]').val(window.location.href);
                $("#mktoForm_5595").find('[name="Division__c"]').val(division_name);
                $("#mktoForm_5595").find('[name="communityName"]').val(that.props.nbd_title);
                
                if(that.props.neigh_status == 'Temporarily Sold Out' || that.props.neigh_status == 'Limited Availability'){
                    $("#mktoForm_5595").find('[name="waitlist"]').val('true');
                }

                var formfields = form.vals();
					var formfields_names = Object.keys(formfields);

                    var formfilterValue = cookie_value;
                    // console.log('Cookie value: '+formfilterValue);
                    var outputArray = [];
                    for (var i = 0; i < formfields_names.length; i++){
                        if(formfields_names[i] && formfields_names[i]!='munchkinId' && formfields_names[i]!='formid'){
                            if ((window.JQUERY.inArray(formfields_names[i], outputArray)) == -1){
                                outputArray.push(formfields_names[i]);
                            }
                        }                               
                    }
                    const reducedArray = outputArray.reduce((fields_names, feilds) => `${fields_names}${feilds},` ,'')
                    var data = "";
					var data = {
    					action: 'formnames',
    					requestData: reducedArray,
    					filterType:'cookies',
    					filterValue: formfilterValue
					};
                    window.JQUERY.ajax({
                        type: 'POST',
                        data: data,
                        url: "/wp-admin/admin-ajax.php",
                        success:function(response){
                            var field_havevalues = [];
                            var results = [];
                            results = JSON.parse(response);
                            var finalvaluestofill = {};
                            window.JQUERY.each(formfields_names,function(value, elem){
                                if(elem){
                                    var keys = elem.toLowerCase();
                                    if(keys in results){
                                        if(results[keys] && results[keys] != ''){
                                            finalvaluestofill[elem] = results[keys];
                                            field_havevalues.push(elem);
                                        }
                                    }
                                }
                            });

                            if (finalvaluestofill) {
                                form.vals(finalvaluestofill);
                            }
                        }
                    });

            });

        }

    }

    componentDidMount() {
        this.state = {
            mkto_cookie: cookie.load('_mkto_trk')
        };
        this.loadMarketoForm();

        // this.setState({
        //     referenceId: this.props.referenceId
        // });

    }

    componentWillReceiveProps(nextProps) {

        if (this.state.reload === true) {

            this.loadMarketoForm();
            this.setState({reload: false});
        }

        // this.setState({
        //     referenceId: this.props.referenceId
        // });

    }

    render() {

        const sidebar_data = this.props.sidebar_data;
        var post_value = this.props.post_data;

        return (
            <>
                <div className="sidebar-signup-panel">
                    {sidebar_data.sidebar_title ? (
                        <p tabindex="0" className="sidebar-signup__title">{sidebar_data.sidebar_title}</p>
                    ):('')}
                    {sidebar_data.sidebar_info ? (
                        <p tabindex="0" className="sidebar-signup__info">{sidebar_data.sidebar_info}</p>
                    ):('')}

                    <div className="sidebar-signup-div">
                        <form id="mktoForm_5595" className="sidebar-signup-form"></form>
                    </div>

                    {this.state.reload === false ? (
                       sidebar_data.sidebar_disclaimer ? (
                        <div tabindex="0" className="sidebar-signup__disclaimer" dangerouslySetInnerHTML={{ __html: sidebar_data.sidebar_disclaimer.replace(/(?:\r\n|\r|\n)/g, '') }}>
                            {/* <div class="sidebar_broker_agent"><a href="https://www.pardeehomes.com/agent-registration/" target="_blank" >Broker or Agent? Stay Informed Here</a></div> */}
                        </div>
                         ):('')
                    ) : ('')}

                    {this.state.reload === true ? (
                        sidebar_data.sidebar_thanks_message ? (
                            <small tabindex="0" className={"thankyou_message " } dangerouslySetInnerHTML={{ __html: sidebar_data.sidebar_thanks_message.replace(/(?:\r\n|\r|\n)/g, '') }}>
                                {/* sidebar_data.sidebar_thankyou_message */}
                            </small>
                            
                         ):(
                            <small tabindex="0" className="thankyou_message">
                                Thanks for signing up.
                            </small>
                         )
                    ) : ('')}
                </div>
            </>
        )
    }
}

export default SidebarSignUpNewForm
