import React from 'react';
import AutocompleteCurrentPositionHit from './AutocompleteCurrentPositionHit';

const createNearbyPlugin = ( { ...rest } ) => {
	return {
		...rest,
		limit: 1,
		getSources( props ) {
			const { setIsOpen, refresh, state } = props;

			if ( ! ( 'geolocation' in navigator ) ) {
				return [];
			}
			return [
				{
					sourceId: 'nearby',

					getItems( ) {
						if ( state.query || state.context.selectedState ) {
							return [];
						}
						return [ { name: 'Search by Current Location' } ];
					},
					templates: {
						item( ) {
							return (
								<AutocompleteCurrentPositionHit />
							);
						},
					},
				},
			];
		},
	};
};

export default createNearbyPlugin;
