import React from 'react'
import './TPHLoader.scss';

class TPHLoader extends React.Component {

    render() {

        return (

            <div id="loader">
                {/* <div id="loader-icon"><img src={require('../../assets/images/loader1.svg')} /></div> */}
                <div>
                    <h2 tabindex="0">one moment</h2>
                </div>
                <div className="arrows-loading">
                    <div>
                        <img tabindex="0" src={require('../../assets/images/lemongreen.png')} ></img>
                    </div>
                    <div>
                        <img tabindex="0" src={require('../../assets/images/greendark.png')} ></img>
                    </div>
                    <div>
                        <img tabindex="0" src={require('../../assets/images/darkgray.png')} ></img>
                    </div>
                    <div>
                        <img tabindex="0" src={require('../../assets/images/gray.png')} ></img>
                    </div>
                    <div>
                        <img tabindex="0" src={require('../../assets/images/lemongreen.png')} ></img>
                    </div>
                    <div>
                        <img tabindex="0" src={require('../../assets/images/yellowgreen.png')}></img>
                    </div>
                    <div>
                        <img tabindex="0" src={require('../../assets/images/lightgray.png')} ></img>
                    </div>
                    <div>
                        <img tabindex="0" src={require('../../assets/images/lightgray.png')} ></img>
                    </div>
                    <div>
                        <img tabindex="0" src={require('../../assets/images/gray.png')} ></img>
                    </div>
                    <div>
                        <img tabindex="0" src={require('../../assets/images/lemongreen.png')} ></img>
                    </div>
                    <div>
                        <img tabindex="0" src={require('../../assets/images/gray.png')} ></img>
                    </div>
                    <div>
                        <img tabindex="0" src={require('../../assets/images/yellowgreen.png')}></img>
                    </div>
                  
                </div>
            </div>

            )

    }
}

export default TPHLoader