import React from 'react';
import { Link } from 'react-router-dom';
import './BlogAndNews.scss';
import BlogAndNewsPost from './BlogAndNewsPost';

class BlogAndNews extends React.Component {
    render() {
        return (
            <section className="blog-and-news">
                <div
                    className="padding"
                    style={{
                        '--padding-desktop': '33px',
                        '--padding-mobile': '35px',
                    }}
                >
                    <div
                        className="blog-and-news__header flex"
                        style={{
                            '--flex-direction': 'row',
                            '--flex-justify-content': 'space-between',
                            '--margin-vertical-bottom-desktop': '33px',
                            '--margin-vertical-bottom-mobile': '35px',
                        }}
                    >
                        <div className="eyebrow">Blog & News</div>

                        {this.props.blog_link && (
                            <Link
                                to={this.props.blog_link}
                                className="hide-mobile"
                                href={this.props.blog_link}
                                target="_blank"
                            >
                                All Articles
                            </Link>
                        )}
                    </div>

                    {this.props.blog_posts.length > 0 && (
                        <div
                            className="flex blog-and-news__posts"
                            style={{
                                '--flex-direction-desktop': 'row',
                                '--flex-direction-mobile': 'column',
                                '--flex-wrap': 'wrap',
                                '--flex-justify-content-desktop': 'space-between',
                                '--flex-justify-content-mobile': 'auto',
                                '--flex-align-items-desktop': 'flex-start',
                                '--flex-align-items-mobile': 'center',
                            }}
                        >
                            {this.props.blog_posts.map((post) => {
                                return <BlogAndNewsPost key={post.permalink} post={post}></BlogAndNewsPost>;
                            })}
                        </div>
                    )}

                    <div className="blog-and-news__footer">
                        <div className="hide-desktop">
                            {this.props.blog_link && (
                                <Link
                                    to={this.props.blog_link}
                                    className="button"
                                    href={this.props.blog_link}
                                    target="_blank"
                                >
                                    All Articles
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default BlogAndNews;
