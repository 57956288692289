import React from 'react'
import qs from 'qs';
import {Switch, Route,withRouter} from 'react-router-dom'
import StaticPage from './views/StaticPage';
import DirectionPage from './views/DirectionPage';
import ReviewPage from './views/ReviewPage';
import Page_Decider from './views/Page_Decider';
import SearchPost from './views/SearchPost';
import NoMatch from './views/NoMatch';
import SubmarketPage from './views/SubmarketPage';
import StatePage from './views/StatePage';
import HomePage from './views/HomePage';
import Sitemap from './views/Sitemap';
// import LogInForm from './views/LogInForm';
import FYHPage from './views/FYHPage';
import MyAccount from './views/MyAccount';
import BrokersPage from './views/BrokersPage';
import BlogPage from './views/BlogPage';
import BlogListing from './views/BlogListing';
import ContactInfo from './views/ContactInfo';
import ProtectedRoute from "../auth/protected-route";
import PromotionPage from './views/PromotionPage';
import $ from 'jquery';
import QmiLandingPage  from './views/QmiLandingPage';
import EventDetailsPage from './views/EventDetailsPage';
import LazyLoad from 'vanilla-lazyload';
import Swiper from 'swiper/bundle';
import NationalPromoPage from './views/NationalPromoPage';
import ReviewListing from './views/ReviewListing';
import CustomNationalPromo from './views/CustomNationalPromo';

const { index, objectID, position, queryID } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

class Main extends React.Component {  
  componentDidMount() {
    window.addEventListener('load', () => {
      window.lazy.update()
    })

    document.addEventListener('DOMContentLoaded', () => {
      window.executeLazyFunction = element => {
        const lazyFunctionName = element.getAttribute('data-lazy-function')
        if (lazyFunctionName && window.hasOwnProperty(lazyFunctionName) && typeof window[lazyFunctionName] === 'function') window[lazyFunctionName](element)
      }

      window.lazy = new LazyLoad({
        elements_selector: '.lazy',
        LoadMode: 1, // 0 -> don't load anything | 1 -> Only load visible els | 2 -> Load also very near view els | 3 -> Load also not so near view els
        unobserve_entered: true, // <- Avoid executing the function multiple times
        callback_enter: window.executeLazyFunction,
        threshold: -50,
      })

      window.swipersInit = () => {
        // swipers init via data-swiper={options}
        document.querySelectorAll('[data-swiper]').forEach(el => {
          const $swiper = new Swiper(el, Object.assign({}, {
            breakpointsInverse: true,
            observer: true,
          }, JSON.parse(el.dataset.swiper)))

          const changeEvent = el.getAttribute('data-swiper-onchange')

          if (changeEvent && window.hasOwnProperty(changeEvent) && typeof window[changeEvent] === 'function') {
            // $swiper.on('slideChange', function(e) {
            // $swiper.on('touchEnd', function(e) {
            $swiper.on('progress', function(e) {
              window[changeEvent](e)
            })
          }

          const clickEvent = el.getAttribute('data-swiper-onclick')

          if (clickEvent && window.hasOwnProperty(clickEvent) && typeof window[clickEvent] === 'function') {
            // $swiper.on('slideclick', function(e) {
            $swiper.on('click', function(swiper, e) {
              window[clickEvent](swiper, e)
            })
          }
        })
      }
    })
  }

  componentWillReceiveProps(nextProps){
    var prev_location = this.props.location.pathname.split("/");
    var next_location = nextProps.location.pathname.split("/");

    $('.header_section .headerWrapper').removeClass('active');
    $('.main-container, .scroll-bar, .footer_section').css('display','block');
    $('body').css('overflow', 'auto');

    if (window.SHOW_CHAT && (prev_location.length > next_location.length || prev_location[3] != next_location[3])) {
      window.SHOW_CHAT = false;
      window.JQUERY(".embeddedServiceSidebar").hide();
      window.JQUERY(".embeddedServiceHelpButton").hide();
      console.log("Chat Removed");
    }
  }

  render() {
    return (
      <div
        className="main-container"
        data-insights-index={index}
        data-insights-object-id={objectID}
        data-insights-position={position}
        data-insights-query-id={queryID}
      >
        <Switch>
          <Route exact path="/" component={HomePage} />
          {/* <Route exact path="////:slug" component={HomePage} /> */}
          <Route path="/find-your-home/*" component={FYHPage} />
          {/* <Route exact path="/signin" component={LogInForm}/> */}
          <Route exact path="/sitemap" component={Sitemap} />
          <Route exact path="/reviews" component={ReviewListing}/>
          <Route exact path="/contact-us" component={ContactInfo}/>
          <ProtectedRoute  exact path="/my_account" component={MyAccount} />
          <Route exact path="/blog" component={BlogListing}/>
          <Route exact path="/blog/category/:slug" component={BlogListing}/>
          <Route exact path="/new-year-new-home" component={NationalPromoPage} />
          <Route exact path="/promotion/:statename/:division/:slug" component={PromotionPage}/>
          <Route exact path="/:statename/:submarket/event/:slug" component={EventDetailsPage}/>
          <Route exact path="/blog/:slug" component={BlogPage}/>
          <Route exact path="/national-promotion/:slug" component={CustomNationalPromo}/>
          <Route exact path="/state/:slug" component={StatePage} />
          <Route exact path="/:statename/:slug/quick-move-in/" component={QmiLandingPage}/>
          <Route exact path="/:statename/:slug" component={SubmarketPage} />
          <Route exact path="/:statename/:submarket/:slug" component={Page_Decider} />
          <Route exact path="/:statename/:submarket/:slug/directions" component={DirectionPage} />
          <Route exact path="/:statename/:submarket/:slug/reviews/" component={ReviewPage}/>
          <Route exact path="/:statename/:submarket/:slug/quick-move-in" component={QmiLandingPage}/>
          <Route exact path="/:statename/:submarket/:neigh/:slug" component={Page_Decider}/>
          <Route exact path="/search_post" component={SearchPost}/>
          <Route exact path="/brokers" component={BrokersPage}/>
          {/* <Route exact path="/blog/:slug" component={StaticPage}/> */}
          <Route exact path="/:slug" component={StaticPage}/>
          <Route component={NoMatch}/>
        </Switch>
      </div>
    );
  }  
}

export default withRouter(Main)