import algoliasearch from 'algoliasearch/lite';
import { config } from '../config/config';

const algoliaClient = algoliasearch(
	config.ALGOLIA_APPLICATION_ID,
	config.ALGOLIA_SEARCH_API_KEY
);

export const searchClient = {
	...algoliaClient,
	search( requests ) {
		// let userToken;

		// if ( aa ) {
		// 	aa( 'getUserToken', null, ( err, newUserToken ) => {
		// 		if ( err ) {
		// 			console.error( err );
		// 			return;
		// 		}
		// 		userToken = newUserToken;
		// 	} );
		// }

		const newRequests = requests.map( ( request ) => {
			// Add analytics request parameter to every request
			// because a lot of the searches may not have queries, but use filters
			request.params.analytics = true;
			// Test for empty string and change `analytics` request parameter to `false`
			// if ( ! request.params.query || request.params.query.length === 0 ) {
			// 	request.params.analytics = false;
			// }
			// if ( userToken ) {
			// 	request.params.userToken = userToken;
			// }
			return request;
		} );

		// console.log( newRequests );

		return algoliaClient.search( newRequests );
	},
};

// needs both this and the middleware to work
// TODO: this is also happening via GTM - check if after launch
export const initAlgoliaAnalytics = () => {
	if ( ! window.aa ) {
		return;
	}

	window.aa( 'init', {
		appId: config.ALGOLIA_APPLICATION_ID,
		apiKey: config.ALGOLIA_SEARCH_API_KEY,
		useCookie: true,
	} );

	window.aa( 'onUserTokenChange', ( userToken ) => {
		window.dataLayer.push( {
			algoliaUserToken: userToken,
		} );
	}, { immediate: true } );
};

