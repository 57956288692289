import React from 'react'
import { Modal } from 'react-bootstrap'
import { srcset } from './Helpers'
import './IframeModal.scss'
import HeadingSection from './HeadingSection'
import cookie from 'react-cookies';
import axios from 'axios';
import { apiConfig } from './Helper.jsx';

class IframeModal extends React.Component {
    constructor(){
      super()

      this.state = {
        showHide : false
      }
    }

    handleModalShowHide() {
      this.setState({ showHide: !this.state.showHide })
    }

    fetchData() {
      return new Promise((resolve, reject) => {
          axios.post(apiConfig().API_URL + '/get_marketo_lead_data/',{
              'get_type': "Activity",
              'activity_type' : "InteractiveFloorplan",
              'cookie' : cookie.load('_mkto_trk'),
              'URL' : window.location.href,
              'value' : 'clicked_floorplan'
          }).then(response => response)
          .then(data => resolve(data))
          .catch(error => reject(error));
      });
    }

    fetchDataForRoomVisualizer() {
      return new Promise((resolve, reject) => {
          axios.post(apiConfig().API_URL + '/get_marketo_lead_data/',{
              'get_type': "Activity",
              'activity_type' : "RoomVisualizer",
              'cookie' : cookie.load('_mkto_trk'),
              'URL' : window.location.href,
              'value' : 'clicked_visualizer'
          }).then(response => response)
          .then(data => resolve(data))
          .catch(error => reject(error));
      });
    }

    data_layer_push(param) {
      this.setState({ value:param })
      if (param && param.data_layer_value == 'InteractiveFloorplan') {
        window.dataLayer.push({'event':'customizeFloorplan'})
        var mkto_confirm = cookie.load('_mkto_trk');
        if (mkto_confirm) {
            this.fetchData()
              .then(data => {
                console.log(data);
              })
              .catch(error => console.error(error));
        }
      }
      if (param && param.data_layer_value == 'VirtualWalkthrough') {
        window.dataLayer.push({'event':'virtualTour'})
      }
      if (param && param.data_layer_value == 'RoomChanger') {
        window.dataLayer.push({'event':'customizeRoomVisualizer'})
        var mkto_confirm = cookie.load('_mkto_trk');
        if (mkto_confirm) {
          this.fetchDataForRoomVisualizer()
            .then(data => {
              console.log(data);
            })
            .catch(error => console.error(error));
        }
      }
    }

    componentDidMount() {
      const iframe_data = this.props.iframe_data;
      const section_id = iframe_data.section_id && iframe_data.section_id.trim()

      if (section_id) {
        setTimeout(function () {
          if (window.location.href.indexOf("#" + section_id) > -1) {
            document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'})
          }
        }, 2000)
      }

      window.lazy.update()
    }

    render() {
      var iframe_data = this.props.iframe_data;
      const iframe_type = this.props.iframe_type;
      console.log(iframe_type);
      if (iframe_type) {
        if (iframe_type === 'InteractiveFloorplan') {
          var data_layer_value = 'InteractiveFloorplan';
        }else if (iframe_type === 'VirtualWalkthrough') {
          var data_layer_value = 'VirtualWalkthrough';
        }else if (iframe_type === 'RoomChanger') {
          var data_layer_value = 'RoomChanger';
        }
      }
      var section_id_fallback = iframe_data.iframe_heading.toLowerCase().indexOf('community map') !== -1 ? 'community-map' : iframe_data.iframe_heading.toLowerCase().replace(/ /g, '-').trim()
      var section_id = iframe_data.section_id ? iframe_data.section_id.trim() : section_id_fallback
      var position = iframe_data.hasOwnProperty('position') ? iframe_data.position : this.props.hasOwnProperty('index') ? (parseFloat(this.props.index) % 2 === 0 ? 'right:Right' : 'left:Left') : 'right:Right'
      var image = (iframe_data.image && iframe_data.image.hasOwnProperty('sizes') && iframe_data.image.sizes || iframe_data.image && iframe_data.image.hasOwnProperty('url') && iframe_data.image.url) ? iframe_data.image : (iframe_data.default_image && iframe_data.default_image.hasOwnProperty('sizes') && iframe_data.default_image.sizes || iframe_data.default_image && iframe_data.default_image.hasOwnProperty('url') && iframe_data.default_image.url) ? iframe_data.default_image : null

      return (
        iframe_data.headline && image ? (position === 'left:Left' ? (
          <div className="iframe-main-div posrev" id={section_id} style={{ 'background-color': '#fff' }}>
            <div className="iframe-content divide-equal">
              <div className="iframe-content-section">
                <HeadingSection
                  subtitle={iframe_data.sub_text}
                  title_part_1={iframe_data.headline}
                  title_part_2={iframe_data.headline_part_2}
                  headline_part_1_color={iframe_data.headline_part_1_color}
                  headline_part_2_color={iframe_data.headline_part_2_color}
                  headline_size={iframe_data.hasOwnProperty('headline_size') ? iframe_data.headline_size : 'large'}
                />

                {iframe_data.description  ? (
                  <div className="iframe-description description" dangerouslySetInnerHTML={{__html:iframe_data.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                ):('')}

                {iframe_data.cta_label && iframe_data.cta_link ? (
                  <a href="javascript:void(0)" onClick={() => {this.handleModalShowHide(); this.data_layer_push({data_layer_value});}} className="iframe-button button">
                    <span class="button__text">
                      {iframe_data.cta_label}
                    </span>
                  </a>
                ):('')}
              </div>
            </div>

            {image ? (
              <div className="iframe-image divide-equal aspect-ratio">
                {image.url ? (
                  <img data-srcset={image.hasOwnProperty('sizes') ? srcset(image) : image.url} onClick={() => {this.handleModalShowHide(); this.data_layer_push({data_layer_value});}} alt={image.alt ? image.alt : image.title } className="iframe-modal-image object-fit lazy"/>
                ):('')}

                {iframe_data.cta_link ? (
                  <div className="iframe-modal-button">
                    <button type="button" onClick={() => {this.handleModalShowHide(); this.data_layer_push({data_layer_value});}} className="iframe-view-more"><span class="visually-hidden">Open modal </span>+</button>
                  </div>
                ):('')}
              </div>
            ):('')}

            <Modal className="sitemap_modal" show={this.state.showHide}>
              <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                <Modal.Title>
                  {iframe_data.iframe_heading && <h2>{iframe_data.iframe_heading}</h2>}
                  {iframe_data.iframe_subtext && <p>{iframe_data.iframe_subtext}</p>}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {iframe_data.cta_link ? (
                  <iframe src={iframe_data.cta_link} className="sitemap_frame" title="sitemap iframe" ></iframe>
                ):('')}
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          <div className="iframe-main-div" id={section_id} style={{ 'background-color': '#fff' }}>
            <div className="iframe-content divide-equal">
              <div className="iframe-content-section">
                <HeadingSection
                  subtitle={iframe_data.sub_text}
                  title_part_1={iframe_data.headline}
                  title_part_2={iframe_data.headline_part_2}
                  headline_part_1_color={iframe_data.headline_part_1_color}
                  headline_part_2_color={iframe_data.headline_part_2_color}
                  headline_size={iframe_data.hasOwnProperty('headline_size') ? iframe_data.headline_size : 'large'}
                />

                {iframe_data.description  ? (
                  <div className="iframe-description description" dangerouslySetInnerHTML={{__html:iframe_data.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                ):('')}

                {iframe_data.cta_label && iframe_data.cta_link ? (
                  <a href="javascript:void(0);" onClick={() => {this.handleModalShowHide(); this.data_layer_push({data_layer_value});}} className="iframe-button button">
                    <span class="button__text">
                      {iframe_data.cta_label}
                    </span>
                  </a>
                ):('')}
              </div>
            </div>

            {image ? (
              <div className="iframe-image divide-equal aspect-ratio">
                {image.url ? (
                  <img data-srcset={image.hasOwnProperty('sizes') ? srcset(image) : image.url} onClick={() => {this.handleModalShowHide(); this.data_layer_push({data_layer_value});}} alt={image.alt ? image.alt : image.title } className="iframe-modal-image object-fit lazy"/>
                ):('')}

                {iframe_data.cta_link ? (
                  <div className="iframe-modal-button">
                    <button type="button" onClick={() => {this.handleModalShowHide(); this.data_layer_push({data_layer_value});}} className="iframe-view-more"><span class="visually-hidden">Open modal </span>+</button>
                  </div>
                ):('')}
              </div>
            ):('')}

            <Modal className="sitemap_modal" show={this.state.showHide}>
              <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                <Modal.Title>
                  {iframe_data.iframe_heading && <h2>{iframe_data.iframe_heading}</h2>}
                  {iframe_data.iframe_subtext && <p>{iframe_data.iframe_subtext}</p>}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {iframe_data.cta_link ? (
                  <iframe src={iframe_data.cta_link} className="sitemap_frame" title="sitemap iframe"></iframe>
                ):('')}
              </Modal.Body>
            </Modal>
          </div>
        )
      ) : ('')
    )
  }
}

export default IframeModal
