import React, { createContext, useContext, useReducer } from 'react';
import logger from 'use-reducer-logger';

// Keep track of when a marker is hovered in the hits to make it active on the map

const SearchStateHitsContext = createContext( null );

const SearchStateHitsDispatchContext = createContext( null );

export function SearchStateHitsProvider( { children } ) {
	const [ searchStateHits, dispatch ] = useReducer(
		process.env.NODE_ENV === 'development' ? logger( searchStateHitsReducer ) : searchStateHitsReducer,
		initialSearchLocation
	);

	return (
		<SearchStateHitsContext.Provider value={searchStateHits}>
			<SearchStateHitsDispatchContext.Provider value={dispatch}>
				{children}
			</SearchStateHitsDispatchContext.Provider>
		</SearchStateHitsContext.Provider>
	);
}

export function useSearchStateHits() {
	return useContext( SearchStateHitsContext );
}

export function useSearchStateHitsDispatch() {
	return useContext( SearchStateHitsDispatchContext );
}

function searchStateHitsReducer( existingState, action ) {
	switch ( action.type ) {
		// why does this get called twice after first one?
		case 'changed': {
			return {
				...existingState,
				activeHitId: action.activeHitId,
			};
		}
		case 'nextPage': {
			window.algoliaSearchState.page = existingState.page + 1;
			return {
				...existingState,
				page: existingState.page + 1,
			};
		}
		case 'changePage': {
			window.algoliaSearchState.page = action.page;
			return {
				...existingState,
				page: action.page,
			};
		}
		default: {
			throw Error( 'Unknown action: ' + action.type );
		}
	}
}

const initialSearchLocation = {
	activeHitId: null,
	page: 1,
};

// need to save page to window because all hits are retrieved to show marker clusters
// so there is no pagniation through algolia
if ( ! window.algoliaSearchState ) {
	window.algoliaSearchState = {};
}
window.algoliaSearchState.page = initialSearchLocation.page;
