import { useState, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion'
import { useMediaQuery } from 'usehooks-ts'
import tinycolor from "tinycolor2";
import './Journey.scss';

const breakpoint = 1023;

export default function Journey({ field }) {
    const { steps } = field.data;

    const [[activeIndex, direction], setActiveIndex] = useState([0, 1]);
    const imageOneRef = useRef();
    const imageTwoRef = useRef();
    const [imageOneWidth, setImageOneWidth] = useState(0);
    const [imageTwoWidth, setImageTwoWidth] = useState(0);

    useEffect(() => {
        if (imageOneRef.current) setImageOneWidth(imageOneRef.current.offsetWidth);
        if (imageTwoRef.current) setImageTwoWidth(imageTwoRef.current.offsetWidth);
    }, [])

    useEffect(() => {
        steps.forEach((step) => {
            new Image().src = step.main_img.sizes.gc_bb_main;
            new Image().src = step.secondary_img.sizes.gc_bb_sub;
        });
    }, [steps]);

    const isDesktop = useMediaQuery(`only screen and (min-width: ${breakpoint}px)`);

    const handlers = useSwipeable({
        onSwipedRight: () => { console.log("swipedRight"); decrementIndex() },
        onSwipedLeft: () => { console.log("swipedLeft"); incrementIndex() },
    });

    const incrementIndex = () => {
        setActiveIndex([(activeIndex + 1) % steps.length, 1]);
    }

    const decrementIndex = () => {
        if (activeIndex === 0) {
            setActiveIndex([steps.length - 1, -1]);
            return;
        }
        setActiveIndex([(activeIndex - 1) % steps.length, -1]);
    }

    const handleClick = (index) => {
        setActiveIndex(oldIndex => {
            return [index, index > oldIndex[0] ? 1 : -1];
        });
    }

    const center = {
        zIndex: 1,
        x: 0,
        opacity: 1,
    }

    const variants = {
        main: {
            enter: (direction) => {
                return {
                    x: direction > 0 ? imageOneWidth : -imageOneWidth,
                    opacity: 1,
                }
            },
            center: { ...center },
            exit: (direction) => {
                return {
                    zIndex: 0,
                    x: direction < 0 ? imageOneWidth : -imageOneWidth,
                    opacity: 1,
                }
            },
        },
        sub: {
            enter: (direction) => {
                return {
                    x: direction > 0 ? imageTwoWidth : -imageTwoWidth,
                    opacity: 1,
                }
            },
            center: { ...center },
            exit: (direction) => {
                return {
                    zIndex: 1,
                    x: direction < 0 ? imageTwoWidth : -imageTwoWidth,
                    opacity: 1,
                }
            },
        },
    }

    if (steps.length === 0) return null;

    const {
        main_img,
        secondary_img
    } = steps[activeIndex];

    return (
        <section
            className="media-lockup section-margin section-padding wrap journey"
        >
            <div className="journey-left">
                <JourneyHeading display={isDesktop} field={field.data} />
                <div className="journey-steps" {...handlers}>
                    <AnimatePresence exitBeforeEnter mode="wait">
                        <motion.div
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            key={steps[activeIndex].id}
                        >
                            <MobileStepContent step={steps[activeIndex]} isDesktop={isDesktop} />
                        </motion.div>
                    </AnimatePresence>
                    {steps.map((step, index) => (
                        <div key={step.heading}>
                            <StepContent
                                isDesktop={isDesktop}
                                onClick={() => handleClick(index)}
                                step={step}
                                key={step.heading}
                                index={index}
                                active={index === activeIndex}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <div className="journey-right">
                <JourneyHeading display={!isDesktop} field={field.data} />
                <div className="journey-images" {...handlers}>
                    <div id="journey-image-1" ref={imageOneRef}>
                        <AnimatePresence custom={direction} initial={false}>
                            <motion.img
                                variants={variants.main}
                                custom={direction}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{ duration: 0.75 }}
                                key={`${activeIndex}-main`}
                                className="journey-image"
                                src={main_img.sizes.gc_bb_main}
                                alt={main_img.alt}
                            />
                        </AnimatePresence>
                    </div>
                    <div id="journey-image-2" ref={imageTwoRef} >
                        <AnimatePresence initial={false} custom={direction}>
                            <motion.img
                                variants={variants.sub}
                                custom={direction}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{ duration: .75 }}
                                key={`${activeIndex}-sub`}
                                className="journey-image"
                                src={secondary_img.sizes.gc_bb_sub}
                                alt={secondary_img.alt}
                            />
                        </AnimatePresence>
                    </div>
                </div>
                {!isDesktop && (
                    <div className="journey-mobile-navigation">
                        <span className="journey-step-number journey-step-number--mobile">{activeIndex + 1}</span>
                        <div className="journey-mobile-navigation-carousel">
                            <div onClick={decrementIndex}>
                                <PrevIcon />
                            </div>
                            {steps.map((step, index) => (
                                <span
                                    className={`journey-mobile-navigation-button ${index === activeIndex ? 'active' : ''}`}
                                    onClick={() => handleClick(index)}
                                />
                            ))}
                            <div onClick={incrementIndex}>
                                <NextIcon />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
}

function MobileStepContent({ step, isDesktop }) {
    if (isDesktop) return null;
    
    const { heading, content, link, cta } = step;
    return (
        <div className="journey-step">
            <div className="journey-step-heading">
                <span className="journey-step-title" >
                    {heading}
                </span>
            </div>
            <div className="journey-step-content active">
                <div style={{ overflow: "hidden", display: "flex", flexDirection: "column", gap: "24px", alignItems: "flex-start" }}>
                    <div className=" tw-text-lightGrey tw-font-light" dangerouslySetInnerHTML={{ __html: content }}></div>
                    {link.link_text && link.link_url && (<JourneyContentLink link={link} />)}
                    {cta.cta_text && cta.cta_link && <JourneyCTA cta={cta} />}                    
                </div>
            </div>
        </div>
    );
}

function StepContent({ step, active, index, onClick, isDesktop }) {
    const activeClass = active ? 'active' : ''
    const { heading, content, link, cta } = step;

    if (!isDesktop) return null;
    return (
        <div className={`journey-step ${activeClass}`}>
            <div className="journey-step-heading">
                <span className="journey-step-number">{index + 1}</span>
                <span
                    className="journey-step-title"
                    onClick={() => onClick(index)}
                >
                    {heading}
                </span>
            </div>
            <div
                className={`journey-step-content desktop ${activeClass}`}
            >
                <div style={{ overflow: "hidden", display: "flex", flexDirection: "column", gap: "24px", alignItems: "flex-start" }}>
                <div className="tw-text-lg tw-text-lightGrey tw-font-light tw-leading-8" dangerouslySetInnerHTML={{ __html: content }}></div>
                    {link.link_text && link.link_url && (<JourneyContentLink link={link} />)}
                    {cta.cta_text && cta.cta_link && <JourneyCTA cta={cta} />}
                </div>
            </div>
        </div>
    )
}

function JourneyContentLink({link}) {
    return (
        <a
        className="tw-text-charcoal tw-relative tw-border-b-2 tw-border-apple tw-pb-[1px] hover:tw-text-slate hover:tw-border-melon hover:tw-no-underline tw-leading-relaxed"
        target="_blank"
        href={link.link_url}
    >
        {link.link_text}
    </a>
    )
}

function JourneyCTA({ cta }) {
    const { cta_text, cta_link, cta_bg_color, cta_text_color } = cta;
    const bg_color = tinycolor(cta_bg_color);
    const hover_bg_color = bg_color.isLight() ? bg_color.darken(7) : bg_color.lighten(7);

    return (
        <a
            className="button"
            style={{ backgroundColor: cta_bg_color, color: cta_text_color, "--btn-background-color-hover": hover_bg_color }} href={cta_link}
        >
            <span className="button__text">{cta_text}</span>
        </a>
    )
}

function JourneyHeading({ display, field }) {
    if (!display) return null;
    const { heading_group, label } = field;
    const { primary, sec } = heading_group;
    return (
        <div>
            <div className="section-header__eyebrow eyebrow relative">{label}</div>
            <h2 className="section-header__title section-header__title--large relative title title--medium">
                <span style={{ color: primary.primary_color }} dangerouslySetInnerHTML={{__html: primary.primary_text}} />
                {sec.sec_text && (
                    <span
                        style={{ color: sec.sec_color }}
                        className="title__highlight journey-subtitle"
                        dangerouslySetInnerHTML={{__html: sec.sec_text}}
                    />
                )}
            </h2>
        </div>
    )
}

function PrevIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            style={{ pointerEvents: 'none' }}
        >
            <g>
                <mask
                    id="mask0_195_1696"
                    style={{ maskType: "alpha" }}
                    width="24"
                    height="24"
                    x="0"
                    y="0"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
                </mask>
                <g mask="url(#mask0_195_1696)">
                    <path
                        fill="#545454"
                        d="M8.985 12.308L14.292 7l.708.708-4.6 4.6 4.6 4.6-.708.707-5.307-5.307z"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

function NextIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            style={{ pointerEvents: 'none' }}
        >
            <mask
                id="mask0_195_1695"
                style={{ maskType: "alpha" }}
                width="24"
                height="24"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
            </mask>
            <g mask="url(#mask0_195_1695)">
                <path
                    fill="#545454"
                    d="M15.015 12.308L9.708 7 9 7.708l4.6 4.6-4.6 4.6.708.707 5.307-5.307z"
                ></path>
            </g>
        </svg>
    );
}