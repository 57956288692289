import React from 'react'
import PlanCardItem from './PlanCardItem'
import SectionHeader from './SectionHeader';

class MediaFeaturedGridFeatures extends React.Component {
  constructor() {
    super()

    this.state = {
      visible: 6
    }

    this.loadMore = this.loadMore.bind(this)
  }

  componentDidMount() {
    window.lazy.update();
    const section_id = this.props.id && this.props.id.trim();
    if (section_id) {
      setTimeout(function () {
          if (window.location.href.indexOf("#" + section_id) > -1) {
              document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
          }
      }, 2000)
    }
  }

  loadMore() {
    this.setState(prev => {
      return {
        visible: prev.visible + 6
      }
    })
  }

  render() {
    const section_id = this.props.id && this.props.id.trim();
    return (
      <>
        {this.props.data.length > 0 &&
          <section class="wrap margin-vertical--off section-margin section-padding"
            data-sticky-subnav-section={section_id || 'available-homes'}
            id={section_id || 'available-homes'}>
            <SectionHeader
              eyebrow="Available Homes"
              headline_part_1="Available dream"
              headline_part_2="homes"
              headline_part_1_color={this.props.data.headline_part_1_color}
              headline_part_2_color={this.props.data.headline_part_2_color}
              headline_size={this.props.data.hasOwnProperty('headline_size') ? this.props.data.headline_size : 'large'}/>
            <div class="media-featured-grid__features grid viewport-width"
              style={{
                '--grid-columns-desktop': '2',
                '--grid-column-gap-desktop': '52px',
                '--grid-column-gap': '40px',
                '--grid-row-gap': '70px',
                '--grid-columns-mobile': '1fr',
                '--viewport-width-desktop': '100%',
                '--viewport-width-mobile': '100vw',
              }}>
              {this.props.data.map(data => (
                <PlanCardItem data={data} large={true} />
              ))}
            </div>
          </section>
        }
      </>
    )
  }
}

export default MediaFeaturedGridFeatures