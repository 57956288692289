import React from 'react';
import './SidebarForm.scss';
import { Modal } from 'react-bootstrap';
import SidebarSignUp from './SidebarSignUp.jsx';
import SidebarSignUpNewForm from './SidebarSignUpNewForm.jsx';
import "../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css";
import $ from 'jquery';
import PromotionForm from './PromotionForm';
import StagePromotionForm from './StagePromotionForm';
import StageSidebarSignUp from './StageSidebarSignUp';
import StageSidebarSignUpNewForm from './StageSidebarSignUpNewForm';

class SidebarForm extends React.Component {  

     constructor(props) {
          super(props);
          this.state = {
               show: null
           };
           this.handleShow = this.handleShow.bind(this);
           this.handleClose = this.handleClose.bind(this);
          
      }
      handleClose() {
          this.setState({ show: null });
      }
  
      handleShow(id) {
          this.setState({ show: id });
      }
     componentDidMount() {
          if(window.location.href.indexOf("#interest-form") > -1) {
              document.getElementById("interest_click_btn").click();
          }
      }
    
     calltoaction_data_layer_push(param) {
        this.setState({ value:param })
        if(param && param==='clickToCall') {
            window.dataLayer.push({'event':'clickToCall'});
        }
     }
  render() {    
     var agent_info = this.props.agent_info; 
     var window_width = window.screen.width;
     var posttype = this.props.posttype; 
     var community_data = this.props.community_data; 
     var post_status = this.props.post_status; 
     var tour_btn = this.props.tour_btn.show_tour_btn;
     var btn_color= this.props.btn_color;
     var pagetitle=this.props.nbd_title;
     var promo_form=this.props.promo_form;
     var promo_popup_color = this.props.promo_popup_color;  
     var show_form=this.props.show_form;
     var current_url = window.location.host;
    if (current_url === 'tripointedev.wpengine.com' || current_url === 'tripointestage.wpengine.com') {
        var stageForm = true;
    }else{
        var stageForm = false;
    }
     return (  
          <div className="sidebar-form">  
             <div
                 className="sidebar-close mobile-close"
                 tabindex="0"
                 onKeyPress={(e) => {
                     if (e.keyCode == 13 || e.which == 13) e.target.click();
                 }}
             >X</div>
               <div className="sidebar-agent-info">
                    {agent_info.agent_nhs_heading ? (
                         <p tabindex="0" className="sidebar-title" style={{color:promo_popup_color}}>{agent_info.agent_nhs_heading}</p>
                    ):(
                         <p tabindex="0" className="sidebar-title" style={{color:promo_popup_color}}>New Home Specialist</p>
                    )}
                    {agent_info.agents_contact_name ? (
                         <p tabindex="0" className="agent-name">{agent_info.agents_contact_name} {agent_info.agent_heading}</p>
                    ):('')}
                    <div className="sidebar-btn">
                         {(window_width < 767) ? [
                                     posttype==='promotion'?[
                                        show_form?
                                        [promo_popup_color ?
                                        <a href="javascript:void(0)" className='promotion-btn' data-src="#interest-list" style={{backgroundColor:promo_popup_color}} title="Join The Interest List" onClick={() => this.handleShow("Request_quote")} id="interest_click_btn">Get In touch</a>
                                        :
                                        <a href="javascript:void(0)" className="filled-btn" title="Join The Interest List" onClick={() => this.handleShow("Request_quote")} id="interest_click_btn">Get In touch</a>]:'',
                                        agent_info.agents_contact_phone ? (
                                             <p tabindex="0" className="small-gray-text">--or--</p>
                                        ):(''),
                                        <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                             <Modal.Header closeButton></Modal.Header>
                                             <Modal.Body>
                                             {
                                                  (
                                                  stageForm ? 
                                                       <StagePromotionForm btn_color={btn_color} promo_form={promo_form} sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name}/>
                                                       :
                                                       <PromotionForm btn_color={btn_color} promo_form={promo_form} sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name}/>
                                                  )
                                             }
                                             </Modal.Body>
                                        </Modal>
                                   //     [ promo_popup_color ?
                                   //      <a href="javascript:void(0)" className='promotion-btn' style={{backgroundColor:promo_popup_color}} title="Join The Interest List" onClick={() => this.handleShow("Request_quote")} id="interest_click_btn">Get In touch</a>
                                   //      :
                                   //      <a href="javascript:void(0)" className="filled-btn" title="Join The Interest List" onClick={() => this.handleShow("Request_quote")} id="interest_click_btn">Get In touch</a>,
                                   //      agent_info.agents_contact_phone ? (
                                   //           <p tabindex="0" className="small-gray-text">--or--</p>
                                   //      ):(''),
                                   //      <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                   //           <Modal.Header closeButton></Modal.Header>
                                   //           <Modal.Body>
                                   //           {
                                   //              (
                                   //                <SidebarSignUp btn_color={btn_color} posttype='promotion' sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.neigh_title} division_name={community_data.division_name}/>
                                   //                )
                                   //           }
                                   //           </Modal.Body>
                                   //      </Modal>]
                                   ]:
                              posttype === 'qmi' ?[
                                   tour_btn  ? [
                                        <a href="javascript:void(0)" data-fancybox-trigger="tour_form"
                                        className="filled-btn">{this.props.schedule_button_text}</a>,
                                        agent_info.agents_contact_phone ? (
                                             <p tabindex="0" className="small-gray-text">--or--</p>
                                        ):('')
                                   ]:[
                                        <a href="javascript:void(0)" className="filled-btn" title="Join The Interest List" onClick={() => this.handleShow("Request_quote")} id="interest_click_btn">Join The Interest List</a>,
                                        agent_info.agents_contact_phone ? (
                                             <p tabindex="0" className="small-gray-text">--or--</p>
                                        ):(''),
                                        <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                             <Modal.Header closeButton></Modal.Header>
                                             <Modal.Body>
                                             {community_data.neigh_id !='85782' ? (
                                                  stageForm ? 
                                                  <StageSidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.neigh_title} division_name={community_data.division_name}/>
                                                  :
                                                  <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.neigh_title} division_name={community_data.division_name}/>
                                             ):(
                                                  stageForm ? 
                                                  <StageSidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle? pagetitle:community_data.neigh_title} division_name={community_data.division_name} post_data ={community_data.neigh_id}/>
                                                  :
                                                  <SidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle? pagetitle:community_data.neigh_title} division_name={community_data.division_name} post_data ={community_data.neigh_id}/>
                                             )}
                                             </Modal.Body>
                                        </Modal>
                                   ]
                              ]:[ 
                                   posttype === "neighborhood" ? [ 
                                        tour_btn  ? [
                                             <a href="javascript:void(0)" className="filled-btn" 
                                             title="schedule tour"
                                             >Schedule a Tour</a>,
                                             agent_info.agents_contact_phone ? (
                                                  <p tabindex="0" className="small-gray-text">--or--</p>
                                             ):('')
                                        ]:[     post_status !== 'Sold Out' ? [
                                             <a href="javascript:void(0)" className="filled-btn" title="Join The Interest List" onClick={() => this.handleShow("Request_quote")}>Join The Interest List</a>,
                                            ]:[''],
                                             agent_info.agents_contact_phone ? (
                                                  <p tabindex="0" className="small-gray-text">--or--</p>
                                             ):(''),
                                             <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                                  <Modal.Header closeButton></Modal.Header>
                                                  <Modal.Body>
                                                  {community_data.post_id !='85782' ? (
                                                       stageForm ? <StageSidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name} neigh_status={community_data.community_status} post_data ={community_data.post_id}/> : 
                                                       <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name} neigh_status={community_data.community_status} post_data ={community_data.post_id}/>
                                                  ):(
                                                       stageForm ? <StageSidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name} neigh_status={community_data.community_status} 
                                                       post_data ={community_data.post_id}/> :
                                                       <SidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name} neigh_status={community_data.community_status} 
                                                       post_data ={community_data.post_id}/>
                                                  )}
                                                  </Modal.Body>
                                             </Modal>
                                        ]
                                   ]:[
                                        tour_btn  ? [
                                             <a href="javascript:void(0)" className="filled-btn" 
                                             title="schedule tour"
                                             >Schedule a Tour</a>,
                                             agent_info.agents_contact_phone ? (
                                                  <p tabindex="0" className="small-gray-text">--or--</p>
                                             ):('')
                                        ]:[
                                            post_status !== 'Sold Out' ? [
                                             <a href="javascript:void(0)" className="filled-btn" title="Join The Interest List" id="interest_click_btn" onClick={() => this.handleShow("Request_quote")}>Join The Interest List</a>,
                                            ]:[''],
                                             agent_info.agents_contact_phone ? (
                                                  <p tabindex="0" className="small-gray-text">--or--</p>
                                             ):(''),
                                             <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                                  <Modal.Header closeButton></Modal.Header>
                                                  <Modal.Body>
                                                       {
                                                            stageForm ? <StageSidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name}/> : <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name}/>
                                                       }
                                                       
                                                  </Modal.Body>
                                             </Modal>
                                        ]
                                   ]
                              ]
                         ]:[
                              posttype==='promotion'?[
                                   show_form?
                                  [ promo_popup_color ?
                                        <a href="javascript:void(0)" className='promotion-btn' data-src="#interest-list" style={{backgroundColor:promo_popup_color}} title="Join The Interest List" onClick={() => this.handleShow("Request_quote")} id="interest_click_btn">Get In touch</a>
                                        :
                                        <a href="javascript:void(0)" className="filled-btn" title="Join The Interest List" onClick={() => this.handleShow("Request_quote")} id="interest_click_btn">Get In touch</a>]:'',
                                   agent_info.agents_contact_phone ? (
                                        <p tabindex="0" className="small-gray-text">--or--</p>
                                   ):(''),
                                   <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Body>
                                        {
                                           (
                                             stageForm ? 
                                             <StagePromotionForm btn_color={btn_color} promo_form={promo_form} sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name}/>
                                             :
                                               <PromotionForm btn_color={btn_color} promo_form={promo_form} sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name}/>
                                             )
                                        }
                                        </Modal.Body>
                                   </Modal>
                                   // [promo_popup_color ?
                                   //      <a href="javascript:void(0)" className='promotion-btn' style={{backgroundColor:promo_popup_color}} title="Join The Interest List" onClick={() => this.handleShow("Request_quote")} id="interest_click_btn">Get In touch</a>
                                   //      :
                                   //      <a href="javascript:void(0)" className="filled-btn" title="Join The Interest List" onClick={() => this.handleShow("Request_quote")} id="interest_click_btn">Get In touch</a>,
                                   // agent_info.agents_contact_phone ? (
                                   //      <p tabindex="0" className="small-gray-text">--or--</p>
                                   // ):(''),
                                   // <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                   //      <Modal.Header closeButton></Modal.Header>
                                   //      <Modal.Body>
                                   //      {
                                   //         (
                                   //           <SidebarSignUp btn_color={btn_color} posttype='promotion' sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.neigh_title} division_name={community_data.division_name}/>
                                   //           )
                                   //      }
                                   //      </Modal.Body>
                                   // </Modal>]
                              ]:
                        [    post_status !== 'Sold Out' ? [
                              <a href="javascript:void(0)" className="filled-btn" title="Join The Interest List" onClick={() => this.handleShow("Request_quote")} id="interest_click_btn">Join The Interest List</a>,
                            ]:[''],
                              agent_info.agents_contact_phone ? (
                                   <p tabindex="0" className="small-gray-text">--or--</p>
                              ):(''),
                              <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                   <Modal.Header closeButton></Modal.Header>
                                   <Modal.Body>
                                   {posttype === 'qmi' ? (
                                        community_data.neigh_id != '85782' ? (
                                        stageForm ? <StageSidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.neigh_title} division_name={community_data.division_name} post_data={community_data.neigh_id}/> : 
                                        <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.neigh_title} division_name={community_data.division_name} post_data={community_data.neigh_id}/>
                                        ):(
                                        stageForm ? <StageSidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.neigh_title} division_name={community_data.division_name} post_data ={community_data.neigh_id}/> :
                                        <SidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.neigh_title} division_name={community_data.division_name} post_data ={community_data.neigh_id}/>
                                        )
                                   ):( 
                                        posttype === 'neighborhood' ? (
                                             community_data.post_id != '85782' ? (
                                                  stageForm ? <StageSidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={community_data.title} division_name={community_data.division_name}  neigh_status={community_data.community_status} post_data ={community_data.post_id}/> :
                                             <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={community_data.title} division_name={community_data.division_name}  neigh_status={community_data.community_status} post_data ={community_data.post_id}/>
                                             ):(
                                                  stageForm ? <StageSidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={community_data.title} division_name={community_data.division_name}  neigh_status={community_data.community_status} 
                                                  post_data ={community_data.post_id}/> :
                                                  <SidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={community_data.title} division_name={community_data.division_name}  neigh_status={community_data.community_status} 
                                                  post_data ={community_data.post_id}/>  
                                             )
                                        ):(
                                             community_data.post_id != '85782' ? (
                                                  stageForm ? <StageSidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name} post_data ={community_data.post_id}/> :
                                             <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name} post_data ={community_data.post_id}/>
                                             ):(
                                                  stageForm ? <StageSidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name} 
                                                  post_data ={community_data.post_id}/> :
                                                  <SidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name} 
                                                  post_data ={community_data.post_id}/>
                                             )
                                        )
                                   )}
                                   </Modal.Body>
                              </Modal>
                        ] ]}
                     
                         {agent_info.agents_contact_phone ? (
                              <a href={"tel:"+agent_info.agents_contact_phone} className="phone-num" title="phone number" onClick={() => this.calltoaction_data_layer_push('clickToCall')}>{agent_info.agents_contact_phone}</a>
                         ):('')}
                    </div>
                    {agent_info.dre_license ? (
                         <p tabindex="0" className="small-gray-text dre-num">{agent_info.dre_license}</p>
                    ):('')}
               </div>
          </div>  
     );  
  }  
}  

export default SidebarForm; 
