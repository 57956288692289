export default function getFormBtnCopy(community_status, tpc_priority_group_form) {
  const PRE_QUALIFIED = "Get Pre-qualified"
  const JOIN = "Join the Priority Group"

  const { application_link_status, registration_button_status} = tpc_priority_group_form

  switch (community_status) {
    case "Now Selling":
    case "Final Homes Now Selling":
      return PRE_QUALIFIED;
    case "Grand Opening":
      if (application_link_status && registration_button_status) {
        return PRE_QUALIFIED;
      }
      return JOIN
    case "Limited Availability":
    case "Priority Group Forming - High Demand":
    case "More Homes Coming Soon":
    case "Priority Group Forming":
    default:
      return JOIN;
  }
}