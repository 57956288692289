import React from 'react';
import './GenericHero.scss';
import $ from 'jquery';
class GenericHero extends React.Component {
    split_string(str,count=1,dir='left',splitBy=' ') {
        var part1,part2;
        var split_array = str.split(splitBy);
        if(dir=='right') {
        part2 = split_array.splice(split_array.length-count).join(splitBy);
        part1 = split_array.join(splitBy);
        }else{
        part1 = split_array.splice(0,count).join(splitBy);
        part2 = split_array.join(splitBy);
        }
        return [part1,part2];
    }
    componentDidMount() {
        const generic_hero = this.props.generic_hero;
        const section_id = generic_hero.section_id && generic_hero.section_id.trim();
        if (section_id) {
            setTimeout(function () {
                if (window.location.href.indexOf("#" + section_id) > -1) {
                    document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth', block:'start' });
                }
            }, 2000)
        }
    }

    render() {
        var generic_hero = this.props.generic_hero;
        var section_id = generic_hero.section_id && generic_hero.section_id.trim();
        var backgroundImage = generic_hero.generic_image;
        if(backgroundImage !==""){
            var background_image = {
                backgroundImage:'url('+backgroundImage+')',
            }
        }
        var headline = generic_hero.headline && this.split_string(generic_hero.headline);
        var headline2 =generic_hero.headline2;
        var headline_color = generic_hero.headline_color;
        var headline2_color = generic_hero.headline2_color;
        if(headline_color){
            var highlight_class = headline_color;
        }
        else{
            var highlight_class = 'green-color';
        }
        if(headline2_color){
            var highlight_class1 = headline2_color;
        }
        else{
            var highlight_class1 = 'gray-color';
        }
        var text_class = 'gray-color';
        var overlay_hide = generic_hero.hide_overlay == false ? 'add_overlay': '';

        return (
            headline || generic_hero.sub_text ? (
                <div className="plan-banner" id={section_id} style={background_image}>
                    {generic_hero.banner_logo ? 
                        <div className="hero-logo">
                        <img src={generic_hero.banner_logo.url} alt="Hero Logo" />
                    </div>
                    : ''
                    }
                    <div className={"HeroContent d-flex " +overlay_hide}>
                        <div className="HeroContentWrapper">
                            <div className="bannerHeader">
                                <div className="heading-section">
                                    {generic_hero.sub_text ?(
                                      <div className="eyebrow">{generic_hero.sub_text}</div>
                                    ):('')}
                                    {headline || headline2 ? (
                                        generic_hero.heading_tags==='h1_tag' ? ( <h1 className={"main-title "+(text_class || '')}>
                                        {headline[0] && (<span style={{color:highlight_class}} dangerouslySetInnerHTML={{__html:headline[0].replace(/(?:\r\n|\r|\n)/g, '')}}></span>
                                            )}
                                        <span> </span>
                                        {headline[1] && (<span dangerouslySetInnerHTML={{__html:headline[1].replace(/(?:\r\n|\r|\n)/g, '')}}></span>)}
                                        {headline2 && <span  style={{color:highlight_class1}} dangerouslySetInnerHTML={{__html:headline2.replace(/(?:\r\n|\r|\n)/g, '')}}></span> }
                                    </h1>):(
                                        <h2 className={"main-title "+(text_class || '')}>
                                            {headline[0] && (<span style={{color:highlight_class}} dangerouslySetInnerHTML={{__html:headline[0].replace(/(?:\r\n|\r|\n)/g, '')}}></span>
                                            )}
                                            <span> </span>
                                            {headline[1] && (<span dangerouslySetInnerHTML={{__html:headline[1].replace(/(?:\r\n|\r|\n)/g, '')}}></span>)}
                                            {headline2 && <span  style={{color:highlight_class1}} dangerouslySetInnerHTML={{__html:headline2.replace(/(?:\r\n|\r|\n)/g, '')}}></span> }
                                        </h2>
                                        )
                                    ):('')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ):('')
        );
  }
}

export default GenericHero;
