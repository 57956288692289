import React from 'react';
import HeadingSection from './HeadingSection';
import './GeoAssoicates.scss';
import { Link } from 'react-router-dom'

class Geo extends React.Component {  

  render() {    
    let bg_color ={backgroundColor: '#8c8c8c'};
    var geo_block = this.props.geo_block;
    var geo_data = this.props.associated_data;
    // let result = this.props.community_details && this.props.community_details.planned_comm;
    let p_comm = geo_data.data ;

    p_comm=p_comm.sort((a, b) => a.title.localeCompare(b.title));
    // console.log('planned_comm',p_comm);
     let test_comm =p_comm ;
    // var ul_no=Math.ceil(p_comm/25);
    var count= Math.ceil(test_comm.length/5);
    var updated_data=[];
    for(var i=0;i<count;i++){
      updated_data.push(test_comm.splice(0,5));
    }

    var global_component=this.props.component;
  // console.log('GC',p_comm);
  // console.log('UC',this.props.associated_data.data);
     return (  
      global_component.component ? global_component.component.length > 0 ? (
        global_component.component.map(items => [
          items.acf_fc_layout === "geo_associates" ? (
          
            updated_data.length > 0  &&
           <div className='Mpc_main_section spring-section' id="Geo_product" style={items.background_color?{backgroundColor: items.background_color}:bg_color}>
              <HeadingSection
                subtitle='' 
                title_part_1={items.geo_title !='' ? items.geo_title:this.props.title}
                title_part_2={items.geo_title !='' ?'': geo_data.title}
                headline_part_1_color={items.text_color}
                headline_part_2_color={items.text_color}
                headline_size={items.hasOwnProperty('headline_size') ? items.headline_size : 'large'}
              />
              <div className='container_geo'>
                {window.screen.width>767 ?
                updated_data.map(item=>(
                  <ul className='mpc-list title-info geo_box'>
                    {item.map(items => (
                    <li style={{color: items.text_color , textDecoration: 'none'}}>▸ <a  href={items.permalink} style={{color: items.text_color, textDecoration: 'none'}} target="_blank">{items.title}</a></li>
                    ))}
                  </ul>))
                  : <ul className='mpc-list title-info' >
               {(updated_data.flat()).map(items => (
               <li style={{color: items.text_color, textDecoration: 'none'}}>▸ <a  href={items.permalink} style={{color: items.text_color, textDecoration: 'none'}} target="_blank">{items.title}</a></li>
               ))}
             </ul>}
             </div>
           </div>
               
               ):('')]) ):(''):(''));  
     
  }  
  
}  


export default Geo;