import React from 'react';
import { Modal } from 'react-bootstrap';
import './ContactAccordion.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Accordion, Card, Button } from 'react-bootstrap';

const options = {
    nav: false,
    dots: false,
    loop: false,
    margin: 0,
    autoWidth: true,
    startPosition: 0,
    responsive: {
        0: {
            items: 3,
            margin: 10,
        },
        767: {
            items: 4,
            margin: 10,
        }
    }
}
class ContactUsAccordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            division_Modal_data: null,
        }
        this.handleDivisionClick = this.handleDivisionClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({ division_Modal_data: null, activeCategory: "" });
    }

    handleDivisionClick(obj, card_title) {
        var array = [];
        obj.division_list.forEach((item) => { array.push(...item.select_category) });
        array.unshift('all_department');
        var arr = [...new Set(array)];
        this.setState({
            division_Modal_data: { ...obj, card_title, categories: arr },
            activeCategory: 'all_department'
        });
    }
    renderCar(activecat, division_Modal_data, contact_accordion) {

        return <OwlCarousel {...options} className="category_carousel owl-theme">
            {division_Modal_data.categories.map((cat, index) =>
                <span
                    className={activecat === cat ? "active_cat" : ""}
                    onClick={() => {
                        this.setState({ activeCategory: cat });
                        options.startPosition = index
                        setTimeout(() => this.setState({ activeCategory: cat }))
                    }}
                >
                    {contact_accordion.select_categories[cat]}
                </span>

            )}
        </OwlCarousel>
    }
    render() {
        var contact_accordion = this.props.Contact_Us_Accordion;
        var division_Modal_data = this.state.division_Modal_data;
        var window_width = window.screen.width;
        if (contact_accordion && contact_accordion.select_categories) {
            contact_accordion.select_categories.all_department = 'All Departments';
        }
        var backgroundImage = contact_accordion.contact_banner_image;
        if (backgroundImage !== "") {
            var background_image = {
                backgroundImage: 'url(' + backgroundImage + ')',
            }
        }
        return (
            <div className="contact-accordion-main">
                {background_image && <div className="mobile-contact-image-box-main contact-us-block" >
                    <div tabindex="0" className="image-box add_overlay" style={background_image}></div>
                </div>
                }
                {contact_accordion && contact_accordion.region_cards.length > 0 ? (
                    <div className="mobile-main-section">
                        <div className="cards-section">
                            <Accordion defaultActiveKey="0items">
                                {contact_accordion.region_cards.map((items, key) => (
                                    items.divisions_data.length > 0 && items.card_title ? (
                                        <Card>
                                            <Accordion.Collapse eventKey={key + "items"}>
                                                <Card.Body>
                                                    <div className="region-card" >
                                                        <ul className="region-list">
                                                            {items.divisions_data.length > 0 && items.divisions_data.map((obj, index) =>
                                                                <li tabindex="0" onClick={() => this.handleDivisionClick(obj, items.card_title)}>
                                                                    {obj.division_name}{obj.state_abbreviation ? `, ${obj.state_abbreviation}` : ''}
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey={key + "items"}>
                                                    {items.card_title ? (
                                                        <div className="card-head">
                                                            <div tabindex="0" className="card-heading">{items.card_title}</div>
                                                            <i tabindex="0" class="fa fa-minus fa_icons"></i>
                                                            <i tabindex="0" class="fa fa-plus fa_icons"></i>
                                                        </div>
                                                    ) : ('')}
                                                </Accordion.Toggle>
                                            </Card.Header>

                                        </Card>
                                    ) : ('')
                                ))}
                            </Accordion>
                        </div>
                    </div>
                ) : ('')}
                {division_Modal_data && <Modal className="Estimate_modal Request_quote division_modal" show={division_Modal_data} onHide={this.handleClose} >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-cards-section">
                            <div className="modal-cards-headsection">
                                {division_Modal_data.card_title ? (
                                    <div tabindex="0" className="region-title">{division_Modal_data.card_title}</div>
                                ) : ('')}
                                {division_Modal_data.division_name ? (
                                    <span tabindex="0" className="card-division_name">{division_Modal_data.division_name}</span>
                                ) : ('')}
                                {division_Modal_data.state_abbreviation ? (
                                    <span tabindex="0" className="card-state_abbreviation"><span className="comma">, </span>{division_Modal_data.state_abbreviation}</span>
                                ) : ('')}
                                {(window_width <= 767) ? (
                                    <div className="category-list">
                                        {this.renderCar(this.state.activeCategory, division_Modal_data, contact_accordion)}
                                    </div>
                                ) : ('')}
                            </div>
                            <div className="contact-info-div">
                                {division_Modal_data.division_list &&
                                    division_Modal_data.division_list.length &&
                                    division_Modal_data.division_list.map((item, key) => {
                                        var phone_number;
                                        if (item.contact && item.contact.indexOf('.') === -1) {
                                            var x = item.contact.split('');
                                            x.splice(7, 0, '.');
                                            phone_number = x.join('');
                                        } else {
                                            phone_number = item.contact;
                                        }
                                        return (item.select_category.includes(this.state.activeCategory) || this.state.activeCategory === 'all_department') && (
                                            item.name ? (
                                                <div className="contact-info" >
                                                    <div className="contact-name">
                                                        {item.name ? (
                                                            <h3 tabindex="0" className="title">{item.name}</h3>
                                                        ) : ('')}
                                                    </div>
                                                    {item.address ? (
                                                        <p tabindex="0" className="contact-text" dangerouslySetInnerHTML={{ __html: item.address.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                                    ) : ('')}
                                                    {phone_number ? (
                                                        <h6 tabindex="0">{phone_number}</h6>
                                                    ) : ('')}
                                                    {item.email_address ? (
                                                        <a className="email" href={"mailto:" + item.email_address.replace(/<br\s*\\?>/g, " ")} dangerouslySetInnerHTML={{ __html: item.email_address.replace(/(?:\r\n|\r|\n)/g, '') }}></a>
                                                    ) : ('')}

                                                    {item.url ? (
                                                        <a tabindex="0" target="_blank" rel="noopener noreferrer" href={item.url} className="contact_url">{item.url}</a>
                                                    ) : ('')}
                                                </div>
                                            ) : ('')
                                        )
                                    })}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                }
            </div>
        );

    }
}
export default ContactUsAccordion; 