import React from 'react';
import axios from 'axios';
import { apiConfig } from '../../Classes/Helper.jsx';
import { Link, Events } from 'react-scroll';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import '../../Classes/EventDetailsPage.scss';
// import EventRsvp from '../../Components/EventRsvp.jsx';
import moment from 'moment';
// import TPHLoader from '../../Components/TPHLoader.jsx';
import $ from 'jquery';
import NoMatch from './NoMatch';
import { Redirect } from "react-router-dom";
import EventRsvp from '../../Classes/EventRsvp.jsx';
import StageEventRsvp from '../../Classes/StageEventRsvp.jsx';
import UtilityNav from '../../Classes/UtilityNav';
import {Helmet} from "react-helmet";
import callTrkSwap from '../../../helpers/callTrkSwap.js';

class EventDetailsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            event_info: '',
            
            formup:false,
            success: false
        };
    }
     /*event structure data*/
     event_structured_data(event_info){

        if(event_info && event_info.event_metas && event_info.event_metas.event_address_time) {
            if( event_info.event_metas.event_address_time[0].city){
                var addressLocality = event_info.event_metas.event_address_time[0].city.post_title;
            }
            if(event_info.event_metas.event_address_time[0].state_abbreviation) {
                var addressRegion = event_info.event_metas.event_address_time[0].state_abbreviation;
            }
            if(event_info.event_metas.event_address_time[0].state_abbreviation){
                var postalCode = event_info.event_metas.event_address_time[0].zip_code;
            }
            if(event_info.event_metas.event_address_time[0].physical_address_heading){
               var streetAddress =event_info.event_metas.event_address_time[0].physical_address_heading;
            }
        }
        if(event_info && event_info.event_metas && event_info.event_metas.event_start_date) {
            var startDate = event_info.event_metas.event_start_date;
        }
        if(event_info && event_info.event_metas && event_info.event_metas.event_end_date){
            var endDate = event_info.event_metas.event_end_date;
        }
        if(event_info.post_title){
            var name = event_info.post_title;
        }



    const script = document.createElement("script");
    // Structure Data script start 
    script.type = "application/ld+json";
    script.innerHTML = `{
        "@context": "https://schema.org",
        "@type": "Event",
        "location": {
        "@type": "Place",
        "address": {
        "@type": "PostalAddress",
        "addressLocality": "${addressLocality}",
        "addressRegion": "${addressRegion}",
        "postalCode": "${postalCode}",
        "streetAddress": "${streetAddress}"
        }
        },
        "name": "${name}",
        "startDate": "${startDate}",
        "endDate": "${endDate}"
        }`
      //  Structure Data script end 
      document.head.appendChild(script);

  }

    componentDidMount() {
        axios.get(apiConfig().API_URL+'/event/' + this.props.match.params.slug)
            .then(response => {
                this.setState({
                    event_info: response.data.data,
                    success: response.data.success,
                    message: response.data.message
                })
            });
            if (window.screen.width <767){
              this.setState({
                formup:true
            })
            }
        
         
          

        if (document.getElementsByClassName('rsvp-section').length > 0) {
            $('html, body').animate({
                scrollTop: $(".rsvp-section").offset().top
            }, 1000);
        }
        callTrkSwap();
    }
    componentDidUpdate(){
      if(this.state.success == true){
        window.dataLayer.push({
            'event': 'communityId',
            'salesforceId' : this.state.event_info.neigh_sf_id ? this.state.event_info.neigh_sf_id : "",
            'e1CommunityId' : this.state.event_info.neigh_comm_id ? this.state.event_info.neigh_comm_id : ""
            
        });
       }
        callTrkSwap();
      }
    
    render() {
        var success = this.state.success;
        var event_info = this.state.event_info;
        var window_width = window.screen.width;
        var current_url = window.location.host;
        if (current_url === 'tripointedev.wpengine.com' || current_url === 'tripointestage.wpengine.com') {
            var stageForm = true;
        }else{
            var stageForm = false;
        }
        if(event_info){
        var neigh_detail=this.state.event_info.event_metas.neighborhood_sf;
        var neigh_id=this.state.event_info.neigh_sf_id;
        var neigh_comm_id=this.state.event_info.neigh_comm_id;
        var neigh_name=this.state.event_info.division_name;
        var thumh_img=event_info.post_thumbnail;
        var thumb_alt=event_info.thumbnail_alt;
        var thumb_title=event_info.thumbnail_title;
        var nbd_title=this.state.event_info.neighbourhood_name;
          
        }
        
      
        var message = this.state.message;
        if (event_info && event_info.page_title) {
            var page_title = event_info.page_title;
          }
          if (event_info && event_info.page_desc) {
            var page_desc = event_info.page_desc;
          }
          if (event_info && event_info.yoast_image) {
            var yoast_image = event_info.yoast_image;
          }
        // const meta = {
        //     title: page_title,
        //     description: page_desc,
        //     canonical: window.location.href
        //   };
          if(event_info){
            this.event_structured_data(event_info);
          }
        return (
            <>
            <Helmet>  
            <title>{page_title}</title>
              <meta name="description" content={page_desc} />
              <meta property="og:title" content={page_title} />
              <meta property="og:description" content={page_desc} /> 
              <meta property="og:image" content={yoast_image}/>
              <link rel="canonical" href={window.location.href} />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:type" content='Website' />
            </Helmet> 
            {
                success === true ? [
                  event_info.state_abb.toLowerCase() == this.props.match.params.statename && event_info!='' ? [
                    <div className="events-detail">
                        {/* <div className="container"> */}
                            <div className="row detail-info">
                                <div className="img">
                                { thumh_img? (
                                    thumh_img ? (
                                    <img src={thumh_img} title={thumb_title?thumb_title:''} alt={thumb_alt?thumb_alt:''} />
                                    ) : (
                                        <img src={require('../../../assets/images/TPH-default-image2.gif').default} title='event image' alt='event image' />
                                    )
                                ) : ('')}
                                    <div className="ev-date">
                                        <span>{moment(event_info.startDate.year + '-' + event_info.startDate.monthNum + '-' + event_info.startDate.day + ' 00:00:00','YYYY-M-D HH:mm:ss').format('dddd')}</span>
                                        <span>{event_info.startDate.day}</span>
                                    </div>
                                </div>

                                <div className="ev-detail">
                                    {/* {event_info.event_metas.categories[0] ? (
                                        <span>{event_info.event_metas.categories[0].post_title}</span>
                                    ) : ('')} */}
                                    {event_info.post_title ? (
                                        <h3>{event_info.post_title}</h3>
                                    ) : ('')}
                                    {event_info.post_content ? (
                                        <p dangerouslySetInnerHTML={{ __html: event_info.post_content.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                    ) : ('')}
                                    {window_width>767?
                                    <div className="rsvp-link">
                                        {
                                            event_info.event_metas.rsvp_options && event_info.event_metas.rsvp_options[0].show_rsvp && event_info.event_metas.event_type == "Future Homeowner Event" || event_info.event_metas.rsvp_options && event_info.event_metas.rsvp_options[0].show_rsvp && event_info.event_metas.event_type == "Resident Event" ? (
                                                <Link className="rsvp-nav-link" to="rsvp-section" title="RSVP TO ATTEND" duration={750} smooth={true} spy={true}>RSVP TO ATTEND</Link>
                                            ) : ('')
                                        }
                                    </div>:''}
                                </div>
                            </div>
                            

                            {event_info.event_metas.author_info ? (
                                event_info.event_metas.author_info.length > 0 ? (
                                    event_info.event_metas.author_info[0].author_position || event_info.event_metas.author_info[0].author_name || event_info.event_metas.author_info[0].author_detail ? (
                                    <div className="row author-box">
                                        <div className="author-detail">
                                            <div className="author-img">
                                                {event_info.event_metas.author_info[0].author_image ? (
                                                    <img src={event_info.event_metas.author_info[0].author_image.url} title={event_info.event_metas.author_info[0].author_image.title} alt={event_info.event_metas.author_info[0].author_image.title} />
                                                ) : ('')}
                                            </div>
                                            <div className="author-info">
                                                {event_info.event_metas.author_info[0].author_position ? (
                                                    <span>{event_info.event_metas.author_info[0].author_position}</span>
                                                ) : ('')}
                                                {event_info.event_metas.author_info[0].author_name ? (
                                                    <h3>{event_info.event_metas.author_info[0].author_name}</h3>
                                                ) : ('')}
                                                {event_info.event_metas.author_info[0].author_detail ? (
                                                    <div dangerouslySetInnerHTML={{ __html: event_info.event_metas.author_info[0].author_detail.replace(/(?:\r\n|\r|\n)/g, '') }}></div>
                                                ) : ('')}
                                                {event_info.event_metas.author_info[0].button_label && event_info.event_metas.author_info[0].button_label ? (
                                                    <a href={event_info.event_metas.author_info[0].button_link} title={event_info.event_metas.author_info[0].button_label}>{event_info.event_metas.author_info[0].button_label}</a>
                                                ) : ('')}
                                            </div>
                                        </div>
                                    </div>
                                    ) : ('')
                                ) : ('')
                            ) : ('')}
                            
                          {window_width > 767 ? (<div className="row rsvp-form" id="rsvp-section">
                                {((event_info.event_metas.rsvp_options && event_info.event_metas.rsvp_options[0].show_rsvp && event_info.event_metas.event_type == "Future Homeowner Event") || (event_info.event_metas.rsvp_options && event_info.event_metas.rsvp_options[0].show_rsvp && event_info.event_metas.event_type == "Resident Event")) ? (
                                    stageForm ? 
                                    <StageEventRsvp nbd_title={nbd_title} neigh_id={neigh_id} neigh_name={neigh_name} event={event_info} neigh_comm_id={neigh_comm_id}/>
                                    :
                                    <EventRsvp  nbd_title={nbd_title} neigh_id={neigh_id} neigh_name={neigh_name} event={event_info} neigh_comm_id={neigh_comm_id}/>
                                ) : ('')}
                            </div>):''}
                            {event_info.event_disclaimer ?
                              <div className="event_disclaimer_section">
                                <div className="event_div_disclaimer">
                                  <p dangerouslySetInnerHTML={{ __html: event_info.event_disclaimer.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                </div>
                              </div>
                            : ''} 

                     
                    </div>,
                          (window_width < 767) ? ( 
                            
                            <UtilityNav  neigh_id={neigh_id} neigh_name={neigh_name} agent_info={''} sfdc_chat='' community_component ={'' }  event_page='yes' event_info={event_info}/>
                        ):(
                            ''
                        )
                    ]:[ <Redirect to={<NoMatch/>} />]
                ] : [ message != 'Not Completed' ?  
                <SkeletonTheme>
                    <div className="generic-main-div">
                      <div className="generic-content divide-equal">
                        <div className="generic-content-section">
                          <div className="heading-section">
                            <Skeleton height={20} />
                            <Skeleton height={40} />
                          </div>
                          <Skeleton height={200} className="generic-description"/>
                          <Skeleton height={20} width={100}/>
                        </div>
                      </div>
                      <div className="generic-image divide-equal">
                        <Skeleton height={"100%"}/>
                      </div>
                    </div>
                    <div className="sectiontitle-main-div">
                      <div className="sectiontitle-headline gray-color">
                        <Skeleton height={40}/>
                      </div>
                      <div className="eyebrow-text-div">
                        <Skeleton height={30} width={200} className="sectiontitle-eyebrow_text"/>
                        <div className="eyebrowtext-border"></div>
                      </div>
                    </div>
                    <div className="common-card">
                      <div className="card-main-div">
                        <div className="card-imgs">
                          <Skeleton height={"100%"} />
                          <div className="plan-img-info">
                            <div className="card-img-info">
                              <Skeleton height={20} className="card-status"/>
                              <Skeleton height={40} className="card-name"/>
                              <Skeleton height={20} className="card-price"/>
                            </div>
                          </div>
                        </div>
                        <div className="card-info">
                          <ul className="card-detail">
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-main-div">
                        <div className="card-imgs">
                          <Skeleton height={"100%"} />
                          <div className="plan-img-info">
                            <div className="card-img-info">
                              <Skeleton height={20} className="card-status"/>
                              <Skeleton height={40} className="card-name"/>
                              <Skeleton height={20} className="card-price"/>
                            </div>
                          </div>
                        </div>
                        <div className="card-info">
                          <ul className="card-detail">
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-main-div">
                        <div className="card-imgs">
                          <Skeleton height={"100%"} />
                          <div className="plan-img-info">
                            <div className="card-img-info">
                              <Skeleton height={20} className="card-status"/>
                              <Skeleton height={40} className="card-name"/>
                              <Skeleton height={20} className="card-price"/>
                            </div>
                          </div>
                        </div>
                        <div className="card-info">
                          <ul className="card-detail">
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-main-div">
                        <div className="card-imgs">
                          <Skeleton height={"100%"} />
                          <div className="plan-img-info">
                            <div className="card-img-info">
                              <Skeleton height={20} className="card-status"/>
                              <Skeleton height={40} className="card-name"/>
                              <Skeleton height={20} className="card-price"/>
                            </div>
                          </div>
                        </div>
                        <div className="card-info">
                          <ul className="card-detail">
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="sectiontitle-main-div">
                      <div className="sectiontitle-headline gray-color">
                        <Skeleton height={40}/>
                      </div>
                      <div className="eyebrow-text-div">
                        <Skeleton height={30} width={200} className="sectiontitle-eyebrow_text"/>
                        <div className="eyebrowtext-border"></div>
                      </div>
                    </div>
                    <div className="common-card">
                      <div className="card-main-div">
                        <div className="card-imgs">
                          <Skeleton height={"100%"} />
                          <div className="plan-img-info">
                            <div className="card-img-info">
                              <Skeleton height={20} className="card-status"/>
                              <Skeleton height={40} className="card-name"/>
                              <Skeleton height={20} className="card-price"/>
                            </div>
                          </div>
                        </div>
                        <div className="card-info">
                          <ul className="card-detail">
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-main-div">
                        <div className="card-imgs">
                          <Skeleton height={"100%"} />
                          <div className="plan-img-info">
                            <div className="card-img-info">
                              <Skeleton height={20} className="card-status"/>
                              <Skeleton height={40} className="card-name"/>
                              <Skeleton height={20} className="card-price"/>
                            </div>
                          </div>
                        </div>
                        <div className="card-info">
                          <ul className="card-detail">
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-main-div">
                        <div className="card-imgs">
                          <Skeleton height={"100%"} />
                          <div className="plan-img-info">
                            <div className="card-img-info">
                              <Skeleton height={20} className="card-status"/>
                              <Skeleton height={40} className="card-name"/>
                              <Skeleton height={20} className="card-price"/>
                            </div>
                          </div>
                        </div>
                        <div className="card-info">
                          <ul className="card-detail">
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-main-div">
                        <div className="card-imgs">
                          <Skeleton height={"100%"} />
                          <div className="plan-img-info">
                            <div className="card-img-info">
                              <Skeleton height={20} className="card-status"/>
                              <Skeleton height={40} className="card-name"/>
                              <Skeleton height={20} className="card-price"/>
                            </div>
                          </div>
                        </div>
                        <div className="card-info">
                          <ul className="card-detail">
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                            <li>
                              <Skeleton width={100}/>
                              <Skeleton width={100}/>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                </SkeletonTheme>  
            :  <Redirect to={<NoMatch />} />] 
            }
            </>
        )
    }
}

export default EventDetailsPage;
