import React from 'react';
import HeadingSection from './HeadingSection';
import { Modal } from 'react-bootstrap';
import PromoNationalForm from './PromoNationalForm.jsx';
import StagePromoNationalForm from './StagePromoNationalForm.jsx';

class SpringPromo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        show: null
     };
     this.handleShow = this.handleShow.bind(this);
     this.handleClose = this.handleClose.bind(this);
    
    }
    handleClose() {
        this.setState({ show: null });
    }
    handleShow(id) {
        this.setState({ show: id });
    }

  componentDidMount() {
    const promo_data = this.props.promo_data;
    const section_id = promo_data.section_id && promo_data.section_id.trim();
    if (section_id) {
      setTimeout(function () {
        if (window.location.href.indexOf("#" + section_id) > -1) {
            document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
        }
      }, 2000)
    }
  }
  handleclick(handler){
    if(handler==true) {
      document.getElementById("interest_click_btn").click();
    }
  }
  render() {
    var promo_data = this.props.promo_data ;
    var section_id = promo_data.section_id && promo_data.section_id.trim();
    var promo_list_form = this.props.promo_list_form;
    var sidebar_form = this.props.sidebar_form;
    var post_title = this.props.post_title;
    var communityOfInterest = this.props.communityOfInterest;
    var current_url = window.location.host;
    if (current_url === 'tripointedev.wpengine.com' || current_url === 'tripointestage.wpengine.com') {
        var stageForm = true;
    }else{
        var stageForm = false;
    }
     return (
      promo_data.action_content.length > 0 ? (
        promo_data.action_content.map(items => (
          items.acf_fc_layout === 'call_to_action_content' ? (
            items.description && items.headline ? (
              <div class="spring-section spring-section--no-padding-h"
                id={section_id}
                style={{backgroundColor: items.background_color}}>
                <HeadingSection
                  wrap_class=' wrap'
                  subtitle={items.sub_text}
                  title_part_1={items.headline}
                  title_part_2={items.headline_part_2}
                  headline_part_1_color={items.headline_part_1_color}
                  headline_part_2_color={items.headline_part_2_color}
                  headline_size={items.hasOwnProperty('headline_size') ? items.headline_size : 'large'}
                />
                <div class="text-flex wrap">
                  {items.description ? (<div class="title-info" dangerouslySetInnerHTML={{__html:items.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>) : ('')}

                  {promo_list_form === 'yes' ?
                    items.cta_label ? [
                      <a href="javascript:void(0)" className='button button--light learn-more'
                      onClick={() => this.handleShow("Request_quote")} id="interest_click_btn" target= {items.open_in_new_tab ? '_blank' : '_self'}>
                        <span class="button__text">
                          {items.cta_label}
                        </span>
                      </a>,
                      <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                        {
                          stageForm ? <StagePromoNationalForm btn_color={'#ef5343'} promo_form={sidebar_form}  communityOfInterest={communityOfInterest} nbd_title={post_title} division_name={post_title}/> :
                            <PromoNationalForm btn_color={'#ef5343'} promo_form={sidebar_form}  communityOfInterest={communityOfInterest} nbd_title={post_title} division_name={post_title}/>
                        }
                        </Modal.Body>
                      </Modal>
                    ]:['']
                  : <>{items.cta_link.includes("#interest-form") && items.cta_label && items.cta_link ? (
                      <a href={items.cta_link} title={items.cta_label} class="button button--light learn-more" onClick={() => this.handleclick(true)} target= {items.open_in_new_tab ? '_blank' : '_self'}>
                        <span class="button__text">
                          {items.cta_label}
                        </span>
                      </a>
                    ):(
                      items.cta_label && items.cta_link ? (
                        <a href={items.cta_link} title={items.cta_label} class="button button--light learn-more" target= {items.open_in_new_tab ? '_blank' : '_self'}>
                          <span class="button__text">
                            {items.cta_label}
                          </span>
                        </a>
                      ):('')
                    )}</>
                  }
                </div>
              </div>
           ) :('')
          ):('')
        ))
      ):('')
     );
  }
}

export default SpringPromo;
