import React from 'react';

const IconCurrentLocation = () => {
	return (
		<svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.50005 19.45V17.9885C7.41672 17.7936 5.69652 17.0177 4.33947 15.6606C2.98242 14.3036 2.20646 12.5834 2.01157 10.5H0.550049V9.50005H2.01157C2.20646 7.41672 2.98242 5.69652 4.33947 4.33947C5.69652 2.98242 7.41672 2.20646 9.50005 2.01157V0.550049H10.5V2.01157C12.5834 2.20646 14.3036 2.98242 15.6606 4.33947C17.0177 5.69652 17.7936 7.41672 17.9885 9.50005H19.45V10.5H17.9885C17.7936 12.5834 17.0177 14.3036 15.6606 15.6606C14.3036 17.0177 12.5834 17.7936 10.5 17.9885V19.45H9.50005ZM10 17C11.9334 17 13.5834 16.3167 14.95 14.95C16.3167 13.5834 17 11.9334 17 10C17 8.06672 16.3167 6.41672 14.95 5.05005C13.5834 3.68338 11.9334 3.00005 10 3.00005C8.06672 3.00005 6.41672 3.68338 5.05005 5.05005C3.68338 6.41672 3.00005 8.06672 3.00005 10C3.00005 11.9334 3.68338 13.5834 5.05005 14.95C6.41672 16.3167 8.06672 17 10 17ZM10 13C9.17505 13 8.4688 12.7063 7.8813 12.1188C7.2938 11.5313 7.00005 10.825 7.00005 10C7.00005 9.17505 7.2938 8.4688 7.8813 7.8813C8.4688 7.2938 9.17505 7.00005 10 7.00005C10.825 7.00005 11.5313 7.2938 12.1188 7.8813C12.7063 8.4688 13 9.17505 13 10C13 10.825 12.7063 11.5313 12.1188 12.1188C11.5313 12.7063 10.825 13 10 13ZM10 12C10.55 12 11.0209 11.8042 11.4125 11.4125C11.8042 11.0209 12 10.55 12 10C12 9.45005 11.8042 8.97922 11.4125 8.58755C11.0209 8.19588 10.55 8.00005 10 8.00005C9.45005 8.00005 8.97922 8.19588 8.58755 8.58755C8.19588 8.97922 8.00005 9.45005 8.00005 10C8.00005 10.55 8.19588 11.0209 8.58755 11.4125C8.97922 11.8042 9.45005 12 10 12Z" fill="#626262"/>
		</svg>
	);
};

export default IconCurrentLocation;
