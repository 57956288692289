import React from 'react';
import { Modal } from 'react-bootstrap';
import './ContactSection.scss';
import HeadingSection from './HeadingSection';
import ContactAccordion from './ContactAccordion';

import $ from 'jquery';
class ContactSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: null,
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    handleClose() {
        this.setState({ show: null });
    }

    handleShow(id) {
        this.setState({ show: id });
    }
    componentDidUpdate() {
        if (this.state.show == 'contact_modal') {
            $("#contact-form").on("load", function () {
                let head = $("#contact-form").contents().find("head");
                let css = `<style> 
                .wpforms-form-page-classic #wpforms-form-page-page {
                    border-top-width: 0px;
                    padding: 0;
                    min-height: auto;
                }
                
                .wpforms-form-page-classic #wpforms-form-page-page .wpforms-form-page-wrap {
                    padding: 0;
                    box-shadow: none;
                    max-width: unset;
                    margin-top: 0;
                }
                
                .wpforms-form-page-classic .wpforms-form-page-footer {
                    padding: 0;
                }
                
                #wpforms-form-page-page input[type=text],
                #wpforms-form-page-page input[type=email],
                #wpforms-form-page-page textarea,
                #wpforms-form-page-page input[type=tel] {
                    width: 100%;
                    padding: 7.5px 20px;
                    display: inline-block;
                    border: 1px solid #CBCBCB;
                    background: #FBFBFA;
                    color: #545454 !important;
                    outline: none;
                    letter-spacing: -0.08px;
                    font-size: 20px;
                    line-height: 32px;
                    font-family: "new-atten", Arial, sans-serif;
                    font-weight: 500;
                }
                
                #wpforms-form-page-page input[type=tel] {
                    padding-left: 50px !important;
                }
                
                #wpforms-form-page-page select {
                    width: 100%;
                    padding: 0px 20px;
                    height: 50px;
                    color: #545454 !important;
                    display: inline-block;
                    border: 1px solid #CBCBCB;
                    background: #FBFBFA;
                    outline: none;
                    letter-spacing: -0.08px;
                    font-size: 20px;
                    line-height: 32px;
                    font-family: "new-atten", Arial, sans-serif;
                    font-weight: 500;
                }
                
                #wpforms-form-page-page button[type=submit] {
                    width: 100%;
                    background-color: #BED245 !important;
                    color: #545454 !important;
                    padding: 14px 20px !important;
                    margin: 8px 0;
                    border: none;
                    cursor: pointer;
                    outline: none;
                    text-transform: uppercase;
                    font-size: 13px;
                    letter-spacing: 3.9px;
                    line-height: 25px;
                    font-family: "new-atten", Arial, sans-serif;
                    font-weight: 700;
                }
                
                .wpforms-description .contact-form-title {
                    font-size: 52px !important;
                    line-height: 70px !important;
                    font-family: "new-atten", Arial, sans-serif !important;
                    font-weight: 500;
                    color: #545454 !important;
                }
                
                .wpforms-form-page-footer p {
                    font-size: 10px;
                    line-height: 10px;
                    color: #545454 !important;
                    opacity: 1;
                    margin: 0;
                    font-family: "new-atten", "Times New Roman", Times, serif !important;
                    font-weight:300;
                }
                .wpforms-confirmation-container-full {
                    background: none !important;
                    border: none !important;
                }
                .wpforms-confirmation-container-full p {
                    font-family: "new-atten",Arial,sans-serif !important;
                    font-weight:500;
                    font-size: 20px !important;
                }
                @media only screen and (max-width: 1600px) {
                    .wpforms-description .contact-form-title {
                        font-size: 40px !important;
                        line-height: normal !important;
                    }
                    #wpforms-form-page-page input[type=text],
                    #wpforms-form-page-page input[type=email],
                    #wpforms-form-page-page textarea,
                    #wpforms-form-page-page input[type=tel] {
                        font-size: 16px;
                    }
                    #wpforms-form-page-page select {
                        font-size: 16px;
                    }
                }
                
                @media only screen and (max-width: 1440px) {
                    .wpforms-container .wpforms-field {
                        padding: 7px 0 !important;
                    }
                    .wpforms-form-page-classic #wpforms-form-page-page .wpforms-description {
                        margin-bottom: 10px;
                    }
                    #wpforms-form-page-page select {
                        height: 45px;
                    }
                    #wpforms-form-page-page input[type=text],
                    #wpforms-form-page-page input[type=email],
                    #wpforms-form-page-page textarea,
                    #wpforms-form-page-page input[type=tel] {
                        padding: 5.5px 20px;
                        height: unset;
                    }
                }
                
                @media only screen and (max-width: 991px) {
                    .wpforms-container .wpforms-field {
                        padding: 10px 0 !important;
                    }
                    .wpforms-form-page-classic #wpforms-form-page-page .wpforms-description {
                        margin-bottom: 30px;
                    }
                }
                
                @media only screen and (max-width: 480px) {
                    .wpforms-container .wpforms-field {
                        padding: 5px 0 !important;
                    }
                    .wpforms-form-page-classic #wpforms-form-page-page .wpforms-description {
                        margin-bottom: 20px;
                    }
                }
                </style>`;
                $(head).append(css);
                $("#contact-form").css('visibility', 'visible')
            });
        }
    }
    render() {
        var contact_section = this.props.contact_section;
        var contact_modal = this.props.contact_modal;
        var backgroundImage = contact_modal.contact_banner_image;
        var window_width = window.screen.width;
        if (backgroundImage !== "") {
            var background_image = {
                backgroundImage: 'url(' + backgroundImage + ')',
            }
        }
        return (
            <div className="contact-section-main">
                {contact_section.grid_section.length > 0 && <div className="mobile-contact-image-box-main" >
                    <div className="image-box add_overlay" style={background_image}></div>
                    {contact_modal.contact_headline || contact_modal.contact_description || contact_section.cta_label_mobile ? (
                    <div className="image-box-content" >
                        {contact_modal.contact_headline ? (
                            <h2 className="image-heading">{contact_modal.contact_headline}</h2>
                        ) : ('')}
                        {contact_modal.contact_description ? (
                            <h6 className="image-description" dangerouslySetInnerHTML={{ __html: contact_modal.contact_description.replace(/(?:\r\n|\r|\n)/g, '') }}></h6>
                        ) : ('')}
                        {contact_section.cta_label_mobile && contact_modal.contact_form_url ? (
                            <a href="javascript:void(0)" onClick={() => this.handleShow("contact_modal")} title={contact_section.cta_label_mobile} className="learn-more">{contact_section.cta_label_mobile}</a>
                        ) : ('')}
                    </div>
                    ):('')}
                </div>}
                    <ContactAccordion contact_accordion={this.props.contact_modal}/>
                {contact_section.grid_section.length > 0 ? (
                    <div className="contact-section">
                        <div className="contact-content" >
                            <div className="header-section">
                                <div className="heading-section-left">
                                    <HeadingSection
                                      subtitle={contact_section.sub_text}
                                      title_part_1={contact_section.headline}
                                      title_part_2={contact_section.headline_part_2}
                                      headline_part_1_color={contact_section.headline_part_1_color}
                                      headline_part_2_color={contact_section.headline_part_2_color}
                                      headline_size={contact_section.hasOwnProperty('headline_size') ? contact_section.headline_size : 'large'}
                                    />
                                </div>
                                <div className="heading-section-right">
                                    {contact_section.description ? (
                                        <div className="description" dangerouslySetInnerHTML={{ __html: contact_section.description.replace(/(?:\r\n|\r|\n)/g, '') }}></div>
                                    ) : ('')}
                                    {contact_section.cta_label && contact_modal.contact_form_url ? (
                                        <a href="javascript:void(0)" onClick={() => this.handleShow("contact_modal")} title={contact_section.cta_label} className="button">
                                          <span class="button__text">{contact_section.cta_label}</span>
                                        </a>
                                    ) : ('')}
                                </div>
                            </div>
                            {contact_section.grid_section.length > 0 ? [
                                <div className="contact-info-div">
                                    {contact_section.grid_section.map((items, key) => {
                                        var phone_number;
                                        if (items.contact && items.contact.indexOf('.') === -1) {
                                            var x = items.contact.split('');
                                            var y = x.splice(3, 0, '.');
                                            x.splice(7, 0, '.');
                                            phone_number = x.join('');
                                        } else {
                                            phone_number = items.contact;
                                        }   
                                        return (
                                            items.name ? (
                                                <div className="contact-info">
                                                    <div className="contact-name">
                                                        {items.name ? (
                                                            <h3 className="title">{items.name}</h3>
                                                        ) : ('')}
                                                    </div>
                                                    {items.address ? (
                                                        <p className="contact-text" dangerouslySetInnerHTML={{ __html: items.address.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                                    ) : ('')}
                                                    {phone_number ? (
                                                        <h6>{phone_number}</h6>
                                                    ) : ('')}
                                                    {items.email_address ? (
                                                        <a className="email" href={"mailto:" + items.email_address.replace(/<br\s*\\?>/g, " ")} dangerouslySetInnerHTML={{ __html: items.email_address.replace(/(?:\r\n|\r|\n)/g, '') }}></a>
                                                    ) : ('')}
                                                    {items.url ? (
                                                        <a target="_blank" href={items.url} className="contact_url">{items.url}</a>
                                                    ) : ('')}
                                                </div>
                                            ) : ('')
                                        )
                                    })}
                                </div>
                            ] : ['']}
                        </div>
                        <Modal className="Estimate_modal Request_quote contact_modal" show={this.state.show === "contact_modal"} onHide={this.handleClose} >
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <div className="contact-modal-section">
                                    <div className="contact-img add_overlay" style={background_image}>
                                        <div className="contact-image-box-content">
                                            {contact_modal.contact_headline ? (
                                                <h2 className="contact-image-heading">{contact_modal.contact_headline}</h2>
                                            ) : ('')}
                                            {contact_modal.contact_description ? (
                                                <h6 className="contact-image-description" dangerouslySetInnerHTML={{ __html: contact_modal.contact_description.replace(/(?:\r\n|\r|\n)/g, '') }}></h6>
                                            ) : ('')}
                                        </div>
                                    </div>
                                    <div className="contact_us_form">
                                        {contact_modal.contact_form_url ? (
                                            <iframe src={contact_modal.contact_form_url} scrolling="no" id="contact-form"></iframe>
                                        ) : ('')}
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal></div>
                ) : ('')}
            </div>
        );
    }
}

export default ContactSection; 