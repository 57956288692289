import React from 'react';
import HeadingSection from './HeadingSection';


class CityDetail extends React.Component {  
 

  render() {    
    var city_data = this.props.city_data ;

     return (  
            city_data.city_headline && city_data.description ? (
            <div tabindex="0" className="spring-section" style={{backgroundColor: city_data.background_color}}>  
                <HeadingSection 
                  subtitle={city_data.city_subtext}
                  title={city_data.city_headline}
                  highlight_class='new-black-color'
                  highlight_dir = 'left'
                  highlighted_word_count = {2}
                  text_class = 'white-color'
                />
                <div className="text-flex">
                  {city_data.description?( <p tabindex="0" className="title-info" dangerouslySetInnerHTML={{__html:city_data.description.replace(/(?:\r\n|\r|\n)/g, '')}}></p> ):('')}
                </div>
            </div>  
           ) :('')
     );  
  }  
}  

export default CityDetail; 