import React from 'react';
import PlanCardItem from './PlanCardItem';
import SectionHeader from './SectionHeader';

class MediaFeaturedGrid extends React.Component {
  constructor() {
    super()

    this.state = {
      visible: 6
    }

    this.loadMore = this.loadMore.bind(this)
  }

  componentDidMount() {
    window.lazy.update();
    const section_id = this.props.id && this.props.id.trim();
    if (section_id) {
      setTimeout(function () {
          if (window.location.href.indexOf("#" + section_id) > -1) {
              document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
          }
      }, 2000)
    }
  }

  loadMore() {
    this.setState(prev => {
      return {
        visible: prev.visible + 6
      }
    })
  }

  render() {
    const section_id = this.props.id && this.props.id.trim();
    return (
      <>
        {this.props.data.length > 0 &&
          <>
            <section class="media-featured-grid margin-vertical--off section-margin section-padding wrap"
              data-sticky-subnav-section={section_id || 'home-plans'}
              id={section_id || 'home-plans'}
              style={{
                'overflow': 'hidden',
              }}>

              <SectionHeader
                eyebrow={this.props.eyebrow ? this.props.eyebrow : this.props.posttype === 'mpc' ? 'Available Homes' : 'Home Plans'}
                headline_part_1={this.props.headline_part_1 ? this.props.headline_part_1 : 'Find your dream'}
                headline_part_2={this.props.headline_part_2 ? this.props.headline_part_2 : 'home'}
                headline_part_1_color={this.props.headline_part_1_color}
                headline_part_2_color={this.props.headline_part_2_color}
                headline_size={this.props.hasOwnProperty('headline_size') ? this.props.headline_size : 'large'}
              />

              <div class="grid viewport-width"
                style={{
                  '--flex-wrap-desktop': 'wrap',
                  '--grid-column-gap-desktop': '52px',
                  '--grid-column-gap-tablet': '30px',
                  '--grid-column-gap-mobile': '20px',
                  '--grid-columns-desktop': '3',
                  '--grid-columns-tablet': '2',
                  '--grid-columns-mobile': '1fr',
                  '--grid-row-gap-desktop': '87px',
                  '--grid-row-gap-tablet': '70px',
                  '--grid-row-gap-mobile': '70px',
                  '--viewport-width-desktop': '100%',
                  '--viewport-width-tablet': '100%',
                  '--viewport-width-mobile': '100vw',
                }}>
                {this.props.data.slice(0, this.state.visible).map(data => (
                  <PlanCardItem data={data} />
                ))}
              </div>

              {this.state.visible < this.props.data.length &&
                <button class="button full-width margin max-width"
                  onClick={this.loadMore}
                  type="button"
                  style={{
                    '--btn-display': 'flex',
                    '--margin-desktop': '80px auto 0',
                    '--margin-mobile': '50px 0 0',
                    '--max-width-desktop': '337px',
                  }}>
                  <span>Load more</span>
                </button>
              }
              {this.props.show_disclaimer ?
                <div className="peace-program-disclaimer">
                    <p className="disclamier-content">{this.props.disclaimer_content}</p>
                </div>  : ''
              }
            </section>
          </>
        }
      </>
    )
  }
}

export default MediaFeaturedGrid