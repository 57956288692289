import React from "react"
import axios from "axios"
import $ from "jquery"
import "./ReviewSection.scss"
import moment from "moment"
import OwlCarousel from "react-owl-carousel"
import SectionHeader from './SectionHeader'
import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"
import { apiConfig } from "./Helper.jsx"
import { FaStar ,FaStarHalfAlt,FaRegStar } from "react-icons/fa"
import { splitString } from './Helpers'
import callTrkSwap from "../../helpers/callTrkSwap.js"

const options = {
  nav: true,
  navText: [
    '<div class="sliderArrow arrowLeft"></div>',
    '<div class="sliderArrow arrowRight"></div>'
  ],
  dots: true,
  loop: false,
  rewind: true,
  items: 1,
  responsive: {
    0: {
      items: 1,
    },
    667: {
      items: 1,
    },
    768: {
      items: 1,
    },
    1024:{
      items: 2,
      margin: 20,
    },
    1280:{
      items: 2,
      margin: 40,
    },
    1601: {
      items: 2,
      margin: 48,
    },
  },
}

class ReviewSection extends React.Component {
  constructor() {
    super()

    this.state = {
      review_component: "",
      success: false,
      review_loaded: true,
      sort: "date",
      flag : 0,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  review_structured_data(review_details) {
    if (review_details != null) {
      if (review_details.jd_orders.average_rating) {
        var ratingValue = review_details.jd_orders.average_rating
      }

      if (review_details.jd_orders.reviews.length) {
        var reviewCount = review_details.jd_orders.reviews.length
      }

      if (
        review_details.eliant_data &&
        review_details.eliant_data[0].eliant_text
      ) {
        var eliant_text = review_details.eliant_data[0].eliant_text

        if (eliant_text.length > 150) {
          var description = eliant_text.substring(0, 150)
          description =description.replace(/(<([^>]+)>)/gi, "").replace(/['"]+/g, '')
        }
      }

      if (review_details.title) {
        var name = review_details.title
      }

      if (review_details.min_price) {
        var min_price = review_details.min_price
      }

      if (review_details.max_price) {
        var max_price = review_details.max_price
      }

      var review_data = []

      if (review_details.jd_orders.reviews.length > 0) {
        for (var i = 0; i < review_details.jd_orders.reviews.length; i++) {
          if (
            review_details.jd_orders.reviews &&
            review_details.jd_orders.reviews[i].date
          ) {
            var date = review_details.jd_orders.reviews[i].date
            var datePublished = moment(date).format("MMMM d, YYYY")
          }
          if (
            review_details.jd_orders.reviews &&
            review_details.jd_orders.reviews[i].comment
          ) {
            var comment = review_details.jd_orders.reviews[i].comment

            if (comment.length > 150) {
              var comment_text = comment.substring(0, 150)
              comment_text = comment_text.replace(/(<([^>]+)>)/gi, "").replaceAll('"', '')
            }
          }

          const structure_schema = `{
            "@type": "Review",
            "author":  {
              "@type" : "Person",
              "name" : "Verified Homeowner"
            },
            "datePublished": "${datePublished}",
            "description": "${comment_text}",
            "name": "Customer Reviews",
            "reviewRating": {
              "@type": "Rating",
              "bestRating": "5",
              "ratingValue": "${ratingValue}",
              "worstRating": "1"
            }
          }`

          review_data.push(structure_schema)
        }
      }
    }

    // Structure Data script
    const script = document.createElement("script")

    script.type = "application/ld+json"
    script.innerHTML = `{
      "@context": "http://schema.org",
      "@type": "Product",

      "description": "${description}",
      "name": "${name}",
      "review": [
          ${review_data}
      ],
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "${ratingValue}",
        "reviewCount": "${reviewCount}"
      },
      "offers": {
        "@type": "AggregateOffer",
        "lowPrice": "${min_price}",
        "highPrice": "${max_price}",
        "priceCurrency": "USD"
      }
    }`

    document.head.appendChild(script)
  }

  handleChange(event) {
    this.setState({
      sort: event.target.value,
      review_component: "",
      success: false,
    })

    this.fetch_data(event.target.value)
  }

  fetch_data(sort = "date") {
    axios
      .get(
        apiConfig().API_URL +
          "/get_reviews/" +
          this.props.review_title +
          "/reviews/" +
          sort
      )
      .then((response) => {
        this.setState({
          review_component: response.data.data,
          success: response.data.success,
          review_loaded: true,
        })
      })
  }

  componentDidMount() {
    this.fetch_data()
    window.JQUERY(".top-scroll").addClass("dynamic")
    callTrkSwap()
  }

  starRating = (star_number) => {
    var finalRating = []
    var rating = star_number
    var ratingDevide = (rating + "").split(".")
    var isHalfPrint = false

    for (var i = 1; i <= 5; i++) {
      if (i <= ratingDevide[0]) {
        finalRating.push(
          <span>
            <FaStar />
          </span>
        )
      } else {
        if (ratingDevide[1] !== "" && isHalfPrint === false) {
          isHalfPrint = true

          finalRating.push(
            <span>
              <FaStarHalfAlt/>
            </span>
          )
        } else {
          finalRating.push(
            <span>
             <FaRegStar />
            </span>
          )
        }
      }
    }

    return finalRating
  }

  Small_starRating = (star_number) => {
    var Small_finalRating = []
    var rating = star_number
    var ratingDevide = (rating + "").split(".")
    var isHalfPrint = false

    for (var i = 1; i <= 5; i++) {
      if (i <= ratingDevide[0]) {
        Small_finalRating.push(
          <span>
            <FaStar />
          </span>
        )
      } else {
        if (ratingDevide[1] !== "" && isHalfPrint === false) {
          isHalfPrint = true

          Small_finalRating.push(
            <span>
              <FaStarHalfAlt />
            </span>
          )
        } else {
          Small_finalRating.push(
            <span>
              <FaRegStar />
            </span>
          )
        }
      }
    }

    return Small_finalRating
  }

  componentDidUpdate() {
      callTrkSwap();

    if (window.location.search.indexOf('?tab=review') > -1) {
			setTimeout(() => {
        $('html, body').animate({
            scrollTop: $(".review-section-view").offset().top
        }, 1000);
      }, 1000);
    }
  }

  render() {
    var toShow, textTrim;
    var window_width = window.screen.width;
    var review_component = this.state.review_component;
    // console.log(review_component);
    var review_details = review_component.review_details;
    var success = this.state.success;
    var message = this.state.message;
    var review_block = this.props.community_details;
    // console.log(review_block);
    var state_name = review_block.state_abb;
    var submarket_name = review_block.submarket;
    if (review_block.jd_orders.reviews.length != 0) {
      if (review_details) {
        this.review_structured_data(review_details)
      }
    }

    return (
      <>
        {success === true
          ? [
              review_details.jd_orders !== null &&
              review_details.show_reviews !== false
                ? review_details.jd_orders.reviews.length > 0
                  ? [
                      <div className="review-main section-margin section-padding" id="review-main">
                        <div className="review-section-view">
                          <div className="reviews-info wrap">
                            <div className="reviews-heading">
                              <SectionHeader
                                eyebrow={review_details.eliant_data[0].eliant_sub_text}
                                headline_part_1={splitString(review_details.eliant_data[0].eliant_headline)[0]}
                                headline_part_2={splitString(review_details.eliant_data[0].eliant_headline)[1]}
                              />
                              <div className="review-star1">
                                <div className='star-review'>
                                  {this.starRating(
                                    review_details.jd_orders.average_rating
                                  )}
                                </div>
                                <div className="reviews-data">
                                  <p className="star-text">
                                    {review_details.jd_orders.average_rating} { " |"}
                                  </p>
                                  <p className="total-review">
                                    {review_details.jd_orders.reviews.length}{" "}
                                    Reviews
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="reviews-logo">
                              {review_details.eliant_data[0].eliant_text ? (
                                <p className="reviews-text"
                                  dangerouslySetInnerHTML={{__html:
                                    review_details.eliant_data[0].eliant_text.replace(
                                      /(?:\r\n|\r|\n)/g,
                                      ""
                                    ),
                                  }}
                                  title={review_details.eliant_data[0].eliant_tooltip_text}
                                ></p>
                              ) : (
                                ""
                              )}
                              <div className="review_logo_data">
                                {review_details.eliant_data[0].eliant_logo ? (
                                <div className="eliant_logo">
                                  <a
                                    href="javascript:void(0)"
                                    rel="noopener noreferrer"
                                    title="eliant"
                                  >
                                    <img
                                      src={
                                        review_details.eliant_data[0]
                                          .eliant_logo.url
                                      }
                                      title={
                                        review_details.eliant_data[0]
                                          .eliant_logo.title
                                      }
                                      alt={
                                        review_details.eliant_data[0]
                                          .eliant_logo.alt
                                          ? review_details.eliant_data[0]
                                              .eliant_logo.alt
                                          : review_details.eliant_data[0]
                                              .eliant_logo.title
                                      }
                                    />
                                  </a>
                                </div>
                              ) : (
                                ""
                              )}
                              </div>

                            </div>
                          </div>

                          <div className="reviews-slider wrap">
                            {review_details.jd_orders.reviews.length === 1 ? (
                              <div className="single-image">
                                <div className="reviews-slider-div">
                                  <div className="review-star1">
                                    <div className="review_rating">
                                      {this.Small_starRating(
                                        review_details.jd_orders.reviews[0]
                                        .star_rating
                                      )}
                                    </div>

                                    <div className="star_content">
                                      <p className="star-text">
                                        {review_details.jd_orders.reviews[0].star_rating} {'/ 5'}
                                      </p>
                                    </div>
                                  </div>

                                  {review_details.jd_orders.reviews[0].comment ? (
                                    review_details.jd_orders.reviews[0].comment.length > 350 ? (
                                      toShow = review_details.jd_orders.reviews[0].comment.substring(0,350),
                                      textTrim = toShow.substring(0, toShow.lastIndexOf(" ")) + "...",
                                      <p className="silder-text">
                                        {textTrim}
                                      </p>
                                    ) : (
                                      <p className="silder-text">
                                        {review_details.jd_orders.reviews[0].comment}
                                      </p>
                                    )
                                    ) : ('')
                                  }
                                  <p className="small-gray-text review-date">
                                    {moment(review_details.jd_orders.reviews[0].date).format("LL")}
                                  </p>
                                </div>
                              </div>
                              ) : (
                              <OwlCarousel {...options} className="owl-theme">
                                {review_details.jd_orders.reviews.map(
                                  (item) => (
                                    <div className="reviews-slider-div">
                                      <div className="review-star1">
                                        <div className="review_rating">
                                          {this.Small_starRating(item.star_rating)}
                                        </div>

                                        <div className="star_content">
                                          <p className="star-text">
                                            {item.star_rating} { " / 5"}
                                          </p>
                                        </div>
                                      </div>

                                      {item.comment ? (
                                        item.comment.length > 350 ? (
                                          toShow = item.comment.substring(0,350),
                                          textTrim = toShow.substring(0, toShow.lastIndexOf(" ")) + "...",
                                          <p className="silder-text">
                                            {textTrim}
                                          </p>
                                        ) : (
                                          <p className="silder-text">
                                            {item.comment}
                                          </p>
                                        )
                                      ) : ('')}

                                      <p className="small-gray-text review-date">
                                        {moment(item.date).format("LL")}
                                      </p>
                                    </div>
                                  )
                                )}
                              </OwlCarousel>
                            )}
                          </div>
                        </div>
                      </div>,
                    ]
                  : ""
                : // [<Redirect to={review_details.comm_permalink} />]
                  "",
              // <Redirect to={review_details.comm_permalink} />
            ]
          : [""]}
      </>
    )
  }
}

export default ReviewSection
