import React from 'react';
import $ from 'jquery';
import './Blogs.scss';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

class Blogs extends React.Component {
  state = {
    current_blog_post: this.props.updated_blog_post,
    selectedCat: {}
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.updated_blog_post !== this.props.updated_blog_post) {
      this.setState({
        current_blog_post: this.props.updated_blog_post
      })
    }
  }
  componentDidMount(){
    const search_param = new URLSearchParams(window.location.search);
    const window_url = window.location.href;
    if (window_url.includes('category')) {
      var search_value_check = window_url.split('/category/')[1];
      var search_value = search_value_check.replace('/','');
    }
    const temp = window_url.includes('category') ? this.props.category_list.filter(item=> item.slug === search_value)[0]:this.props.category_list[0]
    // const temp = search_param.has('category') ? this.props.category_list.filter(item=> item.slug === search_param.get('category'))[0]:this.props.category_list[0]
    this.update_posts(temp)
  }
  update_posts = (selected_cat) => {
    const current_posts = this.props.updated_blog_post.filter((item, i) => item.all_cat.includes(selected_cat.name))
    this.setState({
      current_blog_post: current_posts,
      selectedCat: selected_cat
    })
  }
  handleCatClick = (e) => {
  this.update_posts(this.props.category_list[e.target.selectedIndex-1]);
  var category_select = document.querySelector(".category_dropdown_list");
    var option_slug = category_select.options[category_select.selectedIndex].getAttribute('data-slug');
    var search_value_check_slug = window.location.href.split('/blog/')[1];
    search_value_check_slug != '' ?  window.history.pushState("", "", '/blog/category/'+option_slug+'/') :  window.history.pushState("", "", '/blog/category/'+option_slug+'/');
  }


  render() {
    const { category_list } = this.props;
    const { current_blog_post, selectedCat } = this.state
    var toShow;
    var textTrim;
    var window_width = window.screen.width;  
    const window_url = window.location.href;
    var search_value_check = window_url.split('/blog/')[1];
    if (window_url.includes('category')) {
      var search_value = window_url.split('/category/')[1];
    }
    const meta = {
      title: search_value_check ? `Homeowner Blog - ${selectedCat.name} | Tri Pointe Homes` : `Homeowner Blog | Tri Pointe Homes`,
      description: search_value_check != '' ? `Explore the ${selectedCat.name} section of the Tri Pointe Homes blog to find helpful tips, ideas, and insights into the world of homeownership.` : 
      'The Tri Pointe Homes blog provides helpful tips for homeowners, including resources on home buying and insights into the latest real estate trends.',
    };

    return !current_blog_post || !current_blog_post.length ? null :
      (
        <>
        <Helmet>  
              <title>{meta.title}</title>
              <meta name="description" content={meta.description} />
              <meta property="og:title" content={meta.title} />
              <meta property="og:description" content={meta.description} /> 
              <meta property="og:type" content='Website' />
            </Helmet>
        <div role="main" className="blog_listing_container blogs-section">
          {current_blog_post[0].post_thumbnail_url ? (
            <div className="blog-cover-section">
              <div className="blog-cover-content">
                {current_blog_post[0].post_thumbnail_url &&
                  <div className="blog-cover-img divide-equal">
                    <Link to={`/blog/${current_blog_post[0].post_name}/`}>
                      <img src={current_blog_post[0].post_thumbnail_url} title={current_blog_post[0].image_title} alt={current_blog_post[0].image_alt ? current_blog_post[0].image_alt : current_blog_post[0].image_title} />
                    </Link>
                  </div>
                }
                <div className="blog-cover-info divide-equal">
                  <div className="header-section">
                    <div className="heading-section">
                      {selectedCat.name && <h1 tabindex="0" className="sub-title">
                        {selectedCat.name}
                      </h1>}
                      {current_blog_post[0].post_title && <h3 tabindex="0" className="main-title">
                        <span><Link to={`/blog/${current_blog_post[0].post_name}/`}>{current_blog_post[0].post_title}</Link></span>
                      </h3>}
                    </div>
                    
                      <p tabindex="0" className="title-info" dangerouslySetInnerHTML={{ __html: current_blog_post[0].summary.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                      
                    {current_blog_post[0].post_name && <Link to={`/blog/${current_blog_post[0].post_name}/`} className="read_more">Read More</Link>}
                  </div>
                </div>

              </div>
            </div>
          ) : ('')}
          {(window_width > 991) ? ( 
          <div className="category_list">
            {category_list.map((item, i) => {
              return <a href={"/blog/category/"+item.slug+"/"} tabindex="0" className={`cat_list_item ${selectedCat.slug === item.slug ? "active" : ""}`} key={i} onFocus={() => this.update_posts(item)} onClick={()=>window.history.pushState("", "", '/blog/category/'+item.slug+'/')}>{item.name.replace("&amp;", "&")}</a>
            }) }
            {
            }
          </div>
          ):('')}
          {(window_width <= 991) ? ( 
          <div className="category_list">
          <select name="category_dropdown_list" className="category_dropdown_list" style={{
                backgroundImage:'url('+require('../../assets/images/angle-arrow-down.png')+')',
            }} onChange={this.handleCatClick}>
          <option value="BLOG CATEGORIES">BLOG CATEGORIES</option>
            {category_list.map((item, i) => {
              return <option 
              value={item.name.replace("&amp;", "&")} 
              data-slug={item.slug}
              selected={selectedCat.slug === item.slug} 
              className={`cat_list_item ${selectedCat.slug === item.slug ? "active" : ""}`} 
              key={i}>
              {item.name.replace("&amp;", "&")}
              </option>
            })
            }
            </select>
          </div>
          ):('')}
          {current_blog_post.length > 1 && <div className="card_heading_content" >
            <h2 tabindex="0" className="brand_heading">Tri Pointe Homes Blog</h2>
            <p tabindex="0" className="latest_post_heading">The Latest</p>
          </div>}
          <div className="blog_card_container">
            {current_blog_post.slice(1).map((item, i) => {
              return <div  className="blog_card" key={i}>
                {item.post_thumbnail_url ? (
                  <Link to={`/blog/${item.post_name}/`}>
                    <div className="blog_card_img">
                      <img src={item.post_thumbnail_url} title={item.image_title} alt={item.image_alt ? item.image_alt : item.image_title} />
                    </div>
                  </Link>
                ) : ('')}
                <div className="blog_card_content">
                  {selectedCat.name ? (
                    <div tabindex="0" className="blog_card_category">
                      {selectedCat.name}
                    </div>
                  ) : ('')}
                  {item.post_title ? (
                    <div tabindex="0" className="blog_card_content_caption">
                      <h3><Link to={`/blog/${item.post_name}/`}>{item.post_title}</Link></h3>
                    </div>
                  ) : ('')}
  
                    <div className="blog_card_content_para">
                      <div tabindex="0" dangerouslySetInnerHTML={{ __html: item.summary.replace(/(?:\r\n|\r|\n)/g, '') }} />
                    </div>
                  
                  {item.post_name ? (
                    <div className="read_more_btn">
                      <Link to={`/blog/${item.post_name}/`} className="read_more">Read More</Link>
                    </div>
                  ) : ('')}
                </div>
              </div>
            })}
          </div>
        </div>
            </>
      );
  }
}

export default Blogs;