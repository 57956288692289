import React from 'react';
import {Nav,Navbar,Form,Button} from 'react-bootstrap';
import { Modal } from 'react-bootstrap'
import {Link,Events} from 'react-scroll';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';
import './SubNavbar.scss';
import ScheduleTourForm from './ScheduleTourForm.jsx';
import '../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'

class SubNavbar extends React.Component {  
  constructor(){
    super();
    this.state = {
      showHide : false
    }
  }

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide })
    // console.log("==== resetIframe ====");
    this.forceUpdate();
  }
	componentDidMount() {
    // if(window.innerWidth < 1025){
    //   $(".navbar-toggler").on('click',function(){
    //     $("body").toggleClass("body_overflow");
    //     $(".sub-navbar").toggleClass("navbar-height");
    //   });
    //   $(".navbar-collapse a").on('click',function(){
    //     $(".navbar-toggler").trigger("click");
    //     $("body").removeClass("body_overflow");
    //     $(".sub-navbar").removeClass("navbar-height");
    //   }); 
    // }
    // Events.scrollEvent.register('begin', function(to, element) {
    //   if(to==='comm-qmi-tab'){
    //     if(document.getElementsByClassName('comm-qmi-tab').length > 0){
    //       $(".comm-qmi-tab").trigger("click");
    //     }
    //   }
    //   if(to==='plan-card'){
    //     if(document.getElementsByClassName('plan-card').length > 0){
    //       $(".plan-card").trigger("click");
    //     }
    //   }
    // }); 
   
        
    if(window.location.search.indexOf("neighborhood") > -1 && document.getElementsByClassName('neigh-card-section').length > 0){
        $('html, body').animate({
          scrollTop: $(".neigh-card-section").offset().top
        }, 1000);
    }
    if(window.location.search.indexOf("?tab=qmi") > -1 && document.getElementsByClassName('qmi-card-section').length > 0){
			setTimeout(() => {
        $('html, body').animate({
            scrollTop: $(".qmi-card-section").offset().top
        }, 1000);
      }, 1000);
    }
    if(window.location.search.indexOf("?tab=plan") > -1 && document.getElementsByClassName('residence-card-section').length > 0){
			setTimeout(() => {
        $('html, body').animate({
            scrollTop: $(".residence-card-section").offset().top
        }, 1000);
      }, 1000);
    }
    if(window.location.href.indexOf("?sitemap") > -1 && this.props.posttype!=="qmi") {
      if(this.props.community_details.sitemap_value === 1){
        document.getElementById("sitemap-link").click();
      }
    }
  }
  breadcrumb_schema(breadcrumb_details,posttype){
    if (breadcrumb_details != null) {
      if (breadcrumb_details.breadcrumb_details.breadcrumb_state) {
        var brb_state_name = breadcrumb_details.breadcrumb_details.breadcrumb_state.state_abv.toUpperCase();
        var brb_state_url = breadcrumb_details.breadcrumb_details.breadcrumb_state.state_link;
      }
      if (breadcrumb_details.breadcrumb_details.breadcrumb_submarket) {
        var brb_submarket_url = breadcrumb_details.breadcrumb_details.breadcrumb_submarket.submarket_link;
        var brb_submarket_title = breadcrumb_details.breadcrumb_details.breadcrumb_submarket.submarket_title;
      }
      if (breadcrumb_details.breadcrumb_details.breadcrumb_city) {
        var brb_city_title = breadcrumb_details.breadcrumb_details.breadcrumb_city.city_title;
        var brb_city_link = breadcrumb_details.breadcrumb_details.breadcrumb_city.city_link;
      }
      if (breadcrumb_details.breadcrumb_details.breadcrumb_comm) {
        var brb_comm_link = breadcrumb_details.breadcrumb_details.breadcrumb_comm.comm_link;
        var brb_comm_title = breadcrumb_details.breadcrumb_details.breadcrumb_comm.comm_title;
      }
      if (breadcrumb_details.breadcrumb_details.breadcrumb_current_link && breadcrumb_details.breadcrumb_details.breadcrumb_current_title) {
        var brb_current_link = breadcrumb_details.breadcrumb_details.breadcrumb_current_link;
        var brb_current_title = breadcrumb_details.breadcrumb_details.breadcrumb_current_title;
      }
      if (breadcrumb_details.breadcrumb_details.breadcrumb_neigh) {
        var brb_neigh_title = breadcrumb_details.breadcrumb_details.breadcrumb_neigh.neigh_title;
        var brb_neigh_link = breadcrumb_details.breadcrumb_details.breadcrumb_neigh.neigh_link;
      }
    }
    if (posttype === 'community') {
      const current_location = window.location.href ;
      if (current_location.includes('/directions')) {
        const script = document.createElement("script");
    // Structure Data script start
    script.type = "application/ld+json";
    script.innerHTML = `{
      "@context": "https://schema.org/", 
      "@type": "BreadcrumbList", 
      "itemListElement": [{
        "@type": "ListItem", 
        "position": 1, 
        "name": "${brb_state_name}",
        "item": "https://www.tripointehomes.com${brb_state_url}"  
      },{
        "@type": "ListItem", 
        "position": 2, 
        "name": "${brb_submarket_title}",
        "item": "https://www.tripointehomes.com${brb_submarket_url}"  
      },{
        "@type": "ListItem", 
        "position": 3, 
        "name": "${brb_city_title}",
        "item": "https://www.tripointehomes.com${brb_city_link}"  
      },{
        "@type": "ListItem", 
        "position": 4, 
        "name": "${brb_current_title}",
        "item": "https://www.tripointehomes.com${brb_current_link}"  
      },{
        "@type": "ListItem", 
        "position": 5, 
        "name": "Directions",
        "item": "https://www.tripointehomes.com${brb_current_link}directions/"  
      }
    ]
    }
    `;
    //  Structure Data script end
    document.head.appendChild(script);
      }else{
        const script = document.createElement("script");
    // Structure Data script start
    script.type = "application/ld+json";
    script.innerHTML = `{
      "@context": "https://schema.org/", 
      "@type": "BreadcrumbList", 
      "itemListElement": [{
        "@type": "ListItem", 
        "position": 1, 
        "name": "${brb_state_name}",
        "item": "https://www.tripointehomes.com${brb_state_url}"  
      },{
        "@type": "ListItem", 
        "position": 2, 
        "name": "${brb_submarket_title}",
        "item": "https://www.tripointehomes.com${brb_submarket_url}"  
      },{
        "@type": "ListItem", 
        "position": 3, 
        "name": "${brb_city_title}",
        "item": "https://www.tripointehomes.com${brb_city_link}"  
      },{
        "@type": "ListItem", 
        "position": 4, 
        "name": "${brb_current_title}",
        "item": "https://www.tripointehomes.com${brb_current_link}"  
      }]
    }
    `;
    //  Structure Data script end
    document.head.appendChild(script);
      }
      
    }
    if (posttype === 'neighborhood') {
      const current_location = window.location.href ;
     if (brb_comm_link && brb_comm_title) {
       if (current_location.includes('/directions')) {
        const script = document.createElement("script");
        // Structure Data script start
        script.type = "application/ld+json";
        script.innerHTML = `{
          "@context": "https://schema.org/", 
          "@type": "BreadcrumbList", 
          "itemListElement": [{
            "@type": "ListItem", 
            "position": 1, 
            "name": "${brb_state_name}",
            "item": "https://www.tripointehomes.com${brb_state_url}"  
          },{
            "@type": "ListItem", 
            "position": 2, 
            "name": "${brb_submarket_title}",
            "item": "https://www.tripointehomes.com${brb_submarket_url}"  
          },{
            "@type": "ListItem", 
            "position": 3, 
            "name": "${brb_city_title}",
            "item": "https://www.tripointehomes.com${brb_city_link}"  
          },{
            "@type": "ListItem", 
            "position": 4, 
            "name": "${brb_comm_title}",
            "item": "https://www.tripointehomes.com${brb_comm_link}"  
          },{
            "@type": "ListItem", 
            "position": 5, 
            "name": "${brb_current_title}",
            "item": "https://www.tripointehomes.com${brb_current_link}"  
          },{
            "@type": "ListItem", 
            "position": 6, 
            "name": "Directions",
            "item": "https://www.tripointehomes.com${brb_current_link}directions/"  
          }]
        }
        `;
        //  Structure Data script end
        document.head.appendChild(script);
       }else{
        const script = document.createElement("script");
      // Structure Data script start
      script.type = "application/ld+json";
      script.innerHTML = `{
        "@context": "https://schema.org/", 
        "@type": "BreadcrumbList", 
        "itemListElement": [{
          "@type": "ListItem", 
          "position": 1, 
          "name": "${brb_state_name}",
          "item": "https://www.tripointehomes.com${brb_state_url}"  
        },{
          "@type": "ListItem", 
          "position": 2, 
          "name": "${brb_submarket_title}",
          "item": "https://www.tripointehomes.com${brb_submarket_url}"  
        },{
          "@type": "ListItem", 
          "position": 3, 
          "name": "${brb_city_title}",
          "item": "https://www.tripointehomes.com${brb_city_link}"  
        },{
          "@type": "ListItem", 
          "position": 4, 
          "name": "${brb_comm_title}",
          "item": "https://www.tripointehomes.com${brb_comm_link}"  
        },{
          "@type": "ListItem", 
          "position": 5, 
          "name": "${brb_current_title}",
          "item": "https://www.tripointehomes.com${brb_current_link}"  
        }]
      }
      `;
      //  Structure Data script end
      document.head.appendChild(script);
       }
     }else{
       if (current_location.includes('/directions')) {
        const script = document.createElement("script");
        // Structure Data script start
        script.type = "application/ld+json";
        script.innerHTML = `{
          "@context": "https://schema.org/", 
          "@type": "BreadcrumbList", 
          "itemListElement": [{
            "@type": "ListItem", 
            "position": 1, 
            "name": "${brb_state_name}",
            "item": "https://www.tripointehomes.com${brb_state_url}"  
          },{
            "@type": "ListItem", 
            "position": 2, 
            "name": "${brb_submarket_title}",
            "item": "https://www.tripointehomes.com${brb_submarket_url}"  
          },{
            "@type": "ListItem", 
            "position": 3, 
            "name": "${brb_city_title}",
            "item": "https://www.tripointehomes.com${brb_city_link}"  
          },{
            "@type": "ListItem", 
            "position": 4, 
            "name": "${brb_current_title}",
            "item": "https://www.tripointehomes.com${brb_current_link}"  
          },{
            "@type": "ListItem", 
            "position": 5, 
            "name": "Directions",
            "item": "https://www.tripointehomes.com${brb_current_link}directions/"  
          }]
        }
        `;
        //  Structure Data script end
        document.head.appendChild(script);
       }else{
        const script = document.createElement("script");
        // Structure Data script start
        script.type = "application/ld+json";
        script.innerHTML = `{
          "@context": "https://schema.org/", 
          "@type": "BreadcrumbList", 
          "itemListElement": [{
            "@type": "ListItem", 
            "position": 1, 
            "name": "${brb_state_name}",
            "item": "https://www.tripointehomes.com${brb_state_url}"  
          },{
            "@type": "ListItem", 
            "position": 2, 
            "name": "${brb_submarket_title}",
            "item": "https://www.tripointehomes.com${brb_submarket_url}"  
          },{
            "@type": "ListItem", 
            "position": 3, 
            "name": "${brb_city_title}",
            "item": "https://www.tripointehomes.com${brb_city_link}"  
          },{
            "@type": "ListItem", 
            "position": 4, 
            "name": "${brb_current_title}",
            "item": "https://www.tripointehomes.com${brb_current_link}"  
          }]
        }
        `;
        //  Structure Data script end
        document.head.appendChild(script);
       }
      
     }
    }
    if (posttype === 'qmi') {
      if (brb_comm_link && brb_comm_title) {
        const script = document.createElement("script");
    // Structure Data script start
    script.type = "application/ld+json";
    script.innerHTML = `{
      "@context": "https://schema.org/", 
      "@type": "BreadcrumbList", 
      "itemListElement": [{
        "@type": "ListItem", 
        "position": 1, 
        "name": "${brb_state_name}",
        "item": "https://www.tripointehomes.com${brb_state_url}"  
      },{
        "@type": "ListItem", 
        "position": 2, 
        "name": "${brb_submarket_title}",
        "item": "https://www.tripointehomes.com${brb_submarket_url}"  
      },{
        "@type": "ListItem", 
        "position": 3, 
        "name": "${brb_city_title}",
        "item": "https://www.tripointehomes.com${brb_city_link}"  
      },{
        "@type": "ListItem", 
        "position": 4, 
        "name": "${brb_comm_title}",
        "item": "https://www.tripointehomes.com${brb_comm_link}"  
      },{
        "@type": "ListItem", 
        "position": 5, 
        "name": "${brb_neigh_title}",
        "item": "https://www.tripointehomes.com${brb_neigh_link}"  
      },{
        "@type": "ListItem", 
        "position": 6, 
        "name": "${brb_current_title}",
        "item": "https://www.tripointehomes.com${brb_current_link}"  
      }]
    }
    `;
    //  Structure Data script end
    document.head.appendChild(script);
      }else{
        const script = document.createElement("script");
    // Structure Data script start
    script.type = "application/ld+json";
    script.innerHTML = `{
      "@context": "https://schema.org/", 
      "@type": "BreadcrumbList", 
      "itemListElement": [{
        "@type": "ListItem", 
        "position": 1, 
        "name": "${brb_state_name}",
        "item": "https://www.tripointehomes.com${brb_state_url}"  
      },{
        "@type": "ListItem", 
        "position": 2, 
        "name": "${brb_submarket_title}",
        "item": "https://www.tripointehomes.com${brb_submarket_url}"  
      },{
        "@type": "ListItem", 
        "position": 3, 
        "name": "${brb_city_title}",
        "item": "https://www.tripointehomes.com${brb_city_link}"  
      },{
        "@type": "ListItem", 
        "position": 4, 
        "name": "${brb_neigh_title}",
        "item": "https://www.tripointehomes.com${brb_neigh_link}"  
      },{
        "@type": "ListItem", 
        "position": 5, 
        "name": "${brb_current_title}",
        "item": "https://www.tripointehomes.com${brb_current_link}"  
      }]
    }
    `;
    //  Structure Data script end
    document.head.appendChild(script);
      }
      
    }

  }

  sitemap_data_layer_push(param) {
      this.setState({ value:param })
      if(param && param==='Interactive_Sitemap') {
          window.dataLayer.push({'event':'interactiveSitemap'});
      }
  }
  render() {   
    var navbar_detail = this.props.navbar_detail;   
    var community_details = this.props.community_details;  
    var posttype = this.props.posttype;
    var direction_breadcrumb = this.props.direction_breadcrumb;
    var breadcrumb_details = posttype === "qmi" ? navbar_detail : community_details;
    if (breadcrumb_details) {
      this.breadcrumb_schema(breadcrumb_details,posttype);
    }
     return (  
          <div className="sub-navbar">  
               <Navbar collapseOnSelect expand="xl" aria-label="Primary">
                  <Nav className="left-nav">
                    {posttype==="qmi" ? [
                      <NavLink tabindex="0" to={navbar_detail.comm_permalink} exact className="nav-link active" title={navbar_detail.neigh_title}>{navbar_detail.neigh_title}</NavLink>
                    ]:[
                      <NavLink tabindex="0" to={community_details.comm_permalink} exact activeClassName='active' className="nav-link" title="Overview">Overview</NavLink>,
                      navbar_detail.neighborhood ? (                      
                        window.location.pathname.indexOf("directions") > -1 ?(
                          <NavLink tabindex="0" to={community_details.comm_permalink+"?tab=neighborhood"} exact className="nav-link"  title="Neighborhood"
                          onKeyPress={(e)=>{
                            if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                        }}>Neighborhoods</NavLink>
                        ):(
                          <Link tabindex="0" className="nav-link" to="neigh-card-section" title="Neighborhood"
                          onKeyPress={(e)=>{
                            if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                         }}
                          duration={750}
                          smooth={true}
                          spy={true}
                        >Neighborhoods</Link>
                        )				 
                      ):(''),
                      navbar_detail.residence && posttype==="neighborhood" && community_details.show_plan_tab === true ? (
                          window.location.pathname.indexOf("directions") > -1 ||  window.location.pathname.indexOf("reviews") > -1  ?(
                            <NavLink tabindex="0" to={community_details.comm_permalink+"?tab=plan"} exact activeClassName='active' className="nav-link"  title="Home Designs"
                            onKeyPress={(e)=>{
                              if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                          }}>Home Designs</NavLink>
                          ):(
                            <Link tabindex="0" className="nav-link" to="residence-card-section" title="Home Designs"
                            onKeyPress={(e)=>{
                              if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                            }}
                                      duration={750}
                                      smooth={true}
                                      spy={true}
                            >Home Designs</Link>
                          )
                      ):(''),
                      navbar_detail.qmi ? (
                          window.location.pathname.indexOf("directions") > -1 ||  window.location.pathname.indexOf("reviews") > -1 ?(
                            <NavLink tabindex="0" to={community_details.comm_permalink+"?tab=qmi"} exact activeClassName='active' className="nav-link"  title="Available Homes"
                            onKeyPress={(e)=>{
                              if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                          }}>Available Homes</NavLink>
                          ):(
                            <Link tabindex="0" className="nav-link" to="qmi-card-section" title="Available Homes"
                            onKeyPress={(e)=>{
                              if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                          }}
                                      duration={750}
                                      smooth={true}
                                      spy={true}
                            >Available Homes</Link>
                          )
                      ):(''),
                      community_details.sitemap_value === 1 ? (
                          <a href="javascript:void(0)" onClick={() => {this.handleModalShowHide(); this.sitemap_data_layer_push('Interactive_Sitemap');}} className="nav-link" id="sitemap-link" title="Community Map">Community Map</a>
                      ):(''), 
                      posttype === "neighborhood" ? (
                          community_details.jd_orders !== null ? (
                            community_details.jd_orders.reviews.length > 0 ? (
                              window.location.pathname.indexOf("directions") > -1 ? 
                              <NavLink tabindex="0" to={community_details.comm_permalink+"?tab=review"} exact activeClassName='active' className="nav-link"  title="Reviews"
                              onKeyPress={(e)=>{
                              if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                          }}>Reviews</NavLink> 
                              :
                              <Link tabindex="0" className="nav-link" to="review-main" title="Reviews"
                              onKeyPress={(e)=>{
                                if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                            }}
                                        duration={750}
                                        smooth={true}
                                        spy={true}
                              >Reviews</Link>
                              ):('')
                            ):('')
                      ):(''),
                      community_details.show_direction === true ? (
                        <NavLink tabindex="0" to={community_details.comm_permalink+"directions/"} exact activeClassName='active' className="nav-link" title="directions">Directions</NavLink>
                      ):('')
                    ]}
                  </Nav>
              </Navbar>

              <div className="breadcrumbs_section" aria-label="Secondary">
                <ul  className="breadcrumbs_list">
                    {
                      breadcrumb_details.breadcrumb_details.breadcrumb_state && breadcrumb_details.breadcrumb_details.breadcrumb_state.state_abv != '' ? [
                        <li><NavLink tabindex="0" to={breadcrumb_details.breadcrumb_details.breadcrumb_state.state_link} className="state_breadcrumb">{breadcrumb_details.breadcrumb_details.breadcrumb_state.state_abv}</NavLink></li>,
                        <li tabindex="0" className="breadcrumb_separator"><span>|</span></li>
                      ]:['']
                    }
                    {
                      breadcrumb_details.breadcrumb_details.breadcrumb_submarket && breadcrumb_details.breadcrumb_details.breadcrumb_submarket.submarket_title != '' ? [
                        <li><NavLink tabindex="0" to={breadcrumb_details.breadcrumb_details.breadcrumb_submarket.submarket_link} className="submarket_breadcrumb">{breadcrumb_details.breadcrumb_details.breadcrumb_submarket.submarket_title}</NavLink></li>,
                        <li tabindex="0" className="breadcrumb_separator"><span>|</span></li>
                      ]:['']
                    }
                    {
                      breadcrumb_details.breadcrumb_details.breadcrumb_city && breadcrumb_details.breadcrumb_details.breadcrumb_city.city_title != '' ? [
                        <li><NavLink tabindex="0" to={breadcrumb_details.breadcrumb_details.breadcrumb_city.city_link} className="city_breadcrumb">{breadcrumb_details.breadcrumb_details.breadcrumb_city.city_title}</NavLink></li>,
                        <li tabindex="0" className="breadcrumb_separator"><span>|</span></li>
                      ]:['']
                    }
                    {
                      direction_breadcrumb === 'direction_breadcrumb' ? 
                      posttype === 'community' ?
                          <>
                            {
                              breadcrumb_details.breadcrumb_details.breadcrumb_current_title && breadcrumb_details.breadcrumb_details.breadcrumb_current_title != '' ? [
                                <li><NavLink tabindex="0" to={breadcrumb_details.breadcrumb_details.breadcrumb_current_link} className="comm_breadcrumb">{breadcrumb_details.breadcrumb_details.breadcrumb_current_title}</NavLink></li>,
                                <li tabindex="0" className="breadcrumb_separator"><span>|</span></li>
                              ]:['']
                            }
                            {
                              <li><a tabindex="0" href="javascript:void(0)" className="current_breadcrumb">Directions</a></li>
                            } 
                            </>
                        : posttype === 'neighborhood' ?
                          <>
                          {
                          breadcrumb_details.breadcrumb_details.breadcrumb_comm && breadcrumb_details.breadcrumb_details.breadcrumb_comm.comm_title != '' ? [
                            <li><NavLink tabindex="0" to={breadcrumb_details.breadcrumb_details.breadcrumb_comm.comm_link} className="comm_breadcrumb">{breadcrumb_details.breadcrumb_details.breadcrumb_comm.comm_title}</NavLink></li>,
                            <li tabindex="0" className="breadcrumb_separator"><span>|</span></li>
                          ]:['']
                          }
                          {
                              breadcrumb_details.breadcrumb_details.breadcrumb_current_title && breadcrumb_details.breadcrumb_details.breadcrumb_current_title != '' ? [
                                <li><NavLink tabindex="0" to={breadcrumb_details.breadcrumb_details.breadcrumb_current_link} className="comm_breadcrumb">{breadcrumb_details.breadcrumb_details.breadcrumb_current_title}</NavLink></li>,
                                <li tabindex="0" className="breadcrumb_separator"><span>|</span></li>
                              ]:['']
                          }
                          { 
                            <li><a tabindex="0" href="javascript:void(0)" className="current_breadcrumb">Directions</a></li>
                          } 
                          </> : ''
                      :
                      <>
                      {
                        breadcrumb_details.breadcrumb_details.breadcrumb_comm && breadcrumb_details.breadcrumb_details.breadcrumb_comm.comm_title != '' ? [
                          <li><NavLink tabindex="0" to={breadcrumb_details.breadcrumb_details.breadcrumb_comm.comm_link} className="comm_breadcrumb">{breadcrumb_details.breadcrumb_details.breadcrumb_comm.comm_title}</NavLink></li>,
                          <li tabindex="0" className="breadcrumb_separator"><span>|</span></li>
                        ]:['']
                        }
                        {
                        breadcrumb_details.breadcrumb_details.breadcrumb_neigh && breadcrumb_details.breadcrumb_details.breadcrumb_neigh.neigh_title != '' ? [
                          <li><NavLink tabindex="0" to={breadcrumb_details.breadcrumb_details.breadcrumb_neigh.neigh_link} className="neigh_breadcrumb">{breadcrumb_details.breadcrumb_details.breadcrumb_neigh.neigh_title}</NavLink></li>,
                          <li tabindex="0" className="breadcrumb_separator"><span>|</span></li>
                        ]:['']
                        }
                        {
                        breadcrumb_details.breadcrumb_details.breadcrumb_current_title && breadcrumb_details.breadcrumb_details.breadcrumb_current_title != '' ? [
                          <li><a tabindex="0" href="javascript:void(0)" className="current_breadcrumb">{breadcrumb_details.breadcrumb_details.breadcrumb_current_title}</a></li>
                        ]:['']
                        } 
                      </>
                    }                                     
                </ul>
              </div>

              {posttype==="qmi" ? (''):(
                community_details.sitemap_value === 1 ? (
                    <Modal className="sitemap_modal" show={this.state.showHide}>
                        <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                        <Modal.Title>  
                        <h2 tabindex="0" >Interactive Community Map</h2>
                        {community_details.title && <p tabindex="0"  >{community_details.title}</p>}
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body> <iframe tabindex="0" src={community_details.sitemap_url} className="sitemap_iframe" title="sitemap iframe"></iframe></Modal.Body>
                    </Modal>
                ):('')
              )}
          </div>  
     );  
  }  
}  

export default SubNavbar; 