import React from 'react';
import { Modal } from 'react-bootstrap';
import './ContactBanner.scss';
class ContactBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            division_Modal_data: null,
        }
        this.handleDivisionClick = this.handleDivisionClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({ division_Modal_data: null, activeCategory: "" });
    }

    handleDivisionClick(obj, card_title) {
        var array = [];
        obj.division_list.forEach((item) => { array.push(...item.select_category) });
        array.unshift('all_department');
        var arr = [...new Set(array)];
        this.setState({
            division_Modal_data: { ...obj, card_title, categories: arr },
            activeCategory: 'all_department'
        });
    }
    render() {
        var contact_banner = this.props.contact_banner;
        var backgroundImage = contact_banner.contact_banner_image;
        var division_Modal_data = this.state.division_Modal_data;
        var window_width = window.screen.width;
        if (contact_banner && contact_banner.select_categories) {
            contact_banner.select_categories.all_department = 'All Departments';
        }
        if (backgroundImage !== "") {
            var background_image = {
                backgroundImage: 'url(' + backgroundImage + ')',
            }
        }
        return (
            <div className="contact-main">
                    <div className="desktop-contact-image-box-main">
                   { contact_banner.region_cards.length > 0 && <div className="image-box add_overlay" style={background_image}>
                            <div className="image-box-content">
                                {contact_banner.contact_headline ? (
                                    <h1 tabindex="0" className="image-heading">{contact_banner.contact_headline}</h1>
                                ) : ('')}
                                {contact_banner.contact_description ? (
                                    <h6 tabindex="0" className="image-description" dangerouslySetInnerHTML={{ __html: contact_banner.contact_description.replace(/(?:\r\n|\r|\n)/g, '') }}></h6>
                                ) : ('')}
                                <div className="cards-section">
                                    {contact_banner.region_cards.length > 0 && contact_banner.region_cards.map((items, key) => (
                                         items.divisions_data.length > 0 && items.card_title ? (
                                        <div className="region-card">
                                            {items.card_title ? (
                                                <div tabindex="0" className="card-heading">{items.card_title}</div>
                                            ) : ('')}
                                            <ul className="region-list">
                                                {items.divisions_data.length > 0 && items.divisions_data.map((obj, index) =>
                                                    <li tabindex="0" 
                                                    onKeyPress={(e)=>{
                                                        if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                                      }} 
                                                    onClick={() => this.handleDivisionClick(obj, items.card_title)}>
                                                        {obj.division_name == "Arizona" ? "Phoenix Metro" : obj.division_name == "Washington" ? "Seattle Metro" : obj.division_name }{obj.state_abbreviation ? `, ${obj.state_abbreviation}` : ''}
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                         ):('')
                                    ))}
                                </div>
                            </div>

                        </div> }

                    </div>
        
                {division_Modal_data && <Modal className="Estimate_modal Request_quote division_modal" show={division_Modal_data} onHide={this.handleClose} >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-cards-section">
                            <div className="modal-cards-headsection" >
                                {division_Modal_data.card_title ? (
                                    <div tabindex="0" className="region-title">{division_Modal_data.card_title}</div>
                                ) : ('')}
                                {division_Modal_data.division_name ? (
                                    <span tabindex="0" className="card-division_name">{division_Modal_data.division_name == "Arizona" ? "Phoenix Metro" : division_Modal_data.division_name == "Washington" ? "Seattle Metro" : division_Modal_data.division_name }</span>
                                ) : ('')}
                                {division_Modal_data.state_abbreviation ? (
                                    <span tabindex="0" className="card-state_abbreviation"><span className="comma">, </span>{division_Modal_data.state_abbreviation}</span>
                                ) : ('')}
                                {(window_width > 767) ? (
                                    <div className="category-list">
                                        {division_Modal_data.categories.map((cat) =>
                                            <span tabindex="0" className={this.state.activeCategory === cat ? "active_cat" : ""} 
                                            onKeyPress={(e)=>{
                                                if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                              }} 
                                            onClick={() => this.setState({ activeCategory: cat })}>
                                                {contact_banner.select_categories[cat]}
                                            </span>

                                        )}
                                    </div>
                                ) : ('')}
                            </div>
                            <div className="contact-info-div">
                                {division_Modal_data.division_list &&
                                    division_Modal_data.division_list.length &&
                                    division_Modal_data.division_list.map((item, key) => {
                                    var phone_number;
                                    if (item.contact && item.contact.indexOf('.') === -1) {
                                        var x = item.contact.split('');
                                        var y = x.splice(3, 0, '.');
                                        x.splice(7, 0, '.');
                                        phone_number = x.join('');
                                    } else {
                                        phone_number = item.contact;
                                    }
                                    return (item.select_category.includes(this.state.activeCategory) || this.state.activeCategory === 'all_department') && (
                                            item.name ? (
                                                <div className="contact-info" >
                                                    <div className="contact-name">
                                                        {item.name ? (
                                                            <h3 tabindex="0" className="title">{item.name}</h3>
                                                        ) : ('')}
                                                    </div>
                                                    {item.address ? (
                                                        <p tabindex="0" className="contact-text" dangerouslySetInnerHTML={{ __html: item.address.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                                    ) : ('')}
                                                    {phone_number ? (
                                                        <h6 tabindex="0">{phone_number}</h6>
                                                    ) : ('')}
                                                    {item.email_address ? (                                                  
                                                        <a className="email" href={"mailto:" + item.email_address.replace(/<br\s*\\?>/g, " ")} dangerouslySetInnerHTML={{ __html: item.email_address.replace(/(?:\r\n|\r|\n)/g, '') }}></a>
                                                    ) : ('')}
                                                    
                                                    {item.url ? (
                                                        <a tabindex="0" target="_blank" href={item.url} className="contact_url">{item.url}</a>
                                                    ) : ('')}
                                                </div>
                                            ) : ('')
                                        )
                                    })}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                }
            </div>
        );

    }
}
export default ContactBanner; 
