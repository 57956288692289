import React, { Suspense, lazy } from 'react'
const SearchPageContainer = lazy(() => import('../../Search/SearchPageContainer'));

class FYHPage extends React.Component {
    state = {
      intervalIdHeaderClass: null,      
    }
    //Find your home - sitelink search box
    sitelink_searchbox_structured_data(){
      const script = document.createElement("script");
      var parts = window.location.href.split("/");
      var last_part = parts[parts.length-1];
      var search_term_string= last_part.substring(0, last_part.indexOf('=')).replace("?","");
      
      // Structure Data script start 
      script.type = "application/ld+json";
      script.innerHTML = `{
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Tri Pointe Homes",
        "url":  "${window.location.origin}",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "${window.location.origin}/find-your-home/?${search_term_string}={search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }`
        //  Structure Data script end 
        document.head.appendChild(script);
    }
    componentDidMount(){
      this.sitelink_searchbox_structured_data();

      // add horizontal class to header for this page only
      // header is not present on first load
      const newIntervalId = setInterval(() => {
        const header = document.getElementsByClassName('header_section');
        if (header.length > 0) {
          clearInterval(this.state.intervalIdHeaderClass);
          header[0].classList.add('headerHorizontal');
        }
      }, 100);
      this.setState({ intervalIdHeaderClass: newIntervalId });
    }
    componentWillUnmount() {
      const header = document.getElementsByClassName('header_section');
      header[0].classList.remove('headerHorizontal');
    }
  render () {
    return (
      <Suspense fallback={null}>
        <SearchPageContainer />
      </Suspense>
    )
  }
}

export default FYHPage
