import React, { useEffect, useState } from 'react';
import { getAutocompleteHitUrl } from './helpers';
import IconCurrentLocation from '../ui/icons/IconCurrentLocation';
import { getSubmarketsSortByCoord } from '../Search/helpers/distance';
import useSearchSubmarkets from '../Search/hooks/useSearchSubmarkets';
import { setItem as setStorageItem, getItem as getStorageItem, removeItem as removeStorageItem } from '../Search/helpers/localStorage';

const EXPIRY_TIME = 1000 * 30 * 60; // 30 minutes

// this component unmounts when a query is started and remounts when there is no query
const AutocompleteCurrentPositionHit = () => {
	// prompt, granted, denied
	const [ status, setStatus ] = useState( null );
	const { submarketsByState } = useSearchSubmarkets();
	const [ submarketsByDistance, setSubmarketsByDistance ] = useState( [] );

	const handleDeny = () => {
		setStatus( 'denied' );
		removeStorageItem( 'geolocation' );
	};

	useEffect( () => {
		const requestCurrentPosition = async () => {
			if ( ! ( 'geolocation' in navigator ) ) {
				return;
			}
			// check local storage first if not expired - return first,
			// but also try to get current location in background, permissions could have changed.
			const storedCoords = await getStorageItem( 'geolocation' );
			if ( storedCoords && storedCoords.expiry > Date.now() ) {
				setStatus( 'granted' );
				const submarkets = getSubmarketsSortByCoord( { submarketsByState, lat: storedCoords.lat, lng: storedCoords.lng } );
				setSubmarketsByDistance( submarkets );
			}

			navigator.geolocation.getCurrentPosition( ( position ) => {
				setStatus( 'granted' );
				if ( submarketsByState.length === 0 ) {
					return;
				}
				const submarkets = getSubmarketsSortByCoord( { submarketsByState, lat: position.coords.latitude, lng: position.coords.longitude } );
				setSubmarketsByDistance( submarkets );
				setStorageItem( 'geolocation', { lat: position.coords.latitude, lng: position.coords.longitude, expiry: Date.now() + ( EXPIRY_TIME ) } );
			},
			function( error ) {
				if ( error.code === error.PERMISSION_DENIED ) {
					handleDeny();
				}
			} );
		};

		const handlePermissionsChange = ( result ) => {
			if ( result.state === 'granted' ) {
				setStatus( 'granted' );
			}
			// if waiting for location, just resolve
			if ( result.state === 'prompt' ) {
				removeStorageItem( 'geolocation' );
				setStatus( 'prompt' );
			}
			if ( result.state === 'denied' ) {
				handleDeny();
			}
		};

		const requestGeolocationPermissions = () => {
			// safari is undefined - but only using permissions only way to track if it changes
			if ( ! ( 'permissions' in navigator ) ) {
				return;
			}
			navigator.permissions.query( { name: 'geolocation' } ).then( ( result ) => {
				handlePermissionsChange( result );

				result.onchange = () => {
					handlePermissionsChange( result );
					requestCurrentPosition();
				};
			} );
		};

		// ask for permission to get location - to add change listener
		// either way, request current position to trigger prompt
		requestGeolocationPermissions();
		requestCurrentPosition();
	}, [ submarketsByState ] );

	if ( status === 'granted' && submarketsByDistance.length > 0 ) {
		return (
			<a className='tw-px-1 tw-mt-1 tw-py-3 tw-text-charcoal hover:tw-no-underline hover:tw-text-charcoal tw-flex tw-items-center tw-h-full hover:tw-bg-platinum/60' href={getAutocompleteHitUrl( { stateAbbr: submarketsByDistance[ 0 ]?.state.alpha_2_code.toLowerCase(), submarketSlug: submarketsByDistance[ 0 ]?.slug } )}>
				<IconCurrentLocation />
				<span className="tw-pl-2">Search by Current Location</span>
			</a>
		);
	}
	return (
		<div className='tw-px-1 tw-mt-1 tw-py-3 tw-text-charcoal tw-flex tw-items-center tw-h-full tw-cursor-text hover:tw-bg-transparent'>
			<IconCurrentLocation />
			<span className="tw-pl-2">{status === 'denied' || status === 'prompt' || status === null ? 'Grant browser permission to search by current location' : 'Loading ...'}</span>
		</div>
	);
};

export default AutocompleteCurrentPositionHit;
