import React, { Fragment } from 'react';
import { getAutocompleteHitUrl } from './helpers';

const StateHit = ( { item, onSelect } ) => {
	const handleClick = ( e ) => {
		e.preventDefault();
		e.stopPropagation();
		if ( onSelect ) {
			onSelect( { item } );
		}
	};

	return (
		<div className="tw-text-charcoal tw-flex tw-items-center tw-h-full tw-w-full">
			<button
				className="tw-text-charcoal tw-block tw-text-left tw-h-full tw-w-full"
				onClick={handleClick}
			>
				{item.name}
			</button>
		</div>
	);
};

const createStatesPlugin = ( { submarketsByState, ...rest } ) => {
	const states = submarketsByState
		.filter(s => s.cities.length > 0 || s.submarkets.length > 0)
		.map( ( state ) => {
			return { name: state.name, abbr: state.alpha_2_code };
		});

	return {
		...rest,
		limit: 3,
		getSources( props ) {
			const { setQuery, setContext, refresh, state } = props;

			const onSelectHit = ( { item } ) => {
				setQuery( item.name );
				setContext( { selectedState: item.abbr.toLowerCase() } );
				refresh();
			};
			return [
				{
					sourceId: 'pluginStates',
					getItems( { query } ) {
						if ( states.length === 0 || state.context.selectedState ) {
							return [];
						}
						const queryToMatch = query.toLowerCase().trim();
						return states.filter( ( { name, abbr } ) =>
							name.toLowerCase().includes( queryToMatch ) ||
							abbr.toLowerCase().includes( queryToMatch )
						);
					},
					onSelect( { item } ) {
						onSelectHit( { item } );
					},
					// keyboard
					getItemUrl( { item } ) {
						return getAutocompleteHitUrl( { stateAbbr: item.abbr.toLowerCase() } );
					},
					templates: {
						item( { item } ) {
							return <StateHit item={item} onSelect={onSelectHit} />;
						},
						header( { items } ) {
							if ( items.length === 0 ) {
								return <Fragment />;
							}
							return (
								<Fragment>
									<span className="aa-SourceHeaderTitle">States</span>
									<span className="aa-SourceHeaderLine" />
								</Fragment>
							);
						},
					},
				},
			];
		},
	};
};

export default createStatesPlugin;
