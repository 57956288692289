import qs from 'qs';
import React from 'react';
import cookie from 'react-cookies';
import './TPCPriorityGroupForm.scss';
import axios from 'axios';
import { apiConfig } from './Helper.jsx';

const { index, objectID, position, queryID } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

class TPCPriorityGroupForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          myData: null
        };
    }

    TpcloadMarketoForm() {
        const title = this.props.tpc_form_details.title;
        const community_id = this.props.tpc_form_details.community_id;
        const crm_salesforce_id = this.props.tpc_form_details.crm_salesforce_id;
        const division_name = this.props.division_name;
        const cookie_value = this.state.mkto_cookie;
        const division_text = this.props.tpc_form_details.division_text;
        const tph_division_code = this.props.tpc_form_details.tph_division_code;
        const post_status = this.props.tpc_form_details.post_status;
        const tpc_application_btn = this.props.tpc_form_details.tpc_application_btn;
        const tpc_confirmation_content = this.props.tpc_form_details.tpc_active_content;
        const tpc_disclaimer = this.props.tpc_form_details.tpc_disclaimer;

        if (window.MktoForms2 !== "undefined") {
            setTimeout(function () {
                window.MktoForms2.loadForm("//app-sj10.marketo.com", "167-BTV-942", 5492, function(form) {
                
                    form.onSuccess(function(values, followUpUrl) {
                        window.dataLayer.push({
                            'event': 'priorityGroupForm',
                            'userEmail': values.Email,
                            'userPhone': values.Phone,
                            'userFirstName': values?.FirstName,
                            'userLastName': values?.LastName,
                        });
                        var useremail = values.Email;
                        if (values) {
                
                            if(!values.Currently_Rent_or_Own__c){
                                values.Currently_Rent_or_Own__c = "''";
                            }
                            if(!values.Contingent__c) {
                                values.Contingent__c = "''";
                            }
                            if(!values.Estimated_Down_Payment__c) {
                                values.Estimated_Down_Payment__c = "''";
                            }
                            if(!values.Occupancy__c) {
                                values.Occupancy__c = "''";
                            }
                            if(!values.StateCode){
                                values.StateCode="''";
                            }
                            function fetchData() {
                                return new Promise((resolve, reject) => {
                                    axios.post(apiConfig().API_URL + '/get_marketo_lead_data/',{
                                        'get_type': "Lead",
                                        'activity_type' : "designStudio",
                                        'cookie' : cookie.load('_mkto_trk')
                                    }).then(response => response)
                                    .then(data => resolve(data))
                                    .catch(error => reject(error));
                                });
                            }


                            window.JQUERY('#tour_form #loading_content').css('display', 'block'); 
                            if (tpc_application_btn === false) {
                                window.JQUERY('#tour_form #button-dev').css('display', 'none');
                                window.JQUERY('#tour_form #loading_content').css('display', 'none');
                            }
                            else{
                                if(tph_division_code) {
                                    window.JQUERY('#tour_form #button-dev').css('display', 'none');
                                    window.JQUERY('#tour_form #loading_content').css('display', 'block');
                                    if (cookie.load('_mkto_trk') != '' && cookie.load('_mkto_email') === undefined) {
                                        let myData;
                                        console.log("Private User");
                                        setTimeout(() => {
                                            fetchData()
                                            .then(res => {
                                                myData = res.data.data;
                                                if (myData !== 'Lead Not Found') {
                                                    window.JQUERY('#tour_form #button-dev').css('display', 'flex');
                                                    window.JQUERY('#tour_form #loading_content').css('display', 'none');
                                                    let query_str= "jv=tpc.mla&divisionid=" + tph_division_code + "&externalapplicationid="+myData+"-"+crm_salesforce_id +"&communityid=" +community_id + "&fname=" + values.FirstName + "&lname=" + values.LastName + "&email=" + values.Email + "&phone=" + values.Phone + "&street=" + values.Address + "&city=" + values.City + "&zip=" + values.PostalCode + "&state=" + values.StateCode  + "&resType=" + values.Currently_Rent_or_Own__c + "&isSelling=" + values.Contingent__c.toUpperCase() + "&subjDownPay=" + values.Estimated_Down_Payment__c + "&subjType=" + values.Occupancy__c ;
                                                let en_str =  window.btoa(query_str);
                                                let de_str = window.atob(en_str);
                                                window.JQUERY('#tour_form #button-dev').attr("href", "https://apply.tripointeconnect.com/register/get-started?data="+query_str);
                                                }else{
                                                    window.JQUERY('#tour_form #button-dev').css('display', 'flex');
                                                    window.JQUERY('#tour_form #loading_content').css('display', 'none');
                                                    let query_str= "jv=tpc.mla&divisionid=" + tph_division_code +  "&communityid=" + community_id + "&fname=" + values.FirstName + "&lname=" + values.LastName + "&email=" + values.Email + "&phone=" + values.Phone + "&street=" + values.Address + "&city=" + values.City + "&zip=" + values.PostalCode + "&state=" + values.StateCode  + "&resType=" + values.Currently_Rent_or_Own__c + "&isSelling=" + values.Contingent__c.toUpperCase() + "&subjDownPay=" + values.Estimated_Down_Payment__c + "&subjType=" + values.Occupancy__c ;
                                                    let en_str =  window.btoa(query_str);
                                                    let de_str = window.atob(en_str);
                                                    window.JQUERY('#tour_form #button-dev').attr("href", "https://apply.tripointeconnect.com/register/get-started?data="+query_str);
                                                } 
                                        })
                                        .catch(error => console.error(error));
                                        }, 9000);
                                    }else if (cookie.load('_mkto_trk') != '' && cookie.load('_mkto_email') != '') {
                                        console.log("Both trk and email are present");
                                        if (cookie.load('_mkto_email') != useremail ) {
                                            console.log("Emails are different");
                                            let myData;
                                            setTimeout(() => {
                                            fetchData()
                                            .then(res => {
                                                myData = res.data.data;
                                                if (myData !== 'Lead Not Found') {
                                                    window.JQUERY('#tour_form #button-dev').css('display', 'flex');
                                                    window.JQUERY('#tour_form #loading_content').css('display', 'none');
                                                    let query_str= "jv=tpc.mla&divisionid=" + tph_division_code + "&externalapplicationid="+myData+"-"+crm_salesforce_id +"&communityid=" +community_id + "&fname=" + values.FirstName + "&lname=" + values.LastName + "&email=" + values.Email + "&phone=" + values.Phone + "&street=" + values.Address + "&city=" + values.City + "&zip=" + values.PostalCode + "&state=" + values.StateCode  + "&resType=" + values.Currently_Rent_or_Own__c + "&isSelling=" + values.Contingent__c.toUpperCase() + "&subjDownPay=" + values.Estimated_Down_Payment__c + "&subjType=" + values.Occupancy__c ;
                                                let en_str =  window.btoa(query_str);
                                                let de_str = window.atob(en_str);
                                                window.JQUERY('#tour_form #button-dev').attr("href", "https://apply.tripointeconnect.com/register/get-started?data="+query_str);
                                                }else{
                                                    window.JQUERY('#tour_form #button-dev').css('display', 'flex');
                                                    window.JQUERY('#tour_form #loading_content').css('display', 'none');
                                                    let query_str= "jv=tpc.mla&divisionid=" + tph_division_code +  "&communityid=" + community_id + "&fname=" + values.FirstName + "&lname=" + values.LastName + "&email=" + values.Email + "&phone=" + values.Phone + "&street=" + values.Address + "&city=" + values.City + "&zip=" + values.PostalCode + "&state=" + values.StateCode  + "&resType=" + values.Currently_Rent_or_Own__c + "&isSelling=" + values.Contingent__c.toUpperCase() + "&subjDownPay=" + values.Estimated_Down_Payment__c + "&subjType=" + values.Occupancy__c ;
                                                    let en_str =  window.btoa(query_str);
                                                    let de_str = window.atob(en_str);
                                                    window.JQUERY('#tour_form #button-dev').attr("href", "https://apply.tripointeconnect.com/register/get-started?data="+query_str);
                                                } 
                                            })
                                            .catch(error => console.error(error));
                                            }, 9000);
                                        }else{
                                            console.log('Emails are same');
                                            let myData;
                                            fetchData()
                                            .then(res => {
                                                myData = res.data.data;
                                                if (myData !== 'Lead Not Found') {
                                                    window.JQUERY('#tour_form #button-dev').css('display', 'flex');
                                                    window.JQUERY('#tour_form #loading_content').css('display', 'none');
                                                    let query_str= "jv=tpc.mla&divisionid=" + tph_division_code + "&externalapplicationid="+myData+"-"+crm_salesforce_id +"&communityid=" +community_id + "&fname=" + values.FirstName + "&lname=" + values.LastName + "&email=" + values.Email + "&phone=" + values.Phone + "&street=" + values.Address + "&city=" + values.City + "&zip=" + values.PostalCode + "&state=" + values.StateCode  + "&resType=" + values.Currently_Rent_or_Own__c + "&isSelling=" + values.Contingent__c.toUpperCase() + "&subjDownPay=" + values.Estimated_Down_Payment__c + "&subjType=" + values.Occupancy__c ;
                                                let en_str =  window.btoa(query_str);
                                                let de_str = window.atob(en_str);
                                                window.JQUERY('#tour_form #button-dev').attr("href", "https://apply.tripointeconnect.com/register/get-started?data="+query_str);
                                                }else{
                                                    window.JQUERY('#tour_form #button-dev').css('display', 'flex');
                                                    window.JQUERY('#tour_form #loading_content').css('display', 'none');
                                                    let query_str= "jv=tpc.mla&divisionid=" + tph_division_code +  "&communityid=" + community_id + "&fname=" + values.FirstName + "&lname=" + values.LastName + "&email=" + values.Email + "&phone=" + values.Phone + "&street=" + values.Address + "&city=" + values.City + "&zip=" + values.PostalCode + "&state=" + values.StateCode  + "&resType=" + values.Currently_Rent_or_Own__c + "&isSelling=" + values.Contingent__c.toUpperCase() + "&subjDownPay=" + values.Estimated_Down_Payment__c + "&subjType=" + values.Occupancy__c ;
                                                    let en_str =  window.btoa(query_str);
                                                    let de_str = window.atob(en_str);
                                                    window.JQUERY('#tour_form #button-dev').attr("href", "https://apply.tripointeconnect.com/register/get-started?data="+query_str);
                                                } 
                                            })
                                            .catch(error => console.error(error));
                                        }
                                    }
                                } else {
                                    window.JQUERY('#tour_form #button-dev').css('display', 'none');
                                    window.JQUERY('#tour_form #loading_content').css('display', 'block');
                                    if (cookie.load('_mkto_trk') != '' && cookie.load('_mkto_email') === undefined) {
                                        let myData;
                                        console.log("Private User");
                                        setTimeout(() => {
                                            fetchData()
                                            .then(res => {
                                                myData = res.data.data;
                                                if (myData !== 'Lead Not Found') {
                                                    window.JQUERY('#tour_form #button-dev').css('display', 'flex');
                                                    window.JQUERY('#tour_form #loading_content').css('display', 'none');
                                                    let query_str= "jv=tpc.mla&externalapplicationid="+myData+"-"+crm_salesforce_id +"&communityid=" +community_id + "&fname=" + values.FirstName + "&lname=" + values.LastName + "&email=" + values.Email + "&phone=" + values.Phone + "&street=" + values.Address + "&city=" + values.City + "&zip=" + values.PostalCode + "&state=" + values.StateCode  + "&resType=" + values.Currently_Rent_or_Own__c + "&isSelling=" + values.Contingent__c.toUpperCase() + "&subjDownPay=" + values.Estimated_Down_Payment__c + "&subjType=" + values.Occupancy__c ;
                                                let en_str =  window.btoa(query_str);
                                                let de_str = window.atob(en_str);
                                                window.JQUERY('#tour_form #button-dev').attr("href", "https://apply.tripointeconnect.com/register/get-started?data="+query_str);
                                                }else{
                                                    window.JQUERY('#tour_form #button-dev').css('display', 'flex');
                                                    window.JQUERY('#tour_form #loading_content').css('display', 'none');
                                                    let query_str= "jv=tpc.mla&communityid=" + community_id + "&fname=" + values.FirstName + "&lname=" + values.LastName + "&email=" + values.Email + "&phone=" + values.Phone + "&street=" + values.Address + "&city=" + values.City + "&zip=" + values.PostalCode + "&state=" + values.StateCode  + "&resType=" + values.Currently_Rent_or_Own__c + "&isSelling=" + values.Contingent__c.toUpperCase() + "&subjDownPay=" + values.Estimated_Down_Payment__c + "&subjType=" + values.Occupancy__c ;
                                                    let en_str =  window.btoa(query_str);
                                                    let de_str = window.atob(en_str);
                                                    window.JQUERY('#tour_form #button-dev').attr("href", "https://apply.tripointeconnect.com/register/get-started?data="+query_str);
                                                } 
                                        })
                                        .catch(error => console.error(error));
                                        }, 9000);
                                    }else if (cookie.load('_mkto_trk') != '' && cookie.load('_mkto_email') != '') {
                                        console.log("Both trk and email are present");
                                        if (cookie.load('_mkto_email') != useremail ) {
                                            console.log("Emails are different");
                                            let myData;
                                            setTimeout(() => {
                                            fetchData()
                                            .then(res => {
                                                myData = res.data.data;
                                                if (myData !== 'Lead Not Found') {
                                                    window.JQUERY('#tour_form #button-dev').css('display', 'flex');
                                                    window.JQUERY('#tour_form #loading_content').css('display', 'none');
                                                    let query_str= "jv=tpc.mla&externalapplicationid="+myData+"-"+crm_salesforce_id +"&communityid=" +community_id + "&fname=" + values.FirstName + "&lname=" + values.LastName + "&email=" + values.Email + "&phone=" + values.Phone + "&street=" + values.Address + "&city=" + values.City + "&zip=" + values.PostalCode + "&state=" + values.StateCode  + "&resType=" + values.Currently_Rent_or_Own__c + "&isSelling=" + values.Contingent__c.toUpperCase() + "&subjDownPay=" + values.Estimated_Down_Payment__c + "&subjType=" + values.Occupancy__c ;
                                                let en_str =  window.btoa(query_str);
                                                let de_str = window.atob(en_str);
                                                window.JQUERY('#tour_form #button-dev').attr("href", "https://apply.tripointeconnect.com/register/get-started?data="+query_str);
                                                }else{
                                                    window.JQUERY('#tour_form #button-dev').css('display', 'flex');
                                                    window.JQUERY('#tour_form #loading_content').css('display', 'none');
                                                    let query_str= "jv=tpc.mla&communityid=" + community_id + "&fname=" + values.FirstName + "&lname=" + values.LastName + "&email=" + values.Email + "&phone=" + values.Phone + "&street=" + values.Address + "&city=" + values.City + "&zip=" + values.PostalCode + "&state=" + values.StateCode  + "&resType=" + values.Currently_Rent_or_Own__c + "&isSelling=" + values.Contingent__c.toUpperCase() + "&subjDownPay=" + values.Estimated_Down_Payment__c + "&subjType=" + values.Occupancy__c ;
                                                    let en_str =  window.btoa(query_str);
                                                    let de_str = window.atob(en_str);
                                                    window.JQUERY('#tour_form #button-dev').attr("href", "https://apply.tripointeconnect.com/register/get-started?data="+query_str);
                                                } 
                                            })
                                            .catch(error => console.error(error));
                                            }, 9000);
                                        }else{
                                            console.log('Emails are same');
                                            let myData;
                                            fetchData()
                                            .then(res => {
                                                myData = res.data.data;
                                                if (myData !== 'Lead Not Found') {
                                                    window.JQUERY('#tour_form #button-dev').css('display', 'flex');
                                                    window.JQUERY('#tour_form #loading_content').css('display', 'none');
                                                    let query_str= "jv=tpc.mla&externalapplicationid="+myData+"-"+crm_salesforce_id +"&communityid=" +community_id + "&fname=" + values.FirstName + "&lname=" + values.LastName + "&email=" + values.Email + "&phone=" + values.Phone + "&street=" + values.Address + "&city=" + values.City + "&zip=" + values.PostalCode + "&state=" + values.StateCode  + "&resType=" + values.Currently_Rent_or_Own__c + "&isSelling=" + values.Contingent__c.toUpperCase() + "&subjDownPay=" + values.Estimated_Down_Payment__c + "&subjType=" + values.Occupancy__c ;
                                                let en_str =  window.btoa(query_str);
                                                let de_str = window.atob(en_str);
                                                window.JQUERY('#tour_form #button-dev').attr("href", "https://apply.tripointeconnect.com/register/get-started?data="+query_str);
                                                }else{
                                                    window.JQUERY('#tour_form #button-dev').css('display', 'flex');
                                                    window.JQUERY('#tour_form #loading_content').css('display', 'none');
                                                    let query_str= "jv=tpc.mla&communityid=" + community_id + "&fname=" + values.FirstName + "&lname=" + values.LastName + "&email=" + values.Email + "&phone=" + values.Phone + "&street=" + values.Address + "&city=" + values.City + "&zip=" + values.PostalCode + "&state=" + values.StateCode  + "&resType=" + values.Currently_Rent_or_Own__c + "&isSelling=" + values.Contingent__c.toUpperCase() + "&subjDownPay=" + values.Estimated_Down_Payment__c + "&subjType=" + values.Occupancy__c ;
                                                    let en_str =  window.btoa(query_str);
                                                    let de_str = window.atob(en_str);
                                                    window.JQUERY('#tour_form #button-dev').attr("href", "https://apply.tripointeconnect.com/register/get-started?data="+query_str);
                                                } 
                                            })
                                            .catch(error => console.error(error));
                                        }
                                    }
                                }
                            }
                            
                        } 

                        var cookieexpiry = new Date();
                        cookieexpiry.setFullYear(cookieexpiry.getFullYear() + 2);

                        //set cookie
                        document.cookie="_mkto_email="+useremail+"; expires="+cookieexpiry+"; path=/";
                        return false;
                    });

                    window.JQUERY('#tour_form .bord-p').html(tpc_disclaimer);
                    window.JQUERY('#mktoForm_5492 input[name="External_Lead_Id__c"]').val(community_id);
                    window.JQUERY('.headtxt').html(title);
                    window.JQUERY('#tour_form .logo > a:first-child').attr({href:window.location.origin,title:"Tri Pointe Homes", alt:"Tri Pointe Homes"});
                    window.JQUERY('#tour_form .logo img').attr({ title: "Tri Pointe Homes", alt: "Tri Pointe Homes" });
                    window.JQUERY('#tour_form .link a').attr({href:window.location.href.replace("#tour_form",''),title:title});
                    window.JQUERY('#tour_form .link').css('display', 'none');
                    window.JQUERY('#tour_form .dev-text,#tour_form .anchor-dev, #tour_form #button-dev').css('display', 'none'); 
                    window.JQUERY('#tour_form #button-dev').css('display', 'flex'); 
                    
                    if (division_text ) {
                        window.JQUERY('#tour_form .dev-text').css('display', 'block');    
                        window.JQUERY('#tour_form .dev-text').html(division_text);
                    } 
                });

                window.MktoForms2.whenReady(function(form) {
                    window.JQUERY("#mktoForm_5492").find('[name="Communities_of_Interest__c"]').val(crm_salesforce_id);
                    window.JQUERY("#mktoForm_5492").find('[name="Priority_Group_Community_Id__c"]').val(crm_salesforce_id);
                    window.JQUERY("#mktoForm_5492").find('[name="Most_Recent_Priority_Group_Entry__c"]').val(crm_salesforce_id);
                    window.JQUERY("#mktoForm_5492").find('[name="formURL"]').val(window.location.href);
                    window.JQUERY("#mktoForm_5492").find('[name="Division__c"]').val(division_name);
                    window.JQUERY("#mktoForm_5492").find('[name="communityName"]').val(title);
                    if (tpc_confirmation_content !== null) {
                        window.JQUERY("#mktoForm_5492 #ty_content").html(tpc_confirmation_content);
                    }

                    var formfields = form.vals();
                    var formfields_names = Object.keys(formfields);

                    var formfilterValue = cookie_value;
                    var outputArray = [];
                    for (var i = 0; i < formfields_names.length; i++){
                        if(formfields_names[i] && formfields_names[i]!='munchkinId' && formfields_names[i]!='formid'){
                            if ((window.JQUERY.inArray(formfields_names[i], outputArray)) == -1){
                                outputArray.push(formfields_names[i]);
                            }
                        }                               
                    }
                    const reducedArray = outputArray.reduce((fields_names, feilds) => `${fields_names}${feilds},` ,'')
                    var data = "";
                    var data = {
                        action: 'formnames',
                        requestData: reducedArray,
                        filterType:'cookies',
                        filterValue: formfilterValue
                    };
                    window.JQUERY.ajax({
                        type: 'POST',
                        data: data,
                        url: "/wp-admin/admin-ajax.php",
                        error: function(error) {
                            console.error(error)
                        },
                        success: function(response){
                            var field_havevalues = [];
                            var results = [];
                            results = JSON.parse(response);
                            var finalvaluestofill = {};
                            window.JQUERY.each(formfields_names,function(value, elem){
                                if(elem){
                                    var keys = elem.toLowerCase();
                                    if(keys in results){
                                        if(results[keys] && results[keys] != ''){
                                            finalvaluestofill[elem] = results[keys];
                                            field_havevalues.push(elem);
                                        }
                                    }
                                }
                            });

                            if (finalvaluestofill) {
                                form.vals(finalvaluestofill);
                            }
                        }
                    });

                });
            }, 4000)
        }
    }

    componentDidMount() {
        this.state = {
            mkto_cookie: cookie.load('_mkto_trk')
        };

        this.TpcloadMarketoForm();
        if(window.location.href.indexOf("#priority-group-form") > -1) {
            document.getElementById("tour_click_btn_show").click();
        }
    }

    render() {
        var thumbnail_image = this.props.tpc_form_details.tour_post_thumbnail;
        var tour_post_title = this.props.tpc_form_details.title;
        var priority_group_heading = this.props.tpc_form_details.priority_group_heading;
        var priority_group_description = this.props.tpc_form_details.priority_group_description;
        var thumbnail_image_title=this.props.tpc_form_details.tour_post_thumbnail_title;
        var thumbnail_image_alt=this.props.tpc_form_details.tour_post_thumbnail_alt;

        return (
            <div
                id="tour_form"
                className="tpc_form"
                data-insights-index={index}
                data-insights-object-id={objectID}
                data-insights-position={position}
                data-insights-query-id={queryID}
            >
                <div className="tourpop_form">
                    <div className="form-column">
                        <form id="mktoForm_5492"></form>
                    </div>

                    <div className="form-image">
                    <div className="form_image_overlay"/>
                        <picture>
                            <img tabindex="0" src={thumbnail_image} alt={thumbnail_image_alt ? thumbnail_image_alt : thumbnail_image_title} title={thumbnail_image_title} />
                        </picture>
                        <div className="priority_group_text">
                            {priority_group_heading && <div className="priority_group_heading" dangerouslySetInnerHTML={{ __html: priority_group_heading.replace(/(?:\r\n|\r|\n)/g, '') }} />}
                            {priority_group_description && <div className="priority_group_description" dangerouslySetInnerHTML={{ __html: priority_group_description.replace(/(?:\r\n|\r|\n)/g, '') }} />}    
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
}

export default TPCPriorityGroupForm
