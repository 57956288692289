import React from 'react'
import './ScheduleTour.scss'
import SectionHeader from './SectionHeader'

class ScheduleTour extends React.Component {
  componentDidMount() {
    var scrollPos = 0
    const staggered_data = this.props.staggered_image_content_block
    const section_id = staggered_data.section_id && staggered_data.section_id.trim()

    if (section_id) {
      setTimeout(function () {
        if (window.location.href.indexOf("#" + section_id) > -1) {
          document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth', block:'start' })
        }
      }, 2000)
    }

    window.JQUERY(document).on('scroll', function () {
      if (scrollPos === 0 && window.JQUERY("div").hasClass("schedule-tour")) {
        if (window.JQUERY(this).scrollTop() >= window.JQUERY('.schedule-tour').position().top - 100) {
          scrollPos++
        }
      }
    })
  }

  render() {
    var staggered_data = this.props.staggered_image_content_block
    var section_id = staggered_data.section_id && staggered_data.section_id.trim()
    var backgroundImage = staggered_data.main_image.url

    if (backgroundImage) {
      var background_image = {
        backgroundImage: 'url('+backgroundImage+')',
      }
    }

    var target_change;

    if (staggered_data.open_in_new_tab) {
      target_change = "_blank"
    } else {
      target_change = ""
    }

    return (
      staggered_data.pattern_image.url && staggered_data.headline && staggered_data.description ? (
        <div className="schedule-tour section-margin section-padding wrap" id={section_id}>
          <div className="two-image">
            <div className="image-section" style={background_image}>
              {staggered_data.pattern_image ? (
                <div className="image-building">
                  <img src={staggered_data.pattern_image.url} className="image-building-image" title={staggered_data.pattern_image.title} alt={staggered_data.pattern_image.alt ? staggered_data.pattern_image.alt : staggered_data.pattern_image.title}/>
                </div>
              ):('')}
            </div>
          </div>
            <div className="content-section">
              <SectionHeader
                eyebrow={staggered_data.sub_text}
                headline_part_1={staggered_data.headline}
                headline_part_2={staggered_data.headline_part_2}
                headline_part_1_color={staggered_data.headline_part_1_color}
                headline_part_2_color={staggered_data.headline_part_2_color}
                title_margin_bottom_small={true}
                headline_size={staggered_data.hasOwnProperty('headline_size') ? staggered_data.headline_size : 'large'}
              />

              {staggered_data.description ? (
                <div className="description" dangerouslySetInnerHTML={{__html:staggered_data.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
              ):('')}

              {staggered_data.cta_label && staggered_data.cta_link  ? (
                <a href={staggered_data.cta_link} target={target_change} className="button tour-schedule">
                  <span class="button__text">
                    {staggered_data.cta_label}
                  </span>
                </a>
              ) : ('')}
            </div>
        </div>
      ):('')
    )
  }
}

export default ScheduleTour