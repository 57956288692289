import React from 'react';
import axios from 'axios';
import { apiConfig } from '../../Classes/Helper.jsx';
import { Link } from 'react-router-dom'
import NoMatch from './NoMatch';
import { Redirect } from "react-router-dom";
import GlobalField from '../../Classes/GlobalField';
import ContactBanner from '../../Classes/ContactBanner';
import ContactSection from '../../Classes/ContactSection';
import SkeletonComp from '../../Classes/SkeletonComp.jsx';
import {Helmet} from "react-helmet";

class ContactInfo extends React.Component {
  state = {
    contact_data: [],
    success: false,
    message: '',
  }
  fetch_data() {
    axios.get(apiConfig().API_URL + '/contact_detail')
      .then(response => {
        this.setState({
          contact_data: response.data.data,
          success: response.data.success,
          message: response.data.message,
        })
      })
  }
  componentDidMount() {
    this.fetch_data();
  }
  render() {
    var success = this.state.success;
    var message = this.state.message;
    var contactdata = this.state.contact_data;
    var yoast_following = contactdata.yoast_following;
        var yoast_indexing = contactdata.yoast_indexing;
        var context_yoast = '';
        if (yoast_indexing === 'true' && yoast_following === 'true') {
        var context_yoast = 'index, follow';
        }else if (yoast_indexing === 'true' && yoast_following === 'false') {
        var context_yoast = 'index, nofollow';
        }else if (yoast_indexing === 'false' && yoast_following === 'true') {
        var context_yoast = 'noindex, follow';
        }else if (yoast_indexing === 'false' && yoast_following === 'false') {
        var context_yoast = 'noindex, nofollow';
        }
    return (  
        <>
      <Helmet>  
      <title>{contactdata.page_title}</title>
              <meta name="description" content={contactdata.page_desc} />
              <meta name="robots" content={context_yoast} />  
              <meta property="og:title" content={contactdata.page_title ? contactdata.page_title : ''} />
              <meta property="og:description" content={contactdata.page_desc ? contactdata.page_desc : ''} />
              <meta property="og:image" content={contactdata.yoast_image}/>
              <link rel="canonical" href={window.location.href} />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:type" content='Website' />
            </Helmet> 
      <div role="main" className="contact-main-section">
        {
          success === true ? [
            <ContactBanner contact_banner={contactdata.contact_info} />,
            <ContactSection contact_section={contactdata.contact_data} contact_modal={contactdata.contact_info} />,
            <GlobalField global_component={contactdata.component} />

          ] : [message != 'Not Completed' ? <SkeletonComp /> : <Redirect to={<NoMatch />} />]
        }
      </div>   
        </>
       );

  }
}

export default ContactInfo; 