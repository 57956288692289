// taken from existing site
const distance = ( lat1, lon1, lat2, lon2 ) => {
	const radlat1 = Math.PI * lat1 / 180;
	const radlat2 = Math.PI * lat2 / 180;
	const theta = lon1 - lon2;
	const radtheta = Math.PI * theta / 180;
	// eslint-disable-next-line no-mixed-operators
	let dist = Math.sin( radlat1 ) * Math.sin( radlat2 ) + Math.cos( radlat1 ) * Math.cos( radlat2 ) * Math.cos( radtheta );
	if ( dist > 1 ) {
		dist = 1;
	}
	dist = Math.acos( dist );
	dist = dist * 180 / Math.PI;
	dist = dist * 60 * 1.1515;
	dist = dist * 1.609344;
	return dist;
};

export const getSubmarketsSortByCoord = ( { submarketsByState, lat, lng } ) => {
	const submarkets = submarketsByState.map( ( state ) => {
		return state.submarkets.map( ( submarket ) => {
			return {
				...submarket,
				state: {
					alpha_2_code: state.alpha_2_code,
					name: state.name,
				},
			};
		} );
	} ).flat();

	const submarketsWithCoords = submarkets.filter( ( submarket ) => {
		return submarket.lat !== null && submarket.lng !== null;
	} );
	const submarketsWithDistance = submarketsWithCoords.map( ( submarket ) => {
		return {
			...submarket,
			dist: distance( lat, lng, submarket.lat, submarket.lng ),
		};
	} );

	// eslint-disable-next-line no-nested-ternary
	return submarketsWithDistance.sort( ( a, b ) => ( a.dist > b.dist ) ? 1 : ( ( b.dist > a.dist ) ? -1 : 0 ) );
};
