import React, { useState, useEffect } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-hooks-web';
import { config } from '../../Search/config/config';
import Hits from './Hits';
import NoResultsBoundary from './NoResultsBoundary';
import Tabs from './Tabs';
import { searchClient } from '../../Search/helpers/initAlgolia';
import { useDesktopMediaQuery } from '../../Search/hooks/useResponsive';
import { useSearchStateIndex, useSearchStateIndexDispatch } from '../../Search/hooks/useSearchStateIndex';
import NoResults from './NoResults';
import {useSearchStateHitCounts} from "./hooks/useSearchStateHitCounts";

const NearbyResults = ( { neighborhood_radius, home_radius, has_results } ) => {
	const dispatch = useSearchStateIndexDispatch();
	const isDesktop = useDesktopMediaQuery();
	const { activeIndexName } = useSearchStateIndex();
	const [ radius, setRadius ] = useState(neighborhood_radius);
	const { neighborhood_hits, home_hits } = useSearchStateHitCounts();

	useEffect(() => {
		dispatch({ type: 'changed', index: config.ALGOLIA_INDEX_DEFAULT });
	}, [] );

	useEffect(() => {
		if ( activeIndexName ) {
			setRadius(activeIndexName.includes('homes') ? home_radius : neighborhood_radius);
		}
	}, [ activeIndexName ] );

	useEffect(() => {
		has_results(!( 0 === neighborhood_hits && 0 === home_hits ));
	}, [neighborhood_hits, home_hits] );

	if ( ! activeIndexName || ( 0 === neighborhood_hits && 0 === home_hits ) ) {
		return null;
	}

	return (
		<InstantSearch searchClient={searchClient} indexName={activeIndexName}>
			<Configure
				analyticsTags={[
					config.ANALYTICS_TAGS.searchForm.nearbyResults,
					`Device: ${ isDesktop ? 'Desktop' : 'Mobile' }`,
				]}
				hitsPerPage={4}
				aroundRadius={radius}
				aroundLatLngViaIP={true}
				clickAnalytics
			/>
			<div data-insights-index={activeIndexName}>
				<div className={'tw-text-charcoal tw-font-normal'}>
					<h2 className={'tw-text-2xl lg:tw-text-3xl'}>Near Me</h2>
				</div>
				<Tabs />
				<div className={'tw-mt-6'}>
					<div className={! activeIndexName.includes( 'neighborhoods' ) ? 'tw-hidden' : ''}>
						<NoResultsBoundary fallback={<NoResults />}>
							<Hits hitType={'neighborhood'} activeIndex={activeIndexName} />
						</NoResultsBoundary>
					</div>
					<div className={! activeIndexName.includes( 'homes' ) ? 'tw-hidden' : ''}>
						<NoResultsBoundary fallback={<NoResults />}>
							<Hits hitType={'home'} activeIndex={activeIndexName} />
						</NoResultsBoundary>
					</div>
				</div>
			</div>
		</InstantSearch>
	);
};

export default NearbyResults;