import * as React from "react"

const IconDesignerCollection = ({...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={33}
    fill="none"
    {...props}
  >
    <circle cx={16.798} cy={16.73} r={16} className="group-hover/dc:tw-fill-white tw-fill-aloe tw-transition-colors tw-duration-500" />
    <mask
      id="a"
      width={21}
      height={21}
      x={6}
      y={6}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M6.798 6.73h20v20h-20z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#1C1B1F"
        d="m12.646 23.28-.14-1.185 2.139-5.862c.146.104.299.199.459.282.159.084.328.15.505.197l-2.07 5.76-.893.809Zm8.305 0-.893-.83-2.07-5.738c.177-.048.346-.113.506-.197a2.77 2.77 0 0 0 .458-.303l2.138 5.883-.14 1.186Zm-4.152-7.759a2.21 2.21 0 0 1-1.623-.669 2.21 2.21 0 0 1-.67-1.622c0-.57.174-1.055.52-1.456.348-.401.758-.654 1.23-.76V9.73h1.084v1.285c.473.104.883.357 1.23.759.347.4.52.886.52 1.456a2.21 2.21 0 0 1-.669 1.622 2.21 2.21 0 0 1-1.622.67Zm0-1.083c.336 0 .622-.117.856-.352.235-.234.352-.52.352-.856 0-.337-.117-.623-.352-.857a1.166 1.166 0 0 0-.857-.352c-.336 0-.622.117-.856.352-.235.234-.352.52-.352.857 0 .336.117.622.352.856.234.235.52.352.857.352Z"
      />
    </g>
  </svg>
)
export default IconDesignerCollection
