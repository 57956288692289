import React from 'react';
import './SubNavbar.scss';
import SidebarForm from './SidebarForm';
import $ from 'jquery';
class NhsDesktop extends React.Component {

    constructor(props) {
        super(props);

         this.load_sfdc_chat = this.load_sfdc_chat.bind(this)
        
    }

    componentDidMount() {

        $(".nhs-image, .mobile-pulse").on('click',function(){
            $(".sidebar-form").show();
        });
        $(".mobile-close").on('click',function(){
            $(".sidebar-form").hide();
        });
        setTimeout(() => {
            $(".sidebar-form").show();
        }, 10000);
        if($(".sidebar-form").length > 0 ){
            $(document).on('click touchstart',function(e){
                var class_name = e.target.className;
                if(class_name.indexOf("mobile-pulse") == -1 && class_name.indexOf("nhs-image") == -1){
                    var classList = e.target;
                    if($(classList).closest(".sidebar-form").length <= 0){
                        $(".sidebar-form").not(this).each(function(){
                            if($(this).css('display') == 'block'){
                                $(this).hide();
                            }
                        });
					}
                }
            })
        }

        if(this.props.sfdc_chat.sfdc_community_id != '' && this.props.sfdc_chat.sfdc_button_id != ''){
            window.SHOW_CHAT = true;
            if(window.SHOW_CHAT){
                if(window.JQUERY(".embeddedServiceSidebar").length > 0){
                    window.JQUERY(".embeddedServiceSidebar").show();
                }
                else{
                    window.JQUERY(".embeddedServiceHelpButton").show();
                }            
                this.load_sfdc_chat();
           }
        }

    }

    load_sfdc_chat(){
        var that = this;  
        var initESW = function(gslbBaseURL) {
            window.embedded_svc.settings.displayHelpButton = true; //Or false
            window.embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'
        
            window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
            window.embedded_svc.settings.entryFeature = 'LiveAgent';

            window.embedded_svc.settings.iframeURL = 'https://service.force.com/embeddedservice/5.0/esw.html?parent='+window.location.href;
        
            window.embedded_svc.settings.extraPrechatFormDetails = [
                {"label": "First Name", "transcriptFields": ["ContactFirstName__c"]},
                {"label": "Last Name", "transcriptFields": ["ContactLastName__c"]},
                {"label": "Email", "transcriptFields": ["ContactEmail__c"]},
                {"value": that.props.sfdc_chat.sfdc_community_id, "transcriptFields": ["CommunityCRMId__c"]},
                {"value": window.location.href, "transcriptFields": ["WebsiteURL__c"]},
                {"label": "Question", "transcriptFields": ["ContactQuestion__c"]},
                {"label": "Preferred Phone", "transcriptFields": ["ContactPhone__c"]}, 
                {"value": that.props.sfdc_chat.sfdc_deployment_id, "transcriptFields": ["LiveChatDeployment"]},
                {"value": that.props.sfdc_chat.sfdc_button_id, "transcriptFields": ["LiveChatButton"]},
                {"value": that.props.sfdc_chat.sfdc_eswliveagent_devname, "transcriptFields": ["ESWLiveAgentDevName__c"]},
                {"value": that.props.sfdc_chat.sfdc_embedded_chat_group, "transcriptFields": ["EmbeddedServiceDepName__c"]}
            ];
            
                //Added below code for not creating new Contact in the system if no match found for contact in Salesforce 
                window.embedded_svc.settings.extraPrechatInfo = [
                    {"entityFieldMaps":[
                        {"doCreate":false,"doFind":true,"fieldName":"LastName","isExactMatch":false,"label":"Last Name"},
                        {"doCreate":false,"doFind":true,"fieldName":"FirstName","isExactMatch":false,"label":"First Name"}, 
                        {"doCreate":false,"doFind":true,"fieldName":"Email","isExactMatch":false,"label":"Email"}],
                        "entityName":"Contact","showOnCreate":false}
                ];
            
                window.embedded_svc.init(
                'https://tripointegroup.my.salesforce.com',
                'https://tripointegroup.secure.force.com',
                gslbBaseURL,
                '00Dj0000001s1Gn',
                that.props.sfdc_chat.sfdc_embedded_chat_group,
                {
                    baseLiveAgentContentURL: 'https://c.la3-c1-ph2.salesforceliveagent.com/content',
                    deploymentId: that.props.sfdc_chat.sfdc_deployment_id,
                    buttonId: that.props.sfdc_chat.sfdc_button_id,
                    baseLiveAgentURL: 'https://d.la3-c1-ph2.salesforceliveagent.com/chat',
                    eswLiveAgentDevName: that.props.sfdc_chat.sfdc_eswliveagent_devname,
                    isOfflineSupportEnabled: false
                }
                );
        };
        
        if (!window.embedded_svc) {
            var s = document.createElement('script');
            s.setAttribute('src', 'https://tripointegroup.my.salesforce.com/embeddedservice/5.0/esw.min.js');
            s.onload = function() {
            initESW(null);
            };
            document.body.appendChild(s);
        } else {
            initESW('https://service.force.com');
        }
            
}
    render() {
        var agent_info = this.props.agent_info; 
        var sfdc_chat_details = this.props.sfdc_chat;
        var community_data = this.props.community_component; 
        var posttype = this.props.posttype;
        var pagetitle=this.props.nbd_title;
        var promo_title_color=this.props.promo_title_color;
        var promo_form=this.props.promo_form;
        var show_form=this.props.show_form;
        return (
            
            agent_info.agent_thumbnail ? [
                <div className={`nhs-desktop ${posttype === 'residence' ? 'hide_nhs_desktop' : ''}`}>
                    <div className="nhs-agent">
                        <img tabindex="0" src={agent_info.agent_thumbnail} alt={agent_info.image_alt ? agent_info.image_alt : agent_info.image_title} title={agent_info.image_title} className='nhs-image' />
                        {posttype === "promotion" ? <div className="pulse mobile-pulse" style={{backgroundColor:promo_title_color}}></div> : <div className="pulse mobile-pulse"></div>}
                    </div>
                    {posttype === 'qmi' ? (
                        <SidebarForm show_form={show_form} promo_form ={promo_form} agent_info={community_data.agent_info} post_status={community_data.qmi_status} posttype='qmi' tour_btn={community_data.tour_details} community_data={community_data} btn_color={promo_title_color}/>
                    ):(
                        <SidebarForm show_form={show_form} promo_form ={promo_form} agent_info={community_data.agent_info}  nbd_title={pagetitle} post_status={community_data.community_status} posttype={posttype} tour_btn={community_data.tour_details ? community_data.tour_details : ''} community_data={community_data} promo_popup_color ={promo_title_color} btn_color={promo_title_color}/>
                    )}
                </div>
            ]:['']

        );  
  }  
}  

export default NhsDesktop; 
