import React from 'react'
import { Link } from 'react-router-dom'
import { srcset } from './Helpers'
import './PlanCardItem.scss';

class PlanCardItem extends React.Component {
  componentDidMount() {
    window.lazy.update()
  }

  render() {
    const data = this.props.data

    const wrapStylesDefault = {
      '--wrap-padding-h-desktop': '0',
      '--wrap-padding-h-tablet': '0',
    }

    const wrapStylesLarge = {
      '--wrap-padding-h-desktop': '0',
    }

    const wrapStyles = this.props.large ? wrapStylesLarge : wrapStylesDefault

    return (
      <div
        className="PlanCardItem color-inherit no-underline hover-zoom">
        <figure className="aspect-ratio"
          style={{
            '--aspect-ratio-height': '806',
            '--aspect-ratio-width': '1188',
            '--aspect-ratio-height-mobile': '200',
            '--aspect-ratio-width-mobile': '291',
            'background-color': '#efefef',
          }}>
          {data.card_image[0] &&
            <Link to={data.post_permalink}>
            <img className="media-carousel__swiper-media-item lazy object-fit"
              data-srcset={srcset(data.card_image[0])}
              sizes="auto"
              alt={data.card_image_title[0] ? data.card_image_title[0] : data.card_image_title[1] || ''} />
            </Link>
          }
          {data.post_status &&
            <span className="badge position z-index"
              style={{
                '--badge-background-color': '#BED437',
                '--badge-border': 'none',
                '--badge-color': '#fff',
                '--badge-font-size': '11px',
                '--position': 'absolute',
                '--position-left': '24px',
                '--position-top': '24px',
              }}>
              {data.post_status}
            </span>
          }
          {data.promotion_info ?
            data.promotion_info.promo_snipe != '' ?
            <a href={data.promotion_info.promo_link}><span className="card-snipe" style={{backgroundColor:data.promotion_info.promo_snipe_color}
            }
            tabindex="0"
            onKeyPress={(e)=>{
                if(e.keyCode == 13 || e.which == 13) e.target.click();
            }}>{data.promotion_info.promo_snipe}</span></a>
            : ''
          : ''}
        </figure>

        <div className="wrap"
          style={wrapStyles}>
          <div className="flex padding-vertical"
            style={{
              '--flex-align-items-desktop': 'center',
              '--flex-direction-mobile': 'column',
              '--flex-justify-content-desktop': 'space-between',
              '--padding-vertical-top-desktop': '22px',
              '--padding-vertical-top-mobile': '16px',
              '--wrap-padding-h-desktop': '0',
              '--wrap-padding-h-tablet': '0',
            }}>
            <div className="font--medium"
              style={{
                'font-size': 'var(--media-card-title-font-size, 22px)',
                'flex-shrink': '0',
                'min-width': '70px',
                'margin-right': '10px',
              }}>
              {data.post_title}
            </div>

            {/* {data.post_status || data.price.value &&} */}
            <div className="margin-vertical text-align"
              style={{
                '--margin-vertical-bottom-desktop': '0',
                '--margin-vertical-bottom-mobile': '0',
                '--margin-vertical-top-desktop': '0',
                '--margin-vertical-top-mobile': '6px',
                '--text-align-mobile': 'left',
                '--text-align-dektop': 'right',
              }}>
              <span style={{ 'color': '#9EB50D', }}>{data.post_status} </span>{data.price_value}
            </div>
          </div>

          <div className="PlanCardItem__details media-card-details border-custom--off flex full-width margin-vertical padding-vertical"
            style={{
              '--border-color': '#DEDEDE',
              '--border-style': 'solid',
              '--border-width-desktop': '1px 0 0',
              '--border-width-mobile': '0',
              '--flex-align-items': 'stretch',
              '--flex-justify-content': 'space-between',
              '--flex-wrap': 'nowrap',
              '--margin-vertical-bottom-desktop': '0',
              '--margin-vertical-bottom-mobile': '0',
              '--margin-vertical-top-desktop': '22px',
              '--margin-vertical-top-mobile': '0',
              '--padding-vertical-bottom-desktop': '33px',
              '--padding-vertical-top-desktop': '22px',
              '--padding-vertical-bottom-mobile': '0',
              '--padding-vertical-top-mobile': '19px',
            }}>
            {data.square_feet &&
              <div className="hero-subnav__meta-item flex flex-shrink font-size font--medium padding-vertical text-center width"
                style={{
                  '--flex-align-items': 'center',
                  '--flex-direction-desktop': 'column',
                  '--flex-direction-mobile': 'column',
                  '--flex-justify-content': 'space-between',
                  '--font-size-desktop': '18px',
                  '--font-size-mobile': '14px',
                  '--padding-vertical-bottom-desktop': '5px',
                  '--padding-vertical-top-desktop': '5px',
                  '--padding-vertical-bottom-mobile': '6px',
                  '--padding-vertical-top-mobile': '6px',
                  '--width-mobile': 'auto',
                  '--width-desktop': '25%',
                }}>
                {data.square_feet}
                <div className="hero-subnav__meta-eyebrow eyebrow margin-vertical"
                  style={{
                    '--eyebrow-font-size': 'var(--hero-subnav-meta-item-eyebrow-font-size, 10px)',
                    '--margin-vertical-bottom-desktop': '0',
                    '--margin-vertical-bottom-mobile': '0px',
                    '--margin-vertical-top-desktop': '7px',
                    '--margin-vertical-top-mobile': '7px',
                  }}>
                  <span className="hide-desktop-medium">Sq. Ft</span>
                  <span className="show-desktop-medium">Sq. Footage</span>
                </div>
              </div>
            }

            {data.bed &&
              <>
                <div className="divider-line hide-desktop--off flex-shrink width"
                  style={{
                    '--height': '100%',
                    '--width': '1px',
                    'background-color': '#DEDEDE',
                  }}>
                </div>

                <div className="hero-subnav__meta-item border-custom--off flex flex-shrink font-size font--medium padding-vertical text-center width"
                  style={{
                    '--border-width-desktop': '0 0 0 1px',
                    '--border-width-mobile': '0',
                    '--flex-align-items': 'center',
                    '--flex-direction-desktop': 'column',
                    '--flex-direction-mobile': 'column',
                    '--flex-justify-content': 'space-between',
                    '--font-size-desktop': '18px',
                    '--font-size-mobile': '14px',
                    '--padding-vertical-bottom-desktop': '5px',
                    '--padding-vertical-top-desktop': '5px',
                    '--padding-vertical-bottom-mobile': '6px',
                    '--padding-vertical-top-mobile': '6px',
                    '--width-mobile': 'auto',
                    '--width-desktop': '25%',
                  }}>
                  {data.bed}
                  <div className="hero-subnav__meta-eyebrow eyebrow margin-vertical"
                    style={{
                      '--eyebrow-font-size': 'var(--hero-subnav-meta-item-eyebrow-font-size, 10px)',
                      '--margin-vertical-bottom-desktop': '0',
                      '--margin-vertical-bottom-mobile': '0',
                      '--margin-vertical-top-desktop': '7px',
                      '--margin-vertical-top-mobile': '7px',
                    }}>
                    <span className="hide-desktop-medium">Beds</span>
                    <span className="show-desktop-medium">Bedrooms</span>
                  </div>
                </div>
              </>
            }

            {data.bath &&
              <>
                <div className="divider-line hide-desktop--off flex-shrink width"
                  style={{
                    '--width': '1px',
                    'background-color': '#DEDEDE',
                  }}>
                </div>

                <div className="hero-subnav__meta-item border-custom--off flex flex-shrink font-size font--medium padding-vertical text-center width"
                  style={{
                    '--border-width-desktop': '0 0 0 1px',
                    '--border-width-mobile': '0',
                    '--flex-align-items': 'center',
                    '--flex-direction-desktop': 'column',
                    '--flex-direction-mobile': 'column',
                    '--flex-justify-content': 'space-between',
                    '--font-size-desktop': '18px',
                    '--font-size-mobile': '14px',
                    '--padding-vertical-bottom-desktop': '5px',
                    '--padding-vertical-top-desktop': '5px',
                    '--padding-vertical-bottom-mobile': '6px',
                    '--padding-vertical-top-mobile': '6px',
                    '--width-mobile': 'auto',
                    '--width-desktop': '25%',
                  }}>
                  {data.bath}
                  <div className="hero-subnav__meta-eyebrow eyebrow margin-vertical"
                    style={{
                      '--eyebrow-font-size': 'var(--hero-subnav-meta-item-eyebrow-font-size, 10px)',
                      '--margin-vertical-bottom-desktop': '0',
                      '--margin-vertical-bottom-mobile': '0px',
                      '--margin-vertical-top-desktop': '7px',
                      '--margin-vertical-top-mobile': '7px',
                    }}>
                    <span className="hide-desktop-medium">Baths</span>
                    <span className="show-desktop-medium">Bathrooms</span>
                  </div>
                </div>
              </>
            }

            {data.garage &&
              <>
                <div className="divider-line hide-desktop--off flex-shrink width"
                  style={{
                    '--width': '1px',
                    'background-color': '#DEDEDE',
                  }}>
                </div>

                <div className="hero-subnav__meta-item border-custom--off flex flex-shrink font-size font--medium padding-vertical text-center width"
                  style={{
                    '--border-width-desktop': '0 0 0 1px',
                    '--border-width-mobile': '0',
                    '--flex-align-items': 'center',
                    '--flex-direction-desktop': 'column',
                    '--flex-direction-mobile': 'column',
                    '--flex-justify-content': 'space-between',
                    '--font-size-desktop': '18px',
                    '--font-size-mobile': '14px',
                    '--padding-vertical-bottom-desktop': '5px',
                    '--padding-vertical-top-desktop': '5px',
                    '--padding-vertical-bottom-mobile': '6px',
                    '--padding-vertical-top-mobile': '6px',
                    '--width-mobile': 'auto',
                    '--width-desktop': '25%',
                  }}>
                  {data.garage}
                  <div className="hero-subnav__meta-eyebrow eyebrow margin-vertical"
                    style={{
                      '--eyebrow-font-size': 'var(--hero-subnav-meta-item-eyebrow-font-size, 10px)',
                      '--margin-vertical-bottom-desktop': '0',
                      '--margin-vertical-bottom-mobile': '0px',
                      '--margin-vertical-top-desktop': '7px',
                      '--margin-vertical-top-mobile': '7px',
                    }}>
                    Bay Garage
                  </div>
                </div>
              </>
            }
          </div>

          <Link to={data.post_permalink}>

          <span className="button button--hollow full-width margin-vertical"
            style={{
              '--btn-border': '1px solid var(--highlight-color)',
              '--margin-vertical-bottom-desktop': '0',
              '--margin-vertical-bottom-mobile': '0',
              '--margin-vertical-top-desktop': '33px',
              '--margin-vertical-top-mobile': '32px',
            }}>
              <span>View Details</span>
          </span>
          </Link>
        </div>
      </div>
    )
  }
}

export default PlanCardItem
