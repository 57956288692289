import React from 'react';
import BlogAndNews from './BlogAndNews';
import BuildLocations from './BuildLocations';
import FullImageBlock from './FullImageBlock';
import MortgageSection from './MortgageSection';
import Gallery from './Gallery';
import FocusBoxesHorizontal from './FocusBoxesHorizontal';
import FocusBoxesVertical from './FocusBoxesVertical';
import IframeModal from './IframeModal';
import ImageCopyGrid from './ImageCopyGrid';
import InfoGrid from './InfoGrid';
import LargeCopy from './LargeCopy';
import PhotoGalleryCarousel from './PhotoGalleryCarousel';
import PhotoGalleryGrid from './PhotoGalleryGrid';
import LivingSmart from './LivingSmart';
import SpringPromo from './SpringPromo';
import Testimonials from './Testimonials';
import VideoBlock from './VideoBlock';
import ScheduleTour from './ScheduleTour';
import BlogSection from './BlogSection';
import FourInfoBlock from './FourInfoBlock';
import FlexiableContent from './FlexiableContent';
import GridSection from './GridSection';
import GenericHero from './GenericHero';
import GenericBlock from './GenericBlock';
import SectionTitle from './SectionTitle';
import LivingSmartTabbed from './LivingSmartTabbed';
import BrokersFile from './BrokersFile';
import ContactUsBlock from './ContactUsBlock';
import MPC from './MPC';
import FaqSection from './faqSection';
import ReviewSection from './ReviewSection';
import ProductList from './ProductList';
import { FocusBoxForTable } from './FocusBoxForTable';
import GenericBlockWithTable from './GenericBlockWithTable';
import SelectSearchResults from '../GlobalComponents/SelectSearchResults/SelectSearchResults';
import Journey from './Journey';

class GlobalField extends React.Component {
  state = {
    global_component : [],
    success : false,
  }

  componentDidMount(){
    if(this.props.posttype !== 'community'){
      if(window.location.href.indexOf("?faq") > -1  && document.querySelector('.faq-section')) {
          document.querySelector('.faq-section').scrollIntoView({behavior:'smooth',block:'start'});
      }

    window.lazy.update()
      if(window.location.href.indexOf("?qmi") > -1 && document.getElementsByClassName('qmi-card-section').length > 0) {
        document.querySelector('.qmi-card-section').scrollIntoView({behavior:'smooth',block:'start'});
      }
    }
  }

  render() {
    var global_component =slice_start=slice_end="";
    var global_component_slice = this.props.global_component;
    var fullImage = this.props.fullImage;
    var community_details = this.props.community_details;
    var slice_start = this.props.slice_start;
    var slice_end = this.props.slice_end;
    var promo_list_form = this.props.promo_list_form;
    var sidebar_form = this.props.sidebar_form;
    var post_title = this.props.post_title;
    var communityOfInterest = this.props.communityOfInterest;
    var background_image = this.props.background_image;
    var promo_data = this.props.promo_data;
    const customTheme = this.props.customTheme;

    {slice_start || slice_end ? (
      global_component = global_component_slice.slice(slice_start,slice_end)
    ):(
      global_component = global_component_slice
    )}
    var review_title = this.props.review_title;
     return (
      <div class="global-components" role="complementary" aria-label="primary">
        { global_component ? [
        global_component.length > 0 ? (
          global_component.map(items => [
            items.acf_fc_layout === "journey" ? (
              <Journey field={items} />
            ):(''),
            items.acf_fc_layout === "focus_boxes_with_icons" ? (
              items.hasOwnProperty('display') && items.display === 'vertical' ? (
                <FocusBoxesVertical customTheme={customTheme} focus_data={items} section_id_fallback={this.props.hasOwnProperty('focus_boxes_section_id_fallback') ? this.props.focus_boxes_section_id_fallback : ''} />
              ):(
                items.grid_layout ?
                <FocusBoxesHorizontal customTheme={customTheme} data={items} section_id_fallback={this.props.hasOwnProperty('focus_boxes_section_id_fallback') ? this.props.focus_boxes_section_id_fallback : ''} /> : ''
              )
            ):(''),
            items.acf_fc_layout === "focus_boxes_with_table" ? (
              <FocusBoxForTable focus_table_data={items}/>
            ):(''),
            items.acf_fc_layout === "flexible_content_block" ? (
              <LivingSmart living_smart_data={items}/>
            ):(''),
            items.acf_fc_layout === "call_to_action" ? [
              <SpringPromo promo_data={items} promo_list_form={promo_list_form} sidebar_form={sidebar_form} background_image={background_image} communityOfInterest={communityOfInterest} post_title={post_title}/>,
              <Testimonials testimonial_data={items}/>
            ]:[''],
            items.acf_fc_layout === "inline_component" ? (
              <IframeModal iframe_data={items}/>
            ):(''),
            items.acf_fc_layout === "focus_boxes" ? (
              <GridSection grid_data={items}/>
            ):(''),
            items.acf_fc_layout === "staggered_image_content_block" ? (
              <ScheduleTour staggered_image_content_block={items}/>
            ):(''),
            items.acf_fc_layout === "video_block" ? (
              <VideoBlock video_data={items}/>
            ):(''),
            items.acf_fc_layout === "three_up_info_block" ? (
              <BlogSection three_block_data={items}/>
            ):(''),
            items.acf_fc_layout === "living_smart_tabbed" ? (
              <LivingSmartTabbed living_smart_tabbed={items}/>
            ):(''),
            items.acf_fc_layout === "four_up_info_block" ? (
              <FourInfoBlock four_block_data={items}/>
            ):(''),
            items.acf_fc_layout === "full_image_block" ? (
              <FullImageBlock full_image_block={items} fullImage={fullImage}/>
            ):(''),
            items.acf_fc_layout === "media_gallery" && items.display === 'grid' ? (
              <PhotoGalleryGrid data={items} />
            ):(''),
            items.acf_fc_layout === "media_gallery" && items.display !== 'grid' ? (
              <PhotoGalleryCarousel data={items} />
            ):(''),
            items.acf_fc_layout === "gallery_variation_2" ? (
              <Gallery gallery_v2={items}/>
            ):(''),
            items.acf_fc_layout === "diagonal_content_block" ? (
              <FlexiableContent blogs_data={items}/>
            ):(''),
            items.acf_fc_layout === "image_copy_grid" ? (
                <ImageCopyGrid customTheme={customTheme} data={items}/>
            ):(''),
            items.acf_fc_layout === "large_copy" ? (
              <LargeCopy data={items}/>
            ):(''),
            items.acf_fc_layout === "mortgage_calculator" ? (
              <MortgageSection mortgage_data={items} promo_data={promo_data}/>
            ):(''),
            items.acf_fc_layout === "generic_hero" ? (
              <GenericHero generic_hero={items}/>
            ):(''),
            items.acf_fc_layout === "generic_block" ?
              items.show_wp_table ?
              (
                <GenericBlockWithTable generic_block={items} target_change={false} hide_component={false}/>
              ) :
              (
              <GenericBlock generic_block={items} target_change={false} hide_component={false}/>
              ):(''),
            items.acf_fc_layout === "design_studio_block" ? (
              <GenericBlock generic_block={items} target_change={true} hide_component={items.hide_design_studio}/>
            ):(''),
            items.acf_fc_layout === "section_title" ? (
              <SectionTitle
                subtext={items.section_eyebrow_text}
                title_part_1={items.section_title_headline}
                title_part_2={items.headline_part_2}
                headline_part_1_color={items.headline_part_1_color}
                headline_part_2_color={items.headline_part_2_color}
                headline_size={items.hasOwnProperty('headline_size') ? items.headline_size : 'large'}
                section_id={items.section_id}
              />
            ):(''),
            items.acf_fc_layout === "brokers_details" ? (
              <BrokersFile brokers_details={items}/>
            ):(''),
            items.acf_fc_layout === "contact_us_block" ? (
              <ContactUsBlock contact_us_block={items}/>
            ) : (''),
            items.acf_fc_layout === "mpc_container" ? (
              <MPC mpc_block={items} community_details={community_details}/>
            ) : (''),
            items.acf_fc_layout === "global_faq_section" ? (
              <FaqSection faqs={this.props.hasOwnProperty('faqs') ? this.props.faqs : null} all_props={this.props} community_details={items}/>
            ) : (''),
            items.acf_fc_layout === "review_section" ? (
              community_details.jd_orders?[community_details.jd_orders.reviews?<ReviewSection community_details={community_details} review_section={items} review_title={review_title}/>:'' ]:''
            ) : (''),
            items.acf_fc_layout === "product_list" ? (
              <>
              {items.available_homess.available_homes_list ? <ProductList community_details={community_details} list={items.available_homess.available_homes_list} list_detail={items.available_homess} sort_order={items.available_homess.neigh_sort_by} eyebrow={items.available_homess.eyebrow_text_qmi} posttype='qmi'/>:''}
              {items.neighbpurhoods.neibhourhood_list ? <ProductList community_details={community_details} list={items.neighbpurhoods.neibhourhood_list} list_detail={items.neighbpurhoods } sort_order={items.neighbpurhoods.neigh_sort_by} eyebrow={items.neighbpurhoods.eyebrow_text_neigh}/>:''}
              {items.communities.communty_list ? <ProductList community_details={community_details} list={items.communities.communty_list} list_detail={items.communities } sort_order={items.communities.neigh_sort_by} eyebrow={items.communities.eyebrow_text_comm}/>:''}
              </>
            ) : (''),
            items.acf_fc_layout === "blog_and_news_feed" ? (
              <BlogAndNews
                blog_link={items.blog_link}
                blog_posts={items.blog_posts}
              />
            ):(''),
            items.acf_fc_layout === "build_locations" ? (
              <BuildLocations
                background_color={items.background_color}
                image={items.image}
                eyebrow_text={items.sub_text}
                eyebrow_text_color={items.eyebrow_text_color}
                headline={items.headline}
                headline_color={items.headline_color}
                state_text_color={items.state_text_color}
                submarket_text_color={items.submarket_text_color}
                states={items.states}
              />
            ):(''),
            items.acf_fc_layout === "info_grid" ? (
              <InfoGrid
                section_id={items.section_id}
                eyebrow_text={items.sub_text}
                eyebrow_color={items.eyebrow_color}
                headline_part_1={items.headline}
                headline_part_2={items.headline_part_2}
                headline_part_1_color={items.headline_part_1_color}
                headline_part_2_color={items.headline_part_2_color}
                description={items.description}
                cta_label={items.cta_label}
                cta_link={items.cta_link}
                grid_items={items.grid_section}
              />
            ) : (''),
            items.acf_fc_layout === "select_search_results" ? (
              <SelectSearchResults
                neighborhood_radius={items.radius}
                home_radius={items.radius}
              />
            ) : (''),
          ])
        ):('')
      ]:['']}
      </div>
     );
  }
}

export default GlobalField;
