import React from "react";
import axios from 'axios';
import {apiConfig} from '../Classes/Helper.jsx';
import { Link , useHistory } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from 'js-cookie';
import cookie from 'react-cookies'

const LoginButton = () => {

  const { user,loginWithRedirect,isAuthenticated} = useAuth0();
  const history = useHistory();


//   var user_account_layer = Cookies.get('user_account_layer');
//   if((user_account_layer == '' || user_account_layer == undefined) && isAuthenticated == true && user.sub != ''){
//     axios.post(apiConfig().API_URL+'/get_user_info', {
//       "user_id" : user.sub
//     },
//     {
//       headers: {
//         Authorization: 'Aa147147147#'
//       }
//     }
//     )
//     .then(response => {
//         if(response.data.data.login_count > 1){
//           window.dataLayer.push({
//             'event': 'accountLogin',
//           });
//         }
//         else if(response.data.data.login_count == 1){
//           window.dataLayer.push({
//             'event': 'createAccount',
//           });
//         }
//     })

//     console.log("Layer Done");
// }

  var last_url = Cookies.get('last_url');
  if(last_url != '' && last_url != undefined){

    history.push(last_url);
    Cookies.set('last_url','',{ expires: 2 })
  }

  //Account Login Data Layer Trigger
  var user_account_layer = Cookies.get('user_account_layer');
  if(user_account_layer != true && isAuthenticated == true && user.sub != ''){
      axios.post(apiConfig().API_URL+'/get_user_info', {
        "user_id" : user.sub
      },
      {
        headers: {
          Authorization: 'Aa147147147#'
        }
      }
      )
      .then(response => {
          if(response.data.data.login_count > 1){
            window.dataLayer.push({
              'event': 'accountLogin',
            });
          }
          else if(response.data.data.login_count == 1){
            window.dataLayer.push({
              'event': 'createAccount',
            });
          }
          Cookies.set('user_account_layer',true,{ expires: 1 })
      })
  }

  function fetch_data_for_activity() {
    return new Promise((resolve,reject)=>{
      axios.post(apiConfig().API_URL + '/get_marketo_lead_data/',{
        'get_type': "Activity",
        'activity_type' : "Create_Account",
        'cookie' : cookie.load('_mkto_trk'),
        'URL' : window.location.href,
        'value' : 'true'
      })
      .then(response => response)
      .then(data=> resolve(data))
      .catch(error => reject(error))
    })
  }

function create_button_click(){
    var current_url = window.location.href;
    if(current_url===window.location.origin+"/") {
      window.dataLayer.push({
        'event': 'DivisionName',
        'divisionName': 'Home Page'
      });
    }  else if(current_url.indexOf("find-your-home") > -1){
      window.dataLayer.push({
        'event': 'DivisionName',
        'divisionName': 'Find Your Home'
      });
    } else {
    var parts = current_url.split("/");
    var last_part = parts[parts.length-2];
    var str_uppercase = last_part.replace(/(^|' ')./g, s => s.slice(-1).toUpperCase());
    var result = str_uppercase.replace(/-/g, ' ');

    window.dataLayer.push({
      'event': 'DivisionName',
      'divisionName': result
    });
  }
  fetch_data_for_activity()
    .then(response => console.log(response))
    .catch(error => console.error(error))
}
  function  login_redirection(){
    Cookies.set('last_url',window.location.pathname,{ expires: 2 })
    loginWithRedirect();
  }

  return !isAuthenticated ? [
    <a href="javascript:void(0)" style={{ '--btn-border': '1px solid var(--highlight-color)' }} className="tw-w-full lg:tw-w-auto button" onClick={() => login_redirection()}><span>Sign In</span></a>,
    <a href="javascript:void(0)" className="tw-text-white tw-underline-offset-4 tw-font-semibold tw-block tw-tracking-widest tw-text-xs tw-uppercase tw-mt-4 lg:tw-mt-8" onClick={() => {create_button_click(); login_redirection()}}>Create Account</a> 
  ]
  : [
    <Link to="/my_account" className="tw-text-white tw-underline-offset-4 tw-font-semibold tw-block tw-tracking-widest tw-text-xs tw-uppercase">My Account</Link>
  ];
};

export default LoginButton;