import React from 'react'
import $ from 'jquery'
import Carousel from 'react-slick'
import FloorplanSave from './FloorplanSave'
import HeroSubNav from './HeroSubNav'
import PlanDetails from './PlanDetails'
import ScheduleTourForm from './ScheduleTourForm.jsx'
import StageScheduleTourForm from './StageScheduleTourForm.jsx'
import TPCPriorityGroupForm from './TPCPriorityGroupForm.jsx'
import StageTPCForm from './StageTPCForm.jsx'
import UTour from './UTour.jsx'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import './Residence.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'
import cookie from 'react-cookies';
import axios from 'axios';
import { apiConfig } from './Helper.jsx';
import StageQmiReserveForm from './StageQmiReserveForm'
import StageSidebarSignUp from './StageSidebarSignUp';
import QmiReserveForm from './QmiReserveForm'
import SidebarSignUp from './SidebarSignUp'
import SidebarSignUpNewForm from './SidebarSignUpNewForm'
import StageSidebarSignUpNewForm from './StageSidebarSignUpNewForm'
import getFormBtnCopy from '../../helpers/getFormBtnCopy.js'

class ResidenceScreen1 extends React.Component {
  constructor() {
    super()
    this.iframeRef = React.createRef();
    this.intervalID = null;
    this.i = 0;

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.fetchDataForVisualizer = this.fetchDataForVisualizer.bind(this)
  

    this.state = {
      show: null,
      showHide: false,
      innerModal: '',
      utourtour:true,
      reserveform:true,
    }
  }

  updateInnerModal(str) {
    this.setState({ innerModal: str })
  }
  updateInnerModal1(str) {
    this.setState({ innerModal: str });
    this.setState({utourtour :false})
  }
  handleModalShowHide1() {
    this.setState({utourtour :false})
}

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide })
  }

  handleClose() {
    this.setState({show: null});
  }

  handleShow(id) {
    this.setState({show: id});
  }

  componentDidMount() {
    $('.residence-header').removeClass('bdx-added');
    window.lazy.update()
    var mkto_confirm = cookie.load('_mkto_trk');
    if (mkto_confirm) {
    this.intervalID = window.setInterval(this.checkFocus, 100);
    }
    if (document.getElementById('custom_btn') != null) {
      console.log('custom btn');
      $("#custom_btn").find("#twoform").find('a').eq(0).addClass('button');
      $("#custom_btn").find("#twoform").find('a').eq(1).addClass('button');
    }
    const tourBtn = document.getElementById('tour_click_btn')
    if (tourBtn && window.location.href.includes("#tour-form")) {
      tourBtn.click()
    }
  }

  componentWillUnmount() {
    // Clear the interval when the component unmounts to avoid memory leaks
    clearInterval(this.intervalID);
  }
  PDFDownload_data_layer_push(param) {
    this.setState({ value:param })

    if (param && param==='PDFDownload') {
      window.dataLayer.push({'event':'PDFDownload'})
    }
  }

  fetchDataForVisualizer() {
    return new Promise((resolve, reject) => {
        axios.post(apiConfig().API_URL + '/get_marketo_lead_data/',{
            'get_type': "Activity",
            'activity_type' : "RoomVisualizer",
            'cookie' : cookie.load('_mkto_trk'),
            'URL' : window.location.href,
            'value' : 'clicked_visualizer'
        }).then(response => response)
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  checkFocus = async () => {
    if (document.activeElement === this.iframeRef.current) {
      console.log("clicked " + (this.i++));
      window.focus();

      try {
        var mkto_confirm = cookie.load('_mkto_trk');
        if (mkto_confirm) {
          const data = await this.fetchDataForVisualizer();
          // Handle the fetched data here
          console.log('Fetched Data:', data);
        }
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
      }

    }
  };

  fetchData() {
    return new Promise((resolve, reject) => {
        axios.post(apiConfig().API_URL + '/get_marketo_lead_data/',{
            'get_type': "Activity",
            'activity_type' : "EnvisionLink",
            'cookie' : cookie.load('_mkto_trk'),
            'URL' : window.location.href,
            'value' : 'envision_link_clicked'
        }).then(response => response)
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  EnvisionPresaleLaunch_data_layer_push(param){
    this.setState({ value:param })

    if (param && param==='EnvisionPresaleLaunch') {
      window.dataLayer.push({'event':'EnvisionPresaleLaunch'});
      var mkto_confirm = cookie.load('_mkto_trk');
        if (mkto_confirm) {
          this.fetchData()
              .then(data => {
                console.log(data);
              })
              .catch(error => console.error(error));
        }
    }
  }

  get_forms_btn = () => {
    var residence_component = this.props.residence_component;
    var tpc_form_btn = residence_component.common_detail.tpc_priority_group_form && residence_component.common_detail.tpc_priority_group_form.show_btn && residence_component.common_detail.hide_get_pre_qualified_cta !== true;
    var tpc =  false ;
    var rth = false ;
    var sat = false ;
    var it = false ;

    var isTPCStatus = ["Temporarily Sold Out",
    "Grand Opening",
    "Now Selling",
    "Limited Availability",
    "Final Homes Now Selling"].includes(residence_component.common_detail.neigh_status)
    var current_url = window.location.host;
    if (current_url === 'tripointedev.wpengine.com' || current_url === 'tripointestage.wpengine.com') {
      var stageForm = true;
    }else{
        var stageForm = false;
    }

    if (this.props.is_qmi) {
      for (var i = 0; i < 2; i++) {
        if(residence_component.qmi_detail.active_form[i] == 'tpc_priority') {
            tpc=true;
        }
        else if (residence_component.qmi_detail.active_form[i] == 'reserve_this_home') {
            rth=true
        }
        else if (residence_component.qmi_detail.active_form[i] == 'schedule_a_tour') {
            sat=true;
        } 
        else if(residence_component.qmi_detail.active_form[i] == 'interest_list') {
            it=true;
        }
      }
        return  <div id='twoform'>    
            { tpc  && <React.Fragment>
              <a href="#tour_form"
                        data-fancybox="priority-group-form"
                        data-src="#tour_form"
                        className="qmi_button priorityform1 twobtn"
                        title="JOIN THE PRIORITY GROUP"
                        id="tour_click_btn_show"
                    >
                        <span class="button__text">
                         Get pre-qualified
                            </span>
                </a>
                {stageForm ? <StageTPCForm
                    tpc_form_details={residence_component.qmi_detail.tpc_priority_group_form}
                    division_name={residence_component.qmi_detail.division_name}
                /> : <TPCPriorityGroupForm tpc_form_details={residence_component.qmi_detail.tpc_priority_group_form}
                division_name={residence_component.qmi_detail.division_name}/>}
                
            </React.Fragment> }
            
            { sat  && [(residence_component.qmi_detail.tour_details.active_utour && residence_component.qmi_detail.tour_details.utour_page_url)?
                    <React.Fragment>    
                    <a className="qmi_button twobtn" title="Schedule a Tour" id="tour_click_btn" href="javascript:void(0)" onClick={() => this.handleShow('tour_guide')}>
                    <span class="button__text">
                          Schedule a Tour
                            </span>
                    </a>
                        
                        {this.state.utourtour ?<Modal className="Utour-screen tph-ui-modal" show={this.state.show==='tour_guide'} onHide={this.handleClose}>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <UTour UTourDetails={residence_component.qmi_detail.tour_details} division_name={residence_component.qmi_detail.division_name} />                                    
                            </Modal.Body>
                        </Modal>    :null } 
                    </React.Fragment>       
                :  
                    <React.Fragment>  
                    <a href="javascript:void(0)"
                        className="qmi_button twobtn" title="Schedule a Tour" id="tour_click_btn"  onClick={() => this.handleShow('tour_guide')}>
                          <span class="button__text">
                          Schedule a Tour
                            </span>
                        </a>
                    <Modal className="Utour-screen schedule-Modal tph-ui-modal" show={this.state.show==='tour_guide'} onHide={this.handleClose}>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                            {this.state.utourtour ? 
                            stageForm ?
                            <StageScheduleTourForm form_details={residence_component.qmi_detail.tour_details} division_name={residence_component.qmi_detail.division_name} />
                            : <ScheduleTourForm form_details={residence_component.qmi_detail.tour_details} division_name={residence_component.qmi_detail.division_name} />
                            :null }  
                            </Modal.Body>
                        </Modal>      
                </React.Fragment>]         
            } 
            { rth  && <React.Fragment>
                        
              <a href="javascript:void(0)"
                            className="qmi_button twobtn" id="tour_click_btns"  onClick={() => this.handleShow('reserve_form') } >
                              <span class="button__text">
                              {residence_component.qmi_detail.schedule_button_text}
                              </span>
                            </a>
                            <Modal className="Utour-screen schedule-Modal tph-ui-modal" show={this.state.show==='reserve_form'} onHide={this.handleClose}>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                            {
                               stageForm ? <StageQmiReserveForm form_details={residence_component.qmi_detail.tour_details} agent_name={residence_component.qmi_detail.agent_info} division_name={residence_component.qmi_detail.division_name} homesiteNumber={residence_component.qmi_detail.homesite_number} />
                               : <QmiReserveForm form_details={residence_component.qmi_detail.tour_details} agent_name={residence_component.qmi_detail.agent_info} division_name={residence_component.qmi_detail.division_name}      homesiteNumber={residence_component.qmi_detail.homesite_number}/>
                            } 
                            </Modal.Body>
                        </Modal>         
                    </React.Fragment>
            }
      
            { it  && <React.Fragment>
              <a href="javascript:void(0)" className="qmi_button twobtn" title="Join The Interest List" onClick={() => this.handleShow("Request_quote")} id="interest_click_btn">
              <span class="button__text">
                  {residence_component.qmi_detail.interest_list_overrride?residence_component.qmi_detail.interest_list_overrride:'Join The Interest List'}
                </span>
                </a>
            <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                        {residence_component.qmi_detail.neigh_id !='85782' ? (
                            stageForm ?
                            <StageSidebarSignUp sidebar_data={residence_component.qmi_detail.sidebar_form} communityOfInterest={residence_component.qmi_detail.communityOfInterest} nbd_title={residence_component.qmi_detail.neigh_title} division_name={residence_component.qmi_detail.division_name} /> : 
                            <SidebarSignUp sidebar_data={residence_component.qmi_detail.sidebar_form} communityOfInterest={residence_component.qmi_detail.communityOfInterest} nbd_title={residence_component.qmi_detail.neigh_title} division_name={residence_component.qmi_detail.division_name}/>
                        ):(
                            stageForm ? <StageSidebarSignUpNewForm /> : <SidebarSignUpNewForm />
                        )}
                        </Modal.Body>
                    </Modal>
                </React.Fragment>
            }
            {residence_component.common_detail.envision_url && residence_component.common_detail.show_envision !== false ? [
                    <a href={residence_component.common_detail.envision_url}
                      target="_blank"
                      className="button button--hollow"
                      onClick={() => this.EnvisionPresaleLaunch_data_layer_push('EnvisionPresaleLaunch')}
                      style={{
                        '--btn-border': '1px solid #BED437',
                      }}>
                      <span class="button__text">
                        Online Design Studio
                      </span>
                    </a>
                    ,

                    <Modal className="Estimate_modal envision_modal" show={this.state.show === "Design_studio"} onHide={this.handleClose} >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        {residence_component.common_detail.envision_url ? (
                          <iframe tabindex="0" src={residence_component.common_detail.envision_url} className="envision_frame" title="Online Design Studio" ></iframe>
                        ):('')}
                      </Modal.Body>
                    </Modal>
                  ]:['']}
        </div>
    }else{
      if (tpc_form_btn) {
        return <React.Fragment>
          <a href="javascript:void(0)" title="JOIN THE PRIORITY GROUP" data-fancybox="priority_group_form" data-src="#tour_form" className="button">
            <span class="button__text" onClick={()=>{ window.dataLayer.push({'event':'preQualificationFormClick'});}}>
              {getFormBtnCopy(residence_component.common_detail.neigh_status, residence_component.common_detail.tpc_priority_group_form)}
            </span>
          </a>
          {stageForm ? <StageTPCForm tpc_form_details={residence_component.common_detail.tpc_priority_group_form} division_name={residence_component.common_detail.division_name}/> :<TPCPriorityGroupForm tpc_form_details={residence_component.common_detail.tpc_priority_group_form} division_name={residence_component.common_detail.division_name} /> }
        </React.Fragment>
      } else if (residence_component.common_detail.tour_details.show_tour_btn) {
        if (residence_component.common_detail.tour_details.active_utour && residence_component.common_detail.tour_details.utour_page_url) {
          return <React.Fragment>
            <a className="button"
              id="tour_click_btn"
              href="javascript:void(0)"
              onClick={() => {this.updateInnerModal('tour_guide')
              window.dataLayer.push({'event':'scheduleTourFormClick'})
              }}>
              <span class="button__text">
                Schedule a Tour
              </span>
            </a>
  
            <Modal className="Utour-screen tph-ui-modal" show={this.state.innerModal==='tour_guide'}>
              <Modal.Header closeButton onClick={() => this.updateInnerModal('')}></Modal.Header>
              <Modal.Body>
                <UTour UTourDetails={residence_component.common_detail.tour_details} division_name={residence_component.common_detail.division_name} />
              </Modal.Body>
            </Modal>
          </React.Fragment>
        } else {
          return <React.Fragment>
            <a href="javascript:void(0)"
              className="button"
              id="tour_click_btn"
              onClick={() => {this.handleModalShowHide()
              window.dataLayer.push({'event':'scheduleTourFormClick'})
              }}>
              <span class="button__text">
                Schedule a Tour
              </span>
            </a>
            <Modal className="Utour-screen schedule-Modal tph-ui-modal" show={this.state.showHide}>
              <Modal.Header closeButton onClick={() => this.handleModalShowHide()}></Modal.Header>
              <Modal.Body>
              {stageForm ?  <StageScheduleTourForm form_details={residence_component.common_detail.tour_details} division_name={residence_component.common_detail.division_name}/> : <ScheduleTourForm form_details={residence_component.common_detail.tour_details} division_name={residence_component.common_detail.division_name} />}
              </Modal.Body>
            </Modal>
          </React.Fragment>
        }
      }
    }

    
  }

  render() {
    const residence_component = this.props.residence_component;
    console.log(residence_component);
    const marketingHeadline = this.props.hasOwnProperty('qmi_detail') && this.props.qmi_detail.hasOwnProperty('qmiheadline') && this.props.qmi_detail.qmiheadline ? this.props.qmi_detail.qmiheadline : this.props.hasOwnProperty('plan_details') && this.props.plan_details.hasOwnProperty('qmiheadline') && this.props.plan_details.qmiheadline ? this.props.plan_details.qmiheadline : null

    const slider1Settings = {
      dots: true,
      infinite: false
    }
    
    return (
      <div className="residence-details" id="screen-1">
        <div className="residence-image">
          {residence_component.detail.detail_hero || residence_component.detail.gallery_images.length > 0 || residence_component.detail.video_block ? (
            <Carousel {...slider1Settings}>
              {residence_component.detail.detail_hero ? (
                <div className="iframe-div iframe-div--full-height" id="virtual_tour">
                  <iframe allow="fullscreen" tabindex="0" src={residence_component.detail.detail_hero} title="Plan iframe" id="iframe-modal" ref={this.iframeRef}></iframe>
                </div>
              ):('')}

              {residence_component.detail.video_block ? (
                <div className="iframe-div">
                  <iframe tabindex="0" src={residence_component.detail.video_block} title='Video'></iframe>
                </div>
              ):('')}

              {residence_component.detail.gallery_images.length > 0 ? [
                residence_component.detail.gallery_images.map(items => (
                  <div className="plan_image_slider">
                    <img tabindex="0" src={items.image.url} alt={items.image.image_alt ? items.image.image_alt : items.image.img_title } title={items.image.img_title} />
                    {items.image_caption != '' ? [
                      <div className="plan_image_caption">{items.image_caption}</div>
                    ]:['']}
                  </div>
                ))
              ]:['']}
            </Carousel>
          ):(
            <div className="image-div">
              <img tabindex="0" src={residence_component.detail.feature_image ? residence_component.detail.feature_image[0] : require('../../assets/images/TPH-default-image2.gif')} alt="Plan Gallery" title="Plan Gallery" />
            </div>
          )}
        </div>

        <HeroSubNav
          data={residence_component.common_detail}
          hide_details={true}
          is_qmi={this.props.is_qmi}
          links={this.props.subnavLinks}
          page_data={residence_component}
          posttype="residence"
          sticky={false}
        />

        <div className="residence-content">
            <Link to={residence_component.common_detail.nbd_permalink}
              className="neigh-name">
              <svg class="neigh-name__icon" xmlns="http://www.w3.org/2000/svg" width="16.292" height="12.852" viewBox="0 0 16.292 12.852">
                <path d="M1.276,9.634l4.537,4.391-.388.539L0,9.267,5.425,4l.389.538L1.275,8.93H14.467v.7H1.276Z" transform="translate(1.075 -2.858)" fill="#525252" stroke="#242424" stroke-width="1.5" fill-rule="evenodd"/>
              </svg>

              {residence_component.common_detail.nbd_title ? (
                <div className="neigh-name__text">
                  {residence_component.common_detail.nbd_title}
                </div>
              ) : ('')}
            </Link>

            {!this.props.is_qmi ? (
              <>
                {/* <div className='form-main'>
                  <h2 tabindex="0" className='form-heading'>Name Your Custom Home</h2>
                  {this.state.saved === false ? (
                      <Form className="residence-input" onSubmit={this.favoriteSubmit}>
                          <Form.Group>
                              <Form.Control id="floorplan_name"  type="text" ref={this.name} onChange={this.floorplan_name_save} defaultValue = {residence_component.common_detail.res_title} required/>
                          </Form.Group>
                      </Form>
                  ):('')}
                  {this.state.saved === true ? (
                      <p tabindex="0">{this.name.current.value} is Saved.</p>
                  ):('')}
                </div> */}
                <button class="floorplan-save__icon floorplan-save__icon--hidden"
                  style={{
                    'display': 'none'
                  }}
                  onClick={(e) => this.favoriteSubmit(e)}
                  type="button">
                  <svg fill="#000000" height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 471.701 471.701" xmlSpace="preserve">
                    <g>
                      <path d="M433.601,67.001c-24.7-24.7-57.4-38.2-92.3-38.2s-67.7,13.6-92.4,38.3l-12.9,12.9l-13.1-13.1
                        c-24.7-24.7-57.6-38.4-92.5-38.4c-34.8,0-67.6,13.6-92.2,38.2c-24.7,24.7-38.3,57.5-38.2,92.4c0,34.9,13.7,67.6,38.4,92.3
                        l187.8,187.8c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-3.9l188.2-187.5c24.7-24.7,38.3-57.5,38.3-92.4
                        C471.801,124.501,458.301,91.701,433.601,67.001z M414.401,232.701l-178.7,178l-178.3-178.3c-19.6-19.6-30.4-45.6-30.4-73.3
                        s10.7-53.7,30.3-73.2c19.5-19.5,45.5-30.3,73.1-30.3c27.7,0,53.8,10.8,73.4,30.4l22.6,22.6c5.3,5.3,13.8,5.3,19.1,0l22.4-22.4
                        c19.6-19.6,45.7-30.4,73.3-30.4c27.6,0,53.6,10.8,73.2,30.3c19.6,19.6,30.3,45.6,30.3,73.3
                        C444.801,187.101,434.001,213.101,414.401,232.701z"></path>
                    </g>
                  </svg>
                </button>
                <FloorplanSave residence_component={residence_component} />
              </>
            )
            : ('')}

            {!this.props.is_qmi ?
              residence_component.common_detail.hasOwnProperty('post_status') && residence_component.common_detail.post_status &&
                <div>
                  <b class="badge"
                    style={{
                      '--badge-background-color': '#BED437',
                      '--badge-border': 'none',
                      '--badge-color': '#fff',
                      '--badge-font-size': '11px',
                      '--badge-margin': '0 0 30px 0',
                    }}>
                    {residence_component.common_detail.post_status}
                  </b>
                </div>
              :
                residence_component.qmi_detail.hasOwnProperty('qmi_status') && residence_component.qmi_detail.qmi_status ?
                  
                   residence_component.qmi_detail.qmi_status === 'Sold' || residence_component.qmi_detail.qmi_status === 'Reserved' ? 
                      <div>
                      <b class="badge"
                        style={{
                          '--badge-background-color': '#BED437',
                          '--badge-border': 'none',
                          '--badge-color': '#fff',
                          '--badge-font-size': '11px',
                          '--badge-margin': '0 0 30px 0',
                        }}>
                        {residence_component.qmi_detail.qmi_status}
                      </b>
                      </div>
                : 
                residence_component.qmi_detail.move_in_date ?
                <div>
                    <b class="badge"
                      style={{
                        '--badge-background-color': '#BED437',
                        '--badge-border': 'none',
                        '--badge-color': '#fff',
                        '--badge-font-size': '11px',
                        '--badge-margin': '0 0 30px 0',
                      }}>
                      {residence_component.qmi_detail.move_in_date}
                    </b>
                </div>: ''
              : ''
            }

            {/* {residence_component.common_detail.hasOwnProperty('post_status') && residence_component.common_detail.post_status &&
              <div>
                <b class="badge"
                  style={{
                    '--badge-background-color': '#BED437',
                    '--badge-border': 'none',
                    '--badge-color': '#fff',
                    '--badge-font-size': '11px',
                    '--badge-margin': '0 0 30px 0',
                  }}>
                  {residence_component.common_detail.post_status}
                </b>
              </div>
            } */}

            {residence_component.common_detail.res_title ? (
              <h1 className="section-header__title margin relative title title--medium"
                style={{
                  '--color': '#525252',
                  '--margin-desktop': '0 0 37.5px',
                  '--margin-mobile': '0 0 24px',
                }}>
                {residence_component.common_detail.res_title}
              </h1>
            ):('')}

            {residence_component.common_detail.price_value  ? (
              <div className="fonr--medium font-size"
                style={{
                  '--font-size-desktop': '26px',
                  '--font-size-mobile': '18px',
                  'color': 'var(--black)',
                }}>
                {residence_component.common_detail.price_value}
              </div>
            ):('')}

            {residence_component.common_detail.description || marketingHeadline ? (
              <div className="border-top-custom font--regular font-size margin padding"
                style={{
                  '--border-top-color': 'var(--black)',
                  '--font-size': '16px',
                  '--font-size-line-height': '1.375',
                  '--margin': '20px 0 26px',
                  '--padding': '24px 0 0',
                  'color': 'var(--black)',
                }}>
                {marketingHeadline &&
                  <p>
                    {this.props.plan_details.qmiheadline}
                  </p>
                }
                {residence_component.common_detail.description &&
                  <div dangerouslySetInnerHTML={{__html:residence_component.common_detail.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                }
              </div>
            ):('')}

            {residence_component.common_detail.brouchure ? (
              <a href={residence_component.common_detail.brouchure}
                download
                target="_blank"
                className="interactive-btn"
                onClick={() => this.PDFDownload_data_layer_push('PDFDownload')}
                style={{
                  'color': '#585858',
                  'font-size': '16px',
                  'text-decoration': 'underline',
                }}>
                Download PDF Brochure
              </a>
            ):('')}

            {residence_component.common_detail ? (
              <>
                <div class="plan__details margin"
                  style={{
                    '--margin-desktop': '43px 0 0',
                    '--margin-mobile': '40px 0 0',
                  }}>
                  <PlanDetails data={this.props.plan_details}
                    border_top={false}
                  />
                </div>
              </>
            ):('')}

            {
              this.props.is_qmi ? 
              <>
                <div class="plan__buttons margin"
                style={{
                  '--margin-desktop': '16px 0 0',
                  '--margin-mobile': '40px 0',
                }} id="custom_btn">
                {this.get_forms_btn()}
                </div>
              </>
              :
              <div class="plan__buttons grid margin"
              style={{
                '--grid-column-gap': '18px',
                '--grid-row-gap': '24px',
                '--grid-columns-desktop': '2',
                '--grid-columns-mobile': '1',
                '--grid-columns-tablet': '2',
                '--margin-desktop': '16px 0 0',
                '--margin-mobile': '40px 0',
              }}>
              {this.get_forms_btn()}

              {residence_component.common_detail.envision_url && residence_component.common_detail.show_envision !== false ? [
                <a href={residence_component.common_detail.envision_url}
                  target="_blank"
                  className="button button--hollow"
                  onClick={() => this.EnvisionPresaleLaunch_data_layer_push('EnvisionPresaleLaunch')}
                  style={{
                    '--btn-border': '1px solid #BED437',
                  }}>
                  <span class="button__text">
                    Online Design Studio
                  </span>
                </a>
                ,

                <Modal className="Estimate_modal envision_modal" show={this.state.show === "Design_studio"} onHide={this.handleClose} >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    {residence_component.common_detail.envision_url ? (
                      <iframe tabindex="0" src={residence_component.common_detail.envision_url} className="envision_frame" title="Online Design Studio" ></iframe>
                    ):('')}
                  </Modal.Body>
                </Modal>
              ]:['']}
            </div>
            }

            

            {/* <div className="floor_bottomBtn">
              <ul className="interactive-icons">
                <li>
                </li>

                {residence_component.common_detail.envision_url && residence_component.common_detail.show_envision != false ? [
                  <li>
                  </li>,

                  <Modal className="Estimate_modal envision_modal" show={this.state.show === "Design_studio"} onHide={this.handleClose} >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      {residence_component.common_detail.envision_url ? (
                        <iframe tabindex="0" src={residence_component.common_detail.envision_url} className="envision_frame" title="Online Design Studio" ></iframe>
                      ):('')}
                    </Modal.Body>
                  </Modal>
                ]:['']}
                  <Modal className="Estimate_modal" show={this.state.show === "Estimate"} onHide={this.handleClose} >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <MortgageSection mortgage_data={residence_component.common_detail.mortgage_data}/>
                    </Modal.Body>
                  </Modal>
              </ul>
            </div> */}
        </div>
      </div>
    )
  }
}

export default ResidenceScreen1