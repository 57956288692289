import React from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import GlobalField from './GlobalField'
import HeroSubNav from './HeroSubNav'
import IframeModal from './IframeModal'
import MediaFeaturedGrid from './MediaFeaturedGrid'
import MortgageSection from './MortgageSection';
import PhotoGalleryCarousel from './PhotoGalleryCarousel'
import PhotoGalleryGrid from './PhotoGalleryGrid'
import ResidenceHero from './ResidenceHero'
import SectionTitle from './SectionTitle'
import SpringPromo from './SpringPromo'
import NhsDesktop from './NhsDesktop'
import UtilityNav from './UtilityNav'
import { apiConfig } from '../Classes/Helper.jsx'
import { transparentHeaderCss } from './Helpers'
import { Redirect } from 'react-router-dom'
import { withAuth0 } from '@auth0/auth0-react'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import './Residence.scss'
import PromotionBlock from './PromotionBlock'

class Residence extends React.Component {
  constructor() {
    super()

    this.state = {
      value: 'Details',
      button_no: 2,
      show: null,
      plan_save_status: '',
      plan_popup_message: '',
      plan_popup_show: false
    }

    this.save_floorplan = this.save_floorplan.bind(this)

    console.log('Residence', this)
  }

  save_floorplan() {
    let saved_plan_details = Cookies.get('saved_plan_details')

    if (saved_plan_details !== '' && saved_plan_details !== undefined) {
      saved_plan_details = JSON.parse(saved_plan_details)

      axios.post(`${apiConfig().API_URL}/floorplan_save`, {
        'user_id': this.props.auth0.user.sub,
        'plan_id': saved_plan_details.plan_id,
        'title': saved_plan_details.title,
        'name': saved_plan_details.name,
        'price': saved_plan_details.price,
        'url': saved_plan_details.url,
        'image': saved_plan_details.image,
      },
      {
        headers: {
          Authorization: 'Aa147147147#'
        }
      })
      .then(response => {
        this.setState({
          plan_save_status: response.data.data.status_code,
          plan_popup_message: response.data.data.status,
          plan_popup_show: true
        })

        Cookies.set('saved_plan_details','', { expires: 1 })
      })

      window.dataLayer.push({'event':'saveFloorplan'})
    }
  }

  componentDidMount() {
    if (this.props.auth0.isAuthenticated){
      this.save_floorplan()
    }

    if (window.location.href.indexOf('#view-the-floorplan') > -1) {
      window.JQUERY('.view-the-floorplan').trigger('click')
    }
  }

  render() {
    const residence_component = this.props.residence_component
    const data = residence_component.common_detail;
    console.log(data);
    const hasMortgageCalculatorGlobalComponent = residence_component.hasOwnProperty('global_components') && residence_component.global_components.findIndex(c => c.acf_fc_layout === 'mortgage_calculator') > -1
    let globalComponents = residence_component.hasOwnProperty('global_components') ? [...residence_component.global_components] : null
    let mediaGallery = null
    let mediaGalleryIndex = -1
    //Qmi Page View DataLayer
    if (this.props.is_qmi) {
      window.dataLayer.push({'event': 'qmiPageviews'});
    }
    if (globalComponents) {
      mediaGalleryIndex = globalComponents.findIndex(c => c.acf_fc_layout === 'media_gallery')

      if (mediaGalleryIndex > -1) {
        mediaGallery = globalComponents[mediaGalleryIndex]
        delete globalComponents[mediaGalleryIndex] // remove media gallery from array
        globalComponents = globalComponents.filter(c => c) // remove empty entry from array
      }
    }

    if (residence_component.interactive_media) {
      var interactiveMediaRoomChanger = residence_component.interactive_media.filter(x => x.interactive_type === 'RoomChanger')
      var roomChangerUrl = interactiveMediaRoomChanger.length > 0 && interactiveMediaRoomChanger[0].hasOwnProperty('interactive_url') && interactiveMediaRoomChanger[0].interactive_url ? interactiveMediaRoomChanger[0].interactive_url : null
    }

    const interactiveFloorplanData = {
      'cta_link': residence_component.hasOwnProperty('floorplans') && residence_component.floorplans.hasOwnProperty('floorplan_images') ? residence_component.floorplans.floorplan_images : null,
      'image': residence_component.hasOwnProperty('iframe_modal') && residence_component.iframe_modal.hasOwnProperty('interactive_floorplan_image') && residence_component.iframe_modal.interactive_floorplan_image ? residence_component.iframe_modal.interactive_floorplan_image : {'url': null, 'alt': null, 'title': null},
      'section_id': 'floorplan',
    }

    const roomVisualizerData = {
      'cta_link': residence_component.hasOwnProperty('room_visualizer') && residence_component.room_visualizer.hasOwnProperty('visualizer_data') ? residence_component.room_visualizer.visualizer_data : roomChangerUrl,
      'image': residence_component.hasOwnProperty('iframe_modal') && residence_component.iframe_modal.hasOwnProperty('room_visualizer_image') && residence_component.iframe_modal.room_visualizer_image ? residence_component.iframe_modal.room_visualizer_image : {'url': null, 'alt': null, 'title': null},
      'section_id': 'room-visualizer',
    }

    const virtualTourData = {
      'cta_link': residence_component.hasOwnProperty('virtual_tour') && residence_component.virtual_tour.hasOwnProperty('visualizer_data') ? residence_component.virtual_tour.visualizer_data : residence_component.detail.detail_hero,
      'image': residence_component.hasOwnProperty('iframe_modal') && residence_component.iframe_modal.hasOwnProperty('virtual_tour_image') && residence_component.iframe_modal.virtual_tour_image ? residence_component.iframe_modal.virtual_tour_image : {'url': null, 'alt': null, 'title': null},
      'section_id': 'virtual-tour',
    }

    const iframeModalData = {
      'InteractiveFloorplan': {
        'subnav': {
          'title': data.hero_subnav.floorplan_label || 'Floorplan',
          'slug': 'floorplan',
        },
        ...interactiveFloorplanData,
        ...residence_component.iframe_modal.interactive_floorplan,
      },
      'RoomChanger': {
        'subnav': {
          'title': data.hero_subnav.room_visualizer_label || 'Room Visualizer',
          'slug': 'room-visualizer',
        },
        ...roomVisualizerData,
        ...residence_component.iframe_modal.room_visualizer,
      },
      'VirtualWalkthrough': {
        'subnav': {
          'title': data.hero_subnav.virtual_tour_label || 'Virtual Tour',
          'slug': 'virtual-tour',
        },
        ...virtualTourData,
        ...residence_component.iframe_modal.virtual_tour,
      },
    }

    const subnavLinks = []

    if (residence_component.interactive_media) {
      residence_component.interactive_media.forEach(item => {
        const iframeModalDataItem = iframeModalData[item.interactive_type]
  
        if (!iframeModalDataItem) return
  
        subnavLinks.push(iframeModalDataItem['subnav'])
      })
    }

    subnavLinks.push({
      'title': data.hero_subnav.mortgage_calculator_label || 'Payment Calculator',
      'slug': 'payment-calculator',
    })

    // Add home plans link if residences arrays not empty
    if (residence_component.hasOwnProperty('residence') && residence_component.residence.length > 0) subnavLinks.push({
      'title': data.hero_subnav.floor_plans_label_residence_page || 'Other Plans',
      'slug': 'home-plans',
    })

    return (
      data.neigh_status !== 'Archive' ? (
        <>
          <style>{transparentHeaderCss()}</style>

          <div className="residence-main-div" id='residence-main-div'>
            <ResidenceHero
              is_qmi={this.props.is_qmi}
              plan_details={this.props.plan_details}
              residence_component={this.props.residence_component}
              subnavLinks={subnavLinks}
            />
          </div>

          <HeroSubNav
            data={residence_component.common_detail}
            hide_details={true}
            is_qmi={this.props.is_qmi}
            links={subnavLinks}
            page_data={residence_component}
            posttype="residence"
            sticky={true}
          />

          <div data-sticky-subnav-threshold></div>

          {/* Media Gallery */}
          {mediaGallery && mediaGallery.display === 'grid' &&
            <PhotoGalleryGrid data={mediaGallery} />
          }

          {mediaGallery && mediaGallery.display === 'carousel' &&
            <PhotoGalleryCarousel data={mediaGallery} />
          }
          {/* PromotionBlock */}
          {this.props.is_qmi ?
            residence_component.qmi_detail.promotion_info ?
            <PromotionBlock promo_data={residence_component.qmi_detail.promotion_info}/> : ''
          : ""}
          {/* Default Section title above IframeModals */}
          {residence_component.section_title &&
            <SectionTitle
              subtext={residence_component.section_title.section_eyebrow_text}
              title_part_1={residence_component.section_title.section_title_headline}
              title_part_2={residence_component.section_title.headline_part_2}
              headline_part_1_color={residence_component.section_title.headline_part_1_color}
              headline_part_2_color={residence_component.section_title.headline_part_2_color}
              headline_size={residence_component.section_title.hasOwnProperty('headline_size') ? residence_component.section_title.headline_size : 'large'}
              section_id='section-title'
              wrap={true}
            />
          }

          {/* Show Floorplan, Room Visualizer and Virtual Tour IframeModals based off InteractiveMedia data with default content pulled from Options fields */}
          {residence_component.interactive_media &&
            residence_component.interactive_media.map((item, index) => (
              <>
                {iframeModalData.hasOwnProperty(item.interactive_type) &&
                  <IframeModal
                    iframe_data={iframeModalData[item.interactive_type]}
                    iframe_type = {item.interactive_type}
                    index={index}
                  />
                }
              </>
            ))
          }

          {/* Join the priority list promo (default data pulled from Options fields) */}
          {residence_component.hasOwnProperty('join_priority_list_promo') && residence_component.join_priority_list_promo &&
            <SpringPromo promo_data={residence_component.join_priority_list_promo} />
          }

          {/* If Global Components doesn't have Mortgage Calculator, add it with defaults */}
          {!hasMortgageCalculatorGlobalComponent && residence_component.hasOwnProperty('mortgage_calculator') && residence_component.mortgage_calculator &&
            <MortgageSection mortgage_data={residence_component.mortgage_calculator} />
          }

          {/* All Global Components without Media Gallery */}
          {globalComponents &&
            <GlobalField
              faqs={this.props.is_qmi ? this.props.plan_details.faq_info : null}
              global_component={globalComponents}
              community_details={residence_component.common_detail}
              focus_boxes_horizontal={true}
              focus_boxes_section_id_fallback='neighborhood'
              is_qmi={this.props.is_qmi}
              posttype="residence"
            />
          }

          {/* Other Plans */}
          {residence_component.residence.length > 0 &&
            <MediaFeaturedGrid data={residence_component.residence}
              id="home-plans"
              posttype="residence"
            />
          }

          {window.matchMedia('(max-width: 767px)').matches &&
            <UtilityNav
              sfdc_chat={residence_component.common_detail.sfdc_chat_details}
              agent_info={residence_component.common_detail.agent_info}
              community_component={residence_component.common_detail}
              posttype="residence"
            />
          }
          {window.matchMedia('(min-width: 768px)').matches &&
            <NhsDesktop
              sfdc_chat={residence_component.common_detail.sfdc_chat_details}
              agent_info={residence_component.common_detail.agent_info}
              community_component={residence_component.common_detail}
              posttype="residence"
            />
          }
        </>
      ) : (<Redirect to={ residence_component.common_detail.submarket_slug} />)
    )
  }
}

export default withAuth0(Residence)