import axios from 'axios';
import { useEffect, useReducer, useState } from 'react';
import useSWRImmutable from 'swr/immutable';

const dataFetchReducer = ( state, action ) => {
	switch ( action.type ) {
		case 'FETCH_INIT':
			return {
				...state,
				isLoading: true,
				isError: false,
			};
		case 'FETCH_SUCCESS':
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload,
			};
		case 'FETCH_FAILURE':
			return {
				...state,
				isLoading: false,
				isError: true,
			};
		default:
			throw new Error();
	}
};

const useDataApi = ( initialUrl, initialData ) => {
	const [ url, setUrl ] = useState( initialUrl );
	const [ didCancel, setDidCancel ] = useState( false );

	const [ state, dispatch ] = useReducer( dataFetchReducer, {
		isLoading: false,
		isError: false,
		data: initialData,
	} );

	const fetchData = async () => {
		dispatch( { type: 'FETCH_INIT' } );
		try {
			const result = await axios( url );
			if ( ! didCancel && result.status === 200 ) {
				dispatch( { type: 'FETCH_SUCCESS', payload: result.data } );
				return result.data;
			}
			throw new Error( 'Error fetching data' );
		} catch ( error ) {
			console.log( `Could not fetch ${url}. Got error: `, error );
			if ( ! didCancel ) {
				dispatch( { type: 'FETCH_FAILURE' } );
			}
			throw new Error( 'Error fetching data' );
		}
	};
	useEffect( () => {
		return () => {
			setDidCancel( true );
		};
	}, [ url ] );

	return useSWRImmutable( 'search-data', fetchData );
};

export default useDataApi;
