import React from 'react';
import axios from 'axios';
import {apiConfig} from '../../Classes/Helper.jsx';
import Community from './Community';
import Neighborhood from './Neighborhood';
import CityPage from './CityPage';
import ResidencePage from './ResidencePage';
import QmiPage from './QmiPage';
import { Redirect } from "react-router-dom";
import NoMatch from './NoMatch';
import SkeletonComp from '../../Classes/SkeletonComp.jsx';

class Page_Decider extends React.Component {  
 
  state ={
    post_type : '',
    success : false,
    check : false
  }
  fetch_data(){
    axios.get(apiConfig().API_URL+'/get_info/'+ this.props.match.params.slug)
    .then(response => {
      this.setState({
        post_type : response.data.data,
        success: response.data.success,
        message: response.data.message
      })
    })
  }
  componentDidMount(){
    this.fetch_data();
    // console.log("Mount");
  }
  
    componentWillReceiveProps(nextProps) {
      if (this.props.location.pathname != nextProps.location.pathname) {
        this.resetPageDeciderState();
      }
        this.props = nextProps;
        this.fetch_data();
    }  
    resetPageDeciderState = ()=> {
      this.setState({
        post_type : '',
        success : false,
        check : false
      })
    }
    render() {
      var success = this.state.success;
      var post_type = this.state.post_type;
      var message = this.state.message;
      // console.log(post_type);
      return (
        success === true ? [
          post_type == 'attachment' ? [
            <Redirect to={<NoMatch />} />
          ] : [
            post_type == 'community' ? [
              <Community slug={this.props.match.params.slug} state={this.props.match.params.statename} submarket={this.props.match.params.submarket} />
            ] : [
              post_type == 'neighborhood' ? [
                <Neighborhood slug={this.props.match.params.slug} state={this.props.match.params.statename} submarket={this.props.match.params.submarket} />
              ] : [
                post_type == 'city' ? [
                  [<CityPage slug={this.props.match.params.slug} state={this.props.match.params.statename} submarket={this.props.match.params.submarket} />]
                ] : [
                  post_type == 'residence' ? [
                    <ResidencePage slug={this.props.match.params.slug} state={this.props.match.params.statename} submarket={this.props.match.params.submarket} />
                  ] : [
                    post_type == 'quickmovein' ? [
                      <QmiPage slug={this.props.match.params.slug} state={this.props.match.params.statename} submarket={this.props.match.params.submarket} />
                    ] : [ <Redirect to={<NoMatch />} /> ]
                  ]
                ]
              ]
            ]
          ]
        ] : [message != 'Not Completed' ? <SkeletonComp dynamicClass="dynamic" /> : <Redirect to={<NoMatch />} />]
      );
    }  
}  

export default Page_Decider; 