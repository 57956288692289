import { config } from '../config/config';

// This expects a key in the proper format.
// example: tph_search.state
const getValue = ( key ) => {
	return typeof window.localStorage.getItem( key ) === 'string' || window.localStorage.getItem( key ) instanceof String
		? JSON.parse( window.localStorage.getItem( key ) )
		: window.localStorage.getItem( key );
};

export const pushItem = ( key, value ) => {
	const data = getItem( key ) || [];

	if ( Array.isArray( data ) ) {
		data.push( value );
		setItem( key, data );
	}
};

export const setItem = ( key, value ) => {
	window.localStorage.setItem( `${ config.LOCAL_STORAGE_KEY_PREFIX }${ key }`, JSON.stringify( value ) );
};

export const getItem = ( key ) => {
	return getValue( `${ config.LOCAL_STORAGE_KEY_PREFIX }${ key }` );
};

export const unshiftItem = ( key, value ) => {
	const data = getItem( key ) || [];

	if ( Array.isArray( data ) ) {
		data.unshift( value );
		setItem( key, data );
	}
};

//
// This should return an object of all data stored in localStorage
// prefixed with the config.LOCAL_STORAGE_KEY_PREFIX value.
// example:
// {
//	  state: 'texas',
//	  submarket: 'houston'
// }
//
// One drawback to this is that the object has no structure, so
// clearing the data might be tricky if keys are swapped out or
// if the prefix changes.
export const getItems = ( keys = [] ) => {
	const data = {};

	if ( Array.isArray( keys ) && keys.length > 0 ) {
		for ( let i = 0; i < keys.length; i++ ) {
			data[ keys[ i ] ] = getItem( keys[ i ] );
		}
	} else {
		for ( let i = 0; i < window.localStorage.length; i++ ) {
			if ( window.localStorage.key( i ).startsWith( config.LOCAL_STORAGE_KEY_PREFIX ) ) {
				data[ window.localStorage.key( i ).replace( config.LOCAL_STORAGE_KEY_PREFIX, '' ) ] = getValue( window.localStorage.key( i ) );
			}
		}
	}

	return data;
};

export const removeItem = ( key ) => {
	window.localStorage.removeItem( `${ config.LOCAL_STORAGE_KEY_PREFIX }${ key }` );
};

export const clear = () => {
	for ( let i = 0; i < window.localStorage.length; i++ ) {
		if ( window.localStorage.key( i ).startsWith( config.LOCAL_STORAGE_KEY_PREFIX ) ) {
			window.localStorage.removeItem( window.localStorage.key( i ) );
		}
	}
};

// Below are helper methods for specific data that might be more complicated
// than getting/setting a single value.
export const storeResidenceView = ( id ) => {
	const data = getItem( 'home_views' ) || [];

	if ( Array.isArray( data ) && data.indexOf( id ) === -1 ) {
		data.unshift( id );
	}

	// Allow a max of 10 home views to be logged
	setItem( 'home_views', data.slice( 0, 10 ) );
};

export const storeQuickMoveInView = ( id ) => {
	storeResidenceView( id );
};

export const storeNeighborhoodView = ( id ) => {
	const data = getItem( 'neighborhood_views' ) || [];

	if ( Array.isArray( data ) && data.indexOf( id ) === -1 ) {
		data.unshift( id );
	}

	// Allow a max of 10 neighborhood views to be logged
	setItem( 'neighborhood_views', data.slice( 0, 10 ) );
};
