import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
  let domain = process.env.REACT_APP_AUTH0_STAGE_DOMAIN;
  let clientId = process.env.REACT_APP_AUTH0_STAGE_CLIENTID;  
  if (window.location.hostname === 'www.tripointehomes.com') {
    domain = process.env.REACT_APP_AUTH0_PROD_DOMAIN;
    clientId = process.env.REACT_APP_AUTH0_PROD_CLIENTID;
  }

  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      scope="read:users read:current_user update:users update:users_app_metadata update:current_user_metadata"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;