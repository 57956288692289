import React, { createContext, useContext, useReducer } from 'react';
import logger from 'use-reducer-logger';

// Keep track of when a marker is hovered in the hits to make it active on the map

const SearchStateHitCountContext = createContext( null );

const SearchStateHitCountDispatchContext = createContext( null );

export function SearchStateHitCountProvider( { children } ) {
	const [ searchStateHitCount, dispatch ] = useReducer(
		process.env.NODE_ENV === 'development' ? logger( searchStateHitCountReducer ) : searchStateHitCountReducer,
		initialHitCounts
	);

	return (
	    <SearchStateHitCountContext.Provider value={searchStateHitCount}>
            <SearchStateHitCountDispatchContext.Provider value={dispatch}>
                {children}
            </SearchStateHitCountDispatchContext.Provider>
        </SearchStateHitCountContext.Provider>
    );
}

export function useSearchStateHitCounts() {
    return useContext( SearchStateHitCountContext );
}

export function useSearchStateHitCountDispatch() {
    return useContext( SearchStateHitCountDispatchContext );
}

function searchStateHitCountReducer( existingState, action ) {
    switch ( action.type ) {
        // why does this get called twice after first one?
        case 'changed': {
            if (action.index) {
                if (action.index.includes('homes')) {
                    return {
                        ...existingState,
                        home_hits: action.count,
                    };
                } else if (action.index.includes('neighborhoods')) {
                    return {
                        ...existingState,
                        neighborhood_hits: action.count,
                    };
                }
            }

            return {...existingState};
        }
        default: {
            throw Error( 'Unknown action: ' + action.type );
        }
    }
}

const initialHitCounts = {
    neighborhood_hits: null,
    home_hits: null,
};
