import axios from 'axios'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'

import GlobalField from '../../Classes/GlobalField'
import { apiConfig } from '../../Classes/Helper.jsx'
import Hero from '../../Classes/Hero'
import HeroSubNav from '../../Classes/HeroSubNav'
import LargeCopyWithDetails from '../../Classes/LargeCopyWithDetails'
import MediaFeaturedGrid from '../../Classes/MediaFeaturedGrid'
import MediaFeaturedGridFeatures from '../../Classes/MediaFeaturedGridFeatures'
import NhsDesktop from '../../Classes/NhsDesktop'
import PromotionBlock from '../../Classes/PromotionBlock'
import SkeletonComp from '../../Classes/SkeletonComp.jsx'
import UtilityNav from '../../Classes/UtilityNav'
import { storeNeighborhoodView as storeNeighborhoodViewForSearch } from "../../Search/helpers/localStorage"
import NoMatch from './NoMatch'
import callTrkSwap from '../../../helpers/callTrkSwap.js'

class Neighborhood extends React.Component {
  state = {
    neigh_component : [],
    success : false,
  }

  constructor() {
    super()
  }

  // Local Business
  local_business_structured_data(neighComponent) {
    if (neighComponent) {

      if (neighComponent.agent_info.agents_contact_phone) {
        var telephone = neighComponent.agent_info.agents_contact_phone
      }

      if (neighComponent.min_price) {
        var min_price = neighComponent.min_price
      }

      if (neighComponent.max_price) {
        var max_price = neighComponent.max_price
      }

      var priceRange = min_price + ' - ' + max_price

      if (neighComponent.physical_address.physical_address_heading) {
        var addressLocality = neighComponent.physical_address.physical_address_heading.replace( /(<([^>]+)>)/ig, '')
      }

      if (neighComponent.streetAddress) {
        var streetAddress = neighComponent.streetAddress
      }

      if (neighComponent.postalCode) {
        var postalCode = neighComponent.postalCode
      }

      if (neighComponent.latitude) {
        var latitude = neighComponent.latitude
      }

      if (neighComponent.longitude) {
        var longitude = neighComponent.longitude
      }

      if (neighComponent.state_abb) {
        var state_abb = neighComponent.state_abb.toUpperCase()
      }

      if (neighComponent.site_logo) {
        var site_logo = neighComponent.site_logo
      }
    }

    const script = document.createElement('script')

    // Structure Data script start
    script.type = 'application/ld+json'
    script.innerHTML = `{
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      'name': 'Tri Pointe Homes',
      'image': '${site_logo}',
      '@id': '',
      'url': '${window.location.href}',
      'telephone': '${telephone}',
      'priceRange': '${priceRange}',
      'address': {
      '@type': 'PostalAddress',
      'streetAddress': '${streetAddress}',
      'addressLocality': '${addressLocality}',
      'addressRegion': '${state_abb}',
      'postalCode': '${postalCode}',
      'addressCountry': 'US'
      },
      'geo': {
      '@type': 'GeoCoordinates',
      'latitude': '${latitude}',
      'longitude': '${longitude}'
      }
    }`

    // Structure Data script end
    document.head.appendChild(script)
  }

  // Product Schema
  local_product_structured_data(neighComponent) {
    if (neighComponent) {
      if (neighComponent.title) {
        var neigh_title = neighComponent.title
      }

      if (neighComponent.min_price) {
        var min_price = neighComponent.min_price
      }

      if (neighComponent.max_price) {
        var max_price = neighComponent.max_price
      }
    }

    const product_script = document.createElement('script')

    // Structure Data script start
    product_script.type = 'application/ld+json'

    product_script.innerHTML = `{
      '@context': 'http://schema.org',
      '@type': 'Product',
      'name': '${neigh_title}',
      'offers': {
        '@type': 'AggregateOffer',
        'lowPrice': '${min_price}',
        'highPrice': '${max_price}',
        'priceCurrency': 'USD'
      }
    }`

    // Structure Data script end
    document.head.appendChild(product_script)
  }

  componentDidMount() {
    axios.get(apiConfig().API_URL+'/neighborhood_info/'+ this.props.slug)
    .then(response => {
      var title = response.data.data.neighborhood_details.title

      this.setState({
        neigh_component : response.data.data,
        success: response.data.success,
      })

      window.lazy.update()
      window.swipersInit()
    })

    callTrkSwap();

    if (window.location.href.indexOf("#") === -1) {
      window.scrollTo(0, 0);
    }

    window.JQUERY('.top-scroll').addClass('dynamic')
  }

  componentDidUpdate() {
    if (this.state.success == true) {
      window.dataLayer.push({
        'event': 'communityId',
        'divisionName': this.state.neigh_component.neighborhood_details.title ? this.state.neigh_component.neighborhood_details.title : '',
        'e1CommunityId': this.state.neigh_component.neighborhood_details.e1_community_id ? this.state.neigh_component.neighborhood_details.e1_community_id : '',
        'salesforceId': this.state.neigh_component.neighborhood_details.communityOfInterest ? this.state.neigh_component.neighborhood_details.communityOfInterest : ''
      })
    }

    // Log this
    if (this.state.neigh_component.search_key) {
        console.log(this.state.neigh_component.search_key);
        storeNeighborhoodViewForSearch(this.state.neigh_component.search_key);
    }

    callTrkSwap();
    window.JQUERY('.header_section').show()
    window.JQUERY('.footer_section').show()
    window.JQUERY('.top-scroll').show()
    window.JQUERY('.top-scroll').addClass('dynamic')

    if (window.location.href.indexOf('#') === -1) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    let canonical_location
    let component
    const message = this.state.message
    const neigh_component = this.state.neigh_component
    const neighComponent = this.state.neigh_component.neighborhood_details
    const state_name = this.props.state.toLowerCase()
    const submarket_name = this.props.submarket.toLowerCase()
    const success = this.state.success
    const window_width = window.screen.width

    window.joinModalDataReady = false

    if (neighComponent) {
      window.joinModalData = {
        title: neighComponent.title,
        submarket: submarket_name.replace(/-/g, ' '),
        communityOfInterest : neighComponent.communityOfInterest,
        division_name : neighComponent.division_name,
      };

      document.dispatchEvent(new CustomEvent('joinModalDataReady'));
      window.joinModalDataReady = true
    }

    // if (window.location.href.includes('?faq') || window.location.href.includes('?tab=qmi') || window.location.href.includes('?tab=plan') || window.location.href.includes('?tab=review') || window.location.href.includes('?tab=neighborhood')) {
    //   location = window.location.origin + window.location.pathname;
    // } else{
    //   location = window.location.href ;
    // }

    var current_location = window.location.href;
    if (current_location.includes('#') || current_location.includes('?')) {
      canonical_location = window.location.origin + window.location.pathname  ;
    }else{
        canonical_location = current_location;
    }
    var yoast_following = neigh_component.yoast_following;
    var yoast_indexing = neigh_component.yoast_indexing;
    var context_yoast = '';
    if (yoast_indexing === 'true' && yoast_following === 'true') {
      var context_yoast = 'index, follow';
    }else if (yoast_indexing === 'true' && yoast_following === 'false') {
      var context_yoast = 'index, nofollow';
    }else if (yoast_indexing === 'false' && yoast_following === 'true') {
      var context_yoast = 'noindex, follow';
    }else if (yoast_indexing === 'false' && yoast_following === 'false') {
      var context_yoast = 'noindex, nofollow';
    }
    if (neighComponent) {
      this.local_business_structured_data(neighComponent)

      if (neighComponent.jd_orders  == null) {
        this.local_product_structured_data(neighComponent)
      } else {
        if (neighComponent.jd_orders.reviews.length === 0) {
          this.local_product_structured_data(neighComponent)
        }
      }

      component = neighComponent.component

      for (var x in component) {
        if (component[x].acf_fc_layout == 'global_faq_section') {
          component.push(component.splice(x,1)[0])
        }
      }
    }

    return (
      <>
        <Helmet>
          <title>{neigh_component.yoast_title}</title>
          <meta name="description" content={neigh_component.yoast_desc} />
          <meta name="robots" content={context_yoast}  />
          <meta property="og:title" content={neigh_component.yoast_title ? neigh_component.yoast_title : ''} />
          <meta property="og:description" content={neigh_component.yoast_desc ? neigh_component.yoast_desc : ''} />
          <meta property="og:image" content={neigh_component.yoast_image}/>
          <meta property="og:url" content={canonical_location} />
          <link rel="canonical" href={canonical_location} />
          <meta property="og:type" content='Website' />
        </Helmet>

        <div role="main" className="neighborhood-main" style={{'background-color': '#FAFAFA'}}>
          {success === true ? [
            state_name === neigh_component.neighborhood_details.state_abb.toLowerCase() && submarket_name=== neigh_component.neighborhood_details.submarket.toLowerCase() ? [[neigh_component.neighborhood_details.community_status !== 'Archive' && neigh_component.neighborhood_details.community_status !== 'archive' && neigh_component.neighborhood_details.comm_status !== 'Archive'&& neigh_component.neighborhood_details.comm_status !== 'archive' ? [
              neigh_component.neighborhood_details ? (
                <>
                  <section class="hero hero--with-subnav">
                    <Hero
                      data={neigh_component.neighborhood_details}
                      posttype="neighborhood"
                    />

                    <HeroSubNav
                      data={neigh_component.neighborhood_details}
                      page_data={neigh_component}
                      posttype="neighborhood"
                      sticky={false}
                    />

                    <HeroSubNav
                      data={neigh_component.neighborhood_details}
                      page_data={neigh_component}
                      posttype="neighborhood"
                      sticky={true}
                    />
                  </section>

                  <div data-sticky-subnav-threshold></div>
                </>
              ):(''),

              neigh_component.neighborhood_details.long_desc ? (
                <LargeCopyWithDetails
                  data={neigh_component.neighborhood_details}
                  posttype="neighborhood"
                />
              ):(''),

              neigh_component.qmi && neigh_component.neighborhood_details.show_plan_tab === true ? (
                <MediaFeaturedGridFeatures
                  data={neigh_component.qmi}
                  id="available-homes"
                />
              ):(''),
              
              neigh_component.residence && neigh_component.neighborhood_details.show_plan_tab === true ? (
                <MediaFeaturedGrid
                  data={neigh_component.residence}
                  show_disclaimer ={neighComponent.show_pricing_disclaimer}
                  disclaimer_content ={neighComponent.pricing_disclaimer_description}
                  id="home-plans"
                  posttype="neighborhood"
                />
              ):(''),

              neigh_component.neighborhood_details.promotion_info ? (
                <PromotionBlock
                  promo_data={neigh_component.neighborhood_details.promotion_info}
                />
              ):(''),

              neigh_component.neighborhood_details.component ? (
                <GlobalField
                  global_component={neigh_component.neighborhood_details.component}
                  community_details={neigh_component.neighborhood_details}
                  focus_boxes_horizontal={true}
                  focus_boxes_section_id_fallback="neighborhood"
                  posttype="neighborhood"
                  review_title={this.props.slug}
                />
              ):(''),
              ,

              (window_width < 767) ? (
                <UtilityNav
                  sfdc_chat={neigh_component.neighborhood_details.sfdc_chat_details}
                  agent_info={neigh_component.neighborhood_details.agent_info}
                  community_component={neigh_component.neighborhood_details}
                  posttype="neighborhood"
                />
              ):(
                <NhsDesktop
                  sfdc_chat={neigh_component.neighborhood_details.sfdc_chat_details}
                  agent_info={neigh_component.neighborhood_details.agent_info}
                  community_component={neigh_component.neighborhood_details}
                  posttype="neighborhood"
                />
              )
            ]:(<Redirect to={neigh_component.neighborhood_details.submarket_slug} />)]
            ]:[ <Redirect to={<NoMatch/>} /> ]
          ]:[ message != 'Not Completed' ? <SkeletonComp dynamicClass="dynamic"/> : <Redirect to={<NoMatch/>} /> ]}
        </div>
      </>
    )
  }
}

export default Neighborhood
