import React from 'react';
import './HeadingSection.scss';

class HeadingSection extends React.Component {  
    split_string(str,count=2,dir='left',splitBy=' ') {
        var part1,part2;
        var split_array = str.split(splitBy);
        if(dir === 'right') {
            part2 = split_array.splice(split_array.length-count).join(splitBy);
            part1 = split_array.join(splitBy);
        }else{
            part1 = split_array.splice(0,count).join(splitBy);
            part2 = split_array.join(splitBy);  
        }
        return [part1,part2];
      }
  render() {
    var splited_title =  this.props.title && this.split_string(this.props.title,this.props.highlighted_word_count,this.props.highlight_dir);
    var highlight_class = this.props.highlight_class;
    var text_class = this.props.text_class;
    var heading_tags = this.props.heading_tags;
    var headlineSize = this.props.hasOwnProperty('headline_size') ? this.props.headline_size : 'large'
    if(this.props.highlight_dir === 'right'){
      highlight_class=text_class;
      text_class = this.props.highlight_class;
    }   
    
     return (  
          <div className={`heading-section ${this.props.wrap_class ? this.props.wrap_class : ''}`}>
                {this.props.subtitle ? (<p className="eyebrow">{this.props.subtitle}</p>):('')}
                {heading_tags === 'h1_tag' ? (
                    <React.Fragment>
                    {this.props.title_part_1 || this.props.title_part_2 ? (<h1 className={`main-title main-title--${headlineSize}`}>
                    {this.props.title_part_1 && <span style={{color:this.props.headline_part_1_color}} dangerouslySetInnerHTML={{__html:this.props.title_part_1.replace(/(?:\r\n|\r|\n)/g, '')}}></span>}
                    <span> </span>
                    {this.props.title_part_2 && <span style={{color:this.props.headline_part_2_color}} dangerouslySetInnerHTML={{__html:this.props.title_part_2.replace(/(?:\r\n|\r|\n)/g, '')}}></span>}
                </h1>):this.props.title ? (<h1 className={`main-title main-title--${headlineSize}`}>
                    <span className={highlight_class || ''} dangerouslySetInnerHTML={{__html:splited_title[0].replace(/(?:\r\n|\r|\n)/g, '')}}></span>
                    <span> </span>
                    <span dangerouslySetInnerHTML={{__html:splited_title[1].replace(/(?:\r\n|\r|\n)/g, '')}}></span>
                </h1>):('')}
                </React.Fragment>
                ):(
                    <React.Fragment>
                {this.props.title_part_1 || this.props.title_part_2 ? (this.props.component_name === 'testimonial' ? (<h3 className={`main-title main-title--${headlineSize}`}>
                    {this.props.title_part_1 && <span style={{color:this.props.headline_part_1_color}} dangerouslySetInnerHTML={{__html:this.props.title_part_1.replace(/(?:\r\n|\r|\n)/g, '')}}></span>}
                    <span> </span>
                    {this.props.title_part_2 && <span style={{color:this.props.headline_part_2_color}} dangerouslySetInnerHTML={{__html:this.props.title_part_2.replace(/(?:\r\n|\r|\n)/g, '')}}></span>}
                </h3>):(<h2 style={{'fontFamily': 'var(--font-family, var(--font-family-medium))'}} className={`main-title main-title--${headlineSize}`}>
                    {this.props.title_part_1 && <span style={{color:this.props.headline_part_1_color}} dangerouslySetInnerHTML={{__html:this.props.title_part_1.replace(/(?:\r\n|\r|\n)/g, '')}}></span>}
                    <span> </span>
                    {this.props.title_part_2 && <span style={{color:this.props.headline_part_2_color}} dangerouslySetInnerHTML={{__html:this.props.title_part_2.replace(/(?:\r\n|\r|\n)/g, '')}}></span>}
                </h2>)):this.props.title ? (
                    this.props.component_name === 'header' ? (<div className={`main-title main-title--${headlineSize}`}>
                    <span className={highlight_class || ''} dangerouslySetInnerHTML={{__html:splited_title[0].replace(/(?:\r\n|\r|\n)/g, '')}}></span>
                    <span> </span>
                    <span style={{color:this.props.headline_part_2_color}} dangerouslySetInnerHTML={{__html:splited_title[1].replace(/(?:\r\n|\r|\n)/g, '')}}></span>
                </div>):
                (<p className={`main-title main-title--${headlineSize}`}>
                    <span className={highlight_class || ''} dangerouslySetInnerHTML={{__html:splited_title[0].replace(/(?:\r\n|\r|\n)/g, '')}}></span>
                    <span> </span>
                    <span dangerouslySetInnerHTML={{__html:splited_title[1].replace(/(?:\r\n|\r|\n)/g, '')}}></span>
                </p>)
                ):('')}
                </React.Fragment>
                )}
          </div>  
     );  
  }  
}  

export default HeadingSection;
