import { getAlgoliaResults } from '@algolia/autocomplete-js';
import { searchClient } from '../Search/helpers/initAlgolia';
import React, { Fragment } from 'react';
import config from '../Search/config/config';
import AutocompleteHit from './AutocompleteHit';

const createHomePlansPlugin = ( ...rest ) => {
	return {
		...rest,
		getSources( { query, state } ) {
			return [
				{
					sourceId: 'home-plans',
					templates: {
						header( { items } ) {
							if ( items.length === 0 ) {
								return <Fragment />;
							}
							return (
								<Fragment>
									<span className="aa-SourceHeaderTitle">
                                        Homes & Floorplans
									</span>
									<span className="aa-SourceHeaderLine" />
								</Fragment>
							);
						},
						item( params ) {
							const { item, html } = params;
							return <AutocompleteHit hit={item} type={'home-plans'} insights={state.context.algoliaInsightsPlugin.insights} />;
						},
					},
					getItemInputValue( { item } ) {
						return item.name;
					},
					onSelect( { item } ) {
						return item.url;
					},
					getItems() {
						if ( ! query || state.context.selectedState ) {
							return [];
						}
						return getAlgoliaResults( {
							searchClient,
							queries: [
								{
									indexName: `${ config.ALGOLA_ENV }_${ config.ALGOLIA_INDEX_HOMES }`,
									query,
									params: {
										clickAnalytics: true,
									},
								},
							],
						} );
					},
				},
			];
		},
	};
};

export default createHomePlansPlugin;
