import React, { useEffect, useState } from 'react';
import '../../Classes/QmiLandingPage.scss';
import axios from 'axios';
import {apiConfig} from '../../Classes/Helper.jsx';
import CardSection from '../../Classes/CardSection';
import testImage from'../../../assets/images/TPH-default-image2.png';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import UtilityNav from '../../Classes/UtilityNav';
import NhsDesktop from '../../Classes/NhsDesktop';
import NoMatch from './NoMatch';
import { Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";


export default function QmiLandingPage(props)  {
    const [ getData , setData] = useState({ component_data: [],
        success: false,
        message: '',});

    const [ test , setTest] = useState('move_data');  
    useEffect(()=>{
        fetch_data()},[]
    );
    function fetch_data(){
        axios.get(apiConfig().API_URL+'/sub_city_lp/' + props.match.params.slug)
        .then(response => {
        setData(
            {
                component_data: response.data.data,
                success: response.data.success,
                message: response.data.message
              }
        )
    })
    
    }
    window.JQUERY('.top-scroll').addClass('dynamic');

    var highlight_class = 'green-color';
    var text_class = 'gray-color';
    const sortfx =(e)=>{
        // console.log('value',e.target.value);
        let val=e.target.value;
        // setTest('testingg');
       
        switch(val) {
          case 'move-in-date':
           setTest('move_data')
            break;
          case 'price':
            setTest('price')
            break;
          default:
            // console.log('nothing')
          }
           
      }
   if(getData.component_data!=''){
    var comp_Data = getData.component_data;    
    var city= getData.component_data.city ?
     Object.entries(getData.component_data.city) :
    (getData.component_data.neighbourhood) ? Object.entries(getData.component_data.neighbourhood) : '';
    var cit=city.filter((item)=>{
        return (item[1].length!=0)
        });
    var success = getData.success;
    var message = getData.message;
    var sortCity = city ? city.sort((a, b) => a[0] !== b[0] ? a[0] < b[0] ? -1 : 1 : 0) : '';   
    var page_heading = comp_Data.state_details.title;
    var state_abbs = comp_Data.state_details.state_abb ? comp_Data.state_details.state_abb.toUpperCase() : '';
    var page_title = comp_Data.state_details.page_title;
    var page_desc = comp_Data.state_details.page_desc;
    var banner_img = comp_Data.state_details.banner_image;
    if (banner_img) {
        var background_image = {
            backgroundImage:'url('+banner_img+')',
        }
    }
    else{
        var background_image = {
            backgroundImage:'url('+testImage+')',
        }
    }
    
    }
    const meta = {
        title: 'Quick Move-In Homes in ' + page_heading +', '+ state_abbs + ' | Tri Pointe Homes',
        description:'Explore quick move-in homes in '+  page_heading + ' today. Tri Pointe Homes builds innovative, design-forward homes throughout the US. ',
        canonical: window.location.href
      };
      var window_width = window.screen.width;
  return (
        
      <>
      <Helmet>  
              <title>{meta.title}</title>
              <meta name="description" content={meta.description} />
              <meta property="og:title" content={meta.title} />
              <meta property="og:description" content={meta.description} />
              <meta property="og:url" content={meta.canonical} />
              <link rel="canonical" href={meta.canonical} />
              <meta property="og:type" content='Website' />
            </Helmet> 
        {success=== true ?[
            comp_Data.state_details != '' ? [
                <div tabindex="0" className="qmi-plan-banner" style={background_image}>
                <div className={"HeroContent d-flex "}>
                    <div className="HeroContentWrapper">
                        <div className="bannerHeader">
                        {page_title && page_desc ? <div className="heading-section">
                            <h1 className={"main-title "+(text_class)}>
                                <span tabindex="0" className={highlight_class}> {page_title}</span>
                            </h1>
                            <p tabindex="0" className="generic-text">{page_desc}</p>
                        </div> :

                        <div className="heading-section">
                        <h1 className={"main-title "+(text_class)}>
                            <span tabindex="0" className={highlight_class}>Explore Quick</span>
                            <span> </span>
                            <span tabindex="0">Move-In Homes in {comp_Data.state_details.title}, {state_abbs}</span>
                        </h1>
                        <p tabindex="0" className="generic-text">Tri Pointe Homes builds innovative, design-forward homes throughout the US. Explore quick move-in homes in {comp_Data.state_details.title} today sorted by move-in date or price.</p>
                        </div>
                        }
                        </div>
                    </div>
                </div>
            </div>,
                <div className="qmi-sort-heading">
                <span tabindex="0">Sort by:</span>
                <form id="sort_form">
                    <select tabindex="0" name="sort" onChange={sortfx}>
                        <option tabindex="0"  value="move-in-date" >Move-in-date</option>
                        <option tabindex="0" value="price" >Price</option>
                    </select>
                </form>             
            </div>
                ,[cit.length !=0 ? 
                    sortCity.map(([key,items])=>{ 
                        if(items.length!=0){
                        return(<CardSection city_detail={items} state = {comp_Data.state_details.state_abb} city_name={key}  test={test}/>)}}
                    )
                :[
                    <div role="main" className="page_not">
                        Sorry,QMIs not found.
                    </div>
                ]],
                (window_width < 767) ? ( 
                    <UtilityNav agent_info={comp_Data.state_details.agent_info} sfdc_chat='' remove_map ='no' community_component ={comp_Data.state_details} />
                ):(
                    <NhsDesktop  agent_info={comp_Data.state_details.agent_info} sfdc_chat='' community_component ={comp_Data.state_details}   />
                )
            ] : [
                <Redirect to={<NoMatch/>} />
            ] 
            
            ]:[
                message != 'Not Completed' ?
                <SkeletonTheme>
                    <div tabindex="0" className="plan-banner">
                    <Skeleton height={100}/>
                        <div className={"HeroContent d-flex "}>
                            <div className="HeroContentWrapper">
                                <div className="bannerHeader">
                                    <div className="heading-section">
                                            <h2 className={"main-title "+(text_class)}>
                                            <Skeleton height={20} className={highlight_class}/>
                                            <Skeleton height={40}/>
                                             <Skeleton height={100}/>
                                            </h2>
                                            <p tabindex="0" className="generic-text"> <Skeleton height={"100%"}/></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Skeleton height={100}/>
                </SkeletonTheme>:''
                ]}
    </>
  )
}
