export const srcset = image => {
  return image.hasOwnProperty('sizes') && image.sizes.hasOwnProperty('medium') ? `${image.sizes['medium']} 300w, ${image.sizes['medium_large']} 768w, ${image.sizes['large']} 1170w, ${image.sizes['1536x1536']} 1536w, ${image.sizes['2048x2048']} 2048w` : image.hasOwnProperty('url') ? image.url : image
}

export const splitString = (str, count = 1, dir = 'left', splitBy = ' ') => {
  let part1
  let part2
  const split_array = str.split(splitBy)

  if (dir == 'right') {
    part2 = split_array.splice(split_array.length - count).join(splitBy)
    part1 = split_array.join(splitBy)
  } else {
    part1 = split_array.splice(0, count).join(splitBy)
    part2 = split_array.join(splitBy)
  }

  return [part1, part2]
}

export const groupBy = (arr, property) => {
  return arr.reduce((acc, cur) => {
    acc[cur[property]] = [...acc[cur[property]] || [], cur]

    return acc
  }, {})
}

export const transparentHeaderCss = () => {
  return `
    ::-webkit-scrollbar {
      max-width: 0; /* temp style, until can figure out where its coming from and remove all ::-webkit-scrollbar rules from inline style tag in head */
    }

    body {
      color: var(--black);
    }

    .header_section .headerWrapper:not(.active) .headerLogoArea {
      position: absolute;
      z-index: 1;
    }

    .header_section .headerWrapper:not(.active) .headerLogoArea:after {
      opacity: 0;
    }

    .header_section .headerWrapper:not(.active) .headerLogo {
      display: none;
    }

    .header_section .headerWrapper:not(.active) .headerLogoArea .headerLogoWrapper .headerLogo.logoLight {
      display: block;
    }

    .header_section .headerWrapper:not(.active) .headerLogoArea .headerLogoWrapper .hamburgerBtn > span {
      background-color: #fff;
    }
  `
}