import React from 'react'
import './ScheduleTour.scss'
import HeadingSection from './HeadingSection'
import AgentForm from './AgentForm'
import { Modal } from 'react-bootstrap'

class BrokersFile extends React.Component {
  constructor(props) {
    super(props)

    this.state ={
      show : null,
    }

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose() {
    this.setState({ show: null })
  }

  handleShow(id) {
    this.setState({ show: id })
  }

  componentDidMount() {
    const brokers_details = this.props.brokers_details
    const section_id = brokers_details.section_id && brokers_details.section_id.trim()

    if (section_id) {
      setTimeout(function () {
        if (window.location.href.indexOf("#" + section_id) > -1) {
          document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth', block:'start' })
        }
      }, 2000)
    }
  }

  render() {
    var brokers_details = this.props.brokers_details
    var section_id = brokers_details.section_id && brokers_details.section_id.trim()
    var backgroundImage = brokers_details.main_image.url

    if (backgroundImage) {
      var background_image = {
        backgroundImage:'url('+backgroundImage+')',
      }
    }

    return (
      brokers_details.pattern_image.url && brokers_details.headline && brokers_details.description ? (
        <div className="schedule-tour BrokerBtn section-margin section-padding wrap" id={section_id}>
          <div className="two-image">
            <div tabindex="0" className="image-section" style={background_image}>
              {brokers_details.pattern_image ? (
                <div className="image-building">
                  <img tabindex="0" src={brokers_details.pattern_image.url}  className="image-building-image" title={brokers_details.pattern_image.title} alt={brokers_details.pattern_image.alt ? brokers_details.pattern_image.alt : brokers_details.pattern_image.title}/>
                </div>
              ):('')}
            </div>
          </div>

          <div className="content-section">
            <HeadingSection
              subtitle={brokers_details.sub_text}
              title_part_1={brokers_details.headline}
              title_part_2={brokers_details.headline_part_2}
              headline_part_1_color={brokers_details.headline_part_1_color}
              headline_part_2_color={brokers_details.headline_part_2_color}
              headline_size={brokers_details.hasOwnProperty('headline_size') ? brokers_details.headline_size : 'large'}
            />

            {brokers_details.description ? (
              <div className="description" dangerouslySetInnerHTML={{__html:brokers_details.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
            ):('')}
              <div className="Broker-Btns">
                {brokers_details.broker_data && brokers_details.cta_label ? (
                  <a href="javascript:void(0)" className="button" title={brokers_details.cta_label} onClick={() => this.handleShow("Review_Policy")}><span class="button__text">{brokers_details.cta_label}</span></a>
                ):('')}
                {brokers_details.brokerbtn ? (
                  <a href="javascript:void(0)" className="button" title={brokers_details.brokerbtn} onClick={() => this.handleShow("Broker_modal")}><span class="button__text">{brokers_details.brokerbtn}</span></a>
                ):(
                  <a href="javascript:void(0)" className="button" title="Click Here" onClick={() => this.handleShow("Broker_modal")}><span class="button__text">Click Here</span></a>
                )}
              </div>
          </div>

          <Modal className="Estimate_modal Request_quote" show={this.state.show === "Broker_modal"} onHide={this.handleClose} >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <AgentForm broker_form={brokers_details.broker_form}/>
            </Modal.Body>
          </Modal>

          <Modal className="Estimate_modal Review_Policy_main_div"  show={this.state.show === "Review_Policy"} onHide={this.handleClose} >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {brokers_details.broker_data  ? (
                <div tabindex="0" className="Review_Policy_div"  dangerouslySetInnerHTML={{__html:brokers_details.broker_data.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
              ):('')}
            </Modal.Body>
          </Modal>
        </div>
      ):('')
    )
  }
}

export default BrokersFile