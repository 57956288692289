import React from 'react'
import SectionHeader from './SectionHeader'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { srcset } from './Helpers'
import './PhotoGalleryCarousel.scss'
import './PhotoGalleryModal.scss'
import axios from 'axios';
import { apiConfig } from './Helper.jsx';
import cookie from 'react-cookies';

class PhotoGalleryGrid extends React.Component {
  activeModal = null
  preModalScrollY = null

  constructor() {
    super()
  }

  componentDidMount() {
    this.escapeKeyListener()
    window.lazy.update()
    window.swipersInit();
    const sectionId = this.props.data.section_id && this.props.data.section_id !== '' ? this.props.data.section_id.trim() : 'gallery';
    if (sectionId) {
      setTimeout(function () {
          if (window.location.href.indexOf("#" + sectionId) > -1) {
              document.querySelector("#" + sectionId).scrollIntoView({behavior:'smooth',block:'start'});
          }
      }, 2000)
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  escapeKeyListener() {
    // detect Escape key press
    document.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        this.modalClose()
      }
    })
  }
  loadingModalEnd() {
    if (window.matchMedia('(max-width: 1023px').matches) return

    setTimeout(() => {
      document.querySelector('[data-mouse-follow=unzoom]').classList.add('active')
      document.querySelector('[data-mouse-follow=loading]').classList.remove('active')
    }, 1000)
  }

  mouseFollow(event, name) {
    if (window.matchMedia('(max-width: 1023px').matches) return

    const x = event.clientX
    const y = event.clientY + window.pageYOffset

    const $el = document.querySelector(`[data-mouse-follow="${name}"]`)

    if ($el) $el.classList.add('active')

    document.documentElement.style.setProperty('--mouse-x', `${x}px`)
    document.documentElement.style.setProperty('--mouse-y', `${y}px`)
  }

  mouseFollowLeave(name) {
    if (window.matchMedia('(max-width: 1023px').matches) return

    const $el = document.querySelector(`[data-mouse-follow="${name}"]`)

    if ($el) $el.classList.remove('active')
  }

  fetchData() {
    return new Promise((resolve, reject) => {
        axios.post(apiConfig().API_URL + '/get_marketo_lead_data/',{
            'get_type': "Activity",
            'activity_type' : "ViewGallery",
            'cookie' : cookie.load('_mkto_trk'),
            'URL' : window.location.href,
            'value' : 'true'
        }).then(response => response)
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  modalClose() {
    if (!this.activeModal) return

    enableBodyScroll(this.activeModal)

    if (window.matchMedia('(max-width: 1023px)').matches) {
      // fix scroll top position for iOs
      window.scroll(0, this.preModalScrollY)
    }

    this.activeModal.classList.add('hide')

    this.stopModalScrollTextIO()

    this.activeModal = null
    this.preModalScrollY = null
  }

  modalOpen(key, index, isLast) {
    var mkto_confirm = cookie.load('_mkto_trk');
        if (mkto_confirm) {
            this.fetchData()
              .then(data => {
                console.log(data);
              })
              .catch(error => console.error(error));
        }
    this.activeModal = document.querySelector(`[data-gallery-modal=${key}]`)

    if (!this.activeModal) return

    this.preModalScrollY = window.pageYOffset

    disableBodyScroll(this.activeModal)

    this.activeModal.classList.remove('hide')

    if (window.matchMedia('(min-width: 1024px').matches) {
      document.querySelector('[data-mouse-follow=loading]').classList.add('active')
    }

    setTimeout(() => {
      if (window.matchMedia('(min-width: 1024px').matches) {
        document.querySelector('[data-mouse-follow=loading]').classList.remove('active')
        document.querySelector('[data-mouse-follow=unzoom]').classList.add('active')
      }

      this.activeModal.querySelector(`[data-gallery-modal-index="${index}"]`).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })

      setTimeout(() => {
        this.startModalScrollTextIO()
      }, index > 0 && !isLast ? 2500 : 0)
    }, 500)
  }

  hideModalScrollText () {
    if (!this.activeModal) return

    // Hide modal scroll down text
    window.hideModalScrollTextIOEl = this.activeModal.querySelector('[data-modal-meta-threshold]')

    if (!window.hideModalScrollTextIOEl) return

    window.hideModalScrollTextIO = new IntersectionObserver(([entry]) => {
      document.documentElement.classList[entry.boundingClientRect.y < 0 ? 'add' : 'remove']('gallery-modal--scrolled')
    })

    window.hideModalScrollTextIO.observe(window.hideModalScrollTextIOEl)
  }

  startModalScrollTextIO () {
    if (!this.activeModal) return

    this.hideModalScrollText()
  }

  stopModalScrollTextIO () {
    if (!this.activeModal) return

    if (window.hideModalScrollTextIO) window.hideModalScrollTextIO.unobserve(window.hideModalScrollTextIOEl)

    document.documentElement.classList.remove('gallery-modal--scrolled')
  }
  //clicktocall data-layer
  calltoaction_data_layer_push(param) {
    this.setState({ value:param })
    if(param && param==='right') {
        window.dataLayer.push({'event':'imageGalleryNext'});
    }
    if(param && param==='left') {
      window.dataLayer.push({'event':'imageGalleryPrev'});
    }
  }
  imageGalleryClick_data_layer_push(param) {
    this.setState({ value:param })
    if(param && param==='imageGalleryClick') {
        window.dataLayer.push({'event':'imageGalleryClick'});
    }
 }


  render() {
    const data = this.props.data
    const sectionId = data.section_id && data.section_id !== '' ? data.section_id.trim() : 'gallery'
    const name = `carousel-${sectionId}`

    return (
      data.gallery_images.length > 0 ? (
        <>
          <img data-mouse-follow="unzoom"
            class="mouse-follow lazy position transform hide-mobile z-index"
            data-src="https://assets.codepen.io/7797206/icon-x_2.svg"
            height="168"
            width="168"
            style={{
              '--opacity-loaded': 'var(--mouse-follow-opacity, 0)',
              '--position-left': 'var(--mouse-x-override, var(--mouse-x, auto))',
              '--position-top': 'var(--mouse-y-override, var(--mouse-y, auto))',
              '--z-index': '1001',
              'height': '168px',
              'pointer-events': 'none',
              'transform': 'translate(-50%, -50%)',
              'width': '168px',
            }}
          />

          <svg data-mouse-follow="loading"
            class="mouse-follow height loaded width position hide-mobile z-index"
            style={{
              '--height': '84px',
              '--position-left': 'var(--mouse-x-override, var(--mouse-x, auto))',
              '--position-top': 'var(--mouse-y-override, var(--mouse-y, auto))',
              '--width': '84px',
              '--z-index': '1001',
              'background-color': '#fff',
              'border-radius': '100%',
              'opacity': 'var(--mouse-follow-opacity, 0)',
              'pointer-events': 'none',
              'transform': 'translate(-50%, -50%)',
            }}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="84px"
            height="84px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke="#242424" stroke-width="3" r="16" stroke-dasharray="65.97344572538566 23.991148575128552">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
            </circle>
          </svg>

          <section id={sectionId}
            data-sticky-subnav-section={sectionId}
            class="media-carousel background-fill section-margin section-padding relative wrap">
            <SectionHeader
              eyebrow={data.sub_text}
              headline_part_1={data.headline}
              headline_part_2={data.headline_part_2}
              headline_part_1_color={data.headline_part_1_color}
              headline_part_2_color={data.headline_part_2_color}
              headline_size={data.hasOwnProperty('headline_size') ? data.headline_size : 'large'}
            />

            <div className="media-carousel__container relative"
              data-swiper-container="media-carousel">
              <div className="media-carousel__swiper-gallery swiper"
                data-swiper='{
                  "slidesPerView": "auto",
                  "spaceBetween": 16,
                  "navigation": {
                    "nextEl": "[data-swiper-container=media-carousel] [data-media-carousel-arrow=next]",
                    "prevEl": "[data-swiper-container=media-carousel] [data-media-carousel-arrow=prev]"
                  },
                  "breakpoints": {
                    "1024": {
                      "spaceBetween": 20
                    }
                  }
                }'>
                <div className="media-carousel__arrows position width wrap z-index"
                  style={{
                    "--width": "100%",
                    "--position-left": "50%",
                    "--position-top": "0",
                    "--position-transform": "translate(-50%, 0)",
                    "--z-index": "2",
                    "pointer-events": "none",
                  }}>
                  <button className="media-caorusel__arrow media-caorusel__arrow--prev cursor position swiper-button-disabled transition"
                    data-media-carousel-arrow="prev"
                    type="button"
                    style={{
                      "--position-left": "0",
                      "--position-top": "50%",
                      "--position-transform": "translate(-50%, -50%)",
                      "background": "none",
                      "border": "none",
                      "opacity": "var(--media-carousel-arrow-opacity, 0)",
                      "outline": "none",
                      "pointer-events": "var(--media-carousel-arrow-pointer-events, none)",
                    }} onClick={() => this.calltoaction_data_layer_push('left')}>
                    <img className="media-caorusel__arrow-icon lazy pointer-events-none"
                      data-src="https://assets.codepen.io/7797206/icon-arrow-left.svg"
                      height={168}
                      width={168}
                    />
                  </button>

                  <button className="media-caorusel__arrow media-caorusel__arrow--next cursor position swiper-button-disabled transition"
                    data-media-carousel-arrow="next"
                    type="button"
                    style={{
                      "--position-left": "auto",
                      "--position-right": "0",
                      "--position-top": "50%",
                      "--position-transform": "translate(-50%, -50%)",
                      "background": "none",
                      "border": "none",
                      "opacity": "var(--media-carousel-arrow-opacity, 0)",
                      "outline": "none",
                      "pointer-events": "var(--media-carousel-arrow-pointer-events, none)",
                      "transform": "translate(50%, -50%)",
                    }} onClick={() => this.calltoaction_data_layer_push('right')}>
                    <img className="media-caorusel__arrow-icon lazy pointer-events-none"
                      data-src="https://assets.codepen.io/7797206/icon-arrow-right.svg"
                      height={168}
                      width={168}
                    />
                  </button>
                </div>

                <div className="media-carousel__swiper-wrapper flex swiper-wrapper">
                  {data.gallery_images.map((item, index) => (
                    <div className="media-carousel__swiper-item cursor swiper-slide"
                      style={{
                        "flex-shrink": "0",
                        "width": "var(--media-carousel-item-width, calc(61.875vw - var(--media-carousel-item-col-margin-h, 8px)))",
                      }}>
                      <figure className="media-carousel__swiper-media aspect-ratio"
                        onClick={() => this.modalOpen(name, index, index + 1 === data.gallery_images.length)}
                        style={{
                          "--aspect-ratio-height": "806",
                          "--aspect-ratio-width": "1188",
                          "--aspect-ratio-height-mobile": "200",
                          "--aspect-ratio-width-mobile": "291",
                        }}>
                        <img className="media-carousel__swiper-media-item lazy object-fit"
                          data-srcset={srcset(item.image)}
                          onClick={() => {this.imageGalleryClick_data_layer_push('imageGalleryClick')}}
                          alt={item.image.alt !== '' ? item.image.alt : item.image.title !== '' ? item.image.title : item.title}
                          sizes="auto"
                        />
                      </figure>
                      <div className="media-carousel__copy media-card"
                        style={{
                          "-webkit-flex": "1",
                          "-ms-flex": "1",
                          "flex": "1"
                        }}>
                        {item.image_title &&
                          <div className="media-card__title font--medium"
                            style={{
                              "border-top": "1px solid var(--black)",
                              "font-size": "var(--media-card-title-font-size, 22px)",
                              "margin-top": "var(--media-card-title-margin-top, 16px)",
                              "padding-top": "var(--media-card-title-padding-top, 16px)",
                              "-webkit-transition": "color 300ms ease-in-out",
                              "transition": "color 300ms ease-in-out",
                            }}>
                            {item.image_title}
                          </div>
                        }
                        {item.image_caption &&
                          <div className="media-card__copy rte"
                            style={{
                              "color": "var(--media-card-copy-color, #848484)",
                              "font-size": "var(--media-card-copy-font-size, 16px)",
                              "line-height": "1.375",
                              "margin-top": "16px",
                              "max-width": "var(--media-card-copy-max-width, 50ch)",
                              "-webkit-transition": "color 300ms ease-in-out",
                              "transition": "color 300ms ease-in-out",
                            }}>
                            <p>{item.image_caption}</p>
                          </div>
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div class="gallery-modal cursor position viewport-height z-index hide"
              data-gallery-modal={name}
              onMouseMove={(event) => this.mouseFollow(event, 'unzoom')}
              onMouseLeave={() => this.mouseFollowLeave('unzoom')}
              onClick={() => {
                if (window.matchMedia('(min-width: 1024px)').matches) this.modalClose()
              }}
              style={{
                '--cursor-desktop': 'none',
                '--cursor-mobile': 'default',
                'overflow': 'auto',
                '--position': 'fixed',
                '--position-left': '0',
                '--position-right': '0',
                '--position-top': '0',
                '--z-index': '1000',
                'background': '#fff',
                'overscroll-behavior': 'contain',
              }}>
              {data.gallery_images.length > 1 &&
                <>
                  <span class="gallery-modal__meta"
                    style={{
                      'background-color': 'rgba(255,255,255,0.8)',
                      'border-radius': '4px',
                      'bottom': '10px',
                      'font-size': '14px',
                      'color': 'var(--black)',
                      'left': '50%',
                      'opacity': 'var(--gallery-modal-meta-opacity, 1)',
                      'padding': '10px 15px',
                      'pointer-events': 'none',
                      'position': 'fixed',
                      'text-align': 'center',
                      'transform': 'translateX(-50%)',
                      'transition': 'opacity 500ms ease-in-out',
                      'z-index': '10',
                    }}>
                    Scroll down to see more
                  </span>

                  <span class="gallery-modal__meta-threshold"
                    data-modal-meta-threshold
                    style={{
                      'position': 'absolute',
                      'top': '10px',
                      'height': '1px',
                      'left': '0',
                      'width': '1px',
                      'pointer-events': 'none',
                    }}>
                  </span>
                </>
              }
              {data.gallery_images.map((item, index) => (
                <figure class="gallery-modal__item cursor flex"
                  data-gallery-modal-index={index}
                  style={{
                    '--flex-direction-mobile': 'column',
                    'margin-top': index > 0 ? '20px;' : '0',
                  }}>
                  <div class="gallery-modal__media aspect-ratio width"
                    style={{
                      '--aspect-ratio-height': '911',
                      '--aspect-ratio-width': '1320',
                      '--width-desktop': 'calc(1320/1920 * 100%)',
                      '--width-mobile': '100%',
                    }}>

                    <img class="lazy object-fit"
                      style={{'--object-fit': item.image.height > item.image.width ? 'contain' : 'cover'}}
                      data-srcset={srcset(item.image)}
                      alt={item.image.alt !== '' ? item.image.alt : item.image.title !== '' ? item.image.title : item.title}
                    />
                  </div>

                  <figcaption class="gallery-modal__info media-gallery__copy media-card width padding z-index"
                    style={{
                      '--padding-desktop': '65px',
                      '--padding-mobile': '40px',
                      '--width-desktop': 'calc(600/1920 * 100%)',
                      '--width-mobile': '100%',
                    }}>
                    {item.image_title &&
                      <div class="media-card__title font-size font--medium padding-vertical"
                        style={{
                            '--padding-vertical-bottom-desktop': '0',
                            '--padding-vertical-top-mobile': '16px',
                            '--padding-vertical-bottom-tablet': '0',
                            '--padding-vertical-bottom-mobile': '0',
                            '--padding-vertical-top-desktop': '24px',
                            '--padding-vertical-top-mobile': '16px',
                            '--font-size-desktop': '24px',
                            '--font-size-mobile': '22px',
                            'border-top': '1px solid var(--black)',
                        }}>
                        {item.image_title}
                      </div>
                    }

                    {item.image_caption &&
                      <div class="media-card__copy font-size rte"
                        style={{
                          '--font-size-desktop': '20px',
                          '--font-size-mobile': '16px',
                          'color': '#525252',
                          'line-height': '1.3',
                          'margin-top': '16px',
                        }}>
                        <p>{item.image_caption}</p>
                      </div>
                    }
                  </figcaption>
                </figure>
              ))}
            </div>
            <button class="gallery-modal__close gallery-modal__close--exterior position hide-desktop"
              onClick={() => this.modalClose()}
              type="button"
              style={{
                '--position': 'fixed',
                'background': 'none',
                'border': 'none',
                'height': '100px',
                'right': '0',
                'top': '0',
                'width': '100px',
                'z-index': '1001',
              }}>
              <img src="https://assets.codepen.io/7797206/icon-x_2.svg"
                height="100"
                style={{
                  'display': 'block',
                  'height': 'auto',
                  'pointer-events': 'none',
                  'width': '100%',
                }}
                width="100" />
            </button>
          </section>
        </>
      ):('')
    )
  }
}

export default PhotoGalleryGrid