import React from 'react';
import './SubNavbar.scss';
import SidebarForm from './SidebarForm.jsx';
import SidebarSignUp from './SidebarSignUp.jsx';
import EventRsvp from './EventRsvp.jsx';
import PromotionForm from './PromotionForm.jsx';
import SidebarSignUpNewForm from './SidebarSignUpNewForm.jsx';
import { Modal } from 'react-bootstrap';
import $ from 'jquery';
import UTour from './UTour.jsx';
import ScheduleTourForm from './ScheduleTourForm.jsx';
import STFormQMI from './STFormQMI.jsx';

import { Link, Events } from 'react-scroll';
import StageScheduleTourForm from './StageScheduleTourForm';
import StageSidebarSignUp from './StageSidebarSignUp';
import StageSidebarSignUpNewForm from './StageSidebarSignUpNewForm';
import StagePromotionForm from './StagePromotionForm';
import StageEventRsvp from './StageEventRsvp';

class UtilityNav extends React.Component {
    constructor() {
        super();
        this.state = {
            show: null,
            showHide: false,
         
            innerModal:''
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.load_sfdc_chat = this.load_sfdc_chat.bind(this);
    }
    handleModalShowHide() {
        this.setState({ showHide: !this.state.showHide })
      }
      updateInnerModal(str) {
        this.setState({ innerModal: str })
      }
   
    
      handleShow(id) {
            this.setState({ show: id });
               
       
      }
    handleClose() {
        this.setState({ show: null });
    }

    handleShow(id) {
        this.setState({ show: id });
    }
    componentDidMount() {
        $(".utility-agent-image, .mobile-pulse").on('click',function(){
            $(".sidebar-form").show();
        });
        $(".mobile-close").on('click',function(){
            $(".sidebar-form").hide();
        });
        if($(".sidebar-form").length > 0 ){
            $(document).on('click touchstart',function(e){
                var class_name = e.target.className;
                if(class_name.indexOf("mobile-pulse") == -1  && class_name.indexOf("utility-agent-image") == -1){
                    var classList = e.target;
                    if($(classList).closest(".sidebar-form").length <= 0){
                        $(".sidebar-form").not(this).each(function(){
                            if($(this).css('display') == 'block'){
                                $(this).hide();
                            }
                        });
					}
                }
            })
        }
 
        if(this.props.sfdc_chat.sfdc_community_id != '' && this.props.sfdc_chat.sfdc_button_id != ''){
            window.SHOW_CHAT = true;
            if(window.SHOW_CHAT){
                if(window.JQUERY(".embeddedServiceSidebar").length > 0){
                    window.JQUERY(".embeddedServiceSidebar").show();
                }
                else{
                    window.JQUERY(".embeddedServiceHelpButton").show();
                }            
                this.load_sfdc_chat();
           }
        }
        if(window.location.href.indexOf("#interest-form") > -1) {
            document.getElementById("interest_click_btn").click();
        }
    }


    load_sfdc_chat(){
        var that = this;    
        var initESW = function(gslbBaseURL) {
            window.embedded_svc.settings.displayHelpButton = true; //Or false
            window.embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'
        
            window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
            window.embedded_svc.settings.entryFeature = 'LiveAgent';

            window.embedded_svc.settings.iframeURL = 'https://service.force.com/embeddedservice/5.0/esw.html?parent='+window.location.href;
        
            window.embedded_svc.settings.extraPrechatFormDetails = [
                {"label": "First Name", "transcriptFields": ["ContactFirstName__c"]},
                {"label": "Last Name", "transcriptFields": ["ContactLastName__c"]},
                {"label": "Email", "transcriptFields": ["ContactEmail__c"]},
                {"value": that.props.sfdc_chat.sfdc_community_id, "transcriptFields": ["CommunityCRMId__c"]},
                {"value": window.location.href, "transcriptFields": ["WebsiteURL__c"]},
                {"label": "Question", "transcriptFields": ["ContactQuestion__c"]},
                {"label": "Preferred Phone", "transcriptFields": ["ContactPhone__c"]}, 
                {"value": that.props.sfdc_chat.sfdc_deployment_id, "transcriptFields": ["LiveChatDeployment"]},
                {"value": that.props.sfdc_chat.sfdc_button_id, "transcriptFields": ["LiveChatButton"]},
                {"value": that.props.sfdc_chat.sfdc_eswliveagent_devname, "transcriptFields": ["ESWLiveAgentDevName__c"]},
                {"value": that.props.sfdc_chat.sfdc_embedded_chat_group, "transcriptFields": ["EmbeddedServiceDepName__c"]}
            ];
            
                //Added below code for not creating new Contact in the system if no match found for contact in Salesforce 
                window.embedded_svc.settings.extraPrechatInfo = [
                    {"entityFieldMaps":[
                        {"doCreate":false,"doFind":true,"fieldName":"LastName","isExactMatch":false,"label":"Last Name"},
                        {"doCreate":false,"doFind":true,"fieldName":"FirstName","isExactMatch":false,"label":"First Name"}, 
                        {"doCreate":false,"doFind":true,"fieldName":"Email","isExactMatch":false,"label":"Email"}],
                        "entityName":"Contact","showOnCreate":false}
                ];
            
                window.embedded_svc.init(
                    'https://tripointegroup.my.salesforce.com',
                    'https://tripointegroup.secure.force.com',
                    gslbBaseURL,
                    '00Dj0000001s1Gn',
                    that.props.sfdc_chat.sfdc_embedded_chat_group,
                    {
                    baseLiveAgentContentURL: 'https://c.la3-c1-ph2.salesforceliveagent.com/content',
                    deploymentId: that.props.sfdc_chat.sfdc_deployment_id,
                    buttonId: that.props.sfdc_chat.sfdc_button_id,
                    baseLiveAgentURL: 'https://d.la3-c1-ph2.salesforceliveagent.com/chat',
                    eswLiveAgentDevName: that.props.sfdc_chat.sfdc_eswliveagent_devname,
                    isOfflineSupportEnabled: false
                    }
                );

        };
        
        if (!window.embedded_svc) {
            var s = document.createElement('script');
            s.setAttribute('src', 'https://tripointegroup.my.salesforce.com/embeddedservice/5.0/esw.min.js');
            s.onload = function() {
            initESW(null);
            };
            document.body.appendChild(s);
        } else {
            initESW('https://service.force.com');
        }
            
}
calltoaction_data_layer_push(param) {
    this.setState({ value:param })
    if(param && param==='clickToCall') {
        window.dataLayer.push({'event':'clickToCall'});
    }
 }
 direction_data_layer_push(param) {
    this.setState({ value:param })
    if(param && param==='drivingDirections') {
        window.dataLayer.push({'event':'drivingDirections'});
    }
 }

    render() {
        var agent_info = this.props.agent_info; 
        var remove_map = this.props.remove_map;
        console.log(remove_map);
        var community_data = this.props.community_component;
        console.log(community_data);
        var promotion_nav=this.props.promotion_nav; 
        var pagetitle=this.props.nbd_title;
        var posttype = this.props.posttype; 
        var show_form=this.props.show_form;
        var promo_title_color =this.props.promo_title_color;
        var promo_form=this.props.promo_form;
        var event_page=this.props.event_page;
        var event_info=this.props.event_info;
        var neigh_id=this.props.neigh_id;
        var neigh_name=this.props.neigh_name;
        var current_url = window.location.host;
        if (current_url === 'tripointedev.wpengine.com' || current_url === 'tripointestage.wpengine.com') {
            var stageForm = true;
        }else{
            var stageForm = false;
        }
        return (
            <div className={`utility-nav ${posttype === 'residence' ? 'hide_utility_nav' : ''}`} >
                <div className="utility-nav-info divide-utility" style={event_page=='yes'?{justifyContent:'center'}:{justifyContent:''}}>
                {event_page!='yes'?agent_info.agents_contact_phone ? (
                        posttype === 'promotion' ?
                        <a href={"tel:" + agent_info.agents_contact_phone} className="phone-call" onClick={() => this.calltoaction_data_layer_push('clickToCall')}>
                            <img tabindex="0" src={require('../../assets/images/phone.svg')} alt="phone" title="phone" className="utility-call" />
                            <span tabindex="0" style={{color:promo_title_color}}>Call</span>
                        </a>
                        :
                        <a href={"tel:" + agent_info.agents_contact_phone} className="phone-call" onClick={() => this.calltoaction_data_layer_push('clickToCall')}>
                            <img tabindex="0" src={require('../../assets/images/phone.svg')} alt="phone" title="phone" className="utility-call" />
                            <span tabindex="0">Call</span>
                        </a>
                        
                    ) : (''):''}

                    {event_page!='yes'?
                    promotion_nav != 'no' ?    
                    remove_map != 'no' ?
                    community_data.physical_address.map_directions_link || community_data.sales_office_address.custom_direction_link  ? (
                        community_data.physical_address.map_directions_link ? (
                            <a href={community_data.physical_address.map_directions_link} className="directions" target="_blank" onClick={() => this.direction_data_layer_push('drivingDirections')}>
                            <img tabindex="0" src={require('../../assets/images/map.svg')} alt="map"  title="map" className="utility-map"/>
                            <span tabindex="0">Map</span>
                            </a>
                        ):(
                            <a href={community_data.sales_office_address.custom_direction_link} className="directions" target="_blank" onClick={() => this.direction_data_layer_push('drivingDirections')}>
                            <img tabindex="0" src={require('../../assets/images/map.svg')} alt="map" title="map" className="utility-map"/>
                            <span tabindex="0">Map</span>
                            </a>
                        )
                    ):(''): '':'':''}
                    {/* <a href="javascript:void(0)" className="stay-informed-btn" id="interest_click_btn" onClick={() => this.handleShow("subscribe_form")}>
                        <img tabindex="0" src={require('../../assets/images/mail.svg')} alt="subscribe" title="subscribe" className="utility-subscribe"/>
                        <span tabindex="0">Subscribe</span>
                    </a> */}
                                   {event_page!='yes'?
                         promotion_nav != 'no' ?    
                    (<a href="javascript:void(0)" className="stay-informed-btn" id="interest_click_btn" onClick={() => this.handleShow("subscribe_form")}>
                        <img tabindex="0" src={require('../../assets/images/mail.svg')} alt="subscribe" title="subscribe" className="utility-subscribe" />
                        <span tabindex="0">Subscribe</span>
                    </a>):(
                    show_form?[<React.Fragment>
                                        <a href="javascript:void(0)" className="tour_btn" onClick={() => this.handleModalShowHide()} id="tour_click_btn" >
                                            <img tabindex="0" src={require('../../assets/images/tour.svg')} alt="Tour" title="Tour" className="utility-tour" />
                                            <span tabindex="0" style={{color:promo_title_color}}>Get In touch</span>
                                        </a>
                                        <Modal className="Estimate_modal Request_quote" show={this.state.showHide}>
                                            <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                                            </Modal.Header>
                                            <Modal.Body>
                                               {
                                                   stageForm ? 
                                                   <StagePromotionForm btn_color={promo_title_color} promo_form={promo_form} nbd_title={pagetitle} communityOfInterest={community_data.communityOfInterest} form_details={community_data.tour_details} division_name={community_data.division_name}/>
                                                   :
                                                   <PromotionForm btn_color={promo_title_color} promo_form={promo_form} nbd_title={pagetitle} communityOfInterest={community_data.communityOfInterest} form_details={community_data.tour_details} division_name={community_data.division_name} />
                                               }
                                            </Modal.Body>
                                        </Modal>
                                    </React.Fragment>]:['']):''
          
    }

                  
                    {posttype === 'qmi' ? (
                        community_data.active_qmi_reserve == true && community_data.mobile_button_text ? (
                            <a href="javascript:void(0)" className="tour_btn" data-fancybox-trigger="tour_forms" id="tour_click_btn">
                                <img tabindex="0" src={require('../../assets/images/tour.svg')} alt="Tour" title="Tour" className="utility-tour" />
                                <span tabindex="0">{community_data.mobile_button_text}</span>
                            </a>
                        ) : ('')
                    ) : (
                        event_page!='yes'?
                        promotion_nav != 'no' ?
                        remove_map === 'no' ? 
                             <React.Fragment>
                                <a href="javascript:void(0)" className="tour_btn" onClick={() => this.handleModalShowHide()} id="tour_click_btn" >
                                    <img tabindex="0" src={require('../../assets/images/tour.svg')} alt="Tour" title="Tour" className="utility-tour" />
                                    <span tabindex="0">Tour</span>
                                </a>
                                <Modal className="Utour-screen schedule-Modal tph-ui-modal" show={this.state.showHide}>
                                    <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <STFormQMI form_title = {community_data.title} communityOfInterest={community_data.communityOfInterest} nbd_title={community_data.title} division_name={community_data.division_name}  community_id={community_data.community_id}/>
                                    </Modal.Body>
                                </Modal>
                            </React.Fragment>
                        :
                         community_data.tour_details.show_tour_btn ? (    
                            community_data.tour_details.active_utour && community_data.tour_details.utour_page_url ? (
                                <React.Fragment>
                                    <a href="javascript:void(0)" className="tour_btn" onClick={() => this.updateInnerModal('tour_guide')} id="tour_click_btn" >
                                        <img tabindex="0" src={require('../../assets/images/tour.svg')} alt="Tour" title="Tour" className="utility-tour" />
                                        <span tabindex="0">Tour</span>
                                    </a>
                                    <Modal className="Utour-screen tph-ui-modal" show={this.state.innerModal === 'tour_guide'}>
                                        <Modal.Header closeButton onClick={() => this.updateInnerModal('')}>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <UTour UTourDetails={community_data.tour_details} division_name={community_data.division_name} />
                                        </Modal.Body>
                                    </Modal>
                                </React.Fragment>
                            ) : (
                                    <React.Fragment>
                                        <a href="javascript:void(0)" className="tour_btn" onClick={() => this.handleModalShowHide()} id="tour_click_btn" >
                                            <img tabindex="0" src={require('../../assets/images/tour.svg')} alt="Tour" title="Tour" className="utility-tour" />
                                            <span tabindex="0">Tour</span>
                                        </a>
                                        <Modal className="Utour-screen schedule-Modal tph-ui-modal" show={this.state.showHide}>
                                            <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {
                                                    stageForm ? <StageScheduleTourForm form_details={community_data.tour_details} division_name={community_data.division_name}/> : <ScheduleTourForm form_details={community_data.tour_details} division_name={community_data.division_name} />
                                                }
                                                
                                            </Modal.Body>
                                        </Modal>
                                    </React.Fragment>

                                )
                            ):('')
                            : ''
                            :''
                        )
                     
                    }
                      {
                event_page=='yes'?
                
                <div className="rsvp-link">
                {event_info.event_metas.rsvp_options && event_info.event_metas.rsvp_options[0].show_rsvp && event_info.event_metas.event_type == "Future Homeowner Event" || event_info.event_metas.rsvp_options && event_info.event_metas.rsvp_options[0].show_rsvp && event_info.event_metas.event_type == "Resident Event" ?
                    <React.Fragment>
                    {/* <a href="javascript:void(0)" className="tour_btn" onClick={() => this.handleModalShowHide()} id="tour_click_btn" >
                        <img tabindex="0" src={require('../../assets/images/tour.svg')} alt="Tour" title="Tour" className="utility-tour" />
                        <span tabindex="0">Tour</span>
                    </a> */}
                    
                      <a href="javascript:void(0)" className="rsvp-nav-link tour_btn"  title="RSVP TO ATTEND"  onClick={() => this.handleModalShowHide()} id="tour_click_btn">RSVP TO ATTEND</a>
                      {
                    <Modal className="Utour-screen schedule-Modal tph-ui-modal" show={this.state.showHide}>
                        <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                        </Modal.Header>
                        <Modal.Body>
                        {stageForm ? 
                            <StageEventRsvp  neigh_id={neigh_id} neigh_name={neigh_name} event={event_info}/> : 
                            <EventRsvp neigh_id={neigh_id} neigh_name={neigh_name} event={event_info} />
                        }
                        </Modal.Body>
                    </Modal>}
                </React.Fragment>:('')
                    
                    // event_info.event_metas.rsvp_options && event_info.event_metas.rsvp_options[0].show_rsvp && event_info.event_metas.event_type == "Future Homeowner Event" || event_info.event_metas.rsvp_options && event_info.event_metas.rsvp_options[0].show_rsvp && event_info.event_metas.event_type == "Resident Event" ? (
                    //     <Link className="rsvp-nav-link" to="rsvp-section" title="RSVP TO ATTEND" duration={750} smooth={true} spy={true}>RSVP TO ATTEND</Link>
                        
                    // ) : ('')
                }
            </div>:''
            }

                </div>
                {event_page!='yes'?
                remove_map === 'no' ?
                    <Modal className="Estimate_modal Request_quote" show={this.state.show === "subscribe_form"} onHide={this.handleClose} >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        {posttype === 'qmi' ? (
                            community_data.post_id != '85782' ? (
                                stageForm ? <StageSidebarSignUp /> :
                                <SidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle? pagetitle:community_data.neigh_title} division_name={community_data.division_name} />
                            ):(
                            stageForm ? <StageSidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.neigh_title} division_name={community_data.division_name}/> :
                            <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.neigh_title} division_name={community_data.division_name} />
                        )) : (
                            community_data.post_id != '85782' ? (
                                stageForm ? <StageSidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name}/> :
                                <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name} />
                        ):(
                            stageForm ? <StageSidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name} post_data ={community_data.post_id}/> :
                            <SidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name} post_data ={community_data.post_id} /> 
                        ))}
                    </Modal.Body>
                </Modal> :
                <Modal className="Estimate_modal Request_quote" show={this.state.show === "subscribe_form"} onHide={this.handleClose} >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    {posttype === 'qmi' ? (
                        community_data.neigh_id === '85782' ? (
                            stageForm ? <StageSidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.neigh_title} division_name={community_data.division_name} /> :
                            <SidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.neigh_title} division_name={community_data.division_name} />
                        ):(
                            stageForm ? <StageSidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.neigh_title} division_name={community_data.division_name}/> :
                        <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.neigh_title} division_name={community_data.division_name} />
                    )) : (
                        community_data.post_id != '85782' ? (
                            stageForm ? <StageSidebarSignUp  sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name}/> :
                            <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name} />
                    ):(stageForm ? <StageSidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name} post_data ={community_data.post_id} /> :
                        <SidebarSignUpNewForm sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={pagetitle?pagetitle:community_data.title} division_name={community_data.division_name} post_data ={community_data.post_id} /> 
                    ))}
                </Modal.Body>
            </Modal>:''
                }
                
                {  event_page!='yes'?
                agent_info.agent_thumbnail ? [
                    <div className="utility-specialist-img divide-utility">
                        <img tabindex="0" src={agent_info.agent_thumbnail} alt="Agent" title="Agent" className='utility-agent-image'/>
                        {posttype === "promotion" ? <div className="pulse mobile-pulse" style={{backgroundColor:promo_title_color}}></div> : <div className="pulse mobile-pulse"></div>}
                    </div>,
                    posttype === 'qmi' ? (
                        <SidebarForm  show_form={show_form}  btn_color={promo_title_color} promo_form ={promo_form} agent_info={community_data.agent_info} post_status={community_data.qmi_status} schedule_button_text={community_data.schedule_button_text} tour_btn={community_data.tour_details} posttype='qmi' community_data={community_data} />
                    ) : (
                            <SidebarForm   show_form={show_form} btn_color={promo_title_color} promo_form ={promo_form} agent_info={community_data.agent_info} post_status={community_data.community_status} tour_btn={community_data.tour_details ? community_data.tour_details : ''} community_data={community_data} posttype={posttype}  promo_popup_color ={promo_title_color} />
                        )
                ] : ['']:''}
            </div>
        );
    }
}

export default UtilityNav; 
