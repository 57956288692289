import React, { useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './QmiLandingCards.scss';

export default function QmiLandingCards (props) {
   var qmi_content = props.qmi_data;
    const [ getCity , setCity] = useState(qmi_content);
    
    //  console.log('getCity',gCity);
    const qmi_options = {
        loop: false,
        nav: true,
        navText: [
            '<div class="sliderArrow arrowLeft"></div>',
            '<div class="sliderArrow arrowRight"></div>'
        ],
        dots: true,
        margin: 0,
        autoplay: false,
        startPosition: 0,
        stagePadding:0,
        responsive: {
            0: {
                items: 1,
                margin: 20,
            },
            667 : {
                items: 2,
            },
            768: {
                items: 2,
            },
            1601: {
                items: 2,
                margin : 46,
            }
            
        }
    }
    var window_width = window.screen.width;  
    return(
   <>
        {getCity.length > 2 ? [
                <div className="qmi-card-section-landing" id="qmi-card-section">
                    <div className="qmi-card">
                    <OwlCarousel {...qmi_options} className="owl-theme">
                        {getCity.map(items => (
                            
                            <div className="card-main-div col-lg-12 col-sm-12">
                                <div className="card-imgs">
                                    <div className="plan-img">
                                        {items.card_image.length > 0 ? (
                                            items.card_image.length === 1 ? (
                                                <Link to={items.post_permalink}>
                                                <div className="overlay">
                                                    <img src={items.card_image[0]} className="image-building-image" alt={items.card_image_title[0]?items.card_image_title[0]:items.card_image_title[1]} title={items.card_image_title[1]} />
                                                    </div>
                                                    </Link>
                                            ) : ('')
                                        ) : ('')}
                                    </div>
                                    <div className="plan-img-info">
                                        {items.promotion_info ? (
                                            items.promotion_info.promo_snipe ? (
                                                <a href={items.promotion_info.promo_link}><span className="card-snipe" tabindex="0" style={{backgroundColor:items.promotion_info.promo_snipe_color}}
                                                onKeyPress={(e)=>{
                                                    if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                                }}>{items.promotion_info.promo_snipe}</span></a>
                                            ) : (
                                                items.promotion_info.promo_icon.url ? (
                                                    <a href={items.promotion_info.promo_link}><span className="promo-icon" tabindex="0"
                                                    onKeyPress={(e)=>{
                                                        if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                                    }}><img src={items.promotion_info.promo_icon.url} alt={items.promotion_info.promo_icon.alt ? items.promotion_info.promo_icon.alt : items.promotion_info.promo_icon.title} title={items.promotion_info.promo_icon.title}/></span></a>
                                                ):('')
                                            )
                                        ) : ('')}
                                        <Link to={items.post_permalink}>
                                        <div className="card-img-info">
                                            {items.post_status ? (
                                                <span tabindex="0" className="card-status">{items.post_status}</span>
                                            ) : ('')}
                                            {items.post_title ? (
                                                <h4 tabindex="0" className="card-name">{items.post_title}</h4>
                                            ) : ('')}
                                            <p tabindex="0" className="card-price">{items.city}
                                            {items.city && items.state ? (
                                                ', '
                                            ):('')}
                                            {items.state}
                                            {(items.city || items.state) && items.price_value && items.post_status !== "Sold" ? (
                                                " | "
                                            ):('')}
                                           {items.price_value && items.post_status !== "Sold" ? (
                                            items.price_value
                                           ) : ('')}
                                           </p>
                                        </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="card-info">
                                    <ul className="card-detail">
                                        {items.square_feet ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.square_feet}</span>
                                                <span tabindex="0" className="info-name">Sq.{'\u00A0'}Footage</span>
                                            </li>
                                        ) : ('')}
                                        {items.bed ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.bed}</span>
                                                <span tabindex="0" className="info-name">
                                                    {items.bed === '1' ? ('Bed') : ('Beds')}
                                                </span>
                                            </li>
                                        ) : ('')}
                                        {items.bath ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.bath}</span>
                                                <span tabindex="0" className="info-name">
                                                    {items.bath === '1' ? ('Bath') : ('Baths')}
                                                </span>
                                            </li>
                                        ) : ('')}
                                        {items.stories ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.stories}</span>
                                                <span tabindex="0" className="info-name">
                                                    {items.stories === '1' ? (' Story') : (' Stories')}
                                                </span>
                                            </li>
                                        ) : ('')}
                                    </ul>
                                </div>
                               
                            </div>
                           
                        ))}
                    </OwlCarousel>
                    </div>
                </div>
            ] : [
                window_width < 767 && getCity.length == 2 ?
                <div className="qmi-card-section-landing" id="qmi-card-section">
                    <div className="qmi-card">
                    <OwlCarousel {...qmi_options} className="owl-theme">
                        {getCity.map(items => (
                            
                            <div className="card-main-div col-lg-12 col-sm-12">
                                <div className="card-imgs">
                                    <div className="plan-img">
                                        {items.card_image.length > 0 ? (
                                            items.card_image.length === 1 ? (
                                                <Link to={items.post_permalink}>
                                                <div className="overlay">
                                                    <img src={items.card_image[0]} className="image-building-image" alt={items.card_image_title[0]?items.card_image_title[0]:items.card_image_title[1]} title={items.card_image_title[1]} />
                                                    </div>
                                                    </Link>
                                            ) : ('')
                                        ) : ('')}
                                    </div>
                                    <div className="plan-img-info">
                                        {items.promotion_info ? (
                                            items.promotion_info.promo_snipe ? (
                                                <a href={items.promotion_info.promo_link}><span className="card-snipe" tabindex="0" style={{backgroundColor:items.promotion_info.promo_snipe_color}}
                                                onKeyPress={(e)=>{
                                                    if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                                }}>{items.promotion_info.promo_snipe}</span></a>
                                            ) : (
                                                items.promotion_info.promo_icon.url ? (
                                                    <a href={items.promotion_info.promo_link}><span className="promo-icon" tabindex="0"
                                                    onKeyPress={(e)=>{
                                                        if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                                    }}><img src={items.promotion_info.promo_icon.url} alt={items.promotion_info.promo_icon.alt ? items.promotion_info.promo_icon.alt : items.promotion_info.promo_icon.title} title={items.promotion_info.promo_icon.title}/></span></a>
                                                ):('')
                                            )
                                        ) : ('')}
                                        <Link to={items.post_permalink}>
                                        <div className="card-img-info">
                                            {items.post_status ? (
                                                <span tabindex="0" className="card-status">{items.post_status}</span>
                                            ) : ('')}
                                            {items.post_title ? (
                                                <h4 tabindex="0" className="card-name">{items.post_title}</h4>
                                            ) : ('')}
                                            <p tabindex="0" className="card-price">{items.city}
                                            {items.city && items.state ? (
                                                ', '
                                            ):('')}
                                            {items.state}
                                            {(items.city || items.state) && items.price_value && items.post_status !== "Sold" ? (
                                                " | "
                                            ):('')}
                                           {items.price_value && items.post_status !== "Sold" ? (
                                            items.price_value
                                           ) : ('')}
                                           </p>
                                        </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="card-info">
                                    <ul className="card-detail">
                                        {items.square_feet ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.square_feet}</span>
                                                <span tabindex="0" className="info-name">Sq.{'\u00A0'}Footage</span>
                                            </li>
                                        ) : ('')}
                                        {items.bed ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.bed}</span>
                                                <span tabindex="0" className="info-name">
                                                    {items.bed === '1' ? ('Bed') : ('Beds')}
                                                </span>
                                            </li>
                                        ) : ('')}
                                        {items.bath ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.bath}</span>
                                                <span tabindex="0" className="info-name">
                                                    {items.bath === '1' ? ('Bath') : ('Baths')}
                                                </span>
                                            </li>
                                        ) : ('')}
                                        {items.stories ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.stories}</span>
                                                <span tabindex="0" className="info-name">
                                                    {items.stories === '1' ? (' Story') : (' Stories')}
                                                </span>
                                            </li>
                                        ) : ('')}
                                    </ul>
                                </div>
                               
                            </div>
                           
                        ))}
                    </OwlCarousel>
                    </div>
                    </div>
                    : <div className="qmi-card-section-landing" id="qmi-card-section">
                    <div className="qmi-card">
                        {getCity.map(items => (
                            
                            <div className="card-main-div col-lg-6 col-sm-6">
                                <div className="card-imgs">
                                    <div className="plan-img">
                                        {items.card_image.length > 0 ? (
                                            items.card_image.length === 1 ? (
                                                <Link to={items.post_permalink}>
                                                <div className="overlay">
                                                    <img src={items.card_image[0]} className="image-building-image" alt={items.card_image_title[0]?items.card_image_title[0]:items.card_image_title[1]} title={items.card_image_title[1]} />
                                                    </div>
                                                    </Link>
                                            ) : ('')
                                        ) : ('')}
                                    </div>
                                    <div className="plan-img-info">
                                        {items.promotion_info ? (
                                            items.promotion_info.promo_snipe ? (
                                                <a href={items.promotion_info.promo_link}><span className="card-snipe" tabindex="0" style={{backgroundColor:items.promotion_info.promo_snipe_color}}
                                                onKeyPress={(e)=>{
                                                    if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                                }}>{items.promotion_info.promo_snipe}</span></a>
                                            ) : (
                                                items.promotion_info.promo_icon.url ? (
                                                    <a href={items.promotion_info.promo_link}><span className="promo-icon" tabindex="0"
                                                    onKeyPress={(e)=>{
                                                        if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                                    }}><img src={items.promotion_info.promo_icon.url} alt={items.promotion_info.promo_icon.alt ? items.promotion_info.promo_icon.alt : items.promotion_info.promo_icon.title} title={items.promotion_info.promo_icon.title}/></span></a>
                                                ):('')
                                            )
                                        ) : ('')}
                                        <Link to={items.post_permalink}>
                                        <div className="card-img-info">
                                            {items.post_status ? (
                                                <span tabindex="0" className="card-status">{items.post_status}</span>
                                            ) : ('')}
                                            {items.post_title ? (
                                                <h4 tabindex="0" className="card-name">{items.post_title}</h4>
                                            ) : ('')}
                                            <p tabindex="0" className="card-price">{items.city}
                                            {items.city && items.state ? (
                                                ', '
                                            ):('')}
                                            {items.state}
                                            {(items.city || items.state) && items.price_value && items.post_status !== "Sold" ? (
                                                " | "
                                            ):('')}
                                           {items.price_value && items.post_status !== "Sold" ? (
                                            items.price_value
                                           ) : ('')}
                                           </p>
                                        </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="card-info">
                                    <ul className="card-detail">
                                        {items.square_feet ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.square_feet}</span>
                                                <span tabindex="0" className="info-name">Sq.{'\u00A0'}Footage</span>
                                            </li>
                                        ) : ('')}
                                        {items.bed ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.bed}</span>
                                                <span tabindex="0" className="info-name">
                                                    {items.bed === '1' ? ('Bed') : ('Beds')}
                                                </span>
                                            </li>
                                        ) : ('')}
                                        {items.bath ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.bath}</span>
                                                <span tabindex="0" className="info-name">
                                                    {items.bath === '1' ? ('Bath') : ('Baths')}
                                                </span>
                                            </li>
                                        ) : ('')}
                                        {items.stories ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.stories}</span>
                                                <span tabindex="0" className="info-name">
                                                    {items.stories === '1' ? (' Story') : (' Stories')}
                                                </span>
                                            </li>
                                        ) : ('')}
                                    </ul>
                                </div>    
                            </div>
                           
                        ))}
                    </div>
                </div>
            ]}
    </>
    )
}