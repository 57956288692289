import React from 'react';
import axios from 'axios';
import { apiConfig } from './Helper.jsx';
import './HomeBanner.scss';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import AutocompleteContainer from '../Autocomplete/AutocompleteContainer.jsx';

class HomeBanner extends React.Component {
    componentDidMount() {
        if (window.screen.width <= 1024 && $('.youtube_iframe').length > 0) {
          this.loadVideo();
        }
    }

    loadVideo = () => {
        this.player = new window.YT.Player('video-box', {
            events: {
                onReady: this.onPlayerReady,
            },
        });
    };

    onPlayerReady = event => {
        event.target.playVideo();
    };
   
    render() {
        var banner_data = this.props.banner_data;
        var background_image;
        var backgroundImage = banner_data.banner_image.url;
        var mobilebackgroundImage = banner_data.banner_mobile_image.url
        if (banner_data.background_type !== "video") {
            if (backgroundImage !== "") {
                background_image = {
                    backgroundImage: 'url(' + backgroundImage + ')',
                }
            }
        } else if (banner_data.background_type == "video" && window.screen.width <= 1024) {
            if (mobilebackgroundImage !== "") {
                background_image = {
                    backgroundImage: 'url(' + mobilebackgroundImage + ')',
                }
            }
        }

        return (
            <div role="search" className="banner_section">
                <div className="bannerSection">
                    <div className="bannerSectionWrapper d-flex" style={background_image}>
                        <div className="tw-bg-gradient-radial tw-min-h-screen tw-w-full">
                        {banner_data.background_type === "video" && window.screen.width > 1024 ? (
                            banner_data.banner_video ? (
                                <video id="video_background" className="video-box banner_video lazy" preload="auto" playsinline autoplay="true" loop="loop" muted data-src={banner_data.banner_video}></video>
                            ) : ('')
                        ) : ('')}
                        </div>
                    </div>
                    <div className="bannerBtnWrapper tw-absolute tw-top-[35%] tw-left-0 tw-right-0 tw-mx-auto tw-w-[90%] tw-max-w-[780px]">
                        <div className="headerForm">
                            <div id="headerFormMain">
                                <div className="formgroup">
                                    <h1 className="tw-text-white tw-text-2xl lg:tw-text-3xl tw-font-bold tw-text-center tw-mb-4">Where do you want to live?</h1>
                                    <AutocompleteContainer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HomeBanner;