import React from 'react';
import axios from 'axios';
import './Footer.scss';
import {apiConfig} from '../Classes/Helper.jsx';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import callTrkSwap from '../../helpers/callTrkSwap.js';

class Footer extends React.Component {  

   state ={
      footer_info : [],
      success : false,
      is_visible: false
   }
   
   componentDidMount(){
      axios.get(apiConfig().API_URL+'/footer_info')
      .then(response => {
         this.setState({footer_info : response.data.data,success: response.data.success})
      })

      callTrkSwap();

      var scrollComponent = this;
      document.addEventListener("scroll", function(e) {
        scrollComponent.toggleVisibility();
      });
   }
   toggleVisibility(){
      if (window.pageYOffset > 300) {
        this.setState({
          is_visible: true
        });
      } else {
        this.setState({
          is_visible: false
        });
      }
    }
    socialShare_data_layer_push(e) {
      if(e.target.className==="fa fa-youtube-play") {
        window.dataLayer.push({
          'event':'socialShare',
          'socialName': 'Social - YouTube'
      });
      }
      if(e.target.className==="fa fa-instagram") {
        window.dataLayer.push({
          'event':'socialShare',
          'socialName': 'Social - Instagram'
        });
      }
      if(e.target.className==="fa fa-linkedin") {
        window.dataLayer.push({
          'event':'socialShare',
          'socialName': 'Social - LinkedIn'
        });
      }
      if(e.target.className==="fa fa-facebook-square") {
        window.dataLayer.push({
          'event':'socialShare',
          'socialName': 'Social - Facebook'
        });
      }
   }
   componentDidUpdate(){
       callTrkSwap();
    }
    scrollToTop() {
      window.scrollTo({
         top: 0,
         behavior: "smooth"
       });
    }

    render() {    
       var success = this.state.success; 
       var footer_details = this.state.footer_info;
       var is_visible = this.state.is_visible;
       var visible = {
         opacity: is_visible ? "1" : "0"
      };
       return (
         <>
         <div className="scroll-bar" role="complementary" aria-label="Secondary" class="top-scroll" onClick={() => { this.scrollToTop(); }} style={visible}
           onKeyPress={(e) => {
             if (e.keyCode === 13 || e.which === 13) e.currentTarget.click();
           }}>
           <i class="fa fa-angle-double-up" aria-hidden="true" tabindex="0"></i><p tabindex="0">TOP</p></div>
            <div role="contentinfo" className="footer_section">
            {
               success === true ? [
                  <div className="footer-top" > 
                     <div className="footer-social">
                           <div className="life-group-logo">
                              <img tabindex="0" src={require('../../assets/images/life-inspiring.png')} title="Life-changing by Design logo" alt="Life-changing by Design logo"/>
                           </div>
                           <div className="socialContent">
                              <ul>
                                 {
                                    footer_details.footer_menu.map(items => (
                                       <li><span><a href={items.url}>{items.title}</a></span></li>
                                    ))
                                 }
                              </ul>
                           </div>
                        </div>  
                  </div>,
                  <div className="footer-middle">
                     <div className="footer-logo" >
                        <div className="social_links">
                           {
                              footer_details.social_menu.map(items => (
                                <a href={items.url} rel="noopener noreferrer" target="_blank" title="social media icon"  onClick={(e) => this.socialShare_data_layer_push(e)}><i className={"fa "+items.classes[0]} aria-hidden="true"></i><span class="visually-hidden">{items.title}</span></a>
                              ))
                           }
                        </div>
                     </div>  
                  </div>,
                  <div className="footer-lower" > 
                     <div className="footer-copyright">
                        <div className="copyrightLinks-div">
                           <div tabindex="0" className="copyrightCaption">
                              <p>© {new Date().getFullYear()} Tri Pointe Homes, Inc. All rights reserved.</p>
                           </div>
                           <div className="copyrightLinks">
                              <ul>
                                 {
                                    footer_details.copyright_menu.map(items => (
                                       <li><a href={items.url} target="_blank" rel="noopener noreferrer">{items.title}</a></li>
                                    ))
                                 }												                           
                              </ul>
                              <span>
                                 <img tabindex="0" src={require('../../assets/images/copyIcon.png')} title="copyIcon" alt="copyIcon" />
                              </span>
                           </div>
                        </div>
                        <div tabindex="0" className="copyright-text" dangerouslySetInnerHTML={{__html: footer_details.footer_disclaimer.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                     </div>  
                  </div>
               ]:[
                  <SkeletonTheme>
                     <div className="footer-top">
                        <div className="footer-social">
                           <div className="life-group-logo">
                              <Skeleton height={80} width={200}/>
                           </div>
                           <div className="socialContent">
                              <ul>
                                 <li><Skeleton width={100} /></li>
                                 <li><Skeleton width={100} /></li>
                                 <li><Skeleton width={100} /></li>
                                 <li><Skeleton width={100} /></li>
                                 <li><Skeleton width={100} /></li>
                                 <li><Skeleton width={100} /></li>
                              </ul>
                           </div>
                        </div>
                     </div>,
                     <div className="footer-middle">
                        <div className="footer-logo">
                           <div className="social_links">
                        <Skeleton width={50} height={50}/> &nbsp;
                        <Skeleton width={50} height={50}/> &nbsp;
                        <Skeleton width={50} height={50}/> &nbsp;
                        <Skeleton width={50} height={50}/> &nbsp;
                           </div>
                        </div>
                     </div>,
                     <div className="footer-lower">
                        <div className="footer-copyright">
                           <div className="copyrightLinks-div">
                              <div className="copyrightCaption">
                              <Skeleton width={200}/>
                              </div>
                              <div className="copyrightLinks">
                              <ul>
                                 <li><Skeleton width={100} /></li>
                                 <li><Skeleton width={100} /></li>
                                 <li><Skeleton width={100} /></li>
                                 <li><Skeleton width={100} /></li>
                                 <li><Skeleton width={100} /></li>
                              </ul> 
                              </div>
                           </div>
                           <Skeleton height={200} className = "copyright-text" />
                        </div>
                     </div>
                  </SkeletonTheme>
               ]
            }
            </div>   
         </>      
       );  
    }  
  }  
  
  export default Footer;
